import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Input } from 'sonoma-design-system';

function MoreGourmetInfoFields({ form, handleFormChange }) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 30,
    },
  }));

  const classes = useStyles();

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={3}>
            <Input
              inputId='gou_lt_ingredientes'
              value={form?.gou_lt_ingredientes}
              handleChange={handleFormChange}
              size='small'
              label='Ingredientes'
              fullWidth
              helperText='Ingredientes que compõe o queijo'
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='gou_lt_informacoesnutri'
              value={form?.gou_lt_informacoesnutri}
              handleChange={handleFormChange}
              size='small'
              label='Informações Nutricionais'
              fullWidth
              helperText='Infomações nutricionais do queijo'
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='gou_lt_alergicos'
              value={form?.gou_lt_alergicos}
              handleChange={handleFormChange}
              size='small'
              label='Alérgicos'
              fullWidth
              helperText='Conteúdo alérgico do queijo'
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='gou_lt_armazenamento'
              value={form?.gou_lt_armazenamento}
              handleChange={handleFormChange}
              size='small'
              label='Armazenamento'
              fullWidth
              helperText='Armazenamento do queijo'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MoreGourmetInfoFields.propTypes = {
  form: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func.isRequired,
};

export default MoreGourmetInfoFields;
