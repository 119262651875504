const INITIAL_STATE = {
  loading: false,
  error: false,
  filial: null,
  from: null,
  to: null,
  produtos: [],
  produtosModified: [],
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order: 'asc',
    order_by: 'updatedAt',
    availableOrderBy: ['ped_st_numero', 'ped_st_embarque', 'updatedAt'],
  },
  filters: {
    ref: '',
  },
};

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const setFrom = (state, action) => ({ ...state, from: action.payload });

const setTo = (state, action) => ({ ...state, to: action.payload });

const setProdutos = (state, action) => {
  // Alterando o item do array caso ele ja esteja modificado
  const newState = action.payload.map((e) => {
    // checando se produto ja existe no array de produtosModified
    if (
      state.produtosModified.find((el) => e.pro_in_codigo === el.pro_in_codigo)
    ) {
      // se existir retorno o modificado na lista de produtos
      return state.produtosModified.find(
        (el) => e.pro_in_codigo === el.pro_in_codigo
      );
    }
    // se nao retorno o padrao
    return e;
  });

  return { ...state, produtos: newState };
};

const setPaging = (state, action) => ({
  ...state,
  paging: {
    ...state.paging,
    ...action.payload,
  },
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: {
    ...state.ordering,
    ...action.payload,
  },
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setBulkEdit = (state, action) => {
  // Alterando o item do array
  const newState = state.produtos.map((e) => {
    if (e.pro_in_codigo === action.payload.pro_in_codigo) {
      return action.payload;
    }
    return e;
  });

  // verificando se o item modificado ja foi modificado alguma vez
  const produtoAlreadyModified = state.produtosModified.find(
    (e) => e.pro_in_codigo === action.payload.pro_in_codigo
  );

  // se o item ja foi modificado eu adiciono ele ao ja existentes, se nao adiciono apenas ele ao array
  const newProdutosModified = produtoAlreadyModified
    ? state.produtosModified.map((e) => {
        if (e.pro_in_codigo === action.payload.pro_in_codigo) {
          return action.payload;
        }
        return e;
      })
    : [...state.produtosModified, action.payload];

  // modificando o estado
  return {
    ...state,
    produtos: newState,
    produtosModified: newProdutosModified,
  };
};

export default function pedidosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@editBulkProdutos/setLoading':
      return setLoading(state, action);
    case '@editBulkProdutos/setError':
      return setError(state, action);
    case '@editBulkProdutos/setFrom':
      return setFrom(state, action);
    case '@editBulkProdutos/setTo':
      return setTo(state, action);
    case '@editBulkProdutos/setPaging':
      return setPaging(state, action);
    case '@editBulkProdutos/setOrdering':
      return setOrdering(state, action);
    case '@editBulkProdutos/setFilters':
      return setFilters(state, action);
    case '@editBulkProdutos/setProdutos':
      return setProdutos(state, action);
    case '@editBulkProdutos/setBulkEdit':
      return setBulkEdit(state, action);
    case '@editBulkProdutos/clear':
      return INITIAL_STATE;
    default:
      return state;
  }
}
