/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';

import { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { format } from 'date-fns';

import { Skeleton } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import { MdAddCircle, MdDelete, MdEdit } from 'react-icons/md';
import { Table, DropdownButton, Options } from 'sonoma-design-system';
import ReviewProductModal from '../../components/ReviewProductModal';

import {
  ButtonContent,
  CenterColumn,
  Error,
  Section,
  ProductCard,
  ProductDescription,
  ProductCol,
} from './styles';

import { fetchAll, clear } from '../../store/productsList';

import { getToken, getUser, getUserRoles } from '../../auth/auth';
import { insertIf, containsAny } from '../../utils/arrays';
import { deleteProduct } from '../../services/ProductService';

import defaultWineImg from '../../assets/default-wine.png';

function DateColumn({ item }) {
  return (
    <CenterColumn>
      {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

function ButtonAdd() {
  return (
    <DropdownButton
      size='sm'
      as={Link}
      options={[
        { description: 'Vinhos', link: '/publisher/produtos/vinho/create' },
      ]}
    >
      <ButtonContent>
        Adicionar <MdAddCircle size={21} />
      </ButtonContent>
    </DropdownButton>
  );
}

function Actions({ item, setOpen, setItem }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const token = useMemo(() => getToken(), []);

  const user = useMemo(() => token && getUser(), [token]);
  const isAuthenticated = useMemo(() => token && user, [token, user]);

  const paging = useSelector((state) => state.product.list.paging);
  const ordering = useSelector((state) => state.product.list.ordering);
  const filters = useSelector((state) => state.product.list.filters);

  const roles = useMemo(
    () => (isAuthenticated ? getUserRoles() : []),
    [isAuthenticated]
  );

  const isWriter = useMemo(
    () => containsAny(roles, ['ADMIN', 'WRITER']),
    [roles]
  );

  const isInsider = useMemo(
    () => containsAny(roles, ['ADMIN', 'INSIDER']),
    [roles]
  );

  const handleRedirectToReviewProduct = useCallback(() => {
    if (item.vinho) {
      history.push(`/produtos/${item.pro_in_codigo}/vinhos/review`);
      return;
    }

    // if (item.queijo) {
    //   history.push(`/produtos/${item.pro_in_codigo}/queijo/edit`);
    //   return;
    // }

    // if (item.gourmet) {
    //   history.push(`/produtos/${item.pro_in_codigo}/gourmet/edit`);
    //   return;
    // }

    setOpen(true);
    setItem(item);
  }, [history, item, setOpen, setItem]);

  const handleRedirectToEditProduct = useCallback(() => {
    if (item.vinho)
      history.push(`/publisher/produtos/${item.pro_in_codigo}/vinho/edit`);

    // if (item.queijo)
    //   history.push(`/produtos/${item.pro_in_codigo}/queijo/edit`);

    // if (item.gourmet)
    //   history.push(`/produtos/${item.pro_in_codigo}/gourmet/edit`);
  }, [history, item]);

  const handleDeleteProduto = useCallback(async () => {
    await deleteProduct(item.pro_in_codigo);
    dispatch(
      fetchAll({ page: paging.page, limit: paging.limit }, ordering, filters)
    );
  }, [
    dispatch,
    filters,
    item.pro_in_codigo,
    ordering,
    paging.limit,
    paging.page,
  ]);

  const options = useMemo(
    () => [
      ...insertIf(isWriter, [
        {
          icon: MdEdit,
          text: 'Editar',
          onClick: handleRedirectToEditProduct,
        },
      ]),
      ...insertIf(isInsider, [
        {
          icon: MdEdit,
          text: 'Revisar',
          onClick: handleRedirectToReviewProduct,
        },
      ]),
      {
        icon: MdDelete,
        text: 'Excluir',
        onClick: handleDeleteProduto,
      },
    ],
    [
      isWriter,
      handleRedirectToEditProduct,
      isInsider,
      handleRedirectToReviewProduct,
      handleDeleteProduto,
    ]
  );

  return (
    <td style={{ textAlign: 'center' }}>
      <Options options={options} />
    </td>
  );
}

Actions.propTypes = {
  item: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
};

function Product({ item }) {
  return (
    <ProductCol style={{ maxWidth: '270px' }}>
      <ProductCard>
        <Avatar
          variant='square'
          style={{
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #E0E0E0',
            height: '48px',
            width: '48px',
          }}
          src={item.image_firebase_url || defaultWineImg}
          alt={item.pro_st_descricao}
        />

        <div>
          <ProductDescription title={item.pro_st_descricao}>
            {(item?.pro_st_descricao.length > 35
              ? item?.pro_st_descricao.substring(0, 35).concat('...')
              : item?.pro_st_descricao) || '-'}
          </ProductDescription>
        </div>
      </ProductCard>
    </ProductCol>
  );
}

Product.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function ProductsReviewList() {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const products = useSelector((state) => state.product.list.products);
  const loading = useSelector((state) => state.product.list.loading);
  const error = useSelector((state) => state.product.list.error);

  const paging = useSelector((state) => state.product.list.paging);
  const ordering = useSelector((state) => state.product.list.ordering);
  const filters = useSelector((state) => state.product.list.filters);

  useEffect(() => {
    dispatch(fetchAll(undefined, undefined, { ref: '', reviewed: false }));

    return () => dispatch(clear());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const cols = useMemo(
    () => [
      { id: 'pro_st_descricao', title: 'Produto', component: Product },
      { id: 'pro_st_sku', title: 'SKU' },
      { id: 'createdAt', title: 'Data de criação', component: DateColumn },
      {
        id: 'action',
        title: 'Opções',
        component: (props) => (
          <Actions setOpen={setOpen} setItem={setItem} {...props} />
        ),
      },
    ],
    []
  );

  function goToWineReview() {
    history.push(`/produtos/${item.pro_in_codigo}/vinhos/review`);
  }

  function goToCheeseReview() {
    history.push(`/produtos/${item.pro_in_codigo}/queijo/review`);
  }

  function goToGourmetReview() {
    history.push(`/produtos/${item.pro_in_codigo}/gourmet/review`);
  }

  return (
    <Section style={{ marginTop: '130px' }}>
      {/* <Title>Produtos aguardando revisão</Title> */}

      {loading && <Skeleton animation='wave' height={300} />}

      {error && <Error>Tivemos um problema ao buscar os produtos.</Error>}

      {!loading && !error && (
        <Table
          headerTitle='Review de produtos'
          refValue={filters.ref}
          rows={products}
          columns={cols}
          ordering={ordering}
          onChangeOrder={(order) => dispatch(fetchAll(paging, order, filters))}
          paging={paging}
          onChangePage={(page) =>
            dispatch(fetchAll({ page, limit: paging.limit }, ordering, filters))
          }
          onSearch={(ref) =>
            dispatch(
              fetchAll(
                { page: 1, limit: paging.limit },
                { order_by: 'pro_st_descricao', order: 'asc' },
                { ...filters, ref }
              )
            )
          }
          searchPlaceholder='Buscar por Nome ou SKU'
          headerComponent={() => <ButtonAdd />}
        />
      )}

      {open && (
        <ReviewProductModal
          open={open}
          setOpen={setOpen}
          onSelectWine={goToWineReview}
          onSelectCheese={goToCheeseReview}
          onSelectGourmet={goToGourmetReview}
        />
      )}
    </Section>
  );
}
