import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import { MdAddCircle } from 'react-icons/md';
import { Table, Button } from 'sonoma-design-system';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAll } from '../../store/curador';

import defaultWineImg from '../../assets/default-wine.png';

import {
  ProductCard,
  ProductCol,
  ProductDescription,
  ButtonContent,
} from './styles';

function NameAndImage({ item }) {
  return (
    <ProductCol>
      <ProductCard>
        <Avatar
          variant='square'
          style={{
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #E0E0E0',
            height: '48px',
            width: '48px',
          }}
          src={item.image_firebase_url || defaultWineImg}
          alt={item.cur_st_nome}
        />

        <div>
          <ProductDescription title={item.cur_st_nome}>
            {item.cur_st_nome}
          </ProductDescription>
        </div>
      </ProductCard>
    </ProductCol>
  );
}

NameAndImage.propTypes = {
  item: PropTypes.object.isRequired,
};

function ButtonAdd() {
  const history = useHistory();

  return (
    <Button size='sm' onClick={() => history.push('/create/curador')}>
      <ButtonContent>
        Adicionar <MdAddCircle size={21} />
      </ButtonContent>
    </Button>
  );
}

export default function CuradorList() {
  const dispatch = useDispatch();

  const curadores = useSelector((state) => state.curador.curadores);
  const paging = useSelector((state) => state.curador.paging);
  const ordering = useSelector((state) => state.curador.ordering);
  const filters = useSelector((state) => state.curador.filters);
  const loading = useSelector((state) => state.curador.loading);

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch]);

  const cols = useMemo(
    () => [
      { id: 'cur_st_nome', title: 'Nome', component: NameAndImage },
      { id: 'cur_st_sobre', title: 'Sobre' },
      { id: 'cur_st_link_externo', title: 'Link' },
      { id: 'slr_in_codigo', title: 'VTEX Loja' },
      { id: 'fil_in_codigo', title: 'MLN Filial' },
    ],
    []
  );

  if (loading) {
    return (
      <Skeleton animation='wave' height={300} style={{ marginTop: '130px' }} />
    );
  }

  return (
    <div
      style={{
        marginTop: '130px',
        marginBottom: '30px',
        backgroundColor: 'white',
        padding: '15px 15px',
      }}
    >
      <Table
        headerTitle='Lista de Curadores'
        refValue={filters.ref}
        rows={curadores}
        columns={cols.filter((c) => c)}
        ordering={ordering}
        onChangeOrder={(order) => dispatch(fetchAll(paging, order, filters))}
        paging={paging}
        onChangePage={(page) =>
          dispatch(fetchAll({ page, limit: paging.limit }, ordering, filters))
        }
        onSearch={(ref) =>
          dispatch(
            fetchAll(
              { page: 1, limit: paging.limit },
              { order_by: 'pro_st_descricao', order: 'asc' },
              { ref }
            )
          )
        }
        searchPlaceholder='Buscar por nome'
        headerComponent={() => <ButtonAdd />}
      />
    </div>
  );
}
