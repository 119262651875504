import { toast } from 'react-toastify';
import { format } from 'date-fns';
import api from './api';
import { getUser, getToken } from '../auth/auth';

const token = getToken();

export async function getFaturamentoByFilial(
  filtering = {
    canFilterByFilial: false,
    filteringByFilial: false,
    emails: [],
  },
  dtInicial,
  dtFinal,
  socketId,
  generatePdf
) {
  try {
    const user = getUser();

    const { canFilterByFilial, filteringByFilial, emails } = filtering;

    const response = await api.post(
      `filiais/${filteringByFilial || user.fil_in_codigo}/billing/xmls`,
      {
        socketId,
        dt_inicial: format(dtInicial, 'yyyy-MM-dd').concat('-00-00-00'),
        dt_final: format(dtFinal, 'yyyy-MM-dd').concat('-23-59-59'),
        sendEmail: true,
        email:
          canFilterByFilial && filteringByFilial
            ? emails.filter((e) => e)
            : [user.usu_st_email],
        generatePdf,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (err) {
    toast.error('Falha ao iniciar processamento de notas fiscais');
    throw err;
  }
}

export async function findAll(
  paging = { page: 1, limit: 'all' },
  ordering,
  filters
) {
  try {
    const response = await api.get(`filiais`, {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar filiais');

    throw e;
  }
}

export async function findById(id) {
  try {
    const response = await api.get(`filiais/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar filial');

    throw e;
  }
}

export async function updateFilial(filial) {
  try {
    const result = await api.put(
      `filiais/${filial.fil_in_codigo}`,
      { filial },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Alteração feita com sucesso');
    return result;
  } catch (e) {
    toast.error('Erro ao concluir alteração');

    throw e;
  }
}
