import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, makeStyles, Avatar } from '@material-ui/core';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Input, Select, Button, DragAndDrop } from 'sonoma-design-system';

import dragndropIcon from '../../assets/dragndrop-icon.svg';

import Divider from '../../components/Divider';
import { LoadingSpinner } from '../../layout/LoadingSpinner';
import { ButtonDescription } from '../../styles';

import { create } from '../../services/curadorService';
import { findAll as fetchAllBranches } from '../../services/FilialService';
import { findAll as fetchAllSellers } from '../../services/SellerService';

import {
  Title,
  ImagesContainer,
  Footer,
  BackButtonsContainer,
  InputContainer,
} from './styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: '15px',
  },
  row: {
    marginBottom: 15,
  },
}));

export default function CreateCurador() {
  const classes = useStyles();
  const history = useHistory();

  const [nome, setNome] = useState('');
  const [sobre, setSobre] = useState('');
  const [link, setLink] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filialBelongs, setFilialBelongs] = useState(null);
  const [branches, setBranches] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [errors, setErrors] = useState({});
  const [branch, setBranch] = useState(null);
  const [seller, setSeller] = useState(null);

  useEffect(() => {
    async function fetchBranches() {
      const { data } = await fetchAllBranches();

      setBranches(
        data.map((b) => ({
          label: b.fil_st_nome,
          value: b.fil_in_codigo,
        }))
      );
    }

    async function fetchSellers() {
      const { data } = await fetchAllSellers();

      setSellers(
        data.map((s) => ({
          label: s.slr_st_descricao,
          value: s.slr_in_codigo,
        }))
      );
    }

    fetchBranches();
    fetchSellers();
  }, []);

  const handleDrop = useCallback(
    (files) => {
      const newImages = [];
      const permittedImagesExtensions = ['.jpg', '.jpeg'];

      if (files.length > 1) {
        toast.warning('É permitido somente uma imagem');
        return;
      }

      for (let i = 0; i < files.length; i += 1) {
        if (permittedImagesExtensions.some((e) => files[i].path.includes(e))) {
          newImages.push(
            Object.assign(files[i], {
              preview: URL.createObjectURL(files[i]),
              index: uuidv4(),
            })
          );
        } else {
          toast.warning(
            'Fomato inválido. Por favor, utilize apenas imagens .jpg e .jpeg'
          );
        }
      }

      setImages(newImages);
    },
    [setImages]
  );

  function handleBelongs(e) {
    setBranch(null);
    setSeller(null);
    setFilialBelongs(e.target.value);
  }

  const handleSubmit = useCallback(async () => {
    // validation
    let newErrors = {};

    if (!nome || nome.length === 0) {
      newErrors = { ...newErrors, nome: 'Preencha o nome' };
    }

    if (!sobre || sobre.length === 0) {
      newErrors = { ...newErrors, sobre: 'Preencha o sobre' };
    }

    if (!images || images.length === 0) {
      newErrors = {
        ...newErrors,
        images: 'Adicione uma imagem',
      };
      toast.warning('Adicione uma imagem');
    }

    if (!filialBelongs || filialBelongs.length === 0) {
      newErrors = {
        ...newErrors,
        filialBelongs: 'Preencha a qual filial o curador pertence',
      };
    }

    if (filialBelongs === 'VTEX' && !seller) {
      newErrors = {
        ...newErrors,
        seller: 'Preencha a qual seller o curador pertence',
      };
    }

    if (filialBelongs === 'Millennium' && !branch) {
      newErrors = {
        ...newErrors,
        branch: 'Preencha a qual filial o curador pertence',
      };
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    setLoading(true);
    await create(
      {
        cur_st_nome: nome,
        cur_st_sobre: sobre,
        cur_st_link_externo: link,
        fil_in_codigo: branch,
        slr_in_codigo: seller,
      },
      images
    );
    setLoading(false);
    history.push('/curadores');
  }, [branch, filialBelongs, history, images, link, nome, seller, sobre]);

  return (
    <div
      style={{
        marginTop: '130px',
        marginBottom: '30px',
        backgroundColor: 'white',
        padding: '15px 15px',
      }}
    >
      <Title>Cadastro de Curador</Title>
      <Grid spacing={2} container className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={6} lg={6}>
              <Input
                inputId='name'
                size='small'
                label='*Nome'
                error={errors.nome}
                errorText={errors.nome}
                value={nome}
                handleChange={(e) => setNome(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Input
                inputId='link'
                size='small'
                label='Link externo'
                value={link}
                handleChange={(e) => setLink(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <Input
                inputId='sobre'
                size='small'
                label='*Sobre'
                error={errors.sobre}
                errorText={errors.sobre}
                value={sobre}
                handleChange={(e) => setSobre(e.target.value)}
                fullWidth
                textArea
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={6} lg={6}>
              <InputContainer>
                <Select
                  selectId='branch'
                  size='small'
                  label='*Filial pertencente'
                  error={errors.filialBelongs}
                  errorText={errors.filialBelongs}
                  value={filialBelongs}
                  options={[
                    { value: null, label: 'Selecione' },
                    { value: 'VTEX', label: 'VTEX' },
                    { value: 'Millennium', label: 'Millennium' },
                  ]}
                  handleChange={(e) => handleBelongs(e)}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={6} lg={6}>
              <InputContainer>
                {filialBelongs === 'Millennium' && (
                  <Select
                    selectId='branch'
                    size='small'
                    label='*Filial'
                    error={errors.branch}
                    errorText={errors.branch}
                    value={branch}
                    options={[{ value: null, label: 'Selecione' }, ...branches]}
                    handleChange={(e) => setBranch(e.target.value)}
                  />
                )}
                {filialBelongs === 'VTEX' && (
                  <Select
                    selectId='seller'
                    size='small'
                    label='*Seller'
                    error={errors.seller}
                    errorText={errors.seller}
                    value={seller}
                    options={[{ value: null, label: 'Selecione' }, ...sellers]}
                    handleChange={(e) => setSeller(e.target.value)}
                  />
                )}
              </InputContainer>
            </Grid>
          </Grid>
          <Grid spacing={2} container className={classes.row}>
            <Grid
              item
              xs={12}
              lg={12}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DragAndDrop onDrop={handleDrop} icon={dragndropIcon} isButton />

              <ImagesContainer>
                {images?.map((file) => (
                  <Avatar
                    onClick={() => setImages([])}
                    alt='Remy Sharp'
                    src={file.preview}
                    style={{
                      width: 56,
                      height: 56,
                      border: '1px solid #c4c4c4',
                      cursor: 'pointer',
                    }}
                  />
                ))}
              </ImagesContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ margin: '10px 0px 30px 0px' }} />
      <Footer>
        <BackButtonsContainer>
          <Button
            disabled={loading}
            outlined
            onClick={() => history.push('/curadores')}
            backgroundColor='#B00D1F'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>
        </BackButtonsContainer>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <BackButtonsContainer>
            <Button onClick={() => handleSubmit()} backgroundColor='#9CD186'>
              <ButtonDescription>Cadastrar</ButtonDescription>
            </Button>
          </BackButtonsContainer>
        )}
      </Footer>
    </div>
  );
}
