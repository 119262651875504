import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { v4 as uuidv4 } from 'uuid';

import { Grid } from '@material-ui/core';
import {
  Button,
  DragAndDrop,
  ImageCard,
  Input,
  ModalComponent,
} from 'sonoma-design-system';

import { setForm } from '../../store/imagensForm';

import Divider from '../Divider';

import { BackButtonsContainer, Footer, ImagesContainer } from './styles';
import { ButtonDescription } from '../../styles';
import dragndropIcon from '../../assets/dragndrop-icon.svg';

import { LoadingSpinner } from '../../layout/LoadingSpinner';

export default function ImagensForm({
  loading,
  onPrevious,
  onCreateProduct,
  isEdit,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pro_in_codigo } = useParams();

  const [open, setOpen] = useState(false);

  const formImage = useSelector((state) =>
    isEdit ? state.product.edit.imagens.form : state.product.new.imagens.form
  );

  const form = useSelector((state) => state.product.edit.informacoes.form);
  const freteForm = useSelector((state) =>
    isEdit ? state.product.edit.frete.form : state.product.new.frete.form
  );

  const onFormChange = useCallback(
    (e) => {
      dispatch(setForm({ [e.target.name]: e.target.value }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isEdit && formImage.images.length === 0) {
      const previousImages =
        form.files?.map((file) => {
          const src = file.pim_st_imagesrc;

          // ignore the name of the folders and
          // get only the name of the file
          const name = src.substring(src.lastIndexOf('/'));

          return {
            index: uuidv4(),
            preview: file.url,
            path: name,
            pim_st_imagesrc: src,
            main: file.pim_bo_ismain,
          };
        }) || [];

      onFormChange({
        target: {
          name: 'images',
          value: [...formImage.images, ...previousImages],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const findDuplicated = useCallback(
    (files) =>
      files.find((file) =>
        formImage.images.find((img) => img.path === file.path)
      ),
    [formImage.images]
  );

  const isFileExtensionValid = useCallback((file) => {
    const validExtensions = ['.jpg', '.jpeg'];

    return validExtensions.some((ext) => file.path.includes(ext));
  }, []);

  const findImagesWithInvalidExtension = useCallback(
    (files) => files.find((file) => !isFileExtensionValid(file)),
    [isFileExtensionValid]
  );

  const findImagesWithInvalidSize = useCallback((files) => {
    const KBYTE = 1024;
    const MAX_SIZE_IN_KBYTES = 65;

    return files.find((file) => file.size / KBYTE > MAX_SIZE_IN_KBYTES);
  }, []);

  const handleDrop = useCallback(
    (files) => {
      const duplicated = findDuplicated(files);

      if (duplicated) {
        toast.warning('Não é possivel cadastrar a mesma imagem');
        return;
      }

      const hasImagesWithInvalidExtension =
        findImagesWithInvalidExtension(files);

      if (hasImagesWithInvalidExtension) {
        toast.warning(
          'Fomato inválido. Por favor, utilize apenas imagens .jpg e .jpeg'
        );
        return;
      }

      const hasImagesWithInvalidSize = findImagesWithInvalidSize(files);

      if (hasImagesWithInvalidSize) {
        toast.warning(
          'Tamanho inválido. Por favor, selecione apenas imagens até 65kb'
        );
        return;
      }

      const images = files
        .filter((file) => isFileExtensionValid(file))
        .map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            index: uuidv4(),
            main: index === 0,
          })
        );

      onFormChange({
        target: { name: 'images', value: [...formImage.images, ...images] },
      });
    },
    [
      formImage.images,
      onFormChange,
      findDuplicated,
      isFileExtensionValid,
      findImagesWithInvalidExtension,
      findImagesWithInvalidSize,
    ]
  );

  const removeCard = useCallback(
    (index) => {
      const newImages = formImage.images.filter((item) => item.index !== index);

      onFormChange({
        target: { name: 'images', value: newImages },
      });
    },
    [formImage.images, onFormChange]
  );

  const setMain = useCallback(
    (index) => {
      const newImages = formImage.images.map((item) => {
        if (item.index === index) {
          return Object.assign(item, {
            main: !item.main,
          });
        }

        return Object.assign(item, {
          main: false,
        });
      });

      onFormChange({
        target: { name: 'images', value: newImages },
      });
    },
    [formImage.images, onFormChange]
  );

  const save = useCallback(() => {
    // we need to pass pro_in_codigo in case of edit DBS PRODUCT
    onCreateProduct(formImage.images, pro_in_codigo);
  }, [formImage.images, onCreateProduct, pro_in_codigo]);

  const validationToSave = useCallback(() => {
    const hasMainImage = formImage.images.find((img) => img.main);

    if (!hasMainImage) {
      toast.warning('Selecione a imagem principal');
      return;
    }

    setOpen(true);
  }, [formImage.images]);

  return (
    <section>
      <ModalComponent
        modalId='ModalProdSupl'
        open={open}
        setOpen={setOpen}
        showButton={false}
      >
        <h1>Tem certeza que deseja cadastrar esses valores ao produto ?</h1>
        <br />
        {freteForm?.pro_de_precode && (
          <>
            Preço de:&nbsp;
            <b>
              {(freteForm?.pro_de_precode * 1).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </b>
            <br />
          </>
        )}
        {freteForm?.pro_de_precopor && (
          <>
            Preço por:{' '}
            <b>
              {(freteForm?.pro_de_precopor * 1).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </b>
          </>
        )}

        <br />
        <br />

        <Button onClick={save} backgroundColor='#9CD186'>
          <ButtonDescription>Confirmar</ButtonDescription>
        </Button>
      </ModalComponent>
      <DragAndDrop onDrop={handleDrop} icon={dragndropIcon} isButton />
      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      {!formImage.images.length > 0 && (
        <DragAndDrop onDrop={handleDrop} icon={dragndropIcon} />
      )}
      <ImagesContainer>
        {formImage.images?.map((file) => (
          <ImageCard
            key={file.index}
            title={file.path}
            src={file.preview}
            alt={file.path}
            main={file.main}
            onDelete={() => removeCard(file.index)}
            onSetMain={() => setMain(file.index)}
          />
        ))}
      </ImagesContainer>

      <Divider style={{ margin: '10px 0px 30px 0px' }} />
      <Grid spacing={2} container lg={12} style={{ marginBottom: '1rem' }}>
        <Grid item lg={6}>
          <Input
            inputId='pro_st_video'
            value={formImage?.pro_st_video || ''}
            handleChange={onFormChange}
            size='small'
            label='Vídeo do produto'
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <BackButtonsContainer>
          <Button
            disabled={loading}
            outlined
            onClick={() => history.push('/')}
            backgroundColor='#B00D1F'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>
        </BackButtonsContainer>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <BackButtonsContainer>
            <Button
              disabled={loading}
              outlined
              isStartIcon
              onClick={onPrevious}
              backgroundColor='#8A8A8A'
            >
              <ButtonDescription>Voltar</ButtonDescription>
            </Button>
            <Button onClick={validationToSave} backgroundColor='#9CD186'>
              <ButtonDescription>Finalizar</ButtonDescription>
            </Button>
          </BackButtonsContainer>
        )}
      </Footer>
    </section>
  );
}

ImagensForm.propTypes = {
  loading: PropTypes.bool,
  onPrevious: PropTypes.func.isRequired,
  onCreateProduct: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

ImagensForm.defaultProps = {
  loading: false,
  isEdit: false,
};
