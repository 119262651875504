const INITIAL_STATE = {
  filiais: [],
  seller: null,
  products: [],
  loading: false,
  error: false,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order_by: null,
    order: 'asc',
    availableOrderby: [],
  },
  filters: {
    ref: '',
  },
};

const setProducts = (state, action) => ({
  ...state,
  products: action.payload,
});

const setSeller = (state, action) => ({
  ...state,
  seller: action.payload === 'false' ? null : action.payload,
});

const setPaging = (state, action) => ({
  ...state,
  paging: action.payload,
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: action.payload,
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

export default function productsListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@productsList/setSeller':
      return setSeller(state, action);
    case '@productsList/setProducts':
      return setProducts(state, action);
    case '@productsList/setPaging':
      return setPaging(state, action);
    case '@productsList/setOrdering':
      return setOrdering(state, action);
    case '@productsList/setFilters':
      return setFilters(state, action);
    case '@productsList/setLoading':
      return setLoading(state, action);
    case '@productsList/setError':
      return setError(state, action);
    case '@productsList/clear':
      return INITIAL_STATE;
    default:
      return state;
  }
}
