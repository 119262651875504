import PropTypes from 'prop-types';
import { CalendarToday } from '@material-ui/icons';
import { ButtonContainer, CustomButton } from './styles';

export function CustomInput({ value, onClick }) {
  return (
    <ButtonContainer>
      <CustomButton type='button' className='custom-input' onClick={onClick}>
        {value}
      </CustomButton>

      <CalendarToday onClick={onClick} />
    </ButtonContainer>
  );
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.string,
};

CustomInput.defaultProps = {
  onClick: false,
  value: '',
};
