import { findOne } from '../../services/ProductService';

export const setForm = (data) => ({
  type: '@freteEdit/setForm',
  payload: data,
});

export const setLoading = (loading) => ({
  type: '@freteEdit/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@freteEdit/setError',
  payload: error,
});

export const setErrorOnFill = (error) => ({
  type: '@freteEdit/setErrorOnFill',
  payload: error,
});

export const clearAll = () => ({
  type: '@freteEdit/clearAll',
});

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { data: productData } = await findOne(productId);

    return dispatch([
      setForm({
        pro_de_comprimento: productData.pro_de_comprimento || 16,
        pro_de_peso: productData.pro_de_peso || 1.4,
        pro_de_altura: productData.pro_de_altura || 33,
        pro_de_largura: productData.pro_de_largura || 11,
        pro_de_precode: productData.pro_de_precode,
        pro_de_precopor: productData.pro_de_precopor,
        pro_de_precoatacado: productData.pro_de_precoatacado,
        pro_st_vendidopor: productData.pro_st_vendidopor,
        pro_st_entreguepor: productData.pro_st_entreguepor,
        pro_de_take_produto: productData.pro_de_take_produto,
      }),
      setLoading(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
