import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { Input } from 'sonoma-design-system';
import { toast } from 'react-toastify';

import { CenterColumn } from '../style';
import { setBulkEdit } from '../../../store/editBulkProdutos';
import {
  formatCurrency,
  formatNumber,
  reverseFormatNumber,
} from '../../../utils/number';

export function Price({ item, column }) {
  const dispatch = useDispatch();
  const isIntegrated = item.pro_bo_magentostatus === true;

  const onFormChange = useCallback(
    (e) => {
      dispatch(
        setBulkEdit({
          ...item,
          [column.id]: formatNumber(e.target.value.replaceAll('R$', '')),
        })
      );
    },
    [column.id, dispatch, item]
  );

  const validatePrice = useCallback((e) => {
    if (
      e.target.value &&
      formatNumber(e.target.value.replaceAll('R$', '')) < 10
    )
      toast.warn('Atenção! Você está inserindo um valor abaixo de R$10');
  }, []);

  return isIntegrated ? (
    <CenterColumn>
      <Input
        isNumeric
        inputId={column.id}
        value={reverseFormatNumber(item[column.id])}
        handleChange={onFormChange}
        onBlur={validatePrice}
        prefix='R$'
        size='small'
        style={{
          maxWidth: '110px',
        }}
      />
    </CenterColumn>
  ) : (
    <CenterColumn>{formatCurrency(item[column.id])}</CenterColumn>
  );
}

Price.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
