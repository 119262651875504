import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Input } from 'sonoma-design-system';
import { formatNumber, reverseFormatNumber } from '../../utils/number';

function MeasureFields({ form, onSetForm, errorOnFill }) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 30,
    },
  }));

  const classes = useStyles();

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={9} lg={9}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={3}>
            <Input
              isNumeric
              error={errorOnFill && !form?.pro_de_comprimento}
              inputId='pro_de_comprimento'
              value={reverseFormatNumber(form?.pro_de_comprimento)}
              handleChange={(e) =>
                onSetForm({ pro_de_comprimento: formatNumber(e.target.value) })
              }
              size='small'
              label='Comprimento (cm)'
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Input
              isNumeric
              error={errorOnFill && !form?.pro_de_peso}
              inputId='pro_de_peso'
              value={reverseFormatNumber(form?.pro_de_peso)}
              handleChange={(e) =>
                onSetForm({ pro_de_peso: formatNumber(e.target.value) })
              }
              size='small'
              label='Peso (kg)'
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Input
              isNumeric
              error={errorOnFill && !form?.pro_de_altura}
              inputId='pro_de_altura'
              value={reverseFormatNumber(form?.pro_de_altura)}
              handleChange={(e) =>
                onSetForm({ pro_de_altura: formatNumber(e.target.value) })
              }
              size='small'
              label='Altura (cm)'
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Input
              isNumeric
              error={errorOnFill && !form?.pro_de_largura}
              inputId='pro_de_largura'
              value={reverseFormatNumber(form?.pro_de_largura)}
              handleChange={(e) =>
                onSetForm({ pro_de_largura: formatNumber(e.target.value) })
              }
              size='small'
              label='Largura (cm)'
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MeasureFields.propTypes = {
  form: PropTypes.object.isRequired,
  onSetForm: PropTypes.func.isRequired,
  errorOnFill: PropTypes.bool,
};

MeasureFields.defaultProps = {
  errorOnFill: false,
};

export default MeasureFields;
