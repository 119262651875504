import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';
import { jsonToFormData } from '../utils/buildFormData';
import { ValidationErrorMessage } from '../components/ValidationErrorMessage';

const token = getToken();

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get('produtos', {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produtos');
    throw e;
  }
}

export async function remove(productId) {
  try {
    await api.delete(`produtos/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    toast.error('Erro ao deletar produto');
    throw e;
  }
}

export async function findOne(productId) {
  try {
    const response = await api.get(`produtos/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produto');
    throw e;
  }
}

export async function findOneByDescricao(descricao) {
  try {
    // handle in case of special characters
    const encondedDescription = encodeURIComponent(descricao);

    const response = await api.get(
      `produtos/find/descricao?pro_st_descricao=${encondedDescription}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (e) {
    toast.error('Erro ao procurar produto por descrição');
    throw e;
  }
}

export async function findBySku(sku) {
  try {
    // handle in case of special characters
    const encondedSku = encodeURIComponent(sku);

    const response = await api.get(`produtos/find/sku?sku=${encondedSku}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (e) {
    toast.error('Erro ao procurar produto por sku');
    throw e;
  }
}

export async function checkUrlExistence(produtoId, url = '') {
  try {
    const response = await api.get(
      `produtos/find/url?url=${url}&produtoId=${produtoId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data.data;
  } catch (e) {
    toast.error('Erro ao checar duplicação de URL');
    throw e;
  }
}

function formatRequestBody(data, files) {
  const formData = jsonToFormData(data);

  // append files
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    formData.append('files', file);
  }

  return formData;
}

function handleErrors(exception) {
  const BAD_REQUEST = 400;
  const UNAUTHORIZED = 401;
  const FORBIDDEN = 403;
  const INTERNAL_SERVER_ERROR = 500;

  const autoClose = 10 * 1000; // 10 seconds

  if (exception.response.status === UNAUTHORIZED) {
    toast.error(
      'Oops! Tivemos um problema com as suas credenciais de acesso. Tente relogar em sua conta, caso o problema persista, contate um administrador',
      { autoClose }
    );
    throw exception;
  }

  if (exception.response.status === FORBIDDEN) {
    toast.error(
      'Oops! Tivemos um problema com as suas credenciais de acesso. Tente relogar em sua conta, caso o problema persista, contate um administrador',
      { autoClose }
    );
    throw exception;
  }

  if (
    exception.response.status === BAD_REQUEST &&
    exception.response.data.validation
  ) {
    const baseErrorMessage = exception.response.data.validation.body.message;

    toast.error(<ValidationErrorMessage errorMessage={baseErrorMessage} />, {
      autoClose,
    });
    throw exception;
  }

  if (
    exception.response.status === INTERNAL_SERVER_ERROR &&
    exception.response.data.error === 'MILLENNIUM_INTEGRATION_ERROR'
  ) {
    toast.error(
      'Parece que tivemos um problema ao integrar esse produto com nosso ERP. Tente novamente mais tarde, caso o problema persista, contate um administrador',
      { autoClose }
    );
    throw exception;
  }
}

export async function create(data, files) {
  try {
    const formData = formatRequestBody(data, files);

    await api.post('produtos', formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Produto criado com sucesso!');
  } catch (err) {
    handleErrors(err);

    toast.error('Erro ao criar produto');
    throw err;
  }
}

export async function createKit(data, files) {
  try {
    const formData = formatRequestBody(data, files);

    await api.post('kits', formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Kit criado com sucesso!');
  } catch (e) {
    toast.error('Erro ao criar kit');
    throw e;
  }
}

export async function review(productId, data, files) {
  try {
    const formData = formatRequestBody(data, files);

    await api.put(`produtos/${productId}/review`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Produto revisado com sucesso!');
  } catch (err) {
    handleErrors(err);

    toast.error('Erro ao revisar produto');
    throw err;
  }
}

export async function deleteProduct(productId) {
  try {
    const response = await api.delete(`produtos/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success('Produto removido com sucesso!');
    return response;
  } catch (e) {
    toast.error('Erro ao deletar produto');
    throw e;
  }
}
