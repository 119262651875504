import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../config/db';

export function useLocalDB(table) {
  const data = useLiveQuery(async () => {
    const first = await db[table].toCollection().first();

    return first;
  }, []);

  function clear() {
    db[table].clear();
  }

  function add(item) {
    db[table].add(item);
  }

  return {
    add,
    clear,
    data,
  };
}
