import styled from 'styled-components';

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EventTitle = styled.h3`
  margin-bottom: 15px;
`;
