/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from 'sonoma-design-system';

import FieldsetTitle from '../FieldsetTitle';
import MeasureFields from '../MeasureFields';
import PriceFields from '../PriceFields';
import SellerFields from '../SellerFields';
import Divider from '../Divider';

import { BackButtonsContainer, Footer } from './styles';
import { ButtonDescription } from '../../styles';

import { setForm, setErrorOnFill } from '../../store/freteForm';
import {
  setForm as setFormEdit,
  setErrorOnFill as setErrorOnFillEdit,
} from '../../store/freteEdit';

import { useLocalDB } from '../../hooks';
import { getUser, getUserRoles } from '../../auth/auth';

export default function FreteForm({ onNext, onPrevious, isEdit, isKit }) {
  const history = useHistory();

  const { add, clear, data: localData } = useLocalDB('freight');

  const dispatch = useDispatch();

  const form = useSelector((state) =>
    isEdit ? state.product.edit.frete.form : state.product.new.frete.form
  );
  const errorOnFill = useSelector((state) =>
    isEdit
      ? state.product.edit.frete.errorOnFill
      : state.product.new.frete.errorOnFill
  );

  const isDeliveryBySeller =
    getUserRoles().includes('DELIVERY_BY_SELLER') || getUser().slr_in_codigo;

  useEffect(() => {
    if (localData) {
      dispatch(
        setForm({
          pro_de_comprimento: localData.comprimento,
          pro_de_peso: localData.peso,
          pro_de_altura: localData.altura,
          pro_de_largura: localData.largura,
          pro_de_precode: localData.preco_de,
          pro_de_precopor: localData.preco_por,
          pro_de_precoatacado: localData.preco_caixa,
          pro_st_vendidopor: localData.vendido_por,
          pro_st_entreguepor: localData.entregue_por,
          pro_de_take_produto: localData.pro_de_take_produto,
        })
      );
    }
  }, [dispatch, localData]);

  const onSetForm = useCallback(
    (value) => {
      dispatch(isEdit ? setFormEdit(value) : setForm(value));
    },
    [dispatch, isEdit]
  );

  function validateProduct() {
    if (
      !form?.pro_de_comprimento ||
      !form?.pro_de_peso ||
      !form?.pro_de_altura ||
      !form?.pro_de_largura ||
      !form?.pro_de_precopor ||
      !form?.pro_st_vendidopor ||
      !form?.pro_st_entreguepor
    ) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios');
      return false;
    }

    if (
      Number(form?.pro_de_precode) > 0 &&
      Number(form?.pro_de_precopor) >= Number(form?.pro_de_precode)
    ) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning(
        'O preço promocional não pode ser maior ou igual ao preço base'
      );
      return false;
    }

    dispatch(isEdit ? setErrorOnFillEdit(false) : setErrorOnFill(false));
    return true;
  }

  function validateKit() {
    if (
      !form?.pro_de_comprimento ||
      !form?.pro_de_peso ||
      !form?.pro_de_altura ||
      !form?.pro_de_largura ||
      !form?.pro_st_vendidopor ||
      !form?.pro_st_entreguepor
    ) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    dispatch(isEdit ? setErrorOnFillEdit(false) : setErrorOnFill(false));
    return true;
  }

  function saveLocally() {
    clear();

    add({
      comprimento: form.pro_de_comprimento,
      peso: form.pro_de_peso,
      altura: form.pro_de_altura,
      largura: form.pro_de_largura,
      preco_de: form.pro_de_precode,
      preco_por: form.pro_de_precopor,
      preco_caixa: form.pro_de_precoatacado,
      vendido_por: form.pro_st_vendidopor,
      entregue_por: form.pro_st_entreguepor,
    });
  }

  function isValid() {
    if (isKit) return validateKit();

    return validateProduct();
  }

  function handleNextStep() {
    if (!isValid()) return;

    saveLocally();

    onNext();
  }

  return (
    <section>
      {isDeliveryBySeller && (
        <>
          <FieldsetTitle>Frete</FieldsetTitle>
          <MeasureFields
            form={form}
            onSetForm={onSetForm}
            errorOnFill={errorOnFill}
          />
        </>
      )}

      {!isKit && (
        <>
          <FieldsetTitle>Preço</FieldsetTitle>

          <PriceFields
            form={form}
            onSetForm={onSetForm}
            errorOnFill={errorOnFill}
          />
        </>
      )}

      <FieldsetTitle>Vendedor</FieldsetTitle>

      <SellerFields
        form={form}
        onSetForm={onSetForm}
        errorOnFill={errorOnFill}
        isEdit={isEdit}
      />

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <BackButtonsContainer>
          <Button
            outlined
            onClick={() => history.push('/')}
            backgroundColor='#B00D1F'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>
        </BackButtonsContainer>
        <BackButtonsContainer>
          <Button
            outlined
            isStartIcon
            onClick={onPrevious}
            backgroundColor='#8A8A8A'
          >
            <ButtonDescription>Voltar</ButtonDescription>
          </Button>
          <Button onClick={handleNextStep} backgroundColor='#9CD186'>
            <ButtonDescription>Avançar</ButtonDescription>
          </Button>
        </BackButtonsContainer>
      </Footer>
    </section>
  );
}

FreteForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  isKit: PropTypes.bool,
};

FreteForm.defaultProps = {
  isEdit: false,
  isKit: false,
};
