import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { format } from 'date-fns';

import { Delete } from '@material-ui/icons';

import { Button, Table } from 'sonoma-design-system';
import styled from 'styled-components';

import { CenterColumn } from '../../styles';

import { formatCurrency } from '../../utils/number';

function DateColumn({ item, column }) {
  return (
    <CenterColumn>
      {format(new Date(item[column.id]), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

function SKUColumn({ item }) {
  const { produto } = item;

  return <CenterColumn>{produto.pro_st_sku}</CenterColumn>;
}

SKUColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

function ProductCol({ item }) {
  const product = item.produto;

  return <CenterColumn>{product.pro_st_descricao}</CenterColumn>;
}

ProductCol.propTypes = {
  item: PropTypes.object.isRequired,
};

function SKUCol({ item }) {
  const product = item.produto;

  return <CenterColumn>{product.pro_st_sku}</CenterColumn>;
}

SKUCol.propTypes = {
  item: PropTypes.object.isRequired,
};

function StatusCol({ item }) {
  const status = item.tap_ch_status;

  if (status === 'E')
    return <CenterColumn style={{ color: 'tomato' }}>Erro</CenterColumn>;

  if (status === 'I')
    return (
      <CenterColumn style={{ color: '#6c7a89' }}>Inativa/Expirada</CenterColumn>
    );

  if (status === 'A')
    return <CenterColumn style={{ color: '#b2de27' }}>Ativa</CenterColumn>;

  return <CenterColumn style={{ color: '#bd9b19' }}>Pendente</CenterColumn>;
}

StatusCol.propTypes = {
  item: PropTypes.object.isRequired,
};

function PriceDisplay({ item, column }) {
  const value = item[column.id];

  return (
    <CenterColumn>{value > 0 ? formatCurrency(value) : ' - '}</CenterColumn>
  );
}

PriceDisplay.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

function CreatedBy({ item, column }) {
  const user = item[column.id];

  return <CenterColumn>{user ? user.usu_st_nome : ' - '}</CenterColumn>;
}

CreatedBy.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

const CustomButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;

function RemoveButton({ item, onRemove }) {
  return (
    <td>
      <CustomButton
        style={{
          display: 'block',
          margin: '0 auto',
          color: '#d64541',
          border: 0,
        }}
        outlined
        size='sm'
        onClick={() => onRemove(item.produto.pro_in_codigo, item.tap_in_codigo)}
      >
        <Delete />
      </CustomButton>
    </td>
  );
}

RemoveButton.propTypes = {
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default function PromoList({
  sales,
  ordering,
  onChangeOrder,
  paging,
  onChangePage,
  onRemove,
}) {
  const cols = useMemo(
    () => [
      { id: 'tap_in_codigo', title: 'ID' },
      {
        id: 'produto',
        title: 'Produto',
        component: ProductCol,
      },
      {
        id: 'sku',
        title: 'SKU',
        component: SKUCol,
      },
      {
        id: 'tap_dt_data_inicio',
        title: 'Data de início',
        component: DateColumn,
      },
      {
        id: 'tap_dt_data_fim',
        title: 'Data final',
        component: DateColumn,
      },
      {
        id: 'tap_de_preco_de',
        title: 'Preço de',
        component: PriceDisplay,
      },
      {
        id: 'tap_de_preco_por',
        title: 'Preço por',
        component: PriceDisplay,
      },
      {
        id: 'tap_de_preco_atacado',
        title: 'Preço atacado',
        component: PriceDisplay,
      },
      {
        id: 'status',
        title: 'Status',
        component: StatusCol,
      },
      {
        id: 'usuario',
        title: 'Criada por',
        component: CreatedBy,
      },
      {
        id: 'action',
        title: 'Remover',
        component: (props) => <RemoveButton onRemove={onRemove} {...props} />,
      },
    ],
    [onRemove]
  );

  return sales.length ? (
    <Table
      rows={sales}
      columns={cols}
      ordering={ordering}
      onChangeOrder={onChangeOrder}
      paging={paging}
      onChangePage={onChangePage}
      showSearch={false}
    />
  ) : (
    <p>Nenhuma promoção encontrada</p>
  );
}

PromoList.propTypes = {
  sales: PropTypes.array.isRequired,

  ordering: PropTypes.object,
  onChangeOrder: PropTypes.func,

  paging: PropTypes.object,
  onChangePage: PropTypes.func,

  onRemove: PropTypes.func.isRequired,
};

PromoList.defaultProps = {
  ordering: null,
  onChangeOrder: null,

  paging: null,
  onChangePage: null,
};
