const INITIAL_STATE = {
  errorOnFill: false,
  form: {
    pro_de_comprimento: 16,
    pro_de_peso: 1.4,
    pro_de_largura: 11,
    pro_de_altura: 33,
    pro_st_vendidopor: null,
    pro_st_entreguepor: null,
  },
};

export const setForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    ...action.payload,
  },
});

const setErrorOnFill = (state, action) => ({
  ...state,
  errorOnFill: action.payload,
});

const clearAll = () => INITIAL_STATE;

export default function freteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@frete/setForm':
      return setForm(state, action);
    case '@frete/setErrorOnFill':
      return setErrorOnFill(state, action);
    case '@frete/clearAll':
      return clearAll(state, action);
    default:
      return state;
  }
}
