import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 35px;
`;

export const AlertParagraph = styled.p`
  font-weight: 700;
  margin-bottom: 20px;
`;
