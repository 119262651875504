import { toast } from 'react-toastify';
import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function findAllProducts(
  filialId,
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get(`/filiais/${filialId}/produtos`, {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produtos');
    throw e;
  }
}

export async function updateProductsPrice(ProductsToUpdate) {
  if (ProductsToUpdate.length === 0)
    return toast.error('Nenhum preço modificado');
  try {
    const response = await api.put(
      `/produtos/batch_update`,
      {
        products: ProductsToUpdate,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Preços alterados com sucesso!');

    return response.data;
  } catch (e) {
    toast.error('Erro ao alterar preços.');
    throw e;
  }
}
