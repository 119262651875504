import { findOne } from '../../services/ProductService';

export const setForm = (data) => ({
  type: '@informacoesEdit/setForm',
  payload: data,
});

export const setLoading = (loading) => ({
  type: '@informacoesEdit/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@informacoesEdit/setError',
  payload: error,
});

export const setErrorOnFill = (error) => ({
  type: '@informacoesEdit/setErrorOnFill',
  payload: error,
});

export const clearAll = () => ({
  type: '@informacoesEdit/clearAll',
});

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { data: productData } = await findOne(productId);

    const selos = productData.selos.map((item) => item.selo);
    const politicas_comerciais = productData.politicas_comerciais.map(
      (item) => item.politica_comercial
    );

    return dispatch([
      setForm({
        pro_st_descricao: productData.pro_st_descricao,
        pro_st_ean: productData.pro_st_ean,
        pro_st_url: productData.pro_st_url,
        pro_st_tags: productData.pro_st_tags,
        dep_in_codigo: productData.dep_in_codigo,
        pro_st_palavraschave: productData.pro_st_palavraschave,
        pei_in_codigo: productData.pei_in_codigo,
        clf_in_codigo: productData.clf_in_codigo,
        vic_in_codigo:
          productData.vitrines[0]?.vic_in_codigo || productData.vic_in_codigo,
        clp_in_codigo: productData.clp_in_codigo,
        ori_in_codigo: productData.ori_in_codigo,
        cat_in_codigo: productData.cat_in_codigo,
        pro_lt_metatagdesc: productData.pro_lt_metatagdesc,
        pro_lt_sobre: productData.pro_lt_sobre,
        pro_lt_historia: productData.pro_lt_historia,
        pro_in_score: productData.pro_in_score,
        pro_bo_exibirnosite: productData.pro_bo_exibirnosite,
        pro_bo_ativo: productData.pro_bo_ativo,
        pro_bo_mostraresgotado: productData.pro_bo_mostraresgotado,
        safra: productData?.vinho?.vin_in_safra || null,
        files: productData.files,
        pro_st_codseller: productData.pro_st_codseller,
        selos,
        politicas_comerciais,
        produtos_suplentes: productData.produtos_suplentes,
        slr_in_codigo: productData.slr_in_codigo,
        pro_st_titulopage: productData.pro_st_titulopage,
        pro_bo_express: productData.pro_bo_express,
      }),
      setLoading(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
