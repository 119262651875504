import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';
import RoleBasedRoute from '../../components/RoleBasedRoute';

import CreateQueijo from '../../pages/CreateQueijo';
import CreateVinho from '../../pages/CreateVinho';
import CreateGourmet from '../../pages/CreateGourmet';

import PublisherCreateVinho from '../../pages/PublisherCreateVinho';
import PublisherEditVinho from '../../pages/PublisherEditVinho';

import NewUser from '../../pages/NewUser';
import UserList from '../../pages/UserList';
import UserEdit from '../../pages/UserEdit';
import NewPassword from '../../pages/NewPassword';

import EditVinho from '../../pages/EditVinho';
import EditQueijo from '../../pages/EditQueijo';
import EditGourmet from '../../pages/EditGourmet';

import ProductsList from '../../pages/ProductsList';
import ProductsReviewList from '../../pages/ProductsReviewList';
import EditBulkProdutos from '../../pages/EditBulkProdutos';

import ReviewVinho from '../../pages/ReviewVinho';
import ReviewQueijo from '../../pages/ReviewQueijo';
import ReviewGourmet from '../../pages/ReviewGourmet';

import Pedidos from '../../pages/Pedidos';
import DeliveryBySellerOrders from '../../pages/DeliveryBySellerOrders';
import Dashboard from '../../pages/Dashboard';
import GenerateXml from '../../pages/GenerateXml';

import SettingsPage from '../../pages/SettingsPage';
import EditApelidoPrefixFilialSellerPage from '../../pages/EditApelidoPrefixFilialSeller';

import HomePage from '../../pages/HomePage';

// import NewSellersListPage from '../../pages/NewSellersList';

import CreateCuradorPage from '../../pages/CreateCurador';
import CuradorListPage from '../../pages/CuradorList';

import OrderDetailPage from '../../pages/OrderDetail';
import ConciliacaoPage from '../../pages/Conciliacao';
import SellerConciliacaoPage from '../../pages/SellerConciliacao';
import CreateSale from '../../pages/CreateSale';
import PromoList from '../../pages/PromoList';

import { StyledContainer } from './styles';
import SellerInventory from '../../pages/SellerInventory';

export function WithContainer() {
  return (
    <StyledContainer>
      <Switch>
        <RoleBasedRoute
          roles={['ADMIN']}
          path='/usuarios'
          exact
          component={UserList}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/usuarios/create'
          component={NewUser}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/usuarios/:id/edit'
          component={UserEdit}
        />
        <PrivateRoute
          path='/usuarios/:id/new_password'
          component={NewPassword}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/promocoes'
          exact
          component={PromoList}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos'
          exact
          component={ProductsList}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/produtos/:pro_in_codigo/promo'
          component={CreateSale}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos/vinho/create'
          component={CreateVinho}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos/gourmet/create'
          component={CreateGourmet}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos/queijo/create'
          component={CreateQueijo}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos/:pro_in_codigo/vinho/edit'
          component={EditVinho}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos/:pro_in_codigo/queijo/edit'
          component={EditQueijo}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'SELLER']}
          path='/produtos/:pro_in_codigo/gourmet/edit'
          component={EditGourmet}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'WRITER', 'INSIDER']}
          path='/produtos/review'
          exact
          component={ProductsReviewList}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/produtos/:pro_in_codigo/vinhos/review'
          exact
          component={ReviewVinho}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/produtos/:pro_in_codigo/queijo/review'
          exact
          component={ReviewQueijo}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/produtos/:pro_in_codigo/gourmet/review'
          exact
          component={ReviewGourmet}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'FULFILLS', 'FULFILLS_H', 'FINANCIAL']}
          path='/dashboard'
          exact
          component={Dashboard}
        />
        <RoleBasedRoute
          roles={['FULFILLS']}
          path='/inventory'
          exact
          component={SellerInventory}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'FULFILLS', 'FULFILLS_H', 'INSIDER', 'FINANCIAL']}
          path='/xml'
          exact
          component={GenerateXml}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/editar/produtos/precos'
          exact
          component={EditBulkProdutos}
        />

        <Route
          roles={['ADMIN', 'WRITER', 'INSIDER']}
          path='/publisher/produtos/vinho/create'
          component={PublisherCreateVinho}
        />
        <Route
          roles={['ADMIN', 'WRITER', 'INSIDER']}
          path='/publisher/produtos/:pro_in_codigo/vinho/edit'
          component={PublisherEditVinho}
        />

        <RoleBasedRoute
          roles={['ADMIN', 'FULFILLS', 'FULFILLS_H', 'INSIDER']}
          path='/pedidos'
          component={Pedidos}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'DELIVERY_BY_SELLER', 'INSIDER']}
          path='/seller/:sellerId/orders'
          component={DeliveryBySellerOrders}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/seller/orders'
          component={DeliveryBySellerOrders}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/configuracoes'
          component={SettingsPage}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/filial/edit/:type/:id'
          component={EditApelidoPrefixFilialSellerPage}
        />
        {/* <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/new/sellers/list'
          component={NewSellersListPage}
        /> */}
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/curadores'
          component={CuradorListPage}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER']}
          path='/create/curador'
          component={CreateCuradorPage}
        />
        <RoleBasedRoute
          roles={['ADMIN', 'INSIDER', 'DELIVERY_BY_SELLER']}
          path='/order/:orderId'
          component={OrderDetailPage}
        />
        <RoleBasedRoute
          roles={['FINANCIAL', 'ADMIN']}
          path='/conciliacao'
          component={ConciliacaoPage}
        />
        <RoleBasedRoute
          roles={['FULFILLS', 'FULFILLS_H', 'DELIVERY_BY_SELLER']}
          path='/seller/conciliacao'
          component={SellerConciliacaoPage}
        />
        <PrivateRoute path='/home' component={HomePage} />
      </Switch>
    </StyledContainer>
  );
}
