import PropTypes from 'prop-types';

import { Modal } from '@material-ui/core';
import DateRangePicker from '../DateRangePicker';
import { Body } from './styles';

export function CalendarModal({
  isOpen,
  onClose,
  handleChange,
  startDate,
  endDate,
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <Body>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
        />
      </Body>
    </Modal>
  );
}

CalendarModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

CalendarModal.defaultProps = {
  isOpen: false,
};
