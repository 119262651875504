import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import {
  MdKeyboard,
  MdPowerSettingsNew,
  MdShoppingCart,
  MdDriveFolderUpload,
  MdOutlineListAlt,
  MdDownload,
  MdInventory,
} from 'react-icons/md';

import {
  ShoppingCartOutlined,
  LocalOfferOutlined,
  PlaylistAddCheckOutlined,
  PeopleAltOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  HomeOutlined,
  SupervisedUserCircleOutlined,
} from '@material-ui/icons';

import { WithContainer } from './WithContainer';
import { AuthenticatedDrawer } from './AuthenticatedDrawer';

import { AnalyticPageView } from '../components/AnalyticPageView';

import Login from '../pages/Login';
import RecoverPassword from '../pages/RecoverPassword';
import Home from '../pages/HomePage';
import NewSeller from '../pages/NewSeller';

import {
  containsAny,
  insertIf,
  removeDuplicatesByColumns,
} from '../utils/arrays';
import { getToken, getUser, getUserRoles } from '../auth/auth';
import { logout } from '../services/auth';

import { RouterContainer } from './styles';

export default function Routes() {
  const token = getToken();

  const user = token && getUser();
  const roles = token ? getUserRoles() : [];

  const isAuthenticated = token && user;
  const isWriter = containsAny(roles, ['WRITER']);
  const isInsider = containsAny(roles, ['INSIDER']);
  const isSeller = containsAny(roles, ['SELLER']);
  const isDeliveryBySeller = containsAny(roles, ['DELIVERY_BY_SELLER']);
  // const isFF = containsAny(roles, ['FULFILLS']);
  const isFFH = containsAny(roles, ['FULFILLS_H']); // TODO: validate this role
  const isAdmin = containsAny(roles, ['ADMIN']);
  const isFinancial = containsAny(roles, ['FINANCIAL']);

  const authenticatedMenuItems = [
    {
      label: 'Trocar senha',
      to: `/usuarios/${user?.usu_in_codigo}/new_password`,
      Icon: MdKeyboard,
    },
    {
      label: 'Sair',
      to: '/',
      Icon: MdPowerSettingsNew,
      handleClick: logout,
    },
  ];

  const writerMenuItems = [
    {
      label: 'Home',
      to: '/home',
      Icon: HomeOutlined,
    },
    {
      label: 'Produtos',
      to: '/produtos/review',
      Icon: PlaylistAddCheckOutlined,
    },
  ];

  const financialMenuItems = [
    {
      label: 'Home',
      to: '/home',
      Icon: HomeOutlined,
    },
    {
      label: 'Conciliação',
      to: '/conciliacao',
      Icon: MdDriveFolderUpload,
    },
    {
      label: 'Gerar XMLs',
      to: '/xml',
      Icon: DescriptionOutlined,
    },
    {
      label: 'Vendas por SKU',
      to: '/dashboard',
      Icon: MdShoppingCart,
    },
  ];

  const insiderMenuItems = [
    {
      label: 'Home',
      to: '/home',
      Icon: HomeOutlined,
    },
    {
      label: 'Produtos',
      to: '/produtos',
      Icon: ShoppingCartOutlined,
    },
    {
      label: 'Promoções',
      to: '/promocoes',
      Icon: LocalOfferOutlined,
    },
    {
      label: 'Revisões',
      to: '/produtos/review',
      Icon: PlaylistAddCheckOutlined,
    },
    {
      label: 'Editar Preço',
      to: '/editar/produtos/precos',
      Icon: MdShoppingCart,
    },
    {
      label: 'Pedidos Fulfills',
      to: '/pedidos',
      Icon: ShoppingCartOutlined,
    },
    {
      label: 'Pedidos Delivery by Seller',
      to: '/seller/orders',
      Icon: ShoppingCartOutlined,
    },
    // {
    //   label: 'Novos Sellers',
    //   to: '/new/sellers/list',
    //   Icon: SupervisedUserCircleOutlined,
    // },
    {
      label: 'Curadores',
      to: '/curadores',
      Icon: SupervisedUserCircleOutlined,
    },
    {
      label: 'Notas Fiscais',
      to: '/xml',
      Icon: DescriptionOutlined,
    },
    {
      label: 'Editar perfil',
      to: `/usuarios/${user?.usu_in_codigo}/edit`,
      Icon: PeopleAltOutlined,
    },
    {
      label: 'Configurações',
      to: '/configuracoes',
      Icon: SettingsOutlined,
    },
  ];

  const sellerMenuItems = [
    {
      label: 'Home',
      to: '/home',
      Icon: HomeOutlined,
    },
    (isDeliveryBySeller || isFFH) && {
      label: 'Produtos',
      to: '/produtos',
      Icon: MdShoppingCart,
    },
    // (isDeliveryBySeller || isFFH || isFF) && {
    //   label: isFF ? 'Consultar Estoque' : 'Editar Preço',
    //   to: '/editar/produtos/precos',
    //   Icon: MdShoppingCart,
    // },
    isDeliveryBySeller && {
      label: 'Pedidos',
      to: `/seller/${user?.slr_in_codigo}/orders`,
      Icon: MdShoppingCart,
    },
    !isDeliveryBySeller && {
      label: 'Pedidos',
      to: '/pedidos',
      Icon: MdOutlineListAlt,
    },
    !isDeliveryBySeller && {
      label: 'Vendas por Produto',
      to: '/dashboard',
      Icon: MdShoppingCart,
    },
    !isDeliveryBySeller && {
      label: 'Estoque',
      to: '/inventory',
      Icon: MdInventory,
    },
    !isDeliveryBySeller && {
      label: 'Notas Fiscais',
      to: '/xml',
      Icon: MdDownload,
    },
    !isDeliveryBySeller && {
      label: 'Conciliação',
      to: '/seller/conciliacao',
      Icon: MdDriveFolderUpload,
    },
  ].filter((c) => c);

  const adminMenuItems = [
    {
      label: 'Home',
      to: '/home',
      Icon: HomeOutlined,
    },
    {
      label: 'Produtos',
      to: '/produtos',
      Icon: ShoppingCartOutlined,
    },
    {
      label: 'Promoções',
      to: '/promocoes',
      Icon: LocalOfferOutlined,
    },
    {
      label: 'Revisões',
      to: '/produtos/review',
      Icon: PlaylistAddCheckOutlined,
    },
    {
      label: 'Editar Preço',
      to: '/editar/produtos/precos',
      Icon: MdShoppingCart,
    },
    {
      label: 'Vendas por Produto',
      to: '/dashboard',
      Icon: MdShoppingCart,
    },
    {
      label: 'Pedidos Fulfills',
      to: '/pedidos',
      Icon: ShoppingCartOutlined,
    },
    {
      label: 'Pedidos Delivery by Seller',
      to: '/seller/orders',
      Icon: ShoppingCartOutlined,
    },
    {
      label: 'Conciliação',
      to: '/conciliacao',
      Icon: MdDriveFolderUpload,
    },
    // {
    //   label: 'Novos Sellers',
    //   to: '/new/sellers/list',
    //   Icon: SupervisedUserCircleOutlined,
    // },
    {
      label: 'Curadores',
      to: '/curadores',
      Icon: SupervisedUserCircleOutlined,
    },
    {
      label: 'Notas Fiscais',
      to: '/xml',
      Icon: DescriptionOutlined,
    },
    {
      label: 'Usuários',
      to: '/usuarios',
      Icon: PeopleAltOutlined,
    },
    {
      label: 'Configurações',
      to: '/configuracoes',
      Icon: SettingsOutlined,
    },
  ];

  return (
    <BrowserRouter>
      <AnalyticPageView />
      <RouterContainer isAuthenticated={isAuthenticated}>
        {isAuthenticated && (
          <AuthenticatedDrawer
            menuItens={removeDuplicatesByColumns(
              [
                ...insertIf(isInsider, insiderMenuItems),
                ...insertIf(isWriter, writerMenuItems),
                ...insertIf(isSeller, sellerMenuItems),
                ...insertIf(isAdmin, adminMenuItems),
                ...insertIf(isFinancial, financialMenuItems),
                ...insertIf(isAuthenticated, authenticatedMenuItems),
              ],
              'label',
              'to'
            )}
          />
        )}

        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/login' exact component={Login} />
          <Route path='/recover/password' component={RecoverPassword} />
          <Route path='/new/seller' component={NewSeller} />
          <Route component={WithContainer} />
          <Redirect to='/' />
        </Switch>
      </RouterContainer>
    </BrowserRouter>
  );
}
