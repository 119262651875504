const INITIAL_STATE = {
  isLoading: false,
};

const setLoading = (state, action) => ({ ...state, isLoading: action.payload });

export default function loadingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@loading/setLoading':
      return setLoading(state, action);
    case '@loading/clear':
      return INITIAL_STATE;
    default:
      return state;
  }
}
