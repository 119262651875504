import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

import { BreadcrumbStepper, useStepper } from 'sonoma-design-system';

import { getUser } from '../../auth/auth';

import QueijoForm from '../../components/QueijoForm';
import InformacoesForm from '../../components/InformacoesForm';
import FreteForm from '../../components/FreteForm';
import ImagensForm from '../../components/ImagensForm';

import { clearAll as clearCheese } from '../../store/queijoNew';
import {
  fetchProduct,
  clearAll as clearCheeseEdit,
} from '../../store/queijoEdit';
import {
  fetchProduct as fetchInfoEdit,
  clearAll as clearInfoEdit,
} from '../../store/informacoesEdit';
import {
  fetchProduct as fetchFreteEdit,
  clearAll as clearFreightEdit,
} from '../../store/freteEdit';
import {
  fetchProduct as fetchImagensForm,
  clearAll as clearImagensForm,
} from '../../store/imagensForm';
import { clearAll as clearInfo } from '../../store/informacoesForm';
import { clearAll as clearFreight } from '../../store/freteForm';
import store from '../../store';

import { clearBlankKeysFromNested } from '../../utils/objects';

import { review } from '../../services/ProductService';

import { Main, Title } from './styles';

export default function ReviewQueijo() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pro_in_codigo } = useParams();

  const [isLoading, setLoading] = useState(false);
  const loadingData = useSelector((state) => state.product.edit.queijo.loading);

  const { selected, next, previous } = useStepper();

  const titles = useMemo(
    () => ['QUEIJOS', 'INFORMAÇÕES', 'FRETE E VENDA', 'IMAGENS E VÍDEOS'],
    []
  );

  const getMainImagePath = useCallback(
    (images) => images.find((img) => img.main)?.path,
    []
  );

  const getNewImageFiles = useCallback(
    (images) => images.filter((i) => !i.pim_st_imagesrc),
    []
  );

  const getPreviouslySavedURLs = useCallback(
    (images) =>
      images.filter((i) => i.pim_st_imagesrc).map((i) => i.pim_st_imagesrc),
    []
  );

  useEffect(() => {
    dispatch([
      fetchProduct(pro_in_codigo),
      fetchInfoEdit(pro_in_codigo),
      fetchFreteEdit(pro_in_codigo),
      fetchImagensForm(pro_in_codigo),
    ]);

    return () => {
      dispatch([
        clearCheese(),
        clearInfo(),
        clearFreight(),
        clearCheeseEdit(),
        clearInfoEdit(),
        clearFreightEdit(),
        clearImagensForm(),
      ]);
    };
  }, [dispatch, pro_in_codigo]);

  const handleCreateProduct = useCallback(
    async (images) => {
      setLoading(true);

      const user = getUser();

      try {
        const { product } = store.getState();

        const productWithouBlank = clearBlankKeysFromNested(product.edit);

        const {
          queijo: { form: cheeseData },
          frete: { form: freightData },
          informacoes: { form: infoData },
          imagens: { form: imagens },
        } = productWithouBlank;

        const {
          produtos_suplentes,
          pro_lt_sobre,
          pro_lt_historia,
          ...dataInfo
        } = infoData;

        const similares = Object.keys(produtos_suplentes).map((key) => ({
          pro_in_codigo: produtos_suplentes[key].child_pro_in_codigo,
          pro_st_sku: produtos_suplentes[key].children.pro_st_sku,
          pro_st_descricao: produtos_suplentes[key].children.pro_st_descricao,
        }));

        const {
          harmonizacoes,
          selos,
          tipos,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create, // País name in case of create new one
          reg_in_codigo,
          reg_name_to_create, // Regiao name in case of create new one
          pro_st_conteudo,

          que_st_produtor,
          que_st_casca,
          que_st_massa,
          que_st_intensidade,
          que_st_variedadeleite,
          que_dt_datavalidade,
          que_bo_vegano,
          que_bo_vegetariano,
          que_lt_ingredientes,
          que_lt_informacoesnutri,
          que_lt_alergicos,
          que_lt_armazenamento,
        } = cheeseData;

        // main image path
        const main = getMainImagePath(images);

        // previous saved firebase files
        const previousURLs = getPreviouslySavedURLs(images);

        // new image files
        const newFiles = getNewImageFiles(images);

        const dataToCreate = {
          ...freightData,
          ...dataInfo,

          pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
          pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),

          product_type: 'queijo',

          similares,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create,
          reg_in_codigo,
          reg_name_to_create,
          pro_st_conteudo: pro_st_conteudo || '',
          pro_st_titulopage: infoData.pro_st_descricao,
          pro_st_nomesite: infoData.pro_st_descricao,
          pro_st_palavraschave: infoData.pro_st_tags,
          pro_st_video: imagens?.pro_st_video,
          tipos,
          harmonizacoes,
          selos,
          queijo: {
            que_st_produtor,
            que_st_casca,
            que_st_massa,
            que_st_intensidade,
            que_st_variedadeleite,
            que_dt_datavalidade,
            que_bo_vegano,
            que_bo_vegetariano,
            que_lt_ingredientes,
            que_lt_informacoesnutri,
            que_lt_alergicos,
            que_lt_armazenamento,
          },

          updatedBy: user.usu_in_codigo,

          main,
          previousURLs,
        };

        const dataToCreateWithouBlank = clearBlankKeysFromNested(dataToCreate);

        await review(pro_in_codigo, dataToCreateWithouBlank, newFiles);

        history.push('/produtos/review');
      } catch (err) {
        // handle error
        setLoading(false);
      }
    },
    [
      getMainImagePath,
      getPreviouslySavedURLs,
      getNewImageFiles,
      pro_in_codigo,
      history,
    ]
  );

  if (loadingData) return <Skeleton animation='wave' height={600} />;

  return (
    <Main>
      <Title>Produtos e SKUs | {titles[selected]}</Title>

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Especificações',
            component: () => <QueijoForm onNext={next} isEdit />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm onNext={next} onPrevious={previous} isEdit />
            ),
          },
          {
            title: 'Frete e Venda',
            component: () => (
              <FreteForm onNext={next} onPrevious={previous} isEdit />
            ),
          },
          {
            title: 'Imagens e Vídeos',
            component: () => (
              <ImagensForm
                loading={isLoading}
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
                isEdit
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
