import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get('vitrines/101/produtos', {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produtos integrados');
    throw e;
  }
}
