import styled from 'styled-components';

export const Li = styled.li`
  margin-left: 35px;
`;

export const ProductDescription = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75em;

  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
