/* eslint-disable prettier/prettier */
export function handleStatusMln(status) {
  const handleStatus = {
    'PEDIDO CADASTRADO': 'Pedido Cadastrado',
    'AG. ESTOQUE': 'Aguardando Estoque',
    'ENTREGUE': 'Entregue',
    'PEDIDO CANCELADO': 'Pedido Cancelado',
    'AG. PAGAMENTO BOLETO': 'Aguardando Pagamento Boleto',
    'AG. APROVA??O CART?O': 'Aguardando Aprovação Cartão',
    'AG. IMPRESS?O RELATORIO SEPARA??O':
      'Aguardando Impressão Relatorio Separação',
    'AG. CONFIRMA??O DE ENTREGA': 'Aguardando Confirmação De Entrega',
    'DESPACHADO': 'Despachado',
    'AG. FATURAMENTO': 'Aguardando Faturamento',
    'AG. EMBARQUE': 'Aguardando Embarque',
    'PEDIDO FINALIZADO': 'Pedido Finalizado',
    'AG. APROVA??O PLATAFORMA': 'Aguardando Aprovação Plataforma',
    'PAGAMENTO CONFIRMADO': 'Pagamento Confirmado',
    'AG APROVA??O FINANCEIRO': 'Aguardando Aprovação Financeiro',
  };

  return handleStatus[status] || '-';
}
