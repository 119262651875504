import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Autocomplete, Button, Select } from 'sonoma-design-system';

import { fetchProductTaxData } from '../../services/ProductTaxDataService';
import { ButtonDescription } from '../../styles';

function TaxFields({
  form,

  classificacoes_produto,
  perfis_impostos,
  origens_produto,
  codigos_fiscais,

  onFormChange,
  onSetForm,
  errorOnFill,
}) {
  const [isLoading, setLoading] = useState(false);

  const newWineSKU = useSelector(
    (state) => state.product.new.vinho.form.pro_st_sku
  );
  const newCheeseSKU = useSelector(
    (state) => state.product.new.queijo.form.pro_st_sku
  );
  const newGourmetSKU = useSelector(
    (state) => state.product.new.gourmet.form.pro_st_sku
  );
  const editWineSKU = useSelector(
    (state) => state.product.edit.vinho.form.pro_st_sku
  );
  const editCheeseSKU = useSelector(
    (state) => state.product.edit.queijo.form.pro_st_sku
  );
  const editGourmetSKU = useSelector(
    (state) => state.product.edit.gourmet.form.pro_st_sku
  );

  const fetchTaxData = useCallback(async () => {
    setLoading(true);

    try {
      const sku =
        newWineSKU ||
        newCheeseSKU ||
        newGourmetSKU ||
        editWineSKU ||
        editCheeseSKU ||
        editGourmetSKU;

      const data = await fetchProductTaxData(sku);

      onSetForm({
        clf_in_codigo: data.clf_in_codigo,
        clp_in_codigo: data.clp_in_codigo,
        ori_in_codigo: data.ori_in_codigo,
        pei_in_codigo: data.pei_in_codigo,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [
    newWineSKU,
    newCheeseSKU,
    newGourmetSKU,
    editWineSKU,
    editCheeseSKU,
    editGourmetSKU,
    onSetForm,
  ]);

  useEffect(() => {
    if (
      form.clp_in_codigo &&
      form.clf_in_codigo &&
      form.ori_in_codigo &&
      form.pei_in_codigo
    )
      return;

    fetchTaxData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  if (!perfis_impostos?.length > 0 || !codigos_fiscais?.length > 0)
    return <Skeleton animation='wave' height={300} />;

  if (isLoading) return <Skeleton animation='wave' height={300} />;

  return (
    <Grid
      spacing={2}
      container
      className={classes.root}
      style={{ marginTop: '15px' }}
    >
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={3}>
            <Autocomplete
              id='clf_in_codigo'
              options={codigos_fiscais}
              value={form?.clf_in_codigo}
              error={errorOnFill && !form?.clf_in_codigo}
              handleChange={(_event, selected) =>
                selected && onSetForm({ clf_in_codigo: selected.value })
              }
              size='small'
              label='Código Fiscal *'
              required
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Autocomplete
              id='pei_in_codigo'
              options={perfis_impostos}
              value={form?.pei_in_codigo}
              error={errorOnFill && !form?.pei_in_codigo}
              handleChange={(_event, selected) =>
                selected && onSetForm({ pei_in_codigo: selected.value })
              }
              size='small'
              label='Perfil Imposto *'
              required
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Select
              selectId='clp_in_codigo'
              error={errorOnFill && !form?.clp_in_codigo}
              options={[
                { value: null, label: 'Selecione' },
                ...classificacoes_produto,
              ]}
              value={form?.clp_in_codigo || ''}
              handleChange={onFormChange}
              size='small'
              label='Classificação do Produto'
              required
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Select
              selectId='ori_in_codigo'
              error={
                errorOnFill &&
                (form?.ori_in_codigo === null ||
                  form?.ori_in_codigo === undefined)
              }
              options={[
                { value: null, label: 'Selecione' },
                ...origens_produto,
              ]}
              value={form?.ori_in_codigo || ''}
              handleChange={onFormChange}
              size='small'
              label='Origem do produto'
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={12}>
            <Button outlined onClick={fetchTaxData}>
              <ButtonDescription>
                Carregar informações fiscais
              </ButtonDescription>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

TaxFields.propTypes = {
  form: PropTypes.object.isRequired,

  classificacoes_produto: PropTypes.array.isRequired,
  perfis_impostos: PropTypes.array.isRequired,
  origens_produto: PropTypes.array.isRequired,
  codigos_fiscais: PropTypes.array.isRequired,

  onFormChange: PropTypes.func.isRequired,
  onSetForm: PropTypes.func.isRequired,
  errorOnFill: PropTypes.bool,
};

TaxFields.defaultProps = {
  errorOnFill: false,
};

export default TaxFields;
