import PropTypes from 'prop-types';

import { CenterColumn } from '../styles';

export function Customer({ item }) {
  return <CenterColumn>{item.cliente.cli_st_nome}</CenterColumn>;
}

Customer.propTypes = {
  item: PropTypes.object.isRequired,
};
