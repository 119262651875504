import _ from 'lodash';
import PropTypes from 'prop-types';

import { HeaderContainer } from './styles';

export function CustomHeader({ date, changeYear, changeMonth }) {
  const years = _.range(1990, new Date().getFullYear() + 1, 1);

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return (
    <HeaderContainer>
      <select
        value={date.getFullYear()}
        onChange={({ target }) => changeYear(target.value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[date.getMonth()]}
        onChange={({ target }) => changeMonth(months.indexOf(target.value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </HeaderContainer>
  );
}

CustomHeader.propTypes = {
  date: PropTypes.instanceOf(Date),
  changeYear: PropTypes.func,
  changeMonth: PropTypes.func,
};

CustomHeader.defaultProps = {
  date: new Date(),
  changeYear: null,
  changeMonth: null,
};
