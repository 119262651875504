import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress, Grid } from '@material-ui/core';
import { AddCircle, InfoOutlined } from '@material-ui/icons';
import { Button, Checkbox } from 'sonoma-design-system';
import { toast } from 'react-toastify';
import { differenceInDays } from 'date-fns';

import { DatePickerComponent } from '../../components/DatePicker';
import { ProgressIndicator } from '../../components/ProgressIndicator';

import { Socket } from '../../config/socket';
import { getFaturamentoByFilial } from '../../services/FilialService';
import { getUser, getUserRoles } from '../../auth/auth';
import { setLoading } from '../../store/invoices';

import { Title, Divider } from './styles';

export default function GenerateXml() {
  const user = getUser();

  const [canFilterByFilial, setCanFilterByFilial] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [generatePdf, setGeneratePdf] = useState(false);

  const dispatch = useDispatch();

  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );
  const loading = useSelector((state) => state.invoices.isLoading);

  useEffect(() => {
    if (differenceInDays(endDate, startDate) > 31) {
      toast.warning(
        `XML não pode ser gerado. O período selecionado ultrapassa 31 dias`
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const userRoles = getUserRoles();

    if (
      userRoles.find(
        (e) => e === 'ADMIN' || e === 'INSIDER' || e === 'FINANCIAL'
      )
    ) {
      setCanFilterByFilial(true);
    }
  }, [canFilterByFilial, user.fil_in_codigo]);

  useEffect(() => {
    async function downloadXML() {
      const socket = await Socket.getInstance();

      if (!socket?.connected) return;

      socket.on(`invoice-files-done-${socket.id}`, (response) => {
        dispatch(setLoading(false));

        if (response.message === 'Empty') {
          toast.warning('Nenhum arquivo encontrado');
          return;
        }

        const { data } = response;

        const blob = new Blob([data], { type: 'application/zip' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', 'XMLs do Sonoma Market.zip');
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }

    downloadXML();
  }, [dispatch]);

  async function handleXmlDownload() {
    const socket = await Socket.getInstance();

    dispatch(setLoading(true));

    try {
      await getFaturamentoByFilial(
        {
          canFilterByFilial,
          filteringByFilial: selectedFilial?.fil_in_codigo || null,
          emails: [selectedFilial?.fil_st_email, user.usu_st_email],
        },
        startDate,
        endDate,
        socket.id,
        generatePdf
      );
    } catch (err) {
      dispatch(setLoading(false));
    }
  }

  return (
    <div
      style={{
        marginTop: '130px',
        backgroundColor: '#fff',
        padding: '0px 15px',
      }}
    >
      <Title hideButton>Gerar Notas Fiscais e XML dos pedidos</Title>

      <Divider />

      {loading ? (
        <ProgressIndicator />
      ) : (
        <>
          <p
            style={{
              display: 'flex',
              color: '#B00D1F',
              fontWeight: 700,
              margin: '30px 0 10px 0',
            }}
          >
            <InfoOutlined /> &nbsp; A data máxima a ser selecionada é de 31
            dias!
          </p>
          <p
            style={{
              display: 'flex',
              color: '#B00D1F',
              fontWeight: 700,
              margin: '0 0 10px 0',
            }}
          >
            <InfoOutlined /> &nbsp; O período selecionado se refere a data de
            emissão da nota!
          </p>
          <p
            style={{
              display: 'flex',
              color: '#B00D1F',
              fontWeight: 700,
              margin: '0 0 10px 0',
            }}
          >
            <InfoOutlined /> &nbsp; Gerar PDFs pode fazer com que o tempo de
            geração seja maior.
          </p>

          <div
            style={{
              display: 'flex',
              fontWeight: 700,
              margin: '0 0 30px 3px',
            }}
          >
            <Checkbox
              checkboxId='generatePdf'
              value={generatePdf}
              handleChange={() => setGeneratePdf((oldValue) => !oldValue)}
              label='Gerar PDFs'
            />
          </div>

          <Grid
            container
            xl={12}
            style={{
              display: 'flex',
            }}
          >
            <Grid
              xl={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '30px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <p style={{ marginBottom: '10px', fontWeight: '600' }}>
                    Selecione uma data de:
                  </p>
                  <DatePickerComponent
                    value={startDate}
                    setValue={setStartDate}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    margin: '0 20px',
                  }}
                >
                  <p style={{ marginBottom: '10px', fontWeight: '600' }}>
                    Selecione uma data até:
                  </p>
                  <DatePickerComponent value={endDate} setValue={setEndDate} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  <Button
                    onClick={() => handleXmlDownload()}
                    disabled={
                      loading || differenceInDays(endDate, startDate) > 31
                    }
                  >
                    {loading && (
                      <>
                        <CircularProgress size={30} />
                        &nbsp;
                      </>
                    )}
                    <AddCircle /> &nbsp; Gerar Arquivo
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
