import { toast } from 'react-toastify';

import { getToken } from '../auth/auth';
import api from './api';

const token = getToken();

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get('sellers/wait_list', {
      params: {
        ...paging,
        ...ordering,
        ...filters,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar usuários');
    throw e;
  }
}

export async function create(data) {
  try {
    await api.post('sellers/wait_list', data);

    toast.success('Cadastrado com sucesso!');
    return;
  } catch (e) {
    toast.error('Erro ao cadastrar');

    throw e;
  }
}
