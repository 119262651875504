import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { CustomHeader } from './CustomHeader';
import { CustomInput } from './CustomInput';

import './styles.css';

export function DatePickerComponent({ value, setValue, showTimeSelect }) {
  return (
    <DatePicker
      dateFormat={showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
      selected={value}
      onChange={(date) => setValue(date)}
      customInput={<CustomInput />}
      showTimeSelect={showTimeSelect}
      renderCustomHeader={(props) => <CustomHeader {...props} />}
    />
  );
}

DatePickerComponent.propTypes = {
  value: PropTypes.instanceOf(Date),
  setValue: PropTypes.func,
  showTimeSelect: PropTypes.bool,
};

DatePickerComponent.defaultProps = {
  value: new Date(),
  setValue: null,
  showTimeSelect: false,
};
