import { useCallback, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';

import { changePassword } from '../../services/UserService';

import NewPasswordForm from '../../components/NewPasswordForm';
import { Main } from './styles';

export default function NewPassword() {
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setLoading] = useState(false);

  const handleChangePassword = useCallback(
    async (newPassword, oldPassword) => {
      setLoading(true);

      try {
        await changePassword(id, newPassword, oldPassword);

        history.push('/usuarios');
      } catch (err) {
        setLoading(false);
      }
    },
    [history, id]
  );

  const handleCancel = useCallback(() => {
    history.push('/usuarios');
  }, [history]);

  if (isLoading) return <Skeleton animation='wave' height={300} />;

  return (
    <Main>
      <NewPasswordForm
        onSubmit={handleChangePassword}
        onCancel={handleCancel}
      />
    </Main>
  );
}
