import { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Input, Button } from 'sonoma-design-system';
import { Grid, CircularProgress } from '@material-ui/core';
import { ImageContent, ContainerLogin, SpanPassword } from './styles';

import { login } from '../../services/auth';
import { getToken } from '../../auth/auth';

import logo from '../../assets/logo-completo-lg.png';

const isAuthenticated = getToken();

export default function Login() {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleLogin() {
    setLoading(true);

    if (!email || !password) {
      setError(true);
      setLoading(false);
    }

    await login(email, password);

    setLoading(false);
  }

  if (isAuthenticated) return <Redirect to='/' />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ContainerLogin>
        <div style={{ marginTop: '70px' }}>
          <p
            style={{
              color: 'white',
              height: '20vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt='Logo' />
          </p>
        </div>
        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              width: '320px',
              marginTop: '70px',
              display: 'flex',
              flexDirection: 'column',
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          >
            <Grid spacing={2} container style={{ marginBottom: '30px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  margin: '5px 0px',
                }}
              >
                <h1
                  style={{
                    color: '#3B3B3A',
                    // fontSize: '24px',
                    lineHeight: '32px',
                    fontWeight: 600,
                  }}
                >
                  {' '}
                  Bem-vindo ao Backoffice Sonoma !{' '}
                </h1>
              </div>
            </Grid>
            <Grid spacing={2} container style={{ marginBottom: '20px' }}>
              {/* <p style={{ marginBottom: '10px' }}>Seu Email</p> */}
              <Input
                inputId='email'
                error={error && !email}
                errorText='Digite seu email'
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
                size='small'
                placeholder='Digite seu email'
                fullWidth
              />
            </Grid>
            <Grid spacing={2} container style={{ marginBottom: '20px' }}>
              {/* <p style={{ marginBottom: '10px' }}>Senha</p> */}
              <Input
                inputId='senha'
                error={error && !password}
                errorText='Digite sua senha'
                type='password'
                value={password}
                handleChange={(e) => setPassword(e.target.value)}
                size='small'
                placeholder='Digite sua senha'
                fullWidth
              />
            </Grid>
            <Grid spacing={2} container style={{ marginBottom: '20px' }}>
              <SpanPassword onClick={() => history.push('/recover/password')}>
                Esqueceu sua senha ?
              </SpanPassword>
            </Grid>
            <Grid spacing={2} container style={{ marginBottom: '15px' }}>
              <Button fullWidth onClick={() => handleLogin()}>
                {loading ? <CircularProgress size={25} /> : 'ENTRAR'}
              </Button>
            </Grid>
            <Grid spacing={2} container style={{ marginBottom: '15px' }}>
              <Button
                fullWidth
                outlined
                onClick={() => history.push('/new/seller')}
              >
                {loading ? (
                  <CircularProgress size={25} />
                ) : (
                  'Quero ser um seller !'
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </ContainerLogin>
      <ImageContent />
    </div>
  );
}
