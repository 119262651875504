import { useEffect, useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';

import { getUser } from '../../auth/auth';
import {
  findAllByFilial,
  findAllBySeller,
} from '../../services/FinancialConciliation';

import { Title, Divider } from './styles';

export default function SellerConciliacao() {
  const user = getUser();

  const [diretorios, setDiretorios] = useState([]);
  const [diretorioData, setDiretorioData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function handleData(data) {
      const directoryFiltered = data.map((e) => e.far_st_diretorio);
      const directoryWithoutDuplicated = [...new Set(directoryFiltered)];

      setDiretorios(directoryWithoutDuplicated);
      setDiretorioData(data);

      setLoading(false);
    }

    async function fetchDiretorioData() {
      if (user.slr_in_codigo) {
        const result = await findAllBySeller(user.slr_in_codigo);

        await handleData(result.data);
      } else {
        const result = await findAllByFilial(user.fil_in_codigo);

        await handleData(result.data);
      }
    }

    fetchDiretorioData();
  }, [user.fil_in_codigo, user.slr_in_codigo]);

  if (loading) return <Skeleton animation='wave' height={600} />;

  return (
    <div
      style={{
        margin: '130px 0',
        backgroundColor: '#fff',
        padding: '0px 15px 15px 15px',
      }}
    >
      <Title>Conciliação Financeira</Title>
      <Divider style={{ marginBottom: '30px' }} />
      {diretorios.map((e) => (
        <Accordion style={{ marginBottom: '15px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>{e}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {diretorioData
                .filter((el) => el.far_st_diretorio === e)
                .map((d) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItem: 'center',
                      width: '100%',
                    }}
                  >
                    <a
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                      href={d.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Avatar>
                        <MdFileDownload />
                      </Avatar>
                      <p>{d.far_st_nomearquivo}</p>
                    </a>
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
