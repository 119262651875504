/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from '@material-ui/lab';
import { Table } from 'sonoma-design-system';

import { CenterColumn, Title } from './styles';
import { getUser } from '../../auth/auth';

import { formatCurrency } from '../../utils/number';
import { Section } from '../../styles';
import {
  fetchInventory,
  clear as clearState,
} from '../../store/sellerInventory';

export default function SellerInventory() {
  const dispatch = useDispatch();

  const [filial, setFilial] = useState(false);
  const globalFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const { loading, error, products, paging, ordering, filters } = useSelector(
    (state) => state.sellerInventory
  );

  useEffect(() => {
    const user = getUser();

    const selected = globalFilial?.fil_in_codigo || user.fil_in_codigo;

    setFilial(selected);

    dispatch(fetchInventory(selected, paging, ordering, filters));

    return () => dispatch(clearState());
  }, [filial, globalFilial, dispatch]);

  function CurrencyCol({ item, column }) {
    return <CenterColumn>{formatCurrency(item[column.id])}</CenterColumn>;
  }

  CurrencyCol.propTypes = {
    item: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
  };

  function InventoryCol({ item }) {
    return (
      <CenterColumn>{parseInt(item.estoque[0].est_de_saldo, 10)}</CenterColumn>
    );
  }

  InventoryCol.propTypes = {
    item: PropTypes.object.isRequired,
  };

  function handlePageChange(page) {
    const pagingData = {
      page: page,
      total: paging.total,
      limit: paging.limit,
    };

    dispatch(fetchInventory(filial, pagingData, ordering, filters));
  }

  function handleOrderingChange(orderingChange) {
    const orderingData = {
      ...ordering,
      ...orderingChange,
    };

    dispatch(fetchInventory(filial, paging, orderingData, filters));
  }

  function handleSearch(ref) {
    dispatch(
      fetchInventory(filial, { page: 1, limit: paging.limit }, ordering, {
        ref,
      })
    );
  }

  const cols = useMemo(
    () => [
      { id: 'pro_st_descricao', title: 'Nome do produto' },
      { id: 'pro_de_precode', title: 'Preço de', component: CurrencyCol },
      { id: 'pro_de_precopor', title: 'Preço por', component: CurrencyCol },
      {
        id: 'pro_de_precoatacado',
        title: 'Preço caixa',
        component: CurrencyCol,
      },
      { id: 'saldo_estoque', title: 'Estoque atual', component: InventoryCol },
    ],
    []
  );

  return (
    <div style={{ marginTop: '130px' }}>
      <Title>Estoque</Title>

      <Section style={{ backgroundColor: '#fff' }}>
        {error && (
          <p className='text-danger'>
            Tivemos um problema ao carregar os dados do faturamento
          </p>
        )}

        {loading && <Skeleton animation='wave' height={600} />}

        {!loading && !error && (
          <Table
            rows={products}
            columns={cols}
            ordering={ordering}
            onChangeOrder={(order) => handleOrderingChange(order)}
            paging={paging}
            onChangePage={(page) => handlePageChange(page)}
            onSearch={(ref) => handleSearch(ref)}
            searchPlaceholder='Buscar por Nome ou SKU'
          />
        )}
      </Section>
    </div>
  );
}
