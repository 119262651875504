const INITIAL_STATE = {
  form: {
    images: [],
  },
};

const clearAll = () => INITIAL_STATE;

const setForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    ...action.payload,
  },
});

export default function imagensReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@imagens/clearAll':
      return clearAll(state, action);
    case '@imagens/setForm':
      return setForm(state, action);
    default:
      return state;
  }
}
