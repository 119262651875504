/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
import { findAll as findAllProductsByFilial } from '../../services/SellerProductService';
import { findAll as findAllProducts } from '../../services/ProductService';

export const setLoading = (loading) => ({
  type: '@editBulkProdutos/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@editBulkProdutos/setError',
  payload: error,
});

export const clear = () => ({
  type: '@editBulkProdutos/clear',
});

const setPaging = (paging) => ({
  type: '@editBulkProdutos/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@editBulkProdutos/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@editBulkProdutos/setFilters',
  payload: filters,
});

export const setProdutos = (pedidos) => ({
  type: '@editBulkProdutos/setProdutos',
  payload: pedidos,
});

export const setBulkEdit = (data) => ({
  type: '@editBulkProdutos/setBulkEdit',
  payload: data,
});

export const fetchProdutos =
  (
    aboutUser = { canSeeAllProdutos: false, filteringByFilial: false },
    paging,
    ordering,
    filters
  ) =>
  async (dispatch) => {
    const { canSeeAllProdutos, filteringByFilial, filialId } = aboutUser;

    try {
      dispatch(setLoading(true));

      const produtosData =
        !filteringByFilial && canSeeAllProdutos
          ? await findAllProducts(paging, ordering, { ...filters })
          : await findAllProductsByFilial(filialId, paging, ordering, {
              ...filters,
            });

      return dispatch([
        setLoading(false),
        setError(false),
        setPaging(produtosData._metadata.paging),
        setOrdering(produtosData._metadata.ordering),
        setFilters(produtosData._metadata.filters),
        setProdutos(produtosData.data),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };
