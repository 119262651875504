import { findAll as findAllHarmonizations } from '../../services/harmonizacao';
import { findAll as findAllTypes } from '../../services/tipo';
import { findAll as findAllRegions } from '../../services/regiao';
import { findAll as findAllCountries } from '../../services/pais';
import { findAll as findAllStamps } from '../../services/selos';

export const Types = {
  CLEAR: '@cheese/new/clear',
  SET_FORM: '@cheese/new/form/set_value',
  FORM_ERROR: '@cheese/new/form/error',

  // harmonizations
  ON_FETCH_HARMONIZATIONS: '@cheese/new/harmonizations/fetch',
  LOADING_HARMONIZATIONS: '@cheese/new/harmonizations/loading',
  HARMONIZATIONS_ERROR: '@cheese/new/harmonizations/error',

  // stamps
  ON_FETCH_STAMPS: '@cheese/new/stamps/fetch',
  LOADING_STAMPS: '@cheese/new/stamps/loading',
  ERROR_STAMPS: '@cheese/new/stamps/error',

  // types
  ON_FETCH_TYPES: '@wine/new/types/fetch',
  LOADING_TYPES: '@wine/new/types/loading',
  TYPES_ERROR: '@wine/new/types/error',

  // regions
  ON_FETCH_REGIONS: '@wine/new/regions/fetch',
  LOADING_REGIONS: '@wine/new/regions/loading',
  REGIONS_ERROR: '@wine/new/regions/error',

  // countries
  ON_FETCH_COUNTRIES: '@wine/new/countries/fetch',
  LOADING_COUNTRIES: '@wine/new/countries/loading',
  COUNTRIES_ERROR: '@wine/new/countries/error',
};

export const setForm = (data) => ({
  type: Types.SET_FORM,
  payload: data,
});

export const setErrorOnFill = (error) => ({
  type: Types.FORM_ERROR,
  payload: error,
});

export const clearAll = () => ({
  type: Types.CLEAR,
});

export const fetchHarmonizacoes = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_HARMONIZATIONS });

    const data = await findAllHarmonizations();

    return dispatch({ type: Types.ON_FETCH_HARMONIZATIONS, payload: data });
  } catch (e) {
    return dispatch({ type: Types.HARMONIZATIONS_ERROR });
  }
};

export const fetchStamps = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_STAMPS });

    const data = await findAllStamps();

    const filteredStamps = data.filter(
      (e) =>
        e.sel_st_descricao === 'bestSellers' ||
        e.sel_st_descricao === 'organico' ||
        e.sel_st_descricao === 'natural'
    );

    return dispatch({ type: Types.ON_FETCH_STAMPS, payload: filteredStamps });
  } catch (e) {
    return dispatch({ type: Types.ERROR_STAMPS });
  }
};

export const fetchTypes = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_TYPES });

    const data = await findAllTypes();

    const filteredTypes = data.filter(
      (e) =>
        e.tip_st_descricao === 'Best-Sellers' ||
        e.tip_st_descricao === 'Orgânico-Bio' ||
        e.tip_st_descricao === 'Natural'
    );

    return dispatch({ type: Types.ON_FETCH_TYPES, payload: filteredTypes });
  } catch (e) {
    return dispatch({ type: Types.TYPES_ERROR });
  }
};

export const fetchRegions = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_REGIONS });

    const data = await findAllRegions();

    return dispatch({ type: Types.ON_FETCH_REGIONS, payload: data });
  } catch (e) {
    return dispatch({ type: Types.REGIONS_ERROR });
  }
};

export const fetchCountries = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_COUNTRIES });

    const data = await findAllCountries();

    return dispatch({ type: Types.ON_FETCH_COUNTRIES, payload: data });
  } catch (e) {
    return dispatch({ type: Types.COUNTRIES_ERROR });
  }
};

export const fetchData = () => async (dispatch) =>
  dispatch([
    fetchHarmonizacoes(),
    fetchStamps(),
    fetchTypes(),
    fetchRegions(),
    fetchCountries(),
  ]);
