import { toast } from 'react-toastify';

import { getToken, getUser } from '../auth/auth';
import api from './api';

const token = getToken();

function formatRequestBody(files) {
  const formData = new FormData();

  // append files
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    formData.append('files', file);
  }

  return formData;
}

export async function createByCsv(files) {
  try {
    const user = getUser();
    const formData = formatRequestBody(files);

    await api.post(`sellers/${user.slr_in_codigo}/produtos/csv`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(
      'O arquivo CSV esta sendo processado, quando finalizado os produtos aparecerão na listagem.'
    );
  } catch (e) {
    toast.error(
      'Erro ao tentar cadastrar/editar produtos por csv. Tente novamente mais tarde, caso o problema persista, contate um administrador'
    );

    throw e;
  }
}
