import PropTypes from 'prop-types';

import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';

import { Skeleton } from '@material-ui/lab';
import { MdAddCircle, MdDelete, MdEdit } from 'react-icons/md';
import { Table, Button, Options } from 'sonoma-design-system';

import { Title, ButtonContent, CenterColumn, Error, Section } from './styles';

import { fetchAll } from '../../store/userList';
import { remove } from '../../services/UserService';

function DateColumn({ item }) {
  return (
    <CenterColumn>
      {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
};

function ButtonAdd() {
  const history = useHistory();

  return (
    <Button size='sm' onClick={() => history.push('/usuarios/create')}>
      <ButtonContent>
        Adicionar <MdAddCircle size={21} />
      </ButtonContent>
    </Button>
  );
}

function Actions({ item }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const paging = useSelector((state) => state.user.list.paging);
  const ordering = useSelector((state) => state.user.list.ordering);
  const filters = useSelector((state) => state.user.list.filters);

  const deleteUser = useCallback(
    async (id) => {
      await remove(id);

      dispatch(fetchAll(paging, ordering, filters));
    },
    [dispatch, paging, ordering, filters]
  );

  return (
    <td>
      <Options
        options={[
          {
            icon: MdEdit,
            text: 'Editar',
            onClick: () => history.push(`/usuarios/${item.usu_in_codigo}/edit`),
          },
          {
            icon: MdDelete,
            text: 'Excluir',
            link: '/usuarios',
            onClick: () => deleteUser(item.usu_in_codigo),
          },
        ]}
      />
    </td>
  );
}

Actions.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function UserList() {
  const dispatch = useDispatch();

  const { users, loading, error, paging, ordering, filters } = useSelector(
    (state) => state.user.list
  );

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch]);

  const cols = useMemo(
    () => [
      { id: 'usu_st_nome', title: 'Nome' },
      { id: 'usu_st_email', title: 'E-mail' },
      { id: 'createdAt', title: 'Data de criação', component: DateColumn },
      { id: 'action', title: '', component: Actions },
    ],
    []
  );

  return (
    <Section>
      <Title>Lista de Usuários</Title>

      {loading && <Skeleton animation='wave' height={300} />}

      {error && <Error>Tivemos um problema ao buscar os usuários.</Error>}

      {!loading && !error && (
        <Table
          rows={users}
          columns={cols}
          ordering={ordering}
          onChangeOrder={(order) => dispatch(fetchAll(paging, order, filters))}
          paging={paging}
          onChangePage={(page) =>
            dispatch(fetchAll({ page, limit: paging.limit }, ordering, filters))
          }
          onSearch={(ref) =>
            dispatch(
              fetchAll(
                { page: 1, limit: paging.limit },
                { order_by: 'createdAt', order: 'desc' },
                { ref }
              )
            )
          }
          searchPlaceholder='Buscar por nome ou e-mail'
          headerComponent={() => <ButtonAdd />}
        />
      )}
    </Section>
  );
}
