const INITIAL_STATE = {
  filiais: [],
  filial: null,
  loading: false,
  error: false,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order_by: null,
    order: 'asc',
    availableOrderby: [],
  },
  filters: {
    ref: '',
  },
};

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const setPaging = (state, action) => ({
  ...state,
  paging: action.payload,
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: action.payload,
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setFiliais = (state, action) => ({
  ...state,
  loading: false,
  filiais: action.payload,
});

const setFilial = (state, action) => ({
  ...state,
  loading: false,
  filial: action.payload,
});

const setForm = (state, action) => ({
  ...state,
  filial: {
    ...state.filial,
    ...action.payload,
  },
});

const clearAll = () => INITIAL_STATE;

export default function freteEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@filiais/setFormFilial':
      return setForm(state, action);
    case '@filiais/setFiliais':
      return setFiliais(state, action);
    case '@filiais/setFilial':
      return setFilial(state, action);
    case '@filiais/setLoading':
      return setLoading(state, action);
    case '@filiais/setError':
      return setError(state, action);
    case '@filiais/setPaging':
      return setPaging(state, action);
    case '@filiais/setOrdering':
      return setOrdering(state, action);
    case '@filiais/setFilters':
      return setFilters(state, action);
    case '@filiais/clearAll':
      return clearAll(state, action);
    default:
      return state;
  }
}
