/* eslint-disable no-underscore-dangle */
import {
  findAll,
  findAllBySeller,
} from '../../services/DeliveryBySellerOrdersService';

export const Types = {
  CLEAR: '@deliveryBySeller/orders/clear',
  SET_LOADING: '@deliveryBySeller/orders/loading/set',
  SET_ERROR: '@deliveryBySeller/orders/error/set',

  SET_PAGING: '@deliveryBySeller/orders/paging/set',
  SET_ORDERING: '@deliveryBySeller/orders/ordering/set',
  SET_FILTERS: '@deliveryBySeller/orders/filters/set',

  SET_ORDERS: '@deliveryBySeller/orders/data/set',
};

export const setLoading = (loading) => ({
  type: Types.SET_LOADING,
  payload: loading,
});

export const setError = (error) => ({
  type: Types.SET_ERROR,
  payload: error,
});

export const clear = () => ({
  type: Types.CLEAR,
});

const setPaging = (paging) => ({
  type: Types.SET_PAGING,
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: Types.SET_ORDERING,
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: Types.SET_FILTERS,
  payload: filters,
});

export const setOrders = (orders) => ({
  type: Types.SET_ORDERS,
  payload: orders,
});

export const fetchOrders =
  (sellerId, paging, ordering, filters) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = sellerId
        ? await findAllBySeller(sellerId, paging, ordering, filters)
        : await findAll(paging, ordering, filters);

      return dispatch([
        setLoading(false),
        setError(false),
        setPaging(response._metadata.paging),
        setOrdering(response._metadata.ordering),
        setFilters(response._metadata.filters),
        setOrders(response.data),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };
