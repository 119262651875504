import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';

import { create } from '../../services/UserService';

import UserForm from '../../components/UserForm';
import { Main, Title } from './styles';

export default function NewUser() {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const handleSaveUser = useCallback(
    async (user) => {
      setLoading(true);

      try {
        await create(
          {
            usu_st_nome: user.name,
            usu_st_email: user.email,
            usu_st_senha: user.password,
            fil_in_codigo: user.branch,
            slr_in_codigo: user.seller,
          },
          user.roles
        );

        history.push('/usuarios');
      } catch (err) {
        setLoading(false);
      }
    },
    [history]
  );

  const handleCancel = useCallback(() => {
    history.push('/usuarios');
  }, [history]);

  if (isLoading)
    return (
      <Skeleton animation='wave' style={{ marginTop: '30px' }} height={300} />
    );

  return (
    <Main>
      <Title>Cadastro de usuário</Title>

      <UserForm onSubmit={handleSaveUser} onCancel={handleCancel} />
    </Main>
  );
}
