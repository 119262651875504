import PropTypes from 'prop-types';

import { ModalComponent as Modal, Button } from 'sonoma-design-system';
import { ButtonContainer, MainButtonsContainer, Text, Title } from './styles';

function ReviewProductModal({
  open,
  setOpen,
  onSelectWine,
  onSelectCheese,
  onSelectGourmet,
}) {
  return (
    <Modal
      modalId='product_type'
      showButton={false}
      open={open}
      setOpen={setOpen}
    >
      <Title>Conta mais sobre esse produto 🤔</Title>

      <Text>
        Não temos informações suficiente sobre esse produto para carregar o
        formulário corretamente, você pode nos ajudar selecionando a categoria
        conforme as opções abaixo:
      </Text>

      <ButtonContainer>
        <MainButtonsContainer>
          <Button onClick={onSelectWine}>Vinho</Button>
          <Button onClick={onSelectCheese}>Queijo</Button>
          <Button onClick={onSelectGourmet}>Outros Produtos</Button>
        </MainButtonsContainer>

        <Button outlined onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </ButtonContainer>
    </Modal>
  );
}

ReviewProductModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onSelectWine: PropTypes.func.isRequired,
  onSelectCheese: PropTypes.func.isRequired,
  onSelectGourmet: PropTypes.func.isRequired,
};

ReviewProductModal.defaultProps = {
  open: false,
};

export default ReviewProductModal;
