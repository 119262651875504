import styled from 'styled-components';

export const Main = styled.main`
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  color: #000;
  margin-top: 10px;
  margin-bottom: 60px;
  text-transform: uppercase;
`;
