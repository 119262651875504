import PropTypes from 'prop-types';
import { useCallback } from 'react';

import './styles.css';

const integrationErrorStatus = 'E';

export default function MagentoIntegrationColumn({ item }) {
  const itsError = item.pro_st_status_integration === integrationErrorStatus;

  const handleTitleDescription = itsError ? item.pro_lt_integration_err : '';

  const handleIntegrationStatusDescription = useCallback(
    (integtarionStatus) => {
      const renderText = {
        W: 'Aguardando integração',
        I: 'Integrando produto',
        S: 'Produto integrado',
        E: 'Erro ao integrar',
      };

      return renderText[integtarionStatus] || '-';
    },
    []
  );

  return (
    <td title={handleTitleDescription} className={itsError && 'error'}>
      <p className='text-container'>
        {handleIntegrationStatusDescription(item.pro_st_status_integration)}
      </p>
    </td>
  );
}

MagentoIntegrationColumn.propTypes = {
  item: PropTypes.object.isRequired,
};
