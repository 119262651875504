import styled from 'styled-components';

export const Main = styled.main`
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  color: #3b3b3a;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
  /* margin-bottom: 60px; */
  /* text-transform: uppercase; */
`;
