import PropTypes from 'prop-types';
import { Title } from './styles';

import Divider from '../Divider';

function FieldsetTitle({ children }) {
  return (
    <>
      <Title>{children}</Title>

      <Divider style={{ margin: '10px 0px 30px 0px' }} />
    </>
  );
}

FieldsetTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FieldsetTitle;
