/* eslint-disable eqeqeq */
import { useState, useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Grid, makeStyles, Avatar } from '@material-ui/core';
import { MdAddCircle } from 'react-icons/md';

import { Input, Select, Tag, Button } from 'sonoma-design-system';
import { TagsContainer } from './styles';
import SkuAndDescriptionFields from '../SkuAndDescriptionFields';
import {
  formatInteger,
  formatNumber,
  generateId,
  reverseFormatNumber,
} from '../../utils/number';
import { getUserRoles } from '../../auth/auth';

import bestSellerIcon from '../../assets/best-seller-icon.png';
import organicoIcon from '../../assets/organico-icon.png';
import naturalIcon from '../../assets/natural-icon.png';
import rp100Icon from '../../assets/rp-100-icon.png';
import signature_15 from '../../assets/signature_15.png';

function VinhoFields({
  form,

  uvas,
  tipos,
  paises,
  regioes,
  harmonizacoes,
  selos,
  curadores,

  onFormChange,
  onAddTag,
  onAddTags,
  onSetForm,
  errorOnFill,
  fetchTaxData,

  publisher,

  skuExist,
  isEdit,

  descricaoExist,
}) {
  const [customGrapes, setCustomGrapes] = useState('');

  const isDeliveryBySeller = getUserRoles().includes('DELIVERY_BY_SELLER');
  const isFFH = getUserRoles().includes('FULFILLS_H');

  // effect to exclude pai_name_to_create when doesn't need
  useEffect(() => {
    if (form.pai_in_codigo != 999) {
      onSetForm({
        pai_name_to_create: null,
      });
    }

    if (form.reg_in_codigo != 999) {
      onSetForm({
        reg_name_to_create: null,
      });
    }
  }, [form.pai_in_codigo, form.reg_in_codigo, onSetForm]);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const handleAddCustomGrapes = useCallback(() => {
    // avoid empty
    if (customGrapes.length === 0) return;

    const newGrapes = customGrapes.split(',').map((desc) => ({
      tuv_in_codigo: generateId(),
      tuv_st_descricao: desc.trim(),
      new: true,
    }));

    onAddTags('uvas', newGrapes, 'tuv_st_descricao');

    setCustomGrapes('');
  }, [customGrapes, onAddTags]);

  function handleIconSelos() {
    function renderIcon(tag, iconSrc) {
      return (
        <Avatar
          style={{ cursor: 'pointer' }}
          alt={tag.sel_in_codigo}
          src={iconSrc}
          onClick={() =>
            onSetForm({
              selos: form.selos.filter(
                (t) => t.sel_in_codigo !== tag.sel_in_codigo
              ),
            })
          }
        />
      );
    }
    return (
      <TagsContainer>
        {form?.selos?.map((tag) => {
          if (tag.sel_st_descricao === 'organico') {
            return renderIcon(tag, organicoIcon);
          }
          if (tag.sel_st_descricao === 'bestSellers') {
            return renderIcon(tag, bestSellerIcon);
          }
          if (tag.sel_st_descricao === 'natural') {
            return renderIcon(tag, naturalIcon);
          }
          if (tag.sel_st_descricao === 'Pontuados +90') {
            return renderIcon(tag, rp100Icon);
          }
          if (tag.sel_st_descricao === 'Desconto Signature') {
            return renderIcon(tag, signature_15);
          }
          return null;
        })}
      </TagsContainer>
    );
  }

  return (
    <Grid
      spacing={2}
      container
      className={classes.root}
      style={{ marginTop: '30px' }}
    >
      <SkuAndDescriptionFields
        publisher={publisher}
        errorSkuOnFill={errorOnFill}
        skuForm={form}
        skuExist={skuExist}
        onFormSkuChange={onFormChange}
        isEdit={isEdit}
        descricaoExist={descricaoExist}
        fetchTaxData={fetchTaxData}
        wine
      />
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          {!isDeliveryBySeller && !isFFH && (
            <Grid item xs={6} lg={4}>
              <Select
                selectId='tipos'
                size='small'
                label='Tipo'
                options={[{ value: null, label: 'Selecione' }, ...tipos]}
                handleChange={(e) =>
                  onAddTag(
                    e.target.name,
                    {
                      tip_in_codigo: e.target.value,
                      tip_st_descricao: tipos.find(
                        (t) => t.value === Number(e.target.value)
                      ).label,
                    },
                    'tip_in_codigo'
                  )
                }
              />
              <TagsContainer>
                {form?.tipos?.map((tag) => (
                  <Tag
                    key={tag.tip_in_codigo}
                    onDelete={() =>
                      onSetForm({
                        tipos: form.tipos.filter(
                          (t) => t.tip_in_codigo !== tag.tip_in_codigo
                        ),
                      })
                    }
                  >
                    {tag.tip_st_descricao}
                  </Tag>
                ))}
              </TagsContainer>
            </Grid>
          )}

          <Grid item xs={6} lg={4}>
            <Select
              selectId='pai_in_codigo'
              options={[
                { value: null, label: 'Selecione' },
                { value: 999, label: 'Outros' },
                ...paises,
              ]}
              value={form?.pai_in_codigo || ''}
              handleChange={onFormChange}
              size='small'
              label='País'
            />
          </Grid>

          {form.pai_in_codigo == 999 && (
            <Grid item xs={6} lg={4}>
              <Input
                error={errorOnFill && !form?.pai_name_to_create}
                errorText='Inserir nome do país'
                inputId='pai_name_to_create'
                value={form?.pai_name_to_create || ''}
                handleChange={(e) =>
                  onSetForm({
                    pai_name_to_create: e.target.value,
                  })
                }
                size='small'
                label='Nome do país'
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={6} lg={4}>
            <Select
              selectId='reg_in_codigo'
              options={[
                { value: null, label: 'Selecione' },
                { value: 999, label: 'Outros' },
                ...regioes,
              ]}
              value={form?.reg_in_codigo || ''}
              handleChange={onFormChange}
              size='small'
              label='Regiões'
              disabled={!form?.pai_in_codigo}
            />
          </Grid>

          {form.reg_in_codigo == 999 && (
            <Grid item xs={6} lg={4}>
              <Input
                error={errorOnFill && !form?.reg_name_to_create}
                errorText='Inserir nome a região'
                inputId='reg_name_to_create'
                value={form?.reg_name_to_create || ''}
                handleChange={(e) =>
                  onSetForm({
                    reg_name_to_create: e.target.value,
                  })
                }
                size='small'
                label='Nome da região'
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={4}>
            <Input
              isNumeric
              inputId='vin_de_teoralcoolico'
              value={reverseFormatNumber(form?.vin_de_teoralcoolico) || ''}
              handleChange={(e) =>
                onSetForm({
                  vin_de_teoralcoolico: formatNumber(
                    e.target.value.replaceAll('%', '')
                  ),
                })
              }
              size='small'
              label='Teor Alcóolico'
              helperText='Em porcentagem (%)'
              fullWidth
              suffix='%'
            />
          </Grid>

          <Grid item xs={6} lg={4}>
            <Input
              isNumeric
              inputId='vin_de_temperatura'
              value={reverseFormatNumber(form?.vin_de_temperatura) || ''}
              handleChange={(e) =>
                onSetForm({
                  vin_de_temperatura: formatNumber(
                    e.target.value.replaceAll('ºC', '')
                  ),
                })
              }
              size='small'
              helperText='Em ºC'
              label='Temperatura'
              suffix='ºC'
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={4}>
            <Input
              isNumeric
              inputId='vin_in_tempodescanso'
              value={reverseFormatNumber(form?.vin_in_tempodescanso) || ''}
              handleChange={(e) =>
                onSetForm({
                  vin_in_tempodescanso: formatInteger(e.target.value),
                })
              }
              size='small'
              label='Tempos de Descanso'
              helperText='Em minutos'
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={4}>
            <Select
              selectId='uvas'
              size='small'
              label='Tipos de Uvas'
              options={[{ value: null, label: 'Selecione' }, ...uvas]}
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    tuv_in_codigo: e.target.value,
                    tuv_st_descricao: uvas.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'tuv_in_codigo'
                )
              }
            />

            <TagsContainer>
              {form?.uvas?.map((tag) => (
                <Tag
                  key={tag.tuv_in_codigo}
                  onDelete={() =>
                    onSetForm({
                      uvas: form.uvas.filter(
                        (t) => t.tuv_in_codigo !== tag.tuv_in_codigo
                      ),
                    })
                  }
                >
                  {tag.tuv_st_descricao}
                </Tag>
              ))}
            </TagsContainer>
          </Grid>

          <Grid item xs={6} lg={4}>
            <Input
              inputId='customGrapes'
              value={customGrapes}
              handleChange={(e) => setCustomGrapes(e.target.value)}
              size='small'
              label='Outras uvas'
              helperText='Para adicionar múltiplas uvas, separe-as por vírgula. Ex: Merlot, Chardonnay, Sangiovese'
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={4}>
            <Button
              onClick={handleAddCustomGrapes}
              outlined
              fullWidth
              size='medium'
              icon={<MdAddCircle />}
            >
              Novas uvas
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={publisher ? 6 : 4}>
            <Input
              isNumeric
              inputId='vin_in_tempobarrica'
              value={reverseFormatNumber(form?.vin_in_tempobarrica) || ''}
              handleChange={(e) =>
                onSetForm({
                  vin_in_tempobarrica: formatInteger(e.target.value),
                })
              }
              size='small'
              label='Tempo de Barrica'
              placeholder='Em Meses'
              helperText='Inserir tempo de barrica de carvalho do vinho'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={publisher ? 6 : 4}>
            <Input
              type='number'
              inputId='pro_st_conteudo'
              value={form?.pro_st_conteudo || ''}
              handleChange={onFormChange}
              size='small'
              label='Conteúdo'
              helperText='(ml)'
              fullWidth
            />
          </Grid>

          {!publisher && (
            <Grid item xs={12} lg={4}>
              <Select
                selectId='cur_in_codigo'
                options={[{ value: null, label: 'Selecione' }, ...curadores]}
                value={form?.cur_in_codigo || ''}
                handleChange={onFormChange}
                size='small'
                label='Curador'
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={publisher ? 6 : 4}>
            <Select
              selectId='harmonizacoes'
              options={[{ value: null, label: 'Selecione' }, ...harmonizacoes]}
              size='small'
              label='Harmonização'
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    har_in_codigo: e.target.value,
                    har_st_descricao: harmonizacoes.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'har_in_codigo'
                )
              }
            />
            <TagsContainer>
              {form?.harmonizacoes?.map((tag) => (
                <Tag
                  key={tag.har_in_codigo}
                  onDelete={() =>
                    onSetForm({
                      harmonizacoes: form.harmonizacoes.filter(
                        (t) => t.har_in_codigo !== tag.har_in_codigo
                      ),
                    })
                  }
                >
                  {tag.har_st_descricao}
                </Tag>
              ))}
            </TagsContainer>
          </Grid>

          <Grid item xs={12} lg={publisher ? 6 : 4}>
            <Select
              selectId='selos'
              size='small'
              label='Selos'
              options={[{ value: null, label: 'Selecione' }, ...selos]}
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    sel_in_codigo: e.target.value,
                    sel_st_descricao: selos.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'sel_in_codigo'
                )
              }
            />

            {handleIconSelos()}
            <TagsContainer>
              {form?.selos?.map((tag) => {
                if (
                  tag.sel_st_descricao !== 'organico' &&
                  tag.sel_st_descricao !== 'bestSellers' &&
                  tag.sel_st_descricao !== 'natural' &&
                  tag.sel_st_descricao !== 'Desconto Signature' &&
                  tag.sel_st_descricao !== 'Pontuados +90'
                ) {
                  return (
                    <Tag
                      key={tag.sel_in_codigo}
                      onDelete={() =>
                        onSetForm({
                          selos: form.selos.filter(
                            (t) => t.sel_in_codigo !== tag.sel_in_codigo
                          ),
                        })
                      }
                    >
                      {tag.sel_st_descricao}
                    </Tag>
                  );
                }
                return null;
              })}
            </TagsContainer>
          </Grid>

          {!publisher && (
            <Grid item xs={12} lg={4}>
              <Input
                isNumeric
                inputId='vin_in_quantidadecaixa'
                value={form?.vin_in_quantidadecaixa || ''}
                handleChange={(e) =>
                  onSetForm({
                    vin_in_quantidadecaixa: formatInteger(e.target.value),
                  })
                }
                size='small'
                label='Vinhos na caixa'
                helperText='Qtde. de vinhos na caixa'
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

VinhoFields.propTypes = {
  form: PropTypes.object.isRequired,

  uvas: PropTypes.array.isRequired,
  tipos: PropTypes.array.isRequired,
  paises: PropTypes.array.isRequired,
  regioes: PropTypes.array.isRequired,
  harmonizacoes: PropTypes.array.isRequired,
  selos: PropTypes.array.isRequired,
  curadores: PropTypes.array.isRequired,

  onFormChange: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onAddTags: PropTypes.func.isRequired,
  onSetForm: PropTypes.func.isRequired,
  errorOnFill: PropTypes.bool,
  publisher: PropTypes.bool,
  fetchTaxData: PropTypes.func,

  // check if sku exist
  // if exist don't go to the next step
  skuExist: PropTypes.bool,
  // check if descricao exist
  // if exist don't go to the next step
  descricaoExist: PropTypes.bool,
  isEdit: PropTypes.bool,
};

VinhoFields.defaultProps = {
  errorOnFill: false,
  publisher: false,
  skuExist: false,
  descricaoExist: false,
  isEdit: false,
  fetchTaxData: null,
};

export default VinhoFields;
