import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get('usuarios', {
      params: {
        ...paging,
        ...ordering,
        ...filters,
        roles: 'INSIDER,WRITER,SELLER',
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar usuários');
    throw e;
  }
}

export async function findById(id) {
  try {
    const response = await api.get(`usuarios/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar usuário');
    throw e;
  }
}

export async function create(user, roles) {
  try {
    await api.post(
      'usuarios',
      { ...user, roles },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Usuário cadastrado com sucesso!');
  } catch (e) {
    if (e.response?.data?.error?.message === 'EMAIL_ALREADY_REGISTERED') {
      toast.error('E-mail já cadastrado');
      throw e;
    }

    toast.error('Erro ao salvar usuário');
    throw e;
  }
}

export async function update(id, user, roles) {
  try {
    await api.put(
      `usuarios/${id}`,
      { ...user, roles },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Sucesso ao editar usuário');
  } catch (e) {
    toast.error('Erro ao editar usuário');
    throw e;
  }
}

export async function changePassword(id, newPassword, oldPassword) {
  try {
    await api.post(
      `usuarios/${id}/new_password`,
      {
        new_password: newPassword,
        old_password: oldPassword,
      },
      {
        headers: { authorization: `bearer ${token}` },
      }
    );

    toast.success('Sucesso ao alterar senha');
  } catch (e) {
    if (e.response?.data?.error_status === 'PASSWORD_MISMATCH') {
      toast.error('Senha atual incorreta');
      throw e;
    }

    if (e.response?.data?.error_status === 'FORBIDDEN') {
      toast.error('Você não tem permissões para alterar a senha');
      throw e;
    }

    if (e.response?.data?.error_status === 'EQUAL_PASSWORDS') {
      toast.error('Sua nova senha não deve ser igual a atual');
      throw e;
    }

    if (e.response?.data?.error_status === 'INVALID_LENGTH') {
      toast.error('A senha deve ter no mínimo 6 caracteres');
      throw e;
    }

    toast.error('Falha ao alterar senha');
    throw e;
  }
}

export async function remove(userId) {
  try {
    await api.delete(`usuarios/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Sucesso ao remover usuário');
  } catch (e) {
    toast.error('Erro ao remover usuário');
    throw e;
  }
}
