import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import multi from 'redux-multi';
import { composeWithDevTools } from 'redux-devtools-extension';

import loading from './loading';
import productsListReducer from './productsList';
import gourmetNewReducer from './gourmetNew';
import queijoNewReducer from './queijoNew';
import vinhoNewReducer from './vinhoNew';
import informacoesForm from './informacoesForm';
import freteForm from './freteForm';
import userListReducer from './userList';
import userEditReducer from './userEdit';
import vinhoEdit from './vinhoEdit';
import informacoesEdit from './informacoesEdit';
import freteEdit from './freteEdit';
import queijoEdit from './queijoEdit';
import gourmetEdit from './gourmetEdit';
import imagensForm from './imagensForm';
import newKitReducer from './newKit';
import billingReducer from './billing';
import editBulkProdutosReducer from './editBulkProdutos';
import pedidosReducer from './pedidos';
import dbsOrdersReducer from './dbsOrders';
import filiaisReducer from './filiais';
import sellersReducer from './sellers';
import curadorReducer from './curador';
import selectedFilialToFilterReducer from './selectedFilialToFilter';
import salesListReducer from './sales';
import invoicesReducer from './invoices';
import sellerInventoryReducer from './sellerInventory';

const reducers = combineReducers({
  loading,
  product: combineReducers({
    list: productsListReducer,
    new: combineReducers({
      queijo: queijoNewReducer,
      gourmet: gourmetNewReducer,
      vinho: vinhoNewReducer,
      kit: newKitReducer,
      informacoes: informacoesForm,
      frete: freteForm,
      imagens: imagensForm,
    }),
    edit: combineReducers({
      gourmet: gourmetEdit,
      vinho: vinhoEdit,
      queijo: queijoEdit,
      informacoes: informacoesEdit,
      frete: freteEdit,
      imagens: imagensForm,
    }),
  }),
  user: combineReducers({
    list: userListReducer,
    edit: userEditReducer,
  }),
  billing: billingReducer,
  editBulkProdutos: editBulkProdutosReducer,
  pedidos: pedidosReducer,
  dbsOrders: dbsOrdersReducer,
  filiais: filiaisReducer,
  sellers: sellersReducer,
  curador: curadorReducer,
  selectedFilialToFilter: selectedFilialToFilterReducer,
  invoices: invoicesReducer,
  sales: salesListReducer,
  sellerInventory: sellerInventoryReducer,
});

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk, promise, multi))
);

export default store;
