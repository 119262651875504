const INITIAL_STATE = {
  perfis_impostos: [],
  codigos_fiscais: [],
  categories_vitrine: [],
  classificacoes_produto: [],
  categorias: [],
  departamentos: [],
  politicas_comerciais: [],
  selos: [],
  loading: false,
  error: false,
  errorOnFill: false,
  form: {
    pro_bo_ativo: true,
    pro_bo_exibirnosite: true,
    pro_bo_mostraresgotado: true,
    pro_de_precoatacado: null,
    produtos_suplentes: [],
    pro_bo_express: false,
  },
};

const setForm = (state, action) => ({
  ...state,
  loading: false,
  form: {
    ...state.form,
    ...action.payload,
  },
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });
const setErrorOnFill = (state, action) => ({
  ...state,
  errorOnFill: action.payload,
});

const clearAll = () => INITIAL_STATE;

export default function informacoesEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@informacoesEdit/setForm':
      return setForm(state, action);
    case '@informacoesEdit/setLoading':
      return setLoading(state, action);
    case '@informacoesEdit/setError':
      return setError(state, action);
    case '@informacoesEdit/setErrorOnFill':
      return setErrorOnFill(state, action);
    case '@informacoesEdit/clearAll':
      return clearAll(state, action);
    default:
      return state;
  }
}
