import { Types } from './queijoNew.action';

const INITIAL_STATE = {
  harmonizacoes: {
    loading: false,
    error: false,
    data: [],
  },
  selos: {
    loading: false,
    error: false,
    data: [],
  },
  tipos: {
    loading: false,
    error: false,
    data: [],
  },
  regioes: {
    loading: false,
    error: false,
    data: [],
  },
  paises: {
    loading: false,
    error: false,
    data: [],
  },
  form: {
    que_bo_vegano: false,
    que_bo_vegetariano: false,
    tipos: [],
    harmonizacoes: [],
    selos: [],
  },
  errorOnFill: false,
};

const setHarmonizations = (state, action) => ({
  ...state,
  harmonizacoes: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingHarmonizations = (state) => ({
  ...state,
  harmonizacoes: {
    ...state.harmonizacoes,
    loading: true,
  },
});

const setHarmonizationsError = (state) => ({
  ...state,
  harmonizacoes: {
    ...state.harmonizacoes,
    error: true,
  },
});

const setStamps = (state, action) => ({
  ...state,
  selos: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingStamps = (state) => ({
  ...state,
  selos: {
    ...state.selos,
    loading: true,
  },
});

const setStampsError = (state) => ({
  ...state,
  selos: {
    ...state.selos,
    error: true,
  },
});

const setTypes = (state, action) => ({
  ...state,
  tipos: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingTypes = (state) => ({
  ...state,
  tipos: {
    ...state.tipos,
    loading: true,
  },
});

const setTypesError = (state) => ({
  ...state,
  tipos: {
    ...state.tipos,
    error: true,
  },
});

const setRegions = (state, action) => ({
  ...state,
  regioes: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingRegions = (state) => ({
  ...state,
  regioes: {
    ...state.regioes,
    loading: true,
  },
});

const setRegionsError = (state) => ({
  ...state,
  regioes: {
    ...state.regioes,
    error: true,
  },
});

const setCountries = (state, action) => ({
  ...state,
  paises: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingCountries = (state) => ({
  ...state,
  paises: {
    ...state.paises,
    loading: true,
  },
});

const setCountriesError = (state) => ({
  ...state,
  paises: {
    ...state.paises,
    error: true,
  },
});

const setForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    ...action.payload,
  },
});

const setErrorOnFill = (state, action) => ({
  ...state,
  errorOnFill: action.payload,
});

const clearAll = () => INITIAL_STATE;

export default function queijoNewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ON_FETCH_HARMONIZATIONS:
      return setHarmonizations(state, action);
    case Types.LOADING_HARMONIZATIONS:
      return setLoadingHarmonizations(state);
    case Types.HARMONIZATIONS_ERROR:
      return setHarmonizationsError(state);
    case Types.ON_FETCH_STAMPS:
      return setStamps(state, action);
    case Types.LOADING_STAMPS:
      return setLoadingStamps(state);
    case Types.ERROR_STAMPS:
      return setStampsError(state);
    case Types.ON_FETCH_TYPES:
      return setTypes(state, action);
    case Types.LOADING_TYPES:
      return setLoadingTypes(state);
    case Types.TYPES_ERROR:
      return setTypesError(state);
    case Types.ON_FETCH_REGIONS:
      return setRegions(state, action);
    case Types.LOADING_REGIONS:
      return setLoadingRegions(state);
    case Types.REGIONS_ERROR:
      return setRegionsError(state);
    case Types.ON_FETCH_COUNTRIES:
      return setCountries(state, action);
    case Types.LOADING_COUNTRIES:
      return setLoadingCountries(state);
    case Types.COUNTRIES_ERROR:
      return setCountriesError(state);
    case Types.SET_FORM:
      return setForm(state, action);
    case Types.FORM_ERROR:
      return setErrorOnFill(state, action);
    case Types.CLEAR:
      return clearAll();
    default:
      return state;
  }
}
