import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { create as createFinancialConciliation } from '../../../services/FinancialConciliation';

export function useHandleSubmit(branch, selectedDiretory, files) {
  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    let newErrors = {};

    if (!branch) {
      newErrors = { ...newErrors, branch: 'Preencha a filial' };
    }

    if (!selectedDiretory) {
      newErrors = { ...newErrors, selectedDiretory: 'Preencha o Diretorio' };
    }

    if (files.length === 0) {
      toast.warning('Não é possivel cadastrar sem nenhum arquivo em anexo');
      newErrors = { ...newErrors, files: 'Nenhum arquivo selecionado' };
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setLoading(false);
      return;
    }

    await createFinancialConciliation(
      {
        far_st_nomearquivo: files[0]?.path,
        fil_in_codigo: branch,
        far_st_diretorio: selectedDiretory,
      },
      files
    );

    setLoading(false);
    history.push('/home');
  }, [branch, files, history, selectedDiretory]);

  return { handleSubmit, errors, loading };
}
