import { toast } from 'react-toastify';

import { getToken } from '../auth/auth';
import api from './api';

import { jsonToFormData } from '../utils/buildFormData';

const token = getToken();
function formatRequestBody(data, files) {
  const formData = jsonToFormData(data);

  // append files
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    formData.append('files', file);
  }

  return formData;
}

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get('curadores', {
      params: {
        ...paging,
        ...ordering,
        ...filters,
        roles: 'INSIDER,WRITER,SELLER',
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (e) {
    toast.error('Erro ao buscar curadores');

    throw e;
  }
}

export async function create(data, files) {
  try {
    const formData = formatRequestBody(data, files);

    await api.post('curadores', formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Curador criado com sucesso!');
    return;
  } catch (e) {
    toast.error('Erro ao criar curador');

    throw e;
  }
}
