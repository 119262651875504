import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function startHandling(orderId) {
  try {
    const response = await api.post(
      `orders/${orderId}/start_handling`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Pedido iniciado com sucesso!');

    return response.data;
  } catch (e) {
    toast.error(`Erro ao dar início ao manuseio do pedido ${orderId}`);

    throw e;
  }
}

export async function invoice(orderId, invoiceData, items) {
  try {
    const response = await api.post(
      `orders/${orderId}/invoice`,
      { ...invoiceData, items },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Pedido faturado com sucesso!');

    return response.data;
  } catch (e) {
    toast.error(`Erro ao faturar pedido ${orderId}`);

    throw e;
  }
}

export async function updateTrackingData(orderId, invoiceNumber, invoiceData) {
  try {
    const response = await api.patch(
      `orders/${orderId}/invoice/${invoiceNumber}`,
      { ...invoiceData },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Informações de rastreio atualizadas!');

    return response.data;
  } catch (e) {
    toast.error(
      `Erro ao atualizar informações do rastreio do pedido ${orderId}`
    );

    throw e;
  }
}

export async function updateTrackingStatus(
  orderId,
  invoiceNumber,
  trackingData
) {
  try {
    const response = await api.put(
      `orders/${orderId}/invoice/${invoiceNumber}/tracking`,
      { ...trackingData },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Informações de rastreio atualizadas!');

    return response.data;
  } catch (e) {
    toast.error(
      `Erro ao atualizar informações do rastreio do pedido ${orderId}`
    );

    throw e;
  }
}
