import { findOne } from '../../services/ProductService';

export const clearAll = () => ({
  type: '@imagens/clearAll',
});

export const setForm = (data) => ({
  type: '@imagens/setForm',
  payload: data,
});

export const setError = (error) => ({
  type: '@imagens/setError',
  payload: error,
});

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    const { data: productData } = await findOne(productId);

    return dispatch([
      setForm({
        pro_st_video: productData.pro_st_video,
      }),
    ]);
  } catch (e) {
    return dispatch([setError(true)]);
  }
};
