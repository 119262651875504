/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from 'sonoma-design-system';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import Divider from '../Divider';
import FieldsetTitle from '../FieldsetTitle';
import QueijoFields from '../QueijoFields';

import { findBySku, findOneByDescricao } from '../../services/ProductService';

import { fetchData, setForm, setErrorOnFill } from '../../store/queijoNew';
import {
  setForm as setFormEdit,
  setErrorOnFill as setErrorOnFillEdit,
} from '../../store/queijoEdit';
import {
  setErrorOnFill as setInfoErrorOnFillEdit,
  setForm as setInfoEditForm,
} from '../../store/informacoesEdit';
import {
  setErrorOnFill as setInfoErrorOnFill,
  setForm as setInfoForm,
} from '../../store/informacoesForm';
import queijoDataSelector from '../../store/selectors/queijo';

import { fetchProductTaxData } from '../../services/ProductTaxDataService';

import { Footer, Error } from './styles';
import { ButtonDescription } from '../../styles';
import MoreCheeseInfoFields from '../MoreCheeseInfoFields';

import { useLocalDB } from '../../hooks';

export default function QueijoForm({ onNext, isEdit }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [skuExist, setSkuExist] = useState(false);
  const [skuVerification, setSkuVerification] = useState(false);

  const [descricaoVerification, setDescricaoVerification] = useState(false);
  const [descricaoExist, setDescricaoExist] = useState(false);

  const { add, clear, data: localData } = useLocalDB('cheeses');

  const form = useSelector((state) =>
    isEdit ? state.product.edit.queijo.form : state.product.new.queijo.form
  );
  const infoForm = useSelector((state) =>
    isEdit
      ? state.product.edit.informacoes.form
      : state.product.new.informacoes.form
  );
  const productLoading = useSelector(
    (state) => isEdit && state.product.edit.queijo.loading
  );
  const productError = useSelector(
    (state) => isEdit && state.product.edit.queijo.error
  );

  const errorOnFill = useSelector((state) =>
    isEdit
      ? state.product.edit.queijo.errorOnFill
      : state.product.new.queijo.errorOnFill
  );

  const handleSetForm = useCallback(
    (value) => {
      dispatch(isEdit ? setFormEdit({ ...value }) : setForm({ ...value }));
    },
    [dispatch, isEdit]
  );

  const handleSetInfoForm = useCallback(
    (value) => {
      dispatch(
        isEdit ? setInfoEditForm({ ...value }) : setInfoForm({ ...value })
      );
    },
    [dispatch, isEdit]
  );

  const fetchTaxData = useCallback(
    async (sku) => {
      const data = await fetchProductTaxData(sku);

      handleSetInfoForm({
        clf_in_codigo: data.clf_in_codigo,
        clp_in_codigo: data.clp_in_codigo,
        ori_in_codigo: data.ori_in_codigo,
        pei_in_codigo: data.pei_in_codigo,
      });
    },
    [handleSetInfoForm]
  );

  const {
    harmonizacoes,
    selos,
    tipos,
    regioes,
    paises,
    loading: loadingData,
    error: dataError,
  } = useSelector(queijoDataSelector());

  useEffect(() => {
    if (
      harmonizacoes.length > 0 &&
      selos.length > 0 &&
      tipos.length > 0 &&
      regioes.length > 0 &&
      paises.length > 0
    )
      return;

    dispatch(fetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (localData) {
      dispatch(
        setForm({
          pro_st_sku: localData.sku,
          pai_in_codigo: localData.pais,
          reg_in_codigo: localData.regiao,
          tipos: localData.tipos,
          pro_st_conteudo: localData.conteudo,
          harmonizacoes: localData.harmonizacoes,
          selos: localData.selos,

          que_st_produtor: localData.produtor,
          que_st_casca: localData.casca,
          que_st_massa: localData.massa,
          que_st_intensidade: localData.intensidade,
          que_st_variedadeleite: localData.variedade_leite,
          que_bo_vegano: localData.vegano,
          que_bo_vegetariano: localData.vegetariano,
          que_lt_ingredientes: localData.ingredientes,
          que_lt_informacoesnutri: localData.informacoes_nutri,
          que_lt_alergicos: localData.alergicos,
          que_lt_armazenamento: localData.armazenamento,
        })
      );
    }
  }, [dispatch, localData]);

  const onFormChange = useCallback(
    (e) => {
      // If options choosed is 'Selecione' return null on redux
      if (e.target.value === 'Selecione') {
        handleSetForm({ [e.target.name]: null });
      } else {
        handleSetForm({ [e.target.name]: e.target.value });
      }
    },
    [handleSetForm]
  );

  const onCheckbox = useCallback(
    (e) => {
      handleSetForm({ [e.target.name]: e.target.checked });
    },
    [handleSetForm]
  );

  const onAddTag = useCallback(
    (name, value, atrName) => {
      const oldValue = form?.[name] || [];

      if (form) {
        // eslint-disable-next-line eqeqeq
        const exists = form[name].find((t) => t[atrName] == value[atrName]);

        if (exists) return;
      }

      handleSetForm({ [name]: [...oldValue, value] });
    },
    [handleSetForm, form]
  );

  const loading = useMemo(
    () => loadingData || productLoading,
    [loadingData, productLoading]
  );

  const error = useMemo(
    () => dataError || productError,
    [dataError, productError]
  );

  if (loading) return <Skeleton animation='wave' height={600} />;

  if (error)
    return (
      <Error>
        Tivemos um problema ao carregar as informações, tente novamente mais
        tarde.
      </Error>
    );

  function saveLocally() {
    clear();

    add({
      sku: form.pro_st_sku,
      pais: form.pai_in_codigo,
      regiao: form.reg_in_codigo,
      tipos: form.tipos,
      conteudo: form.pro_st_conteudo,
      harmonizacoes: form.harmonizacoes,
      selos: form.selos,

      produtor: form.que_st_produtor,
      casca: form.que_st_casca,
      massa: form.que_st_massa,
      intensidade: form.que_st_intensidade,
      variedade_leite: form.que_st_variedadeleite,
      vegano: form.que_bo_vegano,
      vegetariano: form.que_bo_vegetariano,
      ingredientes: form.que_lt_ingredientes,
      informacoes_nutri: form.que_lt_informacoesnutri,
      alergicos: form.que_lt_alergicos,
      armazenamento: form.que_lt_armazenamento,
    });
  }

  async function isValid() {
    if (!isEdit && form.pro_st_sku) {
      setSkuVerification(true);

      const skuAlreadyExist = await findBySku(form.pro_st_sku);

      setSkuExist(skuAlreadyExist.length > 0);

      setSkuVerification(false);

      if (skuAlreadyExist.length > 0) {
        toast.warning('SKU ja existente !');
        return false;
      }
    }

    if (!isEdit && infoForm.pro_st_descricao) {
      setDescricaoVerification(true);

      const descriptionAlreadyExist = await findOneByDescricao(
        infoForm.pro_st_descricao
      );

      setDescricaoExist(descriptionAlreadyExist.data);

      setDescricaoVerification(false);

      if (descriptionAlreadyExist.data) {
        toast.warning('Descrição do produto ja existe !');
        return false;
      }
    }

    if (!form?.pro_st_sku) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (!infoForm.pro_st_descricao) {
      dispatch(
        isEdit ? setInfoErrorOnFillEdit(true) : setInfoErrorOnFill(true)
      );
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (!form?.que_st_produtor) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (form.pai_in_codigo == 999 && !form.pai_name_to_create) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (form.reg_in_codigo == 999 && !form.reg_name_to_create) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    dispatch(isEdit ? setErrorOnFillEdit(false) : setErrorOnFill(false));
    return true;
  }

  async function handleNextStep() {
    if (!(await isValid())) return false;

    saveLocally();

    return onNext();
  }

  function handleButtonDescription() {
    if (skuVerification || descricaoVerification) {
      return <CircularProgress size='16px' />;
    }

    return 'Avançar';
  }

  return (
    <section>
      <FieldsetTitle>Queijo</FieldsetTitle>

      <QueijoFields
        form={form}
        tipos={tipos}
        paises={paises}
        regioes={regioes}
        harmonizacoes={harmonizacoes}
        selos={selos}
        onFormChange={onFormChange}
        onCheckbox={onCheckbox}
        onAddTag={onAddTag}
        onSetForm={handleSetForm}
        fetchTaxData={fetchTaxData}
        errorOnFill={errorOnFill}
        skuExist={skuExist}
        isEdit={isEdit}
        descricaoExist={descricaoExist}
      />

      <FieldsetTitle>Informações adicionais</FieldsetTitle>

      <MoreCheeseInfoFields form={form} onFormChange={onFormChange} />

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <Button
          outlined
          onClick={() => history.push('/')}
          backgroundColor='#B00D1F'
        >
          <ButtonDescription>Cancelar</ButtonDescription>
        </Button>

        <Button onClick={handleNextStep} backgroundColor='#9CD186'>
          <ButtonDescription>{handleButtonDescription()}</ButtonDescription>
        </Button>
      </Footer>
    </section>
  );
}

QueijoForm.propTypes = {
  onNext: PropTypes.func,
  isEdit: PropTypes.bool,
};

QueijoForm.defaultProps = {
  onNext: null,
  isEdit: false,
};
