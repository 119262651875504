import { isEqual } from './objects';

export function insertIf(conditional, elements) {
  return conditional ? elements : [];
}

export function contains(array, element) {
  return !!array.find((e) => isEqual(e, element));
}

export function containsAny(array, elements) {
  return !!elements.find((el) => contains(array, el));
}

export function findIndexByColumns(array, item, ...columns) {
  return array.reduce(
    (index, i, pos) =>
      columns.every((c) => isEqual(i[c], item[c])) ? pos : index,
    -1
  );
}

export function removeDuplicatesByColumns(array, ...columns) {
  return array.filter(
    (item, pos) => findIndexByColumns(array, item, ...columns) === pos
  );
}
