export const harmonizations = (aggregator) => (state) =>
  aggregator(state).harmonizacoes.data?.map((item) => ({
    value: item.har_in_codigo,
    label: item.har_st_descricao,
  }));

export const stamps = (aggregator) => (state) =>
  aggregator(state).selos.data?.map((item) => ({
    value: item.sel_in_codigo,
    label: item.sel_st_descricao,
  }));

export const grapes = (aggregator) => (state) =>
  aggregator(state).uvas.data?.map((item) => ({
    value: item.tuv_in_codigo,
    label: item.tuv_st_descricao,
  }));

export const curators = (aggregator) => (state) =>
  aggregator(state).curadores.data?.map((item) => ({
    value: item.cur_in_codigo,
    label: item.cur_st_nome,
    disabled: item.cur_bo_deletado,
  }));

export const types = (aggregator) => (state) =>
  aggregator(state).tipos.data?.map((item) => ({
    value: item.tip_in_codigo,
    label: item.tip_st_descricao,
  }));

export const regions = (aggregator) => (state) => {
  const country = aggregator(state).form.pai_in_codigo;

  const mapRegions = (item) => ({
    value: item.reg_in_codigo,
    label: item.reg_st_name,
  });

  if (country) {
    return (
      aggregator(state)
        // eslint-disable-next-line eqeqeq
        .regioes.data?.filter((r) => r.pai_in_codigo == country)
        .map(mapRegions)
    );
  }

  return aggregator(state).regioes.data?.map(mapRegions);
};

export const countries = (aggregator) => (state) =>
  aggregator(state).paises.data?.map((item) => ({
    value: item.pai_in_codigo,
    label: item.pai_st_name,
  }));
