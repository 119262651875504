import styled from 'styled-components';
import { Divider as MuiDivider } from '@material-ui/core';

export const Title = styled.h1`
  font-family: 'Open Sans';
  color: rgb(59, 59, 58);
  align-content: center;
  font-size: 24px;
  line-height: 32px;
  padding: 16px 0;
  font-weight: 600;
`;

export const Divider = styled(MuiDivider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
`;

export const ButtonDescription = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
