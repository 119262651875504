import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function findAll(
  paging = { page: 1, limit: 'all' },
  ordering,
  filters
) {
  try {
    const response = await api.get('sellers', {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar sellers');
    throw e;
  }
}

export async function findById(id) {
  try {
    const response = await api.get(`sellers/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar seller');

    throw e;
  }
}

export async function updateSeller(seller) {
  try {
    const result = await api.put(
      `sellers/${seller.slr_in_codigo}`,
      { seller },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success('Alteração feita com sucesso');
    return result;
  } catch (e) {
    toast.error('Erro ao concluir alteração');

    throw e;
  }
}
