/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
import { findAll } from '../../services/PedidosService';

export const setLoading = (loading) => ({
  type: '@pedidos/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@pedidos/setError',
  payload: error,
});

export const clear = () => ({
  type: '@pedidos/clear',
});

const setPaging = (paging) => ({
  type: '@pedidos/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@pedidos/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@pedidos/setFilters',
  payload: filters,
});

export const setPedidos = (pedidos) => ({
  type: '@pedidos/setPedidos',
  payload: pedidos,
});

export const fetchPedidos =
  (filial, from, to, paging, ordering, filters) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const pedidosData = await findAll(
        filial,
        from,
        to,
        paging,
        ordering,
        filters
      );

      return dispatch([
        setLoading(false),
        setError(false),
        setPaging(pedidosData._metadata.paging),
        setOrdering(pedidosData._metadata.ordering),
        setFilters(pedidosData._metadata.filters),
        setPedidos(pedidosData.data),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };
