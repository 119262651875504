import { toast } from 'react-toastify';
import { contains } from '../utils/arrays';
import { setToken, setTokenExpiration, clearAuthData } from '../auth/auth';
import api from './api';

function isUserAllowed(roles) {
  return ['ADMIN', 'INSIDER', 'WRITER', 'SELLER', 'FINANCIAL'].find((r) =>
    contains(roles, r)
  );
}

export async function login(email, password) {
  if (!email) {
    toast.error('Por favor digite um email valido');
    return null;
  }
  if (!password) {
    toast.error('Por favor digite uma senha valida');
    return null;
  }

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/.test(
      email
    );

  if (!email === regex) {
    toast.error('Email está inválido');
    return null;
  }

  try {
    const response = await api.post('usuarios/auth', { email, password });

    const roles = response.headers['x-auth-roles']
      ?.split(',')
      .map((role) => role.trim());

    if (!isUserAllowed(roles)) {
      toast.error('O usuário não possui as permissões necessárias');
      return null;
    }

    setToken(response.headers['x-auth-token']);
    setTokenExpiration(response.headers['x-auth-exp']);

    window.location.replace('/'); // FIXME: find a way to use history.push

    return response.data;
  } catch (e) {
    if (e.response?.data) {
      toast.error(e.response.data.message);
    } else {
      toast.error('Erro, por favor tente novamente mais tarde.');
    }

    return e;
  }
}

export function logout() {
  clearAuthData();

  window.location.replace('/'); // FIXME: find a way to use history.push
}

export async function recoverPassword(email) {
  if (!email) {
    toast.error('Por favor digite um email valido');
    throw new Error();
  }
  try {
    const response = await api.post(`usuarios/recover/password`, { email });

    toast.success(response.data.message);
  } catch (e) {
    if (e.response?.data) {
      toast.error(e.response.data.message);
    } else {
      toast.error('Erro, por favor tente novamente mais tarde.');
    }

    throw e;
  }
}
