export const setForm = (data) => ({
  type: '@frete/setForm',
  payload: data,
});

export const setLoading = (loading) => ({
  type: '@frete/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@frete/setError',
  payload: error,
});

export const setErrorOnFill = (error) => ({
  type: '@frete/setErrorOnFill',
  payload: error,
});

export const clearAll = () => ({
  type: '@frete/clearAll',
});
