import PropTypes from 'prop-types';

import { SkuTextContainer } from './styles';
import { CenterColumn } from '../../../styles';

export default function SkuColunm({ item }) {
  return (
    <CenterColumn title={item.pro_st_sku}>
      <SkuTextContainer>{item.pro_st_sku}</SkuTextContainer>
    </CenterColumn>
  );
}

SkuColunm.propTypes = {
  item: PropTypes.object.isRequired,
};
