import PropTypes from 'prop-types';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { FaCalendarAlt, FaWindowClose, FaDownload } from 'react-icons/fa';
import { Table, Button } from 'sonoma-design-system';

import { CenterColumn, DateInfoLabel, Title } from './styles';
import { getUser } from '../../auth/auth';
import { fetchBillingData, clear } from '../../store/billing';

import { getProductsBillingData } from '../../services/BillingService';

import DateRangePicker from '../../components/DateRangePicker';
import Card from '../../components/Card';

import { formatCurrency } from '../../utils/number';
import { downloadCsv } from '../../utils/csvDownload';

export default function BillingDashboard() {
  const dispatch = useDispatch();

  const [showCalendar, setShowCalendar] = useState(false);
  const [filial, setFilial] = useState(false);
  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const loading = useSelector((state) => state.billing.loading);
  const error = useSelector((state) => state.billing.error);

  const totalItemsSoldInMonth = useSelector(
    (state) => state.billing.totalItemsSold
  );
  const totalBilled = useSelector((state) => state.billing.totalBilled);
  const averageTicket = useSelector((state) => state.billing.averageTicket);

  const bestSellers = useSelector((state) => state.billing.bestSellers);

  // paging
  const paging = useSelector((state) => state.billing.paging);

  // ordering
  const ordering = useSelector((state) => state.billing.ordering);

  const formatDate = useCallback((date) => format(date, 'yyyy-MM-dd'), []);

  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );

  const findBillingData = useCallback(
    (ranges) => {
      const pagingData = { page: 1, limit: 15 };

      if (!ranges) {
        dispatch(
          fetchBillingData(
            filial,
            formatDate(startOfMonth(new Date())),
            formatDate(endOfMonth(new Date())),
            pagingData
          )
        );
        return;
      }

      const { selection } = ranges;

      if (selection.startDate && selection.endDate) {
        setStartDate(formatDate(selection.startDate));
        setEndDate(formatDate(selection.endDate));

        dispatch(
          fetchBillingData(
            filial,
            formatDate(selection.startDate),
            formatDate(selection.endDate),
            pagingData
          )
        );
      }
    },
    [dispatch, filial, formatDate]
  );

  useEffect(() => {
    const user = getUser();

    setFilial(selectedFilial?.fil_in_codigo || user.fil_in_codigo);
  }, [dispatch, filial, findBillingData, selectedFilial]);

  useEffect(() => {
    if (filial) {
      findBillingData();
    }

    return () => dispatch(clear());
  }, [dispatch, filial, findBillingData]);

  useEffect(() => {
    // event listner after close print window, make a req again
    window.addEventListener('afterprint', () => {
      const pagingData = { page: 1, limit: 15 };

      setStartDate(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
      setEndDate(format(endOfMonth(new Date()), 'yyyy-MM-dd'));

      dispatch(
        fetchBillingData(
          filial,
          formatDate(startOfMonth(new Date())),
          formatDate(endOfMonth(new Date())),
          pagingData
        )
      );
    });

    // cleanup event listner on destruct
    return () => {
      window.removeEventListener('afterprint', {});
    };
  }, [dispatch, filial, formatDate]);

  function CurrencyCol({ item, column }) {
    return <CenterColumn>{formatCurrency(item[column.id])}</CenterColumn>;
  }

  CurrencyCol.propTypes = {
    item: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
  };

  function IntegerCol({ item, column }) {
    return <CenterColumn>{parseInt(item[column.id], 10)}</CenterColumn>;
  }

  IntegerCol.propTypes = {
    item: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
  };

  function handlePageChange(page) {
    const pagingData = {
      page: page,
      total: paging.total,
      limit: paging.limit,
    };

    dispatch(
      fetchBillingData(filial, startDate, endDate, pagingData, ordering)
    );
  }

  function handleOrderingChange(orderingChange) {
    const orderingData = {
      ...ordering,
      ...orderingChange,
    };

    dispatch(
      fetchBillingData(filial, startDate, endDate, paging, orderingData)
    );
  }

  const cols = useMemo(
    () => [
      { id: 'pro_st_descricao', title: 'Nome do produto' },
      { id: 'preco_de', title: 'Preço de', component: CurrencyCol },
      { id: 'preco_por', title: 'Preço por', component: CurrencyCol },
      { id: 'preco_atacado', title: 'Preço caixa', component: CurrencyCol },
      { id: 'estoque', title: 'Estoque atual', component: IntegerCol },
      { id: 'totalMes', title: 'Itens vendidos' },
      {
        id: 'ticketMedio',
        title: 'Ticket médio',
        component: CurrencyCol,
      },
      {
        id: 'valorTotalMes',
        title: 'Total faturado',
        component: CurrencyCol,
      },
    ],
    []
  );

  const handleCsvDownload = async () => {
    const pagingData = { page: 1, limit: 'all' };

    await getProductsBillingData(
      filial,
      startDate,
      endDate,
      pagingData,
      ordering
    ).then((req) => {
      const dataFormatted = req.best_sellers.map((e) => ({
        Nome: e.pro_st_descricao,
        Estoque: parseInt(e.estoque, 10),
        PrecoCaixa: parseInt(e.preco_atacado, 10),
        PrecoDe: parseInt(e.preco_de, 10),
        PrecoPor: parseInt(e.preco_por, 10),
        TicketMedio: parseInt(e.ticketMedio, 10),
        ItensVendidosMes: parseInt(e.totalMes, 10),
        ValorTotalMes: parseInt(e.valorTotalMes, 10),
      }));
      downloadCsv('Sonoma-Market-Produtos-Relatorio.csv', dataFormatted);
    });
  };

  return (
    <div>
      <Title>Dashboard de faturamento</Title>

      <div className='mb-3 d-flex justify-content-between'>
        <div />
      </div>

      <DateInfoLabel>
        O período selecionado se refere a data de emissão dos pedidos!
      </DateInfoLabel>

      <div style={{ margin: '15px 0px' }}>
        <Button onClick={handleCsvDownload}>
          Baixar Dados &nbsp; <FaDownload />
        </Button>
      </div>
      <Grid className='mb-4' style={{ display: 'flex' }}>
        <div
          style={{
            cursor: 'pointer',
            width: '30%',
            marginRight: '10px',
          }}
          onClick={() => setShowCalendar((current) => !current)}
          role='button'
          aria-hidden
        >
          <Card
            title={<FaCalendarAlt color='grey' />}
            text={`${format(parseISO(startDate), 'MM-dd')} à ${format(
              parseISO(endDate),
              'MM-dd'
            )}`}
          />
        </div>

        {showCalendar && (
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'grey',
              justifyContent: 'center',
            }}
          >
            <span
              onClick={() => setShowCalendar((current) => !current)}
              aria-hidden
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: '#fff',
                cursor: 'pointer',
              }}
            >
              <FaWindowClose color='red' />
            </span>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={findBillingData}
            />
          </Grid>
        )}
      </Grid>

      {error && (
        <p className='text-danger'>
          Tivemos um problema ao carregar os dados do faturamento
        </p>
      )}

      {loading && (
        <>
          <div style={{ display: 'flex' }}>
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              spacing={1}
            >
              <Grid container item xs={12} md={12} sm={12} lg={4} xl={4}>
                <Grid item xs={12}>
                  <Skeleton animation='wave' height={200} />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} sm={12} lg={4} xl={4}>
                <Grid item xs={12}>
                  <Skeleton animation='wave' height={200} />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} sm={12} lg={4} xl={4}>
                <Grid item xs={12}>
                  <Skeleton animation='wave' height={200} />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Skeleton animation='wave' height={600} />
        </>
      )}

      {!loading && !error && (
        <>
          <div style={{ display: 'flex' }}>
            <Grid
              container
              style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              spacing={1}
            >
              <Grid container item xs={12} md={12} sm={12} lg={4} xl={4}>
                <Grid item xs={12}>
                  <Card
                    title='Total de itens vendidos'
                    text={totalItemsSoldInMonth}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} sm={12} lg={4} xl={4}>
                <Grid item xs={12}>
                  <Card
                    title='Total faturado'
                    text={formatCurrency(totalBilled)}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} sm={12} lg={4} xl={4}>
                <Grid item xs={12}>
                  <Card
                    title='Ticket médio'
                    text={formatCurrency(averageTicket)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>

          <Grid style={{ margin: '30px 0px' }}>
            {!loading && !error && (
              <Table
                rows={bestSellers}
                columns={cols}
                ordering={ordering}
                onChangeOrder={(order) => handleOrderingChange(order)}
                paging={paging}
                onChangePage={(page) => handlePageChange(page)}
                showSearch={false}
                searchPlaceholder='Buscar por Nome ou SKU'
              />
            )}
          </Grid>
        </>
      )}
    </div>
  );
}
