import PropTypes from 'prop-types';

export function ValidationErrorMessage({ errorMessage }) {
  return (
    <div>
      <p>Encontramos problemas no preenchimento dos seguintes campos:</p>

      <ul style={{ padding: 20 }}>
        {errorMessage.split('.').map((message) => (
          <li key={message}>{message.trim()}</li>
        ))}
      </ul>
    </div>
  );
}

ValidationErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
