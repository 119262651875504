import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const Section = styled.section`
  background: #fff;
  padding: 15px;
`;

export const InputContainer = styled.div`
  width: 350px;
`;
