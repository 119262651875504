import { findById } from '../../services/UserService';

export const setLoading = (loading) => ({
  type: '@userEdit/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@userEdit/setError',
  payload: error,
});

export const setUser = (user) => ({
  type: '@userEdit/setUser',
  payload: user,
});

export const clear = () => ({
  type: '@userEdit/clear',
});

export const fetchUser = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findById(id);

    return dispatch([
      setLoading(false),
      setError(false),
      setUser(response.data),
    ]);
  } catch (err) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
