const INITIAL_STATE = {
  loading: false,
  error: false,
  filial: null,
  from: null,
  to: null,
  pedidos: [],
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order: 'asc',
    order_by: 'updatedAt',
    availableOrderBy: ['ped_st_numero', 'ped_st_embarque', 'updatedAt'],
  },
  filters: {
    ref: '',
  },
};

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const setFrom = (state, action) => ({ ...state, from: action.payload });

const setTo = (state, action) => ({ ...state, to: action.payload });

const setPedidos = (state, action) => ({ ...state, pedidos: action.payload });

const setPaging = (state, action) => ({
  ...state,
  paging: {
    ...state.paging,
    ...action.payload,
  },
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: {
    ...state.ordering,
    ...action.payload,
  },
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

export default function pedidosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@pedidos/setLoading':
      return setLoading(state, action);
    case '@pedidos/setError':
      return setError(state, action);
    case '@pedidos/setFrom':
      return setFrom(state, action);
    case '@pedidos/setTo':
      return setTo(state, action);
    case '@pedidos/setPaging':
      return setPaging(state, action);
    case '@pedidos/setOrdering':
      return setOrdering(state, action);
    case '@pedidos/setFilters':
      return setFilters(state, action);
    case '@pedidos/setPedidos':
      return setPedidos(state, action);
    case '@pedidos/clear':
      return INITIAL_STATE;
    default:
      return state;
  }
}
