import PropTypes from 'prop-types';

import { ClipLoader } from 'react-spinners';

// eslint-disable-next-line import/no-extraneous-dependencies
import { css } from '@emotion/core';

const override = css`
  display: block;
  margin: 0 auto;
`;

function LoadingSpinner({ loading }) {
  return (
    <>
      <div className='loading-spinner-container'>
        <div className='sweet-loading spinner-bg'>
          <ClipLoader
            css={override}
            sizeUnit='px'
            size={150}
            color='#F0F8FF'
            loading={loading}
          />
        </div>
      </div>

      <div
        className='loading-background'
        style={{ display: loading ? 'block' : 'none' }}
      />
    </>
  );
}

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingSpinner;
