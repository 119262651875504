import styled from 'styled-components';

export const Title = styled.h1`
  margin-bottom: 15px;
`;

export const Text = styled.p`
  margin-bottom: 25px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;
