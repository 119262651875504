import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Input, Button } from 'sonoma-design-system';

import Divider from '../Divider';

import { Footer, Section, InputContainer } from './styles';

export default function NewPasswordForm({ onSubmit, onCancel }) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [errors, setErrors] = useState({});

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const handleSubmit = useCallback(() => {
    // validation
    let newErrors = {};

    if (!password || password.length < 6) {
      newErrors = {
        ...newErrors,
        password: 'A senha deve ter no mínimo 6 caracteres',
      };
    }

    if (password !== passwordConfirmation) {
      newErrors = {
        ...newErrors,
        passwordConfirmation: 'As senhas devem ser iguais',
      };
    }

    if (!oldPassword) {
      newErrors = {
        ...newErrors,
        oldPassword: 'Preencha a sua senha atual',
      };
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    onSubmit(password, oldPassword);
  }, [onSubmit, password, oldPassword, passwordConfirmation]);

  return (
    <Section style={{ marginTop: '130px' }}>
      <h1 style={{ color: '#3B3B3A', fontSize: '24px' }}>Alterar senha</h1>
      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Grid spacing={2} container className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId='oldPassword'
                  size='small'
                  label='Senha atual'
                  type='password'
                  error={errors.oldPassword}
                  errorText={errors.oldPassword}
                  value={oldPassword}
                  handleChange={(e) => setOldPassword(e.target.value)}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>

          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId='password'
                  size='small'
                  label='Nova senha'
                  type='password'
                  error={errors.password}
                  errorText={errors.password}
                  value={password}
                  handleChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>

          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId='passwordConfirmation'
                  size='small'
                  label='Confirme sua nova senha'
                  type='password'
                  error={errors.passwordConfirmation}
                  errorText={errors.passwordConfirmation}
                  value={passwordConfirmation}
                  handleChange={(e) => setPasswordConfirmation(e.target.value)}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <Button outlined onClick={onCancel} backgroundColor='#B00D1F'>
          Cancelar
        </Button>

        <Button onClick={handleSubmit}>Salvar Alterações</Button>
      </Footer>
    </Section>
  );
}

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
