import { findAll } from '../../services/curadorService';

export const setCuradores = (users) => ({
  type: '@curador/setCuradores',
  payload: users,
});

export const setPaging = (paging) => ({
  type: '@curador/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@curador/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@curador/setFilters',
  payload: filters,
});

export const setLoading = (loading) => ({
  type: '@curador/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@curador/setError',
  payload: error,
});

export const fetchAll = (paging, ordering, filters) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findAll(paging, ordering, filters);

    return dispatch([
      setCuradores(response.data),
      setPaging(response._metadata.paging),
      setOrdering(response._metadata.ordering),
      setFilters(response._metadata.filters),

      setLoading(false),
      setError(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
