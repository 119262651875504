import { toast } from 'react-toastify';

import { getToken } from '../auth/auth';
import api from './api';

const token = getToken();

export async function findAll() {
  try {
    const response = await api.get('paises', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar paises');

    throw e;
  }
}
