/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles, Avatar } from '@material-ui/core';
import { Input, Select, Tag } from 'sonoma-design-system';
import { TagsContainer } from './styles';

import SkuAndDescriptionFields from '../SkuAndDescriptionFields';

import bestSellerIcon from '../../assets/best-seller-icon.png';
import organicoIcon from '../../assets/organico-icon.png';
import naturalIcon from '../../assets/natural-icon.png';
import rp100Icon from '../../assets/rp-100-icon.png';
import { getUserRoles } from '../../auth/auth';

function GourmetFields({
  form,
  tipos,
  paises,
  regioes,
  harmonizacoes,
  selos,
  curadores,
  handleFormChange,
  onAddTag,
  onSetForm,
  fetchTaxData,
  errorOnFill,

  skuExist,
  isEdit,

  descricaoExist,
}) {
  const isDeliveryBySeller = getUserRoles().includes('DELIVERY_BY_SELLER');
  const isFFH = getUserRoles().includes('FULFILLS_H');

  // effect to exclude pai_name_to_create when doesn't need
  useEffect(() => {
    if (form.pai_in_codigo != 999) {
      onSetForm({
        pai_name_to_create: null,
      });
    }

    if (form.reg_in_codigo != 999) {
      onSetForm({
        reg_name_to_create: null,
      });
    }
  }, [form.pai_in_codigo, form.reg_in_codigo, onSetForm]);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 30,
    },
  }));

  const classes = useStyles();

  function handleIconSelos() {
    function renderIcon(tag, iconSrc) {
      return (
        <Avatar
          style={{ cursor: 'pointer' }}
          alt={tag.sel_in_codigo}
          src={iconSrc}
          onClick={() =>
            onSetForm({
              selos: form.selos.filter(
                (t) => t.sel_in_codigo !== tag.sel_in_codigo
              ),
            })
          }
        />
      );
    }
    return (
      <TagsContainer>
        {form?.selos?.map((tag) => {
          if (tag.sel_st_descricao === 'organico') {
            return renderIcon(tag, organicoIcon);
          }
          if (tag.sel_st_descricao === 'bestSellers') {
            return renderIcon(tag, bestSellerIcon);
          }
          if (tag.sel_st_descricao === 'natural') {
            return renderIcon(tag, naturalIcon);
          }
          if (tag.sel_st_descricao === 'Pontuados +90') {
            return renderIcon(tag, rp100Icon);
          }
          return null;
        })}
      </TagsContainer>
    );
  }

  return (
    <Grid spacing={2} container className={classes.root}>
      <SkuAndDescriptionFields
        errorSkuOnFill={errorOnFill}
        skuForm={form}
        skuExist={skuExist}
        onFormSkuChange={handleFormChange}
        fetchTaxData={fetchTaxData}
        isEdit={isEdit}
        descricaoExist={descricaoExist}
      />
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          {!isDeliveryBySeller && !isFFH && (
            <Grid item xs={12} lg={4}>
              <Select
                selectId='tipos'
                size='small'
                label='Tipo'
                options={[{ value: null, label: 'Selecione' }, ...tipos]}
                handleChange={(e) =>
                  onAddTag(
                    e.target.name,
                    {
                      tip_in_codigo: e.target.value,
                      tip_st_descricao: tipos.find(
                        (t) => t.value === Number(e.target.value)
                      ).label,
                    },
                    'tip_in_codigo'
                  )
                }
              />
              <TagsContainer>
                {form?.tipos?.map((tag) => (
                  <Tag
                    key={tag.tip_in_codigo}
                    onDelete={() =>
                      onSetForm({
                        tipos: form.tipos.filter(
                          (t) => t.tip_in_codigo !== tag.tip_in_codigo
                        ),
                      })
                    }
                  >
                    {tag.tip_st_descricao}
                  </Tag>
                ))}
              </TagsContainer>
            </Grid>
          )}

          <Grid item xs={12} lg={4}>
            <Select
              selectId='pai_in_codigo'
              options={[
                { value: null, label: 'Selecione' },
                { value: 999, label: 'Outros' },
                ...paises,
              ]}
              value={form?.pai_in_codigo}
              handleChange={handleFormChange}
              size='small'
              label='País'
            />
          </Grid>

          {form.pai_in_codigo == 999 && (
            <Grid item xs={6} lg={4}>
              <Input
                error={errorOnFill && !form?.pai_name_to_create}
                errorText='Inserir nome do país'
                inputId='pai_name_to_create'
                value={form?.pai_name_to_create || ''}
                handleChange={(e) =>
                  onSetForm({
                    pai_name_to_create: e.target.value,
                  })
                }
                size='small'
                label='Nome do país'
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={12} lg={4}>
            <Select
              selectId='reg_in_codigo'
              options={[
                { value: null, label: 'Selecione' },
                { value: 999, label: 'Outros' },
                ...regioes,
              ]}
              value={form?.reg_in_codigo}
              handleChange={handleFormChange}
              size='small'
              label='Regiões'
              disabled={!form?.pai_in_codigo}
            />
          </Grid>

          {form.reg_in_codigo == 999 && (
            <Grid item xs={6} lg={4}>
              <Input
                error={errorOnFill && !form?.reg_name_to_create}
                errorText='Inserir nome a região'
                inputId='reg_name_to_create'
                value={form?.reg_name_to_create || ''}
                handleChange={(e) =>
                  onSetForm({
                    reg_name_to_create: e.target.value,
                  })
                }
                size='small'
                label='Nome da região'
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={3}>
            <Select
              selectId='harmonizacoes'
              options={[{ value: null, label: 'Selecione' }, ...harmonizacoes]}
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    har_in_codigo: e.target.value,
                    har_st_descricao: harmonizacoes.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'har_in_codigo'
                )
              }
              size='small'
              label='Harmonização'
            />
            <TagsContainer>
              {form?.harmonizacoes?.map((tag) => (
                <Tag
                  key={tag.har_in_codigo}
                  onDelete={() =>
                    onSetForm({
                      harmonizacoes: form.harmonizacoes.filter(
                        (t) => t.har_in_codigo !== tag.har_in_codigo
                      ),
                    })
                  }
                >
                  {tag.har_st_descricao}
                </Tag>
              ))}
            </TagsContainer>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Select
              selectId='selos'
              size='small'
              label='Selos'
              options={[{ value: null, label: 'Selecione' }, ...selos]}
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    sel_in_codigo: e.target.value,
                    sel_st_descricao: selos.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'sel_in_codigo'
                )
              }
            />

            {handleIconSelos()}
            <TagsContainer>
              {form?.selos?.map((tag) => {
                if (
                  tag.sel_st_descricao !== 'organico' &&
                  tag.sel_st_descricao !== 'bestSellers' &&
                  tag.sel_st_descricao !== 'natural' &&
                  tag.sel_st_descricao !== 'Pontuados +90'
                ) {
                  return (
                    <Tag
                      key={tag.sel_in_codigo}
                      onDelete={() =>
                        onSetForm({
                          selos: form.selos.filter(
                            (t) => t.sel_in_codigo !== tag.sel_in_codigo
                          ),
                        })
                      }
                    >
                      {tag.sel_st_descricao}
                    </Tag>
                  );
                }
                return null;
              })}
            </TagsContainer>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Select
              selectId='cur_in_codigo'
              options={[{ value: null, label: 'Selecione' }, ...curadores]}
              handleChange={handleFormChange}
              value={form?.cur_in_codigo}
              size='small'
              label='Curador'
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='pro_st_conteudo'
              value={form?.pro_st_conteudo}
              handleChange={handleFormChange}
              size='small'
              label='Conteúdo'
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

GourmetFields.propTypes = {
  form: PropTypes.object.isRequired,

  tipos: PropTypes.array.isRequired,
  paises: PropTypes.array.isRequired,
  regioes: PropTypes.array.isRequired,
  harmonizacoes: PropTypes.array.isRequired,
  selos: PropTypes.array.isRequired,
  curadores: PropTypes.array.isRequired,

  handleFormChange: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onSetForm: PropTypes.func.isRequired,
  fetchTaxData: PropTypes.func,
  errorOnFill: PropTypes.bool,

  // check if sku exist
  // if exist don't go to the next step
  skuExist: PropTypes.bool,
  // check if descricao exist
  // if exist don't go to the next step
  descricaoExist: PropTypes.bool,
  isEdit: PropTypes.bool,
};

GourmetFields.defaultProps = {
  errorOnFill: false,
  skuExist: false,
  descricaoExist: false,
  isEdit: false,
  fetchTaxData: null,
};

export default GourmetFields;
