import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { MdDeleteForever } from 'react-icons/md';
import { ProductDescription } from './styles';
import { ProductCard, ProductCol } from '../../styles';

import defaultWineImg from '../../assets/default-wine.png';

export default function SimilarProducts({
  products,
  handleDeleteIntegrateProduct,
}) {
  if (!products || products.length <= 0)
    return <p>Nenhum produto suplente vinculado</p>;

  return products.map((product) => (
    <div style={{ display: 'flex' }}>
      <ProductCol>
        <ProductCard>
          <Avatar
            variant='square'
            style={{
              marginRight: '10px',
              borderRadius: '5px',
              border: '1px solid #E0E0E0',
              height: '48px',
              width: '48px',
            }}
            src={product.children?.image_firebase_url || defaultWineImg}
            alt={product.children.pro_st_descricao}
          />

          <div
            style={{
              textOverflow: 'ellipsis',
            }}
          >
            <ProductDescription title={product.children.pro_st_descricao}>
              {product.children.pro_st_descricao}
            </ProductDescription>
          </div>
          <div
            style={{
              marginLeft: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MdDeleteForever
              size='25px'
              color='#b00d1f'
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteIntegrateProduct(product)}
            />
          </div>
        </ProductCard>
      </ProductCol>
    </div>
  ));
}

SimilarProducts.propTypes = {
  products: PropTypes.array.isRequired,
  handleDeleteIntegrateProduct: PropTypes.func.isRequired,
};
