import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { BreadcrumbStepper, useStepper } from 'sonoma-design-system';

import { getUser } from '../../auth/auth';

import GourmetForm from '../../components/GourmetForm';
import InformacoesForm from '../../components/InformacoesForm';
import FreteForm from '../../components/FreteForm';
import ImagensForm from '../../components/ImagensForm';

import {
  fetchProduct,
  clearAll as clearGourmetEdit,
} from '../../store/gourmetEdit';
import {
  fetchProduct as fetchInfoEdit,
  clearAll as clearInfoEdit,
} from '../../store/informacoesEdit';
import {
  fetchProduct as fetchFreteEdit,
  clearAll as clearFreightEdit,
} from '../../store/freteEdit';
import {
  fetchProduct as fetchImagensForm,
  clearAll as clearImagensForm,
} from '../../store/imagensForm';
import { clearAll as clearGourmet } from '../../store/gourmetNew';
import { clearAll as clearInfo } from '../../store/informacoesForm';
import { clearAll as clearFreight } from '../../store/freteForm';
import store from '../../store';

import { review } from '../../services/ProductService';

import {
  clearBlankKeys,
  filterKeys,
  removeKeys,
  clearBlankKeysFromNested,
} from '../../utils/objects';

import { Main, Title } from './styles';

export default function CreateGourmet() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pro_in_codigo } = useParams();

  const [isLoading, setLoading] = useState(false);

  const { selected, next, previous } = useStepper();

  const titles = useMemo(
    () => ['GOURMET', 'INFORMAÇÕES', 'FRETE E VENDA', 'IMAGENS E VÍDEOS'],
    []
  );

  const scoreKeys = useMemo(
    () => [
      'pon_in_ws',
      'pon_in_tp',
      'pon_in_wes',
      'pon_in_bh',
      'pon_in_we',
      'pon_in_gp',
      'pon_in_rp',
      'pon_in_st',
      'pon_in_gd',
      'pon_in_js',
      'pon_in_jr',
      'pon_in_ag',
      'pon_in_ds',
      'pon_in_d',
      'pon_in_ta',
      'pon_in_pm',
      'pon_in_uw',
      'pon_in_ak',
      'pon_in_lm',
      'pon_in_ad',
      'pon_in_jr2',
      'pon_in_jd',
      'pon_in_s',
      'pon_in_v',
      'pon_in_gg',
      'pon_in_bh2',
      'pon_in_iwr',
      'pon_in_ce',
      'pon_in_mf',
    ],
    []
  );

  const getMainImagePath = useCallback(
    (images) => images.find((img) => img.main)?.path,
    []
  );

  const getNewImageFiles = useCallback(
    (images) => images.filter((i) => !i.pim_st_imagesrc),
    []
  );

  const getPreviouslySavedURLs = useCallback(
    (images) =>
      images.filter((i) => i.pim_st_imagesrc).map((i) => i.pim_st_imagesrc),
    []
  );

  useEffect(() => {
    dispatch([
      fetchProduct(pro_in_codigo),
      fetchInfoEdit(pro_in_codigo),
      fetchFreteEdit(pro_in_codigo),
      fetchImagensForm(pro_in_codigo),
    ]);

    return () => {
      dispatch([
        clearGourmet(),
        clearInfo(),
        clearFreight(),
        clearGourmetEdit(),
        clearInfoEdit(),
        clearFreightEdit(),
        clearImagensForm(),
      ]);
    };
  }, [dispatch, pro_in_codigo]);

  const getPontuacao = useCallback(
    (data) => clearBlankKeys(filterKeys(data, scoreKeys)),
    [scoreKeys]
  );

  const handleCreateProduct = useCallback(
    async (images) => {
      setLoading(true);

      const user = getUser();

      try {
        const { product } = store.getState();
        const productWithouBlank = clearBlankKeysFromNested(product.edit);

        const {
          gourmet: { form: gourmetData },
          frete: { form: freightData },
          informacoes: { form: infoData },
          imagens: { form: imagens },
        } = productWithouBlank;

        const pontuacao = getPontuacao(gourmetData);

        const gourmet = removeKeys(gourmetData, scoreKeys);

        const {
          harmonizacoes,
          selos,
          tipos,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create, // País name in case of create new one
          reg_in_codigo,
          reg_name_to_create, // Regiao name in case of create new one
          pro_st_conteudo,

          ...data
        } = gourmet;

        const {
          produtos_suplentes,
          pro_lt_sobre,
          pro_lt_historia,
          ...dataInfo
        } = infoData;

        const similares = Object.keys(produtos_suplentes).map((key) => ({
          pro_in_codigo: produtos_suplentes[key].child_pro_in_codigo,
          pro_st_sku: produtos_suplentes[key].children.pro_st_sku,
          pro_st_descricao: produtos_suplentes[key].children.pro_st_descricao,
        }));

        // main image path
        const main = getMainImagePath(images);

        // previous saved firebase files
        const previousURLs = getPreviouslySavedURLs(images);

        // new image files
        const newFiles = getNewImageFiles(images);

        await review(
          pro_in_codigo,
          clearBlankKeysFromNested({
            ...freightData,
            ...dataInfo,

            pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
            pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),

            product_type: 'gourmet',

            similares,

            pro_st_sku,
            pai_in_codigo,
            pai_name_to_create,
            reg_in_codigo,
            reg_name_to_create,
            pro_st_conteudo,
            pro_st_titulopage: infoData.pro_st_descricao,
            pro_st_nomesite: infoData.pro_st_descricao,
            pro_st_palavraschave: infoData.pro_st_tags,
            pro_st_video: imagens?.pro_st_video,

            updatedBy: user.usu_in_codigo,

            tipos,
            harmonizacoes,
            selos,
            gourmet: {
              ...data,
              gou_lt_ingredientes: data.gou_lt_ingredientes || '', // NOTE: yes, this is gambiarra
              pontuacao,
            },

            main,
            previousURLs,
          }),
          newFiles
        );

        history.push('/produtos/review');
      } catch (err) {
        // handle error
        setLoading(false);
      }
    },
    [
      getPontuacao,
      scoreKeys,
      getMainImagePath,
      getPreviouslySavedURLs,
      getNewImageFiles,
      pro_in_codigo,
      history,
    ]
  );

  return (
    <Main>
      <Title>Produtos e SKUs | {titles[selected]}</Title>

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Especificações',
            component: () => <GourmetForm onNext={next} isEdit />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm onNext={next} onPrevious={previous} isEdit />
            ),
          },
          {
            title: 'Frete e Venda',
            component: () => (
              <FreteForm onNext={next} onPrevious={previous} isEdit />
            ),
          },
          {
            title: 'Imagens e Vídeos',
            component: () => (
              <ImagensForm
                loading={isLoading}
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
                isEdit
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
