import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const Error = styled.p`
  color: tomato;
`;
