import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Input, Select, Button } from 'sonoma-design-system';

import FieldsetTitle from '../FieldsetTitle';
import Divider from '../Divider';

import { findAll as fetchAllBranches } from '../../services/FilialService';
import { findAll as fetchAllSellers } from '../../services/SellerService';

import { Footer, Section, InputContainer } from './styles';
import { insertIf } from '../../utils/arrays';

export default function UserForm({ onSubmit, onCancel, edit, user }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(null);
  const [sellerRole, setSellerRole] = useState(null);
  const [filialBelongs, setFilialBelongs] = useState(null);
  const [branch, setBranch] = useState(null);
  const [seller, setSeller] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [branches, setBranches] = useState([]);
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    setName(user?.usu_st_nome);
    setEmail(user?.usu_st_email);
    setRole(user?.roles[0]?.role.rol_st_descricao);

    if (edit) {
      if (user?.slr_in_codigo) {
        setFilialBelongs('VTEX');
        setSeller(user?.slr_in_codigo);
      }
      if (user?.fil_in_codigo) {
        setFilialBelongs('Millennium');
        setBranch(user?.fil_in_codigo);
      }
    }

    async function fetchBranches() {
      const { data } = await fetchAllBranches();

      setBranches(
        data.map((b) => ({
          label: b.apelido,
          value: b.fil_in_codigo,
        }))
      );
    }

    async function fetchSellers() {
      const { data } = await fetchAllSellers();

      setSellers(
        data.map((s) => ({
          label: s.slr_st_descricao,
          value: s.slr_in_codigo,
        }))
      );
    }

    fetchBranches();
    fetchSellers();
  }, [edit, user]);

  const [errors, setErrors] = useState({});

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const isEmailValid = useCallback((emailAddress) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(emailAddress);
  }, []);

  const roles = useMemo(
    () => [
      { value: 'INSIDER', label: 'Revisor' },
      { value: 'WRITER', label: 'Redator' },
      { value: 'SELLER', label: 'Seller' },
    ],
    []
  );

  const sellerRoles = useMemo(
    () => [
      { value: 'DELIVERY_BY_SELLER', label: 'Delivery by Seller' },
      { value: 'FULFILLS', label: 'Fulfills' },
      { value: 'FULFILLS_H', label: 'Fulfills Híbrido' }, // TODO: validate this role
    ],
    []
  );

  const handleSubmit = useCallback(() => {
    // validation
    let newErrors = {};

    if (!name || name.length === 0) {
      newErrors = { ...newErrors, name: 'Preencha o nome' };
    }

    if (!email || email.length === 0) {
      newErrors = { ...newErrors, email: 'Preencha o email' };
    }

    if (!isEmailValid(email)) {
      newErrors = { ...newErrors, email: 'O e-mail digitado é inválido' };
    }

    if (!role) {
      newErrors = { ...newErrors, role: 'Escolha o perfil do usuário' };
    }

    if (role === 'SELLER' && !sellerRole) {
      newErrors = {
        ...newErrors,
        sellerRole: 'Selecione a modalidade do seller',
      };
    }

    if (
      (sellerRole === 'FULFILLS' ||
        sellerRole === 'FULFILLS_H' ||
        (role === 'INSIDER' && filialBelongs === 'Millennium')) &&
      !branch
    ) {
      newErrors = {
        ...newErrors,
        branch: 'Selecione a filial',
      };
    }

    if (
      (sellerRole === 'DELIVERY_BY_SELLER' ||
        (role === 'INSIDER' && filialBelongs === 'VTEX')) &&
      !seller
    ) {
      newErrors = {
        ...newErrors,
        seller: 'Selecione a loja do seller',
      };
    }

    if (role === 'INSIDER' && !filialBelongs) {
      newErrors = {
        ...newErrors,
        filialBelongs: 'Selecione a que tipo de filial o usuario pertence',
      };
    }

    if (!edit) {
      if (!password || password.length < 6) {
        newErrors = {
          ...newErrors,
          password: 'A senha deve ter no mínimo 6 caracteres',
        };
      }

      if (password !== passwordConfirmation) {
        newErrors = {
          ...newErrors,
          passwordConfirmation: 'As senhas devem ser iguais',
        };
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    onSubmit({
      name,
      email,
      branch,
      seller,
      roles: [role, ...insertIf(role === 'SELLER', [sellerRole])],
      password,
      passwordConfirmation,
    });
  }, [
    onSubmit,
    isEmailValid,
    name,
    email,
    role,
    sellerRole,
    branch,
    seller,
    password,
    passwordConfirmation,
    edit,
    filialBelongs,
  ]);

  return (
    <Section>
      <FieldsetTitle>Insider</FieldsetTitle>

      <Grid spacing={2} container className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId='name'
                  size='small'
                  label='*Nome'
                  error={errors.name}
                  errorText={errors.name}
                  value={name}
                  handleChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>

          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Input
                  inputId='email'
                  size='small'
                  label='*E-mail'
                  type='email'
                  error={errors.email}
                  errorText={errors.email}
                  value={email}
                  handleChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>

          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={12}>
              <InputContainer>
                <Select
                  selectId='role'
                  size='small'
                  label='*Perfil'
                  error={errors.role}
                  errorText={errors.role}
                  value={role}
                  options={[{ value: null, label: 'Selecione' }, ...roles]}
                  handleChange={(e) => setRole(e.target.value)}
                />
              </InputContainer>
            </Grid>
          </Grid>

          {role === 'SELLER' && (
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <InputContainer>
                  <Select
                    selectId='sellerRole'
                    size='small'
                    label='*Modalidade do seller'
                    error={errors.sellerRole}
                    errorText={errors.sellerRole}
                    value={sellerRole}
                    options={[
                      { value: null, label: 'Selecione' },
                      ...sellerRoles,
                    ]}
                    handleChange={(e) => setSellerRole(e.target.value)}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          )}

          {role === 'INSIDER' && (
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <InputContainer>
                  <Select
                    selectId='branch'
                    size='small'
                    label='*Filial pertencente'
                    error={errors.filialBelongs}
                    errorText={errors.filialBelongs}
                    value={filialBelongs}
                    options={[
                      { value: null, label: 'Selecione' },
                      { value: 'VTEX', label: 'VTEX' },
                      { value: 'Millennium', label: 'Millennium' },
                    ]}
                    handleChange={(e) => setFilialBelongs(e.target.value)}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          )}
          {(sellerRole === 'FULFILLS' ||
            sellerRole === 'FULFILLS_H' ||
            filialBelongs === 'Millennium') && (
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <InputContainer>
                  <Select
                    selectId='branch'
                    size='small'
                    label='*Filial'
                    error={errors.branch}
                    errorText={errors.branch}
                    value={branch}
                    options={[{ value: null, label: 'Selecione' }, ...branches]}
                    handleChange={(e) => setBranch(e.target.value)}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          )}

          {(sellerRole === 'DELIVERY_BY_SELLER' ||
            filialBelongs === 'VTEX') && (
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <InputContainer>
                  <Select
                    selectId='seller'
                    size='small'
                    label='*Seller'
                    error={errors.seller}
                    errorText={errors.seller}
                    value={seller}
                    options={[{ value: null, label: 'Selecione' }, ...sellers]}
                    handleChange={(e) => setSeller(e.target.value)}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          )}

          {!edit && (
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <InputContainer>
                  <Input
                    inputId='password'
                    size='small'
                    label='*Senha'
                    type='password'
                    error={errors.password}
                    errorText={errors.password}
                    value={password}
                    handleChange={(e) => setPassword(e.target.value)}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
            </Grid>
          )}

          {!edit && (
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <InputContainer>
                  <Input
                    inputId='passwordConfirmation'
                    size='small'
                    label='*Confirme sua senha'
                    type='password'
                    error={errors.passwordConfirmation}
                    errorText={errors.passwordConfirmation}
                    value={passwordConfirmation}
                    handleChange={(e) =>
                      setPasswordConfirmation(e.target.value)
                    }
                    fullWidth
                  />
                </InputContainer>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: '20px' }} />

      <Footer>
        <Button outlined onClick={onCancel}>
          CANCELAR
        </Button>

        <Button onClick={handleSubmit}>SALVAR</Button>
      </Footer>
    </Section>
  );
}

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  edit: PropTypes.bool,
  user: PropTypes.shape({
    usu_in_codigo: PropTypes.number,
    usu_st_nome: PropTypes.string,
    usu_st_email: PropTypes.string,
    slr_in_codigo: PropTypes.string,
    fil_in_codigo: PropTypes.string,
    roles: PropTypes.array,
  }),
};

UserForm.defaultProps = {
  edit: false,
  user: null,
};
