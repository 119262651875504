import {
  findAll as findFiliais,
  findById as findFilial,
} from '../../services/FilialService';

export const setLoading = (loading) => ({
  type: '@filiais/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@filiais/setError',
  payload: error,
});

export const setPaging = (paging) => ({
  type: '@filiais/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@filiais/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@filiais/setFilters',
  payload: filters,
});

export const clearAll = () => ({
  type: '@filiais/clearAll',
});

export const setFiliais = (filiais) => ({
  type: '@filiais/setFiliais',
  payload: filiais,
});

export const setFilial = (filial) => ({
  type: '@filiais/setFilial',
  payload: filial,
});

export const setForm = (data) => ({
  type: '@filiais/setFormFilial',
  payload: data,
});

export const fetchAllFiliais =
  (paging, ordering, filters) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await findFiliais(paging, ordering, filters);

      return dispatch([
        setLoading(false),
        setFiliais(response.data),
        setPaging(response._metadata.paging),
        setOrdering(response._metadata.ordering),
        setFilters(response._metadata.filters),
        setError(false),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };

export const fetchFilialById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findFilial(id);

    return dispatch([setLoading(false), setFilial(response), setError(false)]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
