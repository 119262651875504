import { Types } from './invoices.action';

const INITIAL_STATE = {
  isLoading: false,
};

const setLoading = (state, action) => ({ ...state, isLoading: action.payload });

export default function socketReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_LOADING:
      return setLoading(state, action);
    case Types.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
