import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function findAllByProduct(productId) {
  try {
    const response = await api.get(`produtos/${productId}/prices`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar preços');
    throw e;
  }
}

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const response = await api.get('sales', {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar promoções');
    throw e;
  }
}

export async function create(productId, data) {
  try {
    await api.post(`produtos/${productId}/prices`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Promoção criada com sucesso!');
  } catch (err) {
    toast.error('Erro ao criar promoção');
    throw err;
  }
}

export async function uploadSalesFile(file) {
  try {
    const formData = new FormData();

    formData.append('file', file);

    await api.post('sales/file', formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(
      'Seu arquivo está sendo processado e se tudo estiver certo, em alguns instantes você poderá acompanhar as promoções criadas'
    );
  } catch (err) {
    if (err.response.status === 400) {
      toast.error(err.response.data.message, {
        autoClose: false,
      });
      throw err;
    }

    toast.error('Erro ao criar promoção');
    throw err;
  }
}

export async function remove(productId, saleId) {
  try {
    await api.delete(`produtos/${productId}/prices/${saleId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Promoção removida com sucesso!');
  } catch (err) {
    toast.error('Erro ao remover promoção');
    throw err;
  }
}
