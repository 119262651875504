const INITIAL_STATE = {
  form: {
    tipos: [],
    selos: [],
    harmonizacoes: [],
    uvas: [],
  },
  loading: false,
  error: false,
  errorOnFill: false,
};

const setForm = (state, action) => ({
  ...state,
  loading: false,
  form: {
    ...state.form,
    ...action.payload,
  },
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });
const setErrorOnFill = (state, action) => ({
  ...state,
  errorOnFill: action.payload,
});

const clearAll = () => INITIAL_STATE;

export default function vinhoEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@vinhoEdit/setForm':
      return setForm(state, action);
    case '@vinhoEdit/setLoading':
      return setLoading(state, action);
    case '@vinhoEdit/setError':
      return setError(state, action);
    case '@vinhoEdit/setErrorOnFill':
      return setErrorOnFill(state, action);
    case '@vinhoEdit/clearAll':
      return clearAll(state, action);
    default:
      return state;
  }
}
