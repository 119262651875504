import { createSelector } from 'reselect';
import {
  countries,
  curators,
  harmonizations,
  regions,
  stamps,
  types,
} from './specifications';

const aggregator = (state) => state.product.new.gourmet;

const isLoading = (state) =>
  aggregator(state).harmonizacoes.loading ||
  aggregator(state).selos.loading ||
  aggregator(state).curadores.loading ||
  aggregator(state).tipos.loading ||
  aggregator(state).regioes.loading;

const isErrored = (state) =>
  aggregator(state).harmonizacoes.error ||
  aggregator(state).selos.error ||
  aggregator(state).curadores.error ||
  aggregator(state).tipos.error ||
  aggregator(state).regioes.error ||
  aggregator(state).paises.error;

export default () =>
  createSelector(
    harmonizations(aggregator),
    stamps(aggregator),
    curators(aggregator),
    types(aggregator),
    regions(aggregator),
    countries(aggregator),
    isLoading,
    isErrored,
    (
      harmonizacoes,
      selos,
      curadores,
      tipos,
      regioes,
      paises,
      loading,
      error
    ) => ({
      harmonizacoes,
      selos,
      curadores,
      tipos,
      regioes,
      paises,
      loading,
      error,
    })
  );
