import { findOne } from '../../services/ProductService';

export const setForm = (data) => ({
  type: '@vinhoEdit/setForm',
  payload: data,
});

export const setLoading = (loading) => ({
  type: '@vinhoEdit/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@vinhoEdit/setError',
  payload: error,
});

export const setErrorOnFill = (error) => ({
  type: '@vinhoEdit/setErrorOnFill',
  payload: error,
});

export const clearAll = () => ({
  type: '@vinhoEdit/clearAll',
});

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    const pontuacoes = [];
    dispatch(setLoading(true));

    const { data: productData } = await findOne(productId);

    const tipos = productData.tipos?.map((item) => item.tipo);
    const uvas = productData.vinho?.uvas.map((item) => item.uva);
    const harmonizacoes = productData.produtoHarmonizacao?.map(
      (item) => item.harmonizacao
    );
    const selos = productData.selos?.map((item) => item.selo);

    if (productData.pontuacao?.length > 0) {
      const pontuacaoEdited = productData.pontuacao.reduce(
        (acc, curr) => acc + curr
      );
      delete pontuacaoEdited.pon_in_codigo;
      delete pontuacaoEdited.pro_in_codigo;

      pontuacoes.push(pontuacaoEdited);
    }

    return dispatch([
      setForm({
        ...pontuacoes[0],
        tipos,
        uvas,
        harmonizacoes,
        selos,
        pro_st_sku: productData.pro_st_sku,
        pai_in_codigo: productData.pai_in_codigo,
        reg_in_codigo: productData.reg_in_codigo,
        vin_de_teoralcoolico: productData.vinho?.vin_de_teoralcoolico,
        vin_de_temperatura: productData.vinho?.vin_de_temperatura,
        vin_in_tempodescanso: productData.vinho?.vin_in_tempodescanso,
        vin_in_tempobarrica: productData.vinho?.vin_in_tempobarrica,
        cur_in_codigo: productData.vinho?.cur_in_codigo,
        vin_in_quantidadecaixa: productData.vinho?.vin_in_quantidadecaixa,
        pro_st_conteudo: productData.pro_st_conteudo,
      }),
      setLoading(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
