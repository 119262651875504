import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';
import { ModalComponent, DragAndDrop, Button } from 'sonoma-design-system';
import { toast } from 'react-toastify';
import { Avatar, CircularProgress } from '@material-ui/core';
import { FaFileArchive } from 'react-icons/fa';

import dragndropIcon from '../../assets/dragndrop-icon.svg';
import tutorialImg from '../../assets/como-exportar-para-csv.png';

import { formatFileSizeToHumanReadableValue } from '../../utils/number';
import { Divider } from '../../pages/ProductsList/styles';
import { AlertParagraph, Image } from './styles';

export function UploadFilesModal({
  open,
  setOpen,
  maxFiles,
  allowedFileExtensions,
  maxSizeInBytes,
  onSubmit,
  label,
  modelFile,
  modelFileName,
  additionalInstructions: InstructionsComponent,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const findDuplicated = useCallback(
    (files) =>
      files.find((file) =>
        selectedFiles.find((selectedFile) => selectedFile.path === file.path)
      ),
    [selectedFiles]
  );

  const isFileExtensionValid = useCallback(
    (file, allowedExtensions) =>
      allowedExtensions.some((ext) => file.path.includes(ext)),
    []
  );

  const findFilesWithInvalidExtension = useCallback(
    (files, allowedExtensions) =>
      files.find((file) => !isFileExtensionValid(file, allowedExtensions)),
    [isFileExtensionValid]
  );

  const findFilesWithInvalidSize = useCallback(
    (files) => files.find((file) => file.size > maxSizeInBytes),
    [maxSizeInBytes]
  );

  const handleDrop = useCallback(
    (files) => {
      if (files.length > maxFiles) {
        toast.warn(
          `Você pode cadastrar somente ${maxFiles} arquivo(s) por vez`
        );
        return;
      }

      const duplicated = findDuplicated(files);

      if (duplicated) {
        toast.warning('Arquivo duplicado');
        return;
      }

      const hasFilesWithInvalidExtension = findFilesWithInvalidExtension(
        files,
        allowedFileExtensions
      );

      if (hasFilesWithInvalidExtension) {
        toast.warning(
          `Fomato inválido. Por favor, utilize apenas arquivos no(s) seguinte(s) formato(s): ${allowedFileExtensions.join()}`
        );
        return;
      }

      const hasFilesWithInvalidSize = findFilesWithInvalidSize(files);

      if (hasFilesWithInvalidSize) {
        toast.warning(
          'Um ou mais arquivos ultrapassam o tamanho máximo permitido'
        );
        return;
      }

      const selected = files
        .filter((file) => isFileExtensionValid(file, allowedFileExtensions))
        .map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            index: uuidv4(),
          })
        );

      setSelectedFiles(selected);
    },
    [
      findDuplicated,
      isFileExtensionValid,
      findFilesWithInvalidExtension,
      findFilesWithInvalidSize,
      allowedFileExtensions,
      maxFiles,
    ]
  );

  const dragSubtitle = () => (
    <div style={{ marginTop: '10px' }}>
      <p>
        Tamanho Maximo:{' '}
        <b>{formatFileSizeToHumanReadableValue(maxSizeInBytes, 0)}</b>
      </p>
      <p>
        {maxFiles === 1
          ? 'Permitido somente um arquivo por vez'
          : 'Permitido múltiplos arquivos'}
      </p>
      <p>
        Formatos permitidos: <b>{allowedFileExtensions.join()}</b>
      </p>
    </div>
  );

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await onSubmit(selectedFiles);

      setOpen(false);
      setSelectedFiles([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalComponent
      modalId='FileUploadModal'
      labelButton={label}
      open={open}
      buttonSize='medium'
      setOpen={setOpen}
    >
      {selectedFiles.length > 0 ? (
        selectedFiles?.map((file) => (
          <React.Fragment key={file.path}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar
                onClick={() => setSelectedFiles([])}
                style={{ height: '50px', width: '50px', cursor: 'pointer' }}
              >
                <FaFileArchive />
              </Avatar>
              <p>{file.path}</p>
            </div>

            <Divider style={{ margin: '20px 0' }} />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button disabled={loading} onClick={handleSubmit}>
                {loading ? <CircularProgress size='16px' /> : 'Enviar'}
              </Button>
            </div>
          </React.Fragment>
        ))
      ) : (
        <>
          {modelFile && (
            <>
              <p
                style={{
                  display: 'flex',
                  color: '#B00D1F',
                  fontWeight: 700,
                  margin: '0px 0px 30px 0px',
                }}
              >
                <a
                  download={modelFileName}
                  target='_blank'
                  href={modelFile}
                  rel='noreferrer'
                >
                  Para fazer download da planilha modelo <u>clique aqui!</u>
                </a>
              </p>

              {InstructionsComponent && <InstructionsComponent />}

              <AlertParagraph>
                Para exportar uma planilha em CSV, siga os passos da imagem
                abaixo:
              </AlertParagraph>

              <Image
                src={tutorialImg}
                alt='Como exportar planilhas para o formato CSV'
              />
            </>
          )}

          <DragAndDrop
            onDrop={handleDrop}
            icon={dragndropIcon}
            bodyTitle='Arraste e solte os arquivos aqui'
            bodySubtitle={dragSubtitle}
          />
        </>
      )}
    </ModalComponent>
  );
}

UploadFilesModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxFiles: PropTypes.number,
  /** Example: ['.csv', '.xlsx'] */
  allowedFileExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxSizeInBytes: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
  modelFile: PropTypes.any,
  modelFileName: PropTypes.string,
  additionalInstructions: PropTypes.func,
};

UploadFilesModal.defaultProps = {
  maxFiles: 1,
  label: 'Enviar arquivos',
  modelFile: null,
  modelFileName: '',
  additionalInstructions: null,
};
