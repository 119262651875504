import PropTypes from 'prop-types';
import { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Input, Select, Button } from 'sonoma-design-system';

import Divider from '../Divider';
import FieldsetTitle from '../FieldsetTitle';
import { formatNumber, reverseFormatNumber } from '../../utils/number';
import { DatePickerComponent } from '../DatePicker';
import { ButtonDescription, Footer } from './styles';

export function InvoiceForm({ onSubmit, onCancel }) {
  const [type, setType] = useState('Output');
  const [issuanceDate, setIssuanceDate] = useState(new Date());
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [value, setValue] = useState('');
  const [key, setKey] = useState('');
  const [url, setURL] = useState('');
  const [embedded, setEmbedded] = useState('');

  const types = [
    { value: 'Output', label: 'Saída' },
    { value: 'Input', label: 'Entrada' },
  ];

  function handleSubmit(e) {
    e.preventDefault();

    onSubmit({
      type,
      issuanceDate,
      invoiceNumber,
      value,
      key,
      url,
      embedded,
    });
  }

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <FieldsetTitle>Faturar pedido</FieldsetTitle>

        <Grid spacing={2} container className={classes.root}>
          <Grid item xs={12} lg={12}>
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <p>Data de emissão*</p>
              </Grid>

              <Grid item xs={12} lg={6}>
                <DatePickerComponent
                  value={issuanceDate}
                  setValue={setIssuanceDate}
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <Select
                  selectId='type'
                  size='small'
                  helperText='Entrada / Saída'
                  label='Tipo'
                  fullWidth
                  value={type}
                  options={types}
                  handleChange={(e) => setType(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Input
                  isNumeric
                  prefix='R$'
                  inputId='value'
                  size='small'
                  label='Valor total'
                  fullWidth
                  value={reverseFormatNumber(value)}
                  handleChange={(e) =>
                    setValue(formatNumber(e.target.value.replaceAll('R$', '')))
                  }
                  required
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <Input
                  inputId='invoiceNumber'
                  size='small'
                  label='Número da nota'
                  fullWidth
                  value={invoiceNumber}
                  handleChange={(e) => setInvoiceNumber(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Input
                  inputId='key'
                  size='small'
                  label='Chave de acesso'
                  fullWidth
                  value={key}
                  handleChange={(e) => setKey(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <Input
                  inputId='url'
                  size='small'
                  label='URL'
                  fullWidth
                  value={url}
                  handleChange={(e) => setURL(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={12}>
                <Input
                  inputId='embedded'
                  size='small'
                  label='XML'
                  fullWidth
                  value={embedded}
                  handleChange={(e) => setEmbedded(e.target.value)}
                  textArea
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ margin: '10px 0px 30px 0px' }} />

        <Footer>
          <Button
            outlined
            isStartIcon
            onClick={onCancel}
            backgroundColor='#8A8A8A'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>

          <Button type='submit' backgroundColor='#9CD186'>
            <ButtonDescription>Enviar faturamento</ButtonDescription>
          </Button>
        </Footer>
      </form>
    </section>
  );
}

InvoiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
