/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles, Avatar } from '@material-ui/core';
import { Checkbox, Input, Select, Tag } from 'sonoma-design-system';

import { TagsContainer } from './styles';

import SkuAndDescriptionFields from '../SkuAndDescriptionFields';

import { getUserRoles } from '../../auth/auth';
import bestSellerIcon from '../../assets/best-seller-icon.png';
import naturalIcon from '../../assets/natural-icon.png';
import organicoIcon from '../../assets/organico-icon.png';

function QueijoFields({
  form,

  tipos,
  paises,
  regioes,
  harmonizacoes,
  selos,

  onFormChange,
  onCheckbox,
  onAddTag,
  onSetForm,
  fetchTaxData,
  errorOnFill,

  skuExist,
  isEdit,
  descricaoExist,
}) {
  const isDeliveryBySeller = getUserRoles().includes('DELIVERY_BY_SELLER');
  const isFFH = getUserRoles().includes('FULFILLS_H');

  // effect to exclude pai_name_to_create when doesn't need
  useEffect(() => {
    if (form.pai_in_codigo != 999) {
      onSetForm({
        pai_name_to_create: null,
      });
    }

    if (form.reg_in_codigo != 999) {
      onSetForm({
        reg_name_to_create: null,
      });
    }
  }, [form.pai_in_codigo, form.reg_in_codigo, onSetForm]);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  // function handleData() {
  //   if (form?.que_dt_datavalidade) {
  //     if (form.que_dt_datavalidade !== '') {
  //       return format(parseISO(form?.que_dt_datavalidade), 'yyyy-MM-dd');
  //     }
  //   }
  //   return format(new Date(), 'yyyy-MM-dd');
  // }

  // function handleDataValue(e) {
  //   if (e.target.value.length <= 10) {
  //     onFormChange(e);
  //   }
  // }

  function handleIconSelos() {
    function renderIcon(tag, iconSrc) {
      return (
        <Avatar
          style={{ cursor: 'pointer' }}
          alt={tag.sel_in_codigo}
          src={iconSrc}
          onClick={() =>
            onSetForm({
              selos: form.selos.filter(
                (t) => t.sel_in_codigo !== tag.sel_in_codigo
              ),
            })
          }
        />
      );
    }
    return (
      <TagsContainer>
        {form?.selos?.map((tag) => {
          if (tag.sel_st_descricao === 'organico') {
            return renderIcon(tag, organicoIcon);
          }
          if (tag.sel_st_descricao === 'bestSellers') {
            return renderIcon(tag, bestSellerIcon);
          }
          if (tag.sel_st_descricao === 'natural') {
            return renderIcon(tag, naturalIcon);
          }
          return null;
        })}
      </TagsContainer>
    );
  }

  return (
    <Grid spacing={2} container className={classes.root}>
      <SkuAndDescriptionFields
        errorSkuOnFill={errorOnFill}
        skuForm={form}
        skuExist={skuExist}
        onFormSkuChange={onFormChange}
        isEdit={isEdit}
        descricaoExist={descricaoExist}
        fetchTaxData={fetchTaxData}
      />
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          {!isDeliveryBySeller && !isFFH && (
            <Grid item xs={6} lg={4}>
              <Select
                selectId='tipos'
                size='small'
                label='Tipo'
                options={[{ value: null, label: 'Selecione' }, ...tipos]}
                handleChange={(e) =>
                  onAddTag(
                    e.target.name,
                    {
                      tip_in_codigo: e.target.value,
                      tip_st_descricao: tipos.find(
                        (t) => t.value === Number(e.target.value)
                      ).label,
                    },
                    'tip_in_codigo'
                  )
                }
              />
              <TagsContainer>
                {form?.tipos?.map((tag) => (
                  <Tag
                    key={tag.tip_in_codigo}
                    onDelete={() =>
                      onSetForm({
                        tipos: form.tipos.filter(
                          (t) => t.tip_in_codigo !== tag.tip_in_codigo
                        ),
                      })
                    }
                  >
                    {tag.tip_st_descricao}
                  </Tag>
                ))}
              </TagsContainer>
            </Grid>
          )}

          <Grid item xs={6} lg={4}>
            <Select
              selectId='pai_in_codigo'
              options={[
                { value: null, label: 'Selecione' },
                { value: 999, label: 'Outros' },
                ...paises,
              ]}
              value={form?.pai_in_codigo}
              handleChange={onFormChange}
              size='small'
              label='País'
            />
          </Grid>

          {form.pai_in_codigo == 999 && (
            <Grid item xs={6} lg={4}>
              <Input
                error={errorOnFill && !form?.pai_name_to_create}
                errorText='Inserir nome do país'
                inputId='pai_name_to_create'
                value={form?.pai_name_to_create || ''}
                handleChange={(e) =>
                  onSetForm({
                    pai_name_to_create: e.target.value,
                  })
                }
                size='small'
                label='Nome do país'
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={6} lg={4}>
            <Select
              selectId='reg_in_codigo'
              options={[
                { value: null, label: 'Selecione' },
                { value: 999, label: 'Outros' },
                ...regioes,
              ]}
              value={form?.reg_in_codigo}
              handleChange={onFormChange}
              size='small'
              label='Regiões'
              disabled={!form?.pai_in_codigo}
            />
          </Grid>

          {form.reg_in_codigo == 999 && (
            <Grid item xs={6} lg={4}>
              <Input
                error={errorOnFill && !form?.reg_name_to_create}
                errorText='Inserir nome da região'
                inputId='reg_name_to_create'
                value={form?.reg_name_to_create || ''}
                handleChange={(e) =>
                  onSetForm({
                    reg_name_to_create: e.target.value,
                  })
                }
                size='small'
                label='Nome da região'
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={3}>
            <Select
              selectId='harmonizacoes'
              options={[{ value: null, label: 'Selecione' }, ...harmonizacoes]}
              size='small'
              label='Harmonização'
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    har_in_codigo: e.target.value,
                    har_st_descricao: harmonizacoes.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'har_in_codigo'
                )
              }
            />
            <TagsContainer>
              {form?.harmonizacoes?.map((tag) => (
                <Tag
                  key={tag.har_in_codigo}
                  onDelete={() =>
                    onSetForm({
                      harmonizacoes: form.harmonizacoes.filter(
                        (t) => t.har_in_codigo !== tag.har_in_codigo
                      ),
                    })
                  }
                >
                  {tag.har_st_descricao}
                </Tag>
              ))}
            </TagsContainer>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Select
              selectId='selos'
              size='small'
              label='Selos'
              options={[{ value: null, label: 'Selecione' }, ...selos]}
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    sel_in_codigo: e.target.value,
                    sel_st_descricao: selos.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'sel_in_codigo'
                )
              }
            />

            {handleIconSelos()}
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='pro_st_conteudo'
              size='small'
              label='Conteúdo'
              helperText='(g),(kg)'
              value={form?.pro_st_conteudo}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='que_st_produtor'
              error={errorOnFill && !form?.que_st_produtor}
              errorText='Inserir produtor'
              size='small'
              helperText='Inserir produtor'
              label='Produtor'
              required
              value={form?.que_st_produtor}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={3}>
            <Input
              inputId='que_st_casca'
              errorText='Inserir casca do queijo'
              size='small'
              label='Casca'
              helperText='Inserir casca do queijo'
              value={form?.que_st_casca}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Input
              inputId='que_st_massa'
              errorText='Inserir massa do queijo'
              size='small'
              label='Massa'
              helperText='Inserir massa do queijo'
              value={form?.que_st_massa}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Input
              inputId='que_st_intensidade'
              errorText='Inserir intensidade do queijo'
              size='small'
              helperText='Inserir intensidade do queijo'
              label='Intensidade'
              value={form?.que_st_intensidade}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Input
              inputId='que_st_variedadeleite'
              errorText='Inserir variedade do leite do queijo'
              size='small'
              label='Variedade do leite'
              helperText='Inserir variedade do leite do queijo'
              value={form?.que_st_variedadeleite}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={6}>
            <Checkbox
              checkboxId='que_bo_vegano'
              value={form?.que_bo_vegano}
              handleChange={onCheckbox}
              label='Vegano'
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Checkbox
              checkboxId='que_bo_vegetariano'
              value={form?.que_bo_vegetariano}
              handleChange={onCheckbox}
              label='Vegetariano'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

QueijoFields.propTypes = {
  form: PropTypes.object.isRequired,

  tipos: PropTypes.array.isRequired,
  paises: PropTypes.array.isRequired,
  regioes: PropTypes.array.isRequired,
  harmonizacoes: PropTypes.array.isRequired,
  selos: PropTypes.array.isRequired,

  onFormChange: PropTypes.func.isRequired,
  onCheckbox: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onSetForm: PropTypes.func.isRequired,
  fetchTaxData: PropTypes.func,
  errorOnFill: PropTypes.bool,

  // check if sku exist
  // if exist don't go to the next step
  skuExist: PropTypes.bool,
  // check if descricao exist
  // if exist don't go to the next step
  descricaoExist: PropTypes.bool,
  isEdit: PropTypes.bool,
};

QueijoFields.defaultProps = {
  errorOnFill: false,
  skuExist: false,
  descricaoExist: false,
  isEdit: false,
  fetchTaxData: null,
};

export default QueijoFields;
