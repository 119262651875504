/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from 'sonoma-design-system';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import { fetchData, setForm, setErrorOnFill } from '../../store/vinhoNew';
import {
  setForm as setFormEdit,
  setErrorOnFill as setErrorOnFillEdit,
} from '../../store/vinhoEdit';
import {
  setErrorOnFill as setInfoErrorOnFillEdit,
  setForm as setInfoEditForm,
} from '../../store/informacoesEdit';
import {
  setErrorOnFill as setInfoErrorOnFill,
  setForm as setInfoForm,
} from '../../store/informacoesForm';
import vinhoDataSelector from '../../store/selectors/vinho';

import { findBySku, findOneByDescricao } from '../../services/ProductService';
import { fetchProductTaxData } from '../../services/ProductTaxDataService';

import Divider from '../Divider';
import FieldsetTitle from '../FieldsetTitle';
import VinhoFields from '../VinhoFields';
import ScoreFields from '../ScoreFields';

import { Error, Footer } from './styles';
import { ButtonDescription } from '../../styles';
import { useLocalDB } from '../../hooks';

export default function VinhoForm({ onNext, isEdit, publisher }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { add, clear, data: localData } = useLocalDB('wines');

  const [skuExist, setSkuExist] = useState(false);
  const [skuVerification, setSkuVerification] = useState(false);

  const [descricaoVerification, setDescricaoVerification] = useState(false);
  const [descricaoExist, setDescricaoExist] = useState(false);

  const form = useSelector((state) =>
    isEdit ? state.product.edit.vinho.form : state.product.new.vinho.form
  );
  const infoForm = useSelector((state) =>
    isEdit
      ? state.product.edit.informacoes.form
      : state.product.new.informacoes.form
  );
  const loadingProduct = useSelector(
    (state) => isEdit && state.product.edit.vinho.loading
  );
  const productError = useSelector(
    (state) => isEdit && state.product.edit.vinho.error
  );
  const errorOnFill = useSelector((state) =>
    isEdit
      ? state.product.edit.vinho.errorOnFill
      : state.product.new.vinho.errorOnFill
  );

  const handleSetForm = useCallback(
    (value) => {
      dispatch(isEdit ? setFormEdit({ ...value }) : setForm({ ...value }));
    },
    [dispatch, isEdit]
  );

  const handleSetInfoForm = useCallback(
    (value) => {
      dispatch(
        isEdit ? setInfoEditForm({ ...value }) : setInfoForm({ ...value })
      );
    },
    [dispatch, isEdit]
  );

  const fetchTaxData = useCallback(
    async (sku) => {
      const data = await fetchProductTaxData(sku);

      handleSetInfoForm({
        clf_in_codigo: data.clf_in_codigo,
        clp_in_codigo: data.clp_in_codigo,
        ori_in_codigo: data.ori_in_codigo,
        pei_in_codigo: data.pei_in_codigo,
      });
    },
    [handleSetInfoForm]
  );

  const {
    harmonizacoes,
    selos,
    uvas,
    curadores,
    tipos,
    regioes,
    paises,
    loading: loadingData,
    error: dataError,
  } = useSelector(vinhoDataSelector());

  useEffect(() => {
    if (
      harmonizacoes.length > 0 &&
      selos.length > 0 &&
      uvas.length > 0 &&
      curadores.length > 0 &&
      tipos.length > 0 &&
      regioes.length > 0 &&
      paises.length > 0
    )
      return;

    dispatch(fetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (localData) {
      handleSetForm({
        pro_st_sku: localData.sku,
        pai_in_codigo: localData.pais,
        reg_in_codigo: localData.regiao,
        vin_de_teoralcoolico: localData.teorAlcoolico,
        vin_de_temperatura: localData.temperatura,
        vin_in_tempodescanso: localData.tempoDescanso,
        tipos: localData.tipos,
        uvas: localData.uvas,
        vin_in_tempobarrica: localData.barrica,
        pro_st_conteudo: localData.conteudo,
        cur_in_codigo: localData.curador,
        harmonizacoes: localData.harmonizacoes,
        selos: localData.selos,
        vin_in_quantidadecaixa: localData.caixa,

        pon_in_ws: localData.pon_in_ws,
        pon_in_we: localData.pon_in_we,
        pon_in_gd: localData.pon_in_gd,
        pon_in_pm: localData.pon_in_pm,
        pon_in_jr2: localData.pon_in_jr2,
        pon_in_ad: localData.pon_in_ad,
        pon_in_iwr: localData.pon_in_iwr,
        pon_in_tp: localData.pon_in_tp,
        pon_in_uw: localData.pon_in_uw,
        pon_in_gg: localData.pon_in_gg,
        pon_in_ce: localData.pon_in_ce,
        pon_in_jd: localData.pon_in_jd,
        pon_in_js: localData.pon_in_js,
        pon_in_gp: localData.pon_in_gp,
        pon_in_wes: localData.pon_in_wes,
        pon_in_rp: localData.pon_in_rp,
        pon_in_jr: localData.pon_in_jr,
        pon_in_ak: localData.pon_in_ak,
        pon_in_s: localData.pon_in_s,
        pon_in_ag: localData.pon_in_ag,
        pon_in_ta: localData.pon_in_ta,
        pon_in_v: localData.pon_in_v,
        pon_in_lm: localData.pon_in_lm,
        pon_in_d: localData.pon_in_d,
        pon_in_st: localData.pon_in_st,
        pon_in_bh: localData.pon_in_bh,
        pon_in_mf: localData.pon_in_mf,
      });
    }
  }, [dispatch, handleSetForm, localData]);

  const onFormChange = useCallback(
    (e) => {
      // If options choosed is 'Selecione' return null on redux
      if (e.target.value === 'Selecione') {
        handleSetForm({ [e.target.name]: null });
      } else {
        handleSetForm({ [e.target.name]: e.target.value });
      }
    },
    [handleSetForm]
  );

  const onAddTag = useCallback(
    (name, value, atrName) => {
      const oldValue = form?.[name] || [];

      if (form) {
        // eslint-disable-next-line eqeqeq
        const exists = form[name]?.find((t) => t[atrName] == value[atrName]);

        if (exists) return;
      }

      handleSetForm({ [name]: [...oldValue, value] });
    },
    [handleSetForm, form]
  );

  const onAddTags = useCallback(
    (name, values, attrName) => {
      const oldValues = form?.[name] || [];

      if (!form) return;

      const nonDuplicated = values.filter(
        // eslint-disable-next-line eqeqeq
        (value) => !oldValues.find((t) => t[attrName] == value[attrName])
      );

      handleSetForm({ [name]: [...oldValues, ...nonDuplicated] });
    },
    [handleSetForm, form]
  );

  const loading = useMemo(
    () => loadingData || loadingProduct,
    [loadingData, loadingProduct]
  );

  const error = useMemo(
    () => dataError || productError,
    [dataError, productError]
  );

  if (loading) return <Skeleton animation='wave' height={600} />;

  if (error)
    return (
      <Error>
        Tivemos um problema ao carregar as informações, tente novamente mais
        tarde.
      </Error>
    );

  async function isValid() {
    if (!isEdit && form.pro_st_sku) {
      setSkuVerification(true);

      const skuAlreadyExist = await findBySku(form.pro_st_sku);

      setSkuExist(skuAlreadyExist.length > 0);

      setSkuVerification(false);

      if (skuAlreadyExist.length > 0) {
        toast.warning('SKU ja existente !');
        return false;
      }
    }

    if (!isEdit && infoForm.pro_st_descricao) {
      setDescricaoVerification(true);

      const descriptionAlreadyExist = await findOneByDescricao(
        infoForm.pro_st_descricao
      );

      setDescricaoExist(descriptionAlreadyExist.data);

      setDescricaoVerification(false);

      if (descriptionAlreadyExist.data) {
        toast.warning('Descrição do produto ja existe !');
        return false;
      }
    }

    if (!publisher && !form.pro_st_sku) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (!infoForm.pro_st_descricao) {
      dispatch(
        isEdit ? setInfoErrorOnFillEdit(true) : setInfoErrorOnFill(true)
      );
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (form.pai_in_codigo == 999 && !form.pai_name_to_create) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    if (form.reg_in_codigo == 999 && !form.reg_name_to_create) {
      dispatch(isEdit ? setErrorOnFillEdit(true) : setErrorOnFill(true));
      toast.warning('Preencha os campos obrigatórios.');
      return false;
    }

    dispatch(isEdit ? setErrorOnFillEdit(false) : setErrorOnFill(false));
    return true;
  }

  function saveLocally() {
    clear();

    add({
      sku: form.pro_st_sku,
      pais: form.pai_in_codigo,
      regiao: form.reg_in_codigo,
      teorAlcoolico: form.vin_de_teoralcoolico,
      temperatura: form.vin_de_temperatura,
      tempoDescanso: form.vin_in_tempodescanso,
      tipos: form.tipos,
      uvas: form.uvas,
      barrica: form.vin_in_tempobarrica,
      conteudo: form.pro_st_conteudo,
      curador: form.cur_in_codigo,
      harmonizacoes: form.harmonizacoes,
      selos: form.selos,
      caixa: form.vin_in_quantidadecaixa,

      pon_in_ws: form.pon_in_ws,
      pon_in_we: form.pon_in_we,
      pon_in_gd: form.pon_in_gd,
      pon_in_pm: form.pon_in_pm,
      pon_in_jr2: form.pon_in_jr2,
      pon_in_ad: form.pon_in_ad,
      pon_in_iwr: form.pon_in_iwr,
      pon_in_tp: form.pon_in_tp,
      pon_in_uw: form.pon_in_uw,
      pon_in_gg: form.pon_in_gg,
      pon_in_ce: form.pon_in_ce,
      pon_in_jd: form.pon_in_jd,
      pon_in_js: form.pon_in_js,
      pon_in_gp: form.pon_in_gp,
      pon_in_wes: form.pon_in_wes,
      pon_in_rp: form.pon_in_rp,
      pon_in_jr: form.pon_in_jr,
      pon_in_ak: form.pon_in_ak,
      pon_in_s: form.pon_in_s,
      pon_in_ag: form.pon_in_ag,
      pon_in_ta: form.pon_in_ta,
      pon_in_v: form.pon_in_v,
      pon_in_lm: form.pon_in_lm,
      pon_in_d: form.pon_in_d,
      pon_in_st: form.pon_in_st,
      pon_in_bh: form.pon_in_bh,
      pon_in_mf: form.pon_in_mf,
    });
  }

  async function handleNextStep() {
    if (!(await isValid())) return false;

    saveLocally();

    return onNext();
  }

  function handleButtonDescription() {
    if (skuVerification || descricaoVerification) {
      return <CircularProgress size='16px' />;
    }

    return 'Avançar';
  }

  return (
    <section>
      <FieldsetTitle>Vinho</FieldsetTitle>

      <VinhoFields
        form={form}
        uvas={uvas}
        tipos={tipos}
        paises={paises}
        regioes={regioes}
        harmonizacoes={harmonizacoes}
        selos={selos}
        curadores={curadores}
        onFormChange={onFormChange}
        onAddTag={onAddTag}
        onAddTags={onAddTags}
        onSetForm={handleSetForm}
        fetchTaxData={fetchTaxData}
        errorOnFill={errorOnFill}
        publisher={publisher}
        skuExist={skuExist}
        descricaoExist={descricaoExist}
        isEdit={isEdit}
      />

      <FieldsetTitle>Pontuação</FieldsetTitle>

      <ScoreFields form={form} handleFormChange={onFormChange} />

      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Footer>
        <Button
          outlined
          onClick={() => history.push('/')}
          backgroundColor='#B00D1F'
        >
          <ButtonDescription>Cancelar</ButtonDescription>
        </Button>

        <Button onClick={handleNextStep} backgroundColor='#9CD186'>
          <ButtonDescription>{handleButtonDescription()}</ButtonDescription>
        </Button>
      </Footer>
    </section>
  );
}

VinhoForm.propTypes = {
  onNext: PropTypes.func,
  isEdit: PropTypes.bool,
  publisher: PropTypes.bool,
};

VinhoForm.defaultProps = {
  onNext: null,
  isEdit: false,
  publisher: false,
};
