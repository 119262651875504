import styled from 'styled-components';

export const Title = styled.h1`
  font-family: 'Monotype Gil Sans Nova Bold', sans-serif;
  color: #3b3b3a;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* margin-top: 10px; */
  /* padding: 15px; */
  background-color: #fff;
`;
