import reducer from './billing.reducer';

export {
  clear,
  fetchBillingData,
  setAverageTicket,
  setError,
  setFilial,
  setFrom,
  setLoading,
  setSales,
  setTo,
  setTotal,
} from './billing.action';

export default reducer;
