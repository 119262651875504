import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import { getToken, getUserRoles } from '../../auth/auth';
import { containsAny } from '../../utils/arrays';

function RoleBasedRoute({ component: Component, roles, ...rest }) {
  const token = getToken();

  if (!token) return <Redirect to='/login' />;

  const userRoles = getUserRoles();

  if (!containsAny(userRoles, roles)) return <Redirect to='/login' />;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

RoleBasedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  roles: PropTypes.array.isRequired,
};

export default RoleBasedRoute;
