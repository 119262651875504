import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #8a8a8a;
  display: flex;
  align-content: space-between;
`;

export const CustomButton = styled.button`
  all: unset;
  width: 100%;
`;
