import { useCallback, useEffect } from 'react';

import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';

import { getToken, getExpiration, clearAuthData } from './auth/auth';
import Routes from './routes';
import LoadingSpinner from './components/LoadingSpinner';
import { Socket } from './config/socket';

import './config/firebase';

const isAuthenticated = getToken();
const exp = getExpiration();

toast.configure({
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  newestOnTop: true,
});

function App() {
  const isLoading = useSelector((state) => state.loading.isLoading);

  const dispatch = useDispatch();

  function removeLocalStorageItensAndRedirect() {
    clearAuthData();
    window.location.replace('/');
  }

  // check jwt expiration date
  const checkJWTExpiration = useCallback(() => {
    if (exp && new Date() >= exp) {
      removeLocalStorageItensAndRedirect();
    }
  }, []);

  // app init check jwt expiration and jwt valid
  // if dont have jwt, routes.js control this
  useEffect(() => {
    if (isAuthenticated) {
      checkJWTExpiration();
    }
  }, [checkJWTExpiration]);

  useEffect(
    () => () => {
      async function disconnect() {
        const socket = await Socket.getInstance();
        socket.disconnect();
      }

      disconnect();
    },
    [dispatch]
  );

  return (
    <div>
      <Routes />

      <LoadingSpinner loading={isLoading} />
    </div>
  );
}

export default App;
