import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Input } from 'sonoma-design-system';

function ScoreFields({ form, handleFormChange }) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      marginBottom: 30,
    },
    gridRow: {
      marginBottom: 10,
    },
  }));

  const classes = useStyles();

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_ad'
              value={form?.pon_in_ad || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='AD'
              avatarTitle='Guia Adega'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_ag'
              value={form?.pon_in_ag || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='AG'
              avatarTitle='Antonio Galloni'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_ak'
              value={form?.pon_in_ak || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='AK'
              avatarTitle='Alykhan Karim'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_bh'
              value={form?.pon_in_bh || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='BH'
              avatarTitle='Burghound (Allen Meadows)'
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_ce'
              value={form?.pon_in_ce || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='CE'
              avatarTitle='Christian Eedes'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_d'
              value={form?.pon_in_d || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='D'
              avatarTitle='Decanter Magazine'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_gd'
              value={form?.pon_in_gd || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='GD'
              avatarTitle='Guia Descorchados'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              isNumeric
              inputId='pon_in_gg'
              value={form?.pon_in_gg || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='GG'
              avatarTitle='Gilbert Gaillard'
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_gp'
              value={form?.pon_in_gp || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='GP'
              avatarTitle='Guia Peñín'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_iwr'
              value={form?.pon_in_iwr || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='IW'
              avatarTitle='IWR'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_jd'
              value={form?.pon_in_jd || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='JD'
              avatarTitle='Jeb Dunnuck'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_jr'
              value={form?.pon_in_jr || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='JR'
              avatarTitle='Jancis Robinson'
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_jr2'
              value={form?.pon_in_jr2 || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='JR'
              avatarTitle='Josh Raynolds'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_js'
              value={form?.pon_in_js || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='JS'
              avatarTitle='James Suckling'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_lm'
              value={form?.pon_in_lm || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='LM'
              avatarTitle='Luca Maroni'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_mf'
              value={form?.pon_in_mf || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='MF'
              avatarTitle='MIke Frost'
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_pm'
              value={form?.pon_in_pm || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='PM'
              avatarTitle='Prazeres da Mesa'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_rp'
              value={form?.pon_in_rp || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='RP'
              avatarTitle="Robert Parker's Wine Advocate"
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_s'
              value={form?.pon_in_s || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='S'
              avatarTitle='Sonoma'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_st'
              value={form?.pon_in_st || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='ST'
              avatarTitle='Stephen Tanzer'
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_ta'
              value={form?.pon_in_ta || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='TA'
              avatarTitle='Tim Atkin'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_tp'
              value={form?.pon_in_tp || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='TP'
              avatarTitle='Tasting Panel The Somm Journal'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_uw'
              value={form?.pon_in_uw || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='UW'
              avatarTitle='Ultimate Wine Challenge'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_v'
              value={form?.pon_in_v || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='V'
              avatarTitle='Vinous'
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={classes.gridRow}>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_we'
              value={form?.pon_in_we || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='WE'
              avatarTitle='Wine Enthusiast'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_ws'
              value={form?.pon_in_ws || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='WS'
              avatarTitle='Wine Spectator'
            />
          </Grid>
          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pon_in_wes'
              value={form?.pon_in_wes || ''}
              handleChange={handleFormChange}
              size='small'
              fullWidth
              avatar
              avatarName='WS'
              avatarTitle='Wine & Spirits Magazine'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ScoreFields.propTypes = {
  form: PropTypes.object.isRequired,

  handleFormChange: PropTypes.func.isRequired,
};

export default ScoreFields;
