const INITIAL_STATE = {
  sellers: [],
  seller: null,
  loading: false,
  error: false,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order_by: null,
    order: 'asc',
    availableOrderby: [],
  },
  filters: {
    ref: '',
  },
};

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const setPaging = (state, action) => ({
  ...state,
  paging: action.payload,
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: action.payload,
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setSellers = (state, action) => ({
  ...state,
  loading: false,
  sellers: action.payload,
});

const setSeller = (state, action) => ({
  ...state,
  loading: false,
  seller: action.payload,
});

const setForm = (state, action) => ({
  ...state,
  seller: {
    ...state.seller,
    ...action.payload,
  },
});

const clearAll = () => INITIAL_STATE;

export default function freteEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@sellers/setFormSeller':
      return setForm(state, action);
    case '@sellers/setSellers':
      return setSellers(state, action);
    case '@sellers/setSeller':
      return setSeller(state, action);
    case '@sellers/setLoading':
      return setLoading(state, action);
    case '@sellers/setError':
      return setError(state, action);
    case '@sellers/setPaging':
      return setPaging(state, action);
    case '@sellers/setOrdering':
      return setOrdering(state, action);
    case '@sellers/setFilters':
      return setFilters(state, action);
    case '@sellers/clearAll':
      return clearAll(state, action);
    default:
      return state;
  }
}
