import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { Table, Options } from 'sonoma-design-system';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ListAltOutlined } from '@material-ui/icons';
import { MdInventory } from 'react-icons/md';

import { fetchOrders, setLoading } from '../../store/dbsOrders';

import { CalendarModal } from '../../components/CalendarModal';
import { CalendarSelect } from '../../components/CalendarSelect';

import { DateColumn } from './DateColumn';
import { ItemAmount } from './ItemAmount';
import { Customer } from './Customer';

import { startHandling as startHandlingOrder } from '../../services/HandleOrderStatusService';

import { Section } from './styles';
import { getToken, getUserRoles } from '../../auth/auth';
import { containsAny } from '../../utils/arrays';

export default function DeliveryBySellerOrders() {
  const dispatch = useDispatch();

  const { sellerId } = useParams();

  const { orders, loading, error, paging, ordering, filters } = useSelector(
    (state) => state.dbsOrders
  );

  const token = getToken();
  const roles = token ? getUserRoles() : [];
  const isDeliveryBySeller = containsAny(roles, ['DELIVERY_BY_SELLER']);

  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    dispatch(
      fetchOrders(
        sellerId,
        { page: paging.page, limit: paging.limit },
        { order: ordering.order, order_by: ordering.order_by },
        {
          from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
          to: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
        }
      )
    );
  }, [
    dispatch,
    sellerId,
    paging.page,
    paging.limit,
    ordering.order,
    ordering.order_by,
  ]);

  async function startHandling(orderId) {
    setLoading(true);

    await startHandlingOrder(orderId);

    setLoading(false);
  }

  function Actions({ item }) {
    const history = useHistory();
    const [isHandling, setIsHandling] = useState(false);

    const options = [
      {
        icon: ListAltOutlined,
        text: 'Detalhes Do Pedido',
        onClick: () => history.push(`/order/${item.pev_in_codigo}`),
      },
      item.pev_st_status === 'ready-for-handling' &&
        isDeliveryBySeller && {
          icon: MdInventory,
          text: 'Começar manuseio',
          onClick: () => {
            if (!isHandling) {
              startHandling(item.pev_in_codigo);
              setIsHandling(true);
            }
          },
        },
    ].filter((e) => e);

    return (
      <td>
        <Options options={options} />
      </td>
    );
  }

  Actions.propTypes = {
    item: PropTypes.object.isRequired,
  };

  const cols = useMemo(
    () => [
      { id: 'pev_st_vtex_id', title: 'Pedido' },
      {
        id: 'cliente',
        title: 'Nome do cliente',
        component: Customer,
      },
      {
        id: 'pev_dt_data_criacao',
        title: 'Data de emissão',
        component: (props) => (
          <DateColumn {...props} field='pev_dt_data_criacao' />
        ),
      },
      {
        id: 'pev_de_total',
        title: 'Valor do pedido',
      },
      {
        id: 'ped_in_quantidade',
        title: 'Quantidade',
        component: ItemAmount,
      },
      {
        id: 'pev_st_status_description',
        title: 'Status',
      },
      {
        id: 'updatedAt',
        title: 'Última alteração',
        component: (props) => <DateColumn {...props} field='updatedAt' />,
      },
      { id: 'action', title: '', component: (props) => <Actions {...props} /> },
    ],
    []
  );

  const findPedidosByDate = useCallback(
    (ranges) => {
      if (!ranges) return;

      const { selection } = ranges;

      if (selection.startDate && selection.endDate) {
        dispatch(
          fetchOrders(
            sellerId,
            { page: 1, limit: 15 },
            { order: ordering.order, order_by: ordering.order_by },
            {
              ...filters,
              from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
              to: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
            }
          )
        );
      }
    },
    [dispatch, sellerId, filters, ordering.order, ordering.order_by]
  );

  return (
    <Section>
      <CalendarModal
        isOpen={showCalendar}
        onClose={() => setShowCalendar(false)}
        handleChange={findPedidosByDate}
        startDate={format(new Date(filters.from), 'yyyy-MM-dd')}
        endDate={format(new Date(filters.to), 'yyyy-MM-dd')}
      />

      {loading && <Skeleton animation='wave' height={600} />}

      {error && <p>Tivemos um problema ao carregar os dados dos pedidos</p>}

      {!loading && !error && (
        <>
          <Grid style={{ marginTop: '30px' }}>
            <Table
              headerTitle='Pedidos / Detalhes dos pedidos'
              refValue={filters.ref}
              rows={orders}
              columns={cols.filter((c) => c)}
              ordering={ordering}
              onChangeOrder={(order) =>
                dispatch(
                  fetchOrders(
                    sellerId,
                    { page: paging.page, limit: paging.limit },
                    order,
                    filters
                  )
                )
              }
              paging={paging}
              onChangePage={(page) =>
                dispatch(
                  fetchOrders(
                    sellerId,
                    { page, limit: paging.limit },
                    { order: ordering.order, order_by: ordering.order_by },
                    filters
                  )
                )
              }
              onSearch={(ref) =>
                dispatch(
                  fetchOrders(
                    sellerId,
                    { page: 1, limit: paging.limit },
                    { order: ordering.order, order_by: ordering.order_by },
                    { ...filters, ref }
                  )
                )
              }
              searchPlaceholder='Buscar por pedido'
              headerComponent={() => (
                <div style={{ width: '100%' }}>
                  <CalendarSelect
                    handleClick={() => setShowCalendar((current) => !current)}
                    from={format(new Date(filters.from), 'dd-MM')}
                    to={format(new Date(filters.to), 'dd-MM')}
                  />
                </div>
              )}
            />
          </Grid>
        </>
      )}
    </Section>
  );
}
