import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Skeleton } from '@material-ui/lab';

import PromoForm from '../../components/PromoForm';
import { findOne } from '../../services/ProductService';

import { Section, Subtitle, Title as DefaultTitle } from '../../styles';
import { create, findAllByProduct, remove } from '../../services/PromoService';
import PromoList from '../../components/PromoList';

const Title = styled(DefaultTitle)`
  margin-bottom: 10px;
`;

export default function CreateSale() {
  const history = useHistory();

  const [product, setProduct] = useState(null);
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);

  const { pro_in_codigo: productId } = useParams();

  const fetchPromosByProduct = useCallback(async (id) => {
    const data = await findAllByProduct(id);

    setSales(data);
  }, []);

  const removePromo = useCallback(
    async (prodId, saleId) => {
      setLoading(true);

      await remove(prodId, saleId);

      await fetchPromosByProduct(prodId);

      setLoading(false);
    },
    [fetchPromosByProduct]
  );

  useEffect(() => {
    async function fetchProduct(id) {
      const { data } = await findOne(id);

      setProduct(data);
    }

    async function fetchData(id) {
      setLoading(true);

      await Promise.all([fetchProduct(id), fetchPromosByProduct(id)]);

      setLoading(false);
    }

    fetchData(productId);
  }, [productId, fetchPromosByProduct]);

  const createPromo = useCallback(
    async (promo) => {
      setLoading(true);

      try {
        await create(productId, {
          from: promo.from,
          to: promo.to,
          price: promo.price,
          special_price: promo.specialPrice,
          box_price: promo.boxPrice,
          product_take: promo.productTake,
        });

        await fetchPromosByProduct(productId);
      } finally {
        setLoading(false);
      }
    },
    [productId, fetchPromosByProduct]
  );

  const cancel = useCallback(() => {
    history.push('/produtos');
  }, [history]);

  if (loading)
    return (
      <Skeleton animation='wave' style={{ marginTop: '30px' }} height={300} />
    );

  return (
    <Section style={{ marginTop: '130px' }}>
      <Title>Promoções</Title>
      <Subtitle>{`${product?.pro_st_sku} - ${product?.pro_st_titulopage}`}</Subtitle>

      <PromoList sales={sales} onRemove={removePromo} />

      <br />
      <br />

      <PromoForm product={product} onSubmit={createPromo} onCancel={cancel} />
    </Section>
  );
}
