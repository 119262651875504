import styled from 'styled-components';

export const ProductCol = styled.td`
  padding: 7px;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductDescription = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75em;

  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonContent = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
