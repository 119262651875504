import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';

import { update } from '../../services/UserService';

import UserForm from '../../components/UserForm';
import { Error, Main, Title } from './styles';
import { fetchUser, setLoading } from '../../store/userEdit';

export default function UserEdit() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, error, user } = useSelector((state) => state.user.edit);

  useEffect(() => {
    dispatch(fetchUser(id));
  }, [dispatch, id]);

  const handleSaveUser = useCallback(
    async (data) => {
      dispatch(setLoading(true));

      try {
        await update(
          id,
          {
            usu_st_nome: data.name,
            usu_st_email: data.email,
            fil_in_codigo: data.branch,
            slr_in_codigo: data.seller,
          },
          data.roles
        );

        history.push('/usuarios');
      } catch (err) {
        dispatch(setLoading(false));
      }
    },
    [dispatch, id, history]
  );

  const handleCancel = useCallback(() => {
    history.push('/usuarios');
  }, [history]);

  if (loading)
    return (
      <Skeleton animation='wave' height={300} style={{ marginTop: '130px' }} />
    );

  if (error) return <Error>Erro ao carregar usuário</Error>;

  return (
    <Main>
      <Title>Editar usuário</Title>

      <UserForm
        onSubmit={handleSaveUser}
        onCancel={handleCancel}
        user={user}
        edit
      />
    </Main>
  );
}
