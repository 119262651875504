/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
import {
  getBillingData,
  getProductsBillingData,
} from '../../services/BillingService';

export const setLoading = (loading) => ({
  type: '@billing/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@billing/setError',
  payload: error,
});

export const setSales = (sales) => ({
  type: '@billing/setSales',
  payload: sales,
});

export const setTotal = (total) => ({
  type: '@billing/setTotal',
  payload: total,
});

export const setTotalItemsSold = (totalItemsSold) => ({
  type: '@billing/setTotalItemsSold',
  payload: totalItemsSold,
});

export const setTotalBilled = (totalBilled) => ({
  type: '@billing/setTotalBilled',
  payload: totalBilled,
});

export const setTicket = (ticket) => ({
  type: '@billing/setTicket',
  payload: ticket,
});

export const setOrdering = (ordering) => ({
  type: '@billing/setOrdering',
  payload: ordering,
});

export const setAverageTicket = (averageTicket) => ({
  type: '@billing/setAverageTicket',
  payload: averageTicket,
});

export const setBestSellers = (averageTicket) => ({
  type: '@billing/setBestSellers',
  payload: averageTicket,
});

export const setLeastSelling = (averageTicket) => ({
  type: '@billing/setLeastSelling',
  payload: averageTicket,
});

export const setFilial = (filial) => ({
  type: '@billing/setFilial',
  payload: filial,
});

export const setFrom = (from) => ({
  type: '@billing/setFrom',
  payload: from,
});

export const setTo = (to) => ({
  type: '@billing/setTo',
  payload: to,
});

export const clear = () => ({
  type: '@billing/clear',
});

const setPaging = (paging) => ({
  type: '@billing/setPaging',
  payload: paging,
});

export const fetchBillingData =
  (filial, from, to, paging, ordering) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const billingData = await getBillingData(filial, from, to);

      const productsData = await getProductsBillingData(
        filial,
        from,
        to,
        paging,
        ordering
      );

      return dispatch([
        setLoading(false),
        setError(false),
        setFilial(filial),
        setFrom(from),
        setTo(to),
        setSales(billingData.sales),
        setTotal(billingData.total),
        setBestSellers(productsData.best_sellers),
        setLeastSelling(productsData.least_selling),
        setPaging(productsData._metadata.paging),
        setOrdering(productsData._metadata.ordering),
        setTotalItemsSold(productsData.total.totalItemsSold),
        setTotalBilled(productsData.total.totalBilled),
        setAverageTicket(productsData.total.ticket),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };
