import styled from 'styled-components';

export const TagsContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 700;
`;
