const distinctions = {
  á: 'a',
  ã: 'a',
  à: 'a',
  â: 'a',
  é: 'e',
  è: 'e',
  í: 'i',
  ì: 'i',
  ó: 'o',
  ò: 'o',
  õ: 'o',
  ú: 'u',
  ù: 'u',
  ñ: 'n',
  ç: 'c',
};

export function removeAccentuation(char) {
  return distinctions[char] || char;
}

export function normalizeToUrl(string) {
  const urlString = string
    .replaceAll(' ', '-')
    .toLowerCase()
    .replace(/[^\w ]/g, removeAccentuation)
    .replace(/[^a-zA-Z0-9/-]/g, '');

  return urlString;
}
