import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Input } from 'sonoma-design-system';

import { getUser, getUserRoles } from '../../auth/auth';
import { findById as findFilialById } from '../../services/FilialService';
import { findById as findSellerById } from '../../services/SellerService';

function SellerFields({ form, onSetForm, errorOnFill, isEdit }) {
  const formReduceValue = useSelector((state) =>
    isEdit ? state.product.edit.frete.form : state.product.new.frete.form
  );

  useEffect(() => {
    async function handleSearchUserInfos() {
      const user = getUser();
      const roles = getUserRoles();

      const seller =
        user.slr_in_codigo && (await findSellerById(user.slr_in_codigo));
      const filial =
        user.fil_in_codigo && (await findFilialById(user.fil_in_codigo));

      const isFfh = roles.find((e) => e === 'FULFILLS_H');

      if (seller) {
        onSetForm({
          pro_st_vendidopor:
            form?.pro_st_vendidopor || seller?.slr_st_apelido || '',
        });
        onSetForm({
          pro_st_entreguepor:
            form?.pro_st_entreguepor || seller?.slr_st_apelido || '',
        });
      }

      if (filial) {
        if (isFfh) {
          onSetForm({
            pro_st_vendidopor:
              form?.pro_st_vendidopor || filial?.fil_st_apelido || '',
          });
          onSetForm({
            pro_st_entreguepor:
              form?.pro_st_entreguepor || filial?.fil_st_apelido || '',
          });
        } else {
          onSetForm({ pro_st_vendidopor: form?.pro_st_vendidopor || 'Sonoma' });
          onSetForm({
            pro_st_entreguepor: form?.pro_st_entreguepor || 'Sonoma',
          });
        }
      }
    }

    if (!isEdit) {
      handleSearchUserInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, onSetForm]);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 30,
    },
  }));

  const classes = useStyles();

  if (formReduceValue.loading)
    return <Skeleton animation='wave' height={100} />;

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={9} lg={9}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={3}>
            <Input
              error={errorOnFill && !form?.pro_st_vendidopor}
              inputId='pro_st_vendidopor'
              value={form?.pro_st_vendidopor || ''}
              handleChange={(e) =>
                onSetForm({ pro_st_vendidopor: e.target.value })
              }
              size='small'
              label='Vendido por'
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Input
              error={errorOnFill && !form?.pro_st_entreguepor}
              inputId='pro_st_entreguepor'
              value={form?.pro_st_entreguepor || ''}
              handleChange={(e) =>
                onSetForm({ pro_st_entreguepor: e.target.value })
              }
              size='small'
              label='Entregue por'
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SellerFields.propTypes = {
  form: PropTypes.object.isRequired,
  onSetForm: PropTypes.func.isRequired,
  errorOnFill: PropTypes.bool,
  isEdit: PropTypes.bool,
};

SellerFields.defaultProps = {
  errorOnFill: false,
  isEdit: false,
};

export default SellerFields;
