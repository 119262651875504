import { LoadingSpinner } from './LoadingSpinner';
import {
  BoldText,
  Container,
  SmallText,
  AlertSmall,
  AlertContainer,
} from './styles';

export function ProgressIndicator() {
  return (
    <Container>
      <BoldText>Geração de XML em progresso</BoldText>

      <LoadingSpinner />

      <SmallText>
        Em breve os arquivos gerados estarão disponíveis para você fazer
        download!
      </SmallText>

      <AlertContainer>
        <AlertSmall>
          Atenção! Caso você feche essa aba do navegador, o download de arquivos
          não será finalizado (você ainda receberá os arquivos via e-mail).
        </AlertSmall>

        <br />

        <AlertSmall>
          Mas você pode navegar livremente por nossa plataforma durante esse
          processamento.
        </AlertSmall>
      </AlertContainer>
    </Container>
  );
}
