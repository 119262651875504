import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import { getToken } from '../../auth/auth';

function PrivateRoute({ component: Component, ...rest }) {
  const token = getToken();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default PrivateRoute;
