import styled from 'styled-components';
import loginImg from '../../assets/VinhoLogin.png';

export const Main = styled.main`
  margin-bottom: 30px;
`;

export const ImageContent = styled.div`
  background: url(${loginImg}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 50%;
  height: 100vh;

  @media (max-width: 750px) {
    display: none;
  }
`;

export const ContainerLogin = styled.div`
  width: 50%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    width: 100%;
    /* background-color: blue; */
  }
`;

export const SpanPassword = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 5px 0px;
  cursor: pointer;
  font-size: 14px;

  color: #85b074;
`;
