import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'sonoma-design-system';
import { Grid, CircularProgress } from '@material-ui/core';
import { ContainerLogin } from './styles';

import { recoverPassword } from '../../services/auth';

import logo from '../../assets/logo-completo.png';

export default function RecoverPassword() {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleRecoverPassword() {
    setLoading(true);

    if (!email) {
      setError(true);
      setLoading(false);
    }

    try {
      await recoverPassword(email);
      history.push('/');
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <ContainerLogin>
        <div>
          <p
            style={{
              color: 'white',
              height: '10vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt='Logo' />
          </p>
        </div>
        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            xs={9}
            lg={9}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '60vh',
            }}
          >
            <Grid spacing={2} container style={{ marginBottom: '15px' }}>
              <Grid item xs={12} lg={12}>
                <h1> Digite seu email para recuperar a senha. </h1>
              </Grid>
              <Grid item xs={12} lg={12} style={{ marginBottom: '15px' }}>
                <Input
                  error={error && !email}
                  errorText='Digite seu email'
                  inputId='email'
                  value={email}
                  handleChange={(e) => setEmail(e.target.value)}
                  size='small'
                  placeholder='Digite seu email'
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Grid spacing={1} container style={{ marginBottom: '15px' }}>
              <Grid item xs={6} lg={6}>
                <Button fullWidth onClick={() => history.push('/')}>
                  {loading ? <CircularProgress size={25} /> : 'Voltar'}
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button fullWidth onClick={() => handleRecoverPassword()}>
                  {loading ? <CircularProgress size={25} /> : 'Recuperar Senha'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ContainerLogin>
    </div>
  );
}
