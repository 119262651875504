import { toast } from 'react-toastify';

import api from './api';
import { getToken, getUser } from '../auth/auth';

import { jsonToFormData } from '../utils/buildFormData';

const token = getToken();

function getURL(user, sellerId) {
  if (user.fil_in_codigo)
    return `filiais/${sellerId || user.fil_in_codigo}/produtos`;
  if (user.slr_in_codigo)
    return `sellers/${sellerId || user.slr_in_codigo}/produtos`;

  return '';
}

export async function findAll(
  sellerId,
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const user = getUser();

    const response = await api.get(getURL(user, sellerId), {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produtos');
    throw e;
  }
}

function formatRequestBody(data, files) {
  const formData = jsonToFormData(data);

  // append files
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    formData.append('files', file);
  }

  return formData;
}

export async function create(data, files, slr_in_codigo = false) {
  try {
    const user = getUser();
    const formData = formatRequestBody(data, files);

    // in case of being ADMIN/INSIDER editing DBS product,
    // we need to pass slr_in_codigo because we need to check on API to not duplicated
    await api.post(getURL(slr_in_codigo ? { slr_in_codigo } : user), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Produto criado com sucesso!');
  } catch (e) {
    toast.error('Erro ao criar produto');
    throw e;
  }
}
