/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
export function formatCurrency(value) {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function formatNumber(string) {
  const result = parseFloat(string.replaceAll('.', '').replaceAll(',', '.'));

  return Number.isNaN(result) ? null : result;
}

export function reverseFormatNumber(value) {
  return String(parseFloat(value) || '').replaceAll('.', ',');
}

export function formatInteger(string) {
  return parseInt(string.replaceAll('.', '').replaceAll(',', ''), 10);
}

export function generateId() {
  return Math.floor(Math.random() * 100000);
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function formatFileSizeToHumanReadableValue(bytes, dp = 1) {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
}
