import { useState } from 'react';

import whatsappImg from '../../assets/whatsapp.svg';
import ShowChartImg from '../../assets/ShowSupportChat.svg';

import { Button, ShowChat } from './styles';

export const SupportChat = () => (
  <Button
    href='https://api.whatsapp.com/send?phone=5511998285365'
    target='_blank'
    rel='noopener noreferrer nofollow'
    title='WhatsApp'
    aria-label='WhatsApp'
  >
    <img src={whatsappImg} alt='Chat de suporte' />
  </Button>
);

export function ShowSupportChat() {
  const [chat, setChat] = useState(false);

  return (
    <>
      {chat ? (
        <ShowChat
          onClick={() => setChat((oldValue) => !oldValue)}
          style={{ right: '0px' }}
        >
          <img
            src={ShowChartImg}
            style={{ transform: 'rotate(180deg)' }}
            alt='Botão de ativar e desativar Whatsapp'
          />
        </ShowChat>
      ) : (
        <>
          <ShowChat onClick={() => setChat((oldValue) => !oldValue)}>
            <img
              src={ShowChartImg}
              alt='Botão de ativar e desativar Whatsapp'
            />
          </ShowChat>
          <SupportChat />
        </>
      )}
    </>
  );
}
