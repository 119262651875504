import { toast } from 'react-toastify';
import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function findOne(orderId) {
  try {
    const response = await api.get(`orders/${orderId}`, {
      headers: { authorization: `bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar detalhes do pedido');
    throw e;
  }
}
