/* eslint-disable no-unused-vars */
export function isEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function isEqualByProps(obj1, obj2, ...props) {
  let equals = true;

  props.forEach((prop) => {
    if (JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop]))
      equals = false;
  });

  return equals;
}

export function clearBlankKeys(obj) {
  return Object.keys(obj)
    .filter((k) => k)
    .reduce((newObj, key) => ({ ...newObj, [key]: obj[key] }), {});
}

export function filterKeys(obj, keysToFilter) {
  return Object.keys(obj)
    .filter((key) => keysToFilter.includes(key))
    .reduce(
      (newObj, key) => ({
        ...newObj,
        [key]: obj[key],
      }),
      {}
    );
}

export function clearBlankKeysFromNested(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? clearBlankKeysFromNested(v) : v])
  );
}

export function deleteAttributes(arrayAttr, obj) {
  const clonedObj = obj;
  arrayAttr.map((atr) => {
    if (typeof atr === 'string') {
      delete clonedObj[atr];
      return clonedObj;
    }
    delete clonedObj[atr[0]][atr[1]];
    return clonedObj;
  });
}

export function removeKeys(obj, keysToFilter) {
  return Object.keys(obj)
    .filter((key) => !keysToFilter.includes(key))
    .reduce(
      (newObj, key) => ({
        ...newObj,
        [key]: obj[key],
      }),
      {}
    );
}

/**
 * When you have a string attr.attr2.attr3 and need to get this value from obj
 * @param {object} obj
 * @param {string} prop
 */
export function fetchFromObject(obj, prop) {
  if (typeof obj === 'undefined') return false;

  const index = prop.indexOf('.');
  if (index > -1)
    return fetchFromObject(
      obj[prop.substring(0, index)],
      prop.substr(index + 1)
    );

  return obj[prop];
}

export function setInObject(obj, prop, value) {
  if (typeof obj === 'undefined') return false;

  const index = prop.indexOf('.');
  if (index > -1)
    return setInObject(
      obj[prop.substring(0, index)],
      prop.substr(index + 1),
      value
    );

  // eslint-disable-next-line no-param-reassign
  obj[prop] = value;

  return obj;
}

export function getInvalidFields(target, mandatory) {
  return mandatory.filter((field) => !target[field]);
}
