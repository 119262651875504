import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';

import './styles.css';

function DateRangePicker({ onChange, startDate, endDate }) {
  return (
    <ReactDateRangePicker
      ranges={[
        {
          startDate: parseISO(startDate),
          endDate: parseISO(endDate),
          key: 'selection',
        },
      ]}
      onChange={onChange}
      showDateDisplay={false}
      staticRanges={[]}
      inputRanges={[]}
      locale={ptBR}
    />
  );
}

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default DateRangePicker;
