import styled from 'styled-components';

export const Title = styled.h1`
  color: #000000;
  font-size: 2.5rem;
  margin: 30px 0;
  text-transform: uppercase;
`;

export const CenterColumn = styled.td`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875em;
  text-align: center;
  color: #000000;
  padding: 0px 10px;
`;
