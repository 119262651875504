import { findOne } from '../../services/ProductService';

export const setForm = (data) => ({
  type: '@queijoEdit/setForm',
  payload: data,
});

export const setLoading = (loading) => ({
  type: '@queijoEdit/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@queijoEdit/setError',
  payload: error,
});

export const setErrorOnFill = (error) => ({
  type: '@queijoEdit/setErrorOnFill',
  payload: error,
});

export const clearAll = () => ({
  type: '@queijoEdit/clearAll',
});

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { data } = await findOne(productId);

    const tipos = data.tipos.map((item) => item.tipo);
    const harmonizacoes = data.produtoHarmonizacao.map(
      (item) => item.harmonizacao
    );
    const selos = data.selos.map((item) => item.selo);

    const { queijo, ...productData } = data;

    return dispatch([
      setForm({
        ...queijo,
        ...productData,
        tipos,
        harmonizacoes,
        selos,
      }),
      setLoading(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
