import { toast } from 'react-toastify';
import { getToken } from '../auth/auth';
import api from './api';

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters = { ref: '' }
) {
  try {
    const token = getToken();

    const response = await api.get('orders', {
      headers: { authorization: `bearer ${token}` },
      params: { ...paging, ...ordering, ...filters },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar pedidos');
    throw e;
  }
}

export async function findAllBySeller(
  sellerId,
  paging = { page: 1, limit: 15 },
  ordering,
  filters = { ref: '' }
) {
  try {
    const token = getToken();

    const response = await api.get(`sellers/${sellerId}/orders`, {
      headers: { authorization: `bearer ${token}` },
      params: { ...paging, ...ordering, ...filters },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar pedidos');
    throw e;
  }
}
