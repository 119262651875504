import { createSelector } from 'reselect';
import {
  countries,
  harmonizations,
  regions,
  stamps,
  types,
} from './specifications';

const aggregator = (state) => state.product.new.queijo;

const isLoading = (state) =>
  aggregator(state).harmonizacoes.loading ||
  aggregator(state).selos.loading ||
  aggregator(state).tipos.loading ||
  aggregator(state).regioes.loading ||
  aggregator(state).paises.loading;

const isErrored = (state) =>
  aggregator(state).harmonizacoes.error ||
  aggregator(state).selos.error ||
  aggregator(state).tipos.error ||
  aggregator(state).regioes.error ||
  aggregator(state).paises.error;

export default () =>
  createSelector(
    harmonizations(aggregator),
    stamps(aggregator),
    types(aggregator),
    regions(aggregator),
    countries(aggregator),
    isLoading,
    isErrored,
    (harmonizacoes, selos, tipos, regioes, paises, loading, error) => ({
      harmonizacoes,
      selos,
      tipos,
      regioes,
      paises,
      loading,
      error,
    })
  );
