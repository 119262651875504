import styled from 'styled-components';

export const CenterColumn = styled.td`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875em;
  text-align: center;
  color: #000000;
`;

export const Error = styled.p`
  color: tomato;
`;

export const Section = styled.section`
  margin-bottom: 30px;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductDescription = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75em;

  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const ProductLink = styled.a`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75em;
  font-weight: 700;
  color: #99ce86;
`;

export const ProductCol = styled.td`
  padding: 7px;
`;

export const ButtonDescription = styled.p`
  font-weight: 600;
  font-size: 16px;
`;

export const Title = styled.h1`
  color: #000;
  margin-top: 10px;
  margin-bottom: 60px;
  text-transform: uppercase;
`;

export const Subtitle = styled.h3`
  color: #000;
  margin-bottom: 40px;
`;
