import styled from 'styled-components';

export const CenterColumn = styled.td`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875em;
  text-align: center;
  color: #000000;
`;

export const Title = styled.h1`
  color: #3b3b3a;
  font-size: 2.5rem;
`;

export const Section = styled.section`
  margin-bottom: 30px;
  background-color: #ffffff;
  margin-top: 130px;
`;
