import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { Input, Button, Checkbox } from 'sonoma-design-system';

import Divider from '../Divider';
import FieldsetTitle from '../FieldsetTitle';
import { DatePickerComponent } from '../DatePicker';

import { ButtonDescription, Footer } from './styles';
import { TrackingEvents } from '../TrackingEvents';

export function TrackingStatusForm({ onSubmit, onCancel }) {
  const [invoiceNumber, setInvoiceNumber] = useState('');

  const [isDelivered, setIsDelivered] = useState(false);
  const [deliveredDate, setDeliveredDate] = useState(new Date());
  const [events, setEvents] = useState([
    {
      city: '',
      state: '',
      description: '',
      date: new Date(),
    },
  ]);

  function handleSubmit(e) {
    e.preventDefault();

    onSubmit({
      invoiceNumber,
      isDelivered,
      deliveredDate,
      events,
    });
  }

  const newEvent = useCallback(() => {
    setEvents([
      ...events,
      {
        city: '',
        state: '',
        description: '',
        date: new Date(),
      },
    ]);
  }, [events]);

  const removeEvent = useCallback(
    (index) => {
      setEvents(events.filter((_, i) => i !== index));
    },
    [events]
  );

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <FieldsetTitle>Atualizações no rastreio</FieldsetTitle>

        <Grid spacing={2} container className={classes.root}>
          <Grid item xs={12} lg={12}>
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <Input
                  inputId='invoiceNumber'
                  size='small'
                  label='Número da nota'
                  fullWidth
                  value={invoiceNumber}
                  handleChange={(e) => setInvoiceNumber(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Checkbox
                  checkboxId='isDelivered'
                  value={isDelivered}
                  handleChange={(e) => setIsDelivered(e.target.checked)}
                  label='Entregue'
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <p>Data de entrega*</p>
              </Grid>

              <Grid item xs={12} lg={6}>
                <DatePickerComponent
                  value={deliveredDate}
                  setValue={setDeliveredDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <FieldsetTitle>Eventos</FieldsetTitle>

        <TrackingEvents
          events={events}
          setEvents={setEvents}
          onAddNewEvent={newEvent}
          onRemoveEvent={removeEvent}
        />

        <Divider style={{ margin: '10px 0px 30px 0px' }} />

        <Footer>
          <Button
            outlined
            isStartIcon
            onClick={onCancel}
            backgroundColor='#8A8A8A'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>

          <Button type='submit' backgroundColor='#9CD186'>
            <ButtonDescription>Enviar faturamento</ButtonDescription>
          </Button>
        </Footer>
      </form>
    </section>
  );
}

TrackingStatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
