import { Types } from './informacoesForm.action';

const INITIAL_STATE = {
  perfis_impostos: {
    loading: false,
    error: false,
    data: [],
  },
  origens_produto: {
    loading: false,
    error: false,
    data: [],
  },
  codigos_fiscais: {
    loading: false,
    error: false,
    data: [],
  },
  categories_vitrine: {
    loading: false,
    error: false,
    data: [],
  },
  classificacoes_produto: {
    loading: false,
    error: false,
    data: [],
  },
  categorias: {
    loading: false,
    error: false,
    data: [],
  },
  departamentos: {
    loading: false,
    error: false,
    data: [],
  },
  politicas_comerciais: {
    loading: false,
    error: false,
    data: [],
  },
  integrated_products: {
    loading: false,
    error: false,
    data: [],
    paging: {
      page: 1,
      limit: 15,
      total: 0,
    },
    ordering: {
      order_by: null,
      order: 'asc',
      availableOrderby: [],
    },
    filters: {
      ref: null,
    },
  },
  errorOnFill: false,
  form: {
    pro_bo_ativo: true,
    pro_bo_exibirnosite: true,
    pro_bo_mostraresgotado: true,
    politicas_comerciais: [{ poc_in_codigo: 1, poc_st_descricao: 'Principal' }],
    produtos_suplentes: [],
    categorias_similares: [],
  },
};

const setErrorOnFill = (state, action) => ({
  ...state,
  errorOnFill: action.payload,
});

const setTaxProfiles = (state, action) => ({
  ...state,
  perfis_impostos: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingTaxProfiles = (state) => ({
  ...state,
  perfis_impostos: {
    ...state.perfis_impostos,
    loading: true,
  },
});

const setTaxProfilesError = (state) => ({
  ...state,
  perfis_impostos: {
    ...state.perfis_impostos,
    error: true,
  },
});

const setProductOrigins = (state, action) => ({
  ...state,
  origens_produto: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingOrigins = (state) => ({
  ...state,
  origens_produto: {
    ...state.origens_produto,
    loading: true,
  },
});

const setOriginsError = (state) => ({
  ...state,
  origens_produto: {
    ...state.origens_produto,
    error: true,
  },
});

const setTaxClassifications = (state, action) => ({
  ...state,
  codigos_fiscais: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingTaxClassifications = (state) => ({
  ...state,
  codigos_fiscais: {
    ...state.codigos_fiscais,
    loading: true,
  },
});

const setTaxClassificationsError = (state) => ({
  ...state,
  codigos_fiscais: {
    ...state.codigos_fiscais,
    error: true,
  },
});

const setShowcaseCategories = (state, action) => ({
  ...state,
  categories_vitrine: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingShowcaseCategories = (state) => ({
  ...state,
  categories_vitrine: {
    ...state.categories_vitrine,
    loading: true,
  },
});

const setShowcaseCategoriesError = (state) => ({
  ...state,
  categories_vitrine: {
    ...state.categories_vitrine,
    error: true,
  },
});

const setProductClassifications = (state, action) => ({
  ...state,
  classificacoes_produto: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingProductClassifications = (state) => ({
  ...state,
  classificacoes_produto: {
    ...state.classificacoes_produto,
    loading: true,
  },
});

const setProductClassificationsError = (state) => ({
  ...state,
  classificacoes_produto: {
    ...state.classificacoes_produto,
    error: true,
  },
});

const setCategories = (state, action) => ({
  ...state,
  categorias: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingCategories = (state) => ({
  ...state,
  categorias: {
    ...state.categorias,
    loading: true,
  },
});

const setCategoriesError = (state) => ({
  ...state,
  categorias: {
    ...state.categorias,
    error: true,
  },
});

const setDepartments = (state, action) => ({
  ...state,
  departamentos: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingDepartments = (state) => ({
  ...state,
  departamentos: {
    ...state.departamentos,
    loading: true,
  },
});

const setDepartmentsError = (state) => ({
  ...state,
  departamentos: {
    ...state.departamentos,
    error: true,
  },
});

const setTradePolicies = (state, action) => ({
  ...state,
  politicas_comerciais: {
    loading: false,
    error: false,
    data: action.payload,
  },
});

const setLoadingTradePolicies = (state) => ({
  ...state,
  politicas_comerciais: {
    ...state.politicas_comerciais,
    loading: true,
  },
});

const setTradePoliciesError = (state) => ({
  ...state,
  politicas_comerciais: {
    ...state.politicas_comerciais,
    error: true,
  },
});

const setLoadingIntegratedProducts = (state) => ({
  ...state,
  integrated_products: {
    ...state.integrated_products,
    loading: true,
  },
});

const setIntegratedProducts = (state, action) => ({
  ...state,
  integrated_products: {
    loading: false,
    error: false,
    data: action.payload.data,
    paging: {
      page: action.payload._metadata.paging.page,
      limit: action.payload._metadata.paging.limit,
      total: action.payload._metadata.paging.total,
    },
    ordering: {
      order_by: action.payload._metadata.ordering.order_by,
      order: action.payload._metadata.ordering.order,
      availableOrderby:
        action.payload._metadata.ordering.availableOrderby || [],
    },
    filters: {
      ref: action.payload._metadata.filters.ref,
    },
  },
});

const setIntegratedProductsError = (state) => ({
  ...state,
  integrated_products: {
    ...state.integrated_products,
    error: true,
  },
});

const setForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    ...action.payload,
  },
});

const clearAll = () => INITIAL_STATE;

export default function informacoesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_FORM:
      return setForm(state, action);
    case Types.ON_FETCH_TAX_PROFILES:
      return setTaxProfiles(state, action);
    case Types.LOADING_TAX_PROFILES:
      return setLoadingTaxProfiles(state, action);
    case Types.TAX_PROFILES_ERROR:
      return setTaxProfilesError(state, action);
    case Types.ON_FETCH_ORIGINS:
      return setProductOrigins(state, action);
    case Types.LOADING_ORIGINS:
      return setLoadingOrigins(state, action);
    case Types.ORIGINS_ERROR:
      return setOriginsError(state, action);
    case Types.ON_FETCH_TAX_CLASSIFICATIONS:
      return setTaxClassifications(state, action);
    case Types.LOADING_TAX_CLASSIFICATIONS:
      return setLoadingTaxClassifications(state, action);
    case Types.TAX_CLASSIFICATIONS_ERROR:
      return setTaxClassificationsError(state, action);
    case Types.ON_FETCH_SHOWCASE_CATEGORIES:
      return setShowcaseCategories(state, action);
    case Types.LOADING_SHOWCASE_CATEGORIES:
      return setLoadingShowcaseCategories(state, action);
    case Types.SHOWCASE_CATEGORIES_ERROR:
      return setShowcaseCategoriesError(state, action);
    case Types.ON_FETCH_PRODUCT_CLASSIFICATIONS:
      return setProductClassifications(state, action);
    case Types.LOADING_PRODUCT_CLASSIFICATIONS:
      return setLoadingProductClassifications(state, action);
    case Types.PRODUCT_CLASSIFICATIONS_ERROR:
      return setProductClassificationsError(state, action);
    case Types.ON_FETCH_CATEGORIES:
      return setCategories(state, action);
    case Types.LOADING_CATEGORIES:
      return setLoadingCategories(state, action);
    case Types.CATEGORIES_ERROR:
      return setCategoriesError(state, action);
    case Types.ON_FETCH_DEPARTMENTS:
      return setDepartments(state, action);
    case Types.LOADING_DEPARTMENTS:
      return setLoadingDepartments(state, action);
    case Types.DEPARTMENTS_ERROR:
      return setDepartmentsError(state, action);
    case Types.ON_FETCH_TRADE_POLICIES:
      return setTradePolicies(state, action);
    case Types.LOADING_TRADE_POLICIES:
      return setLoadingTradePolicies(state, action);
    case Types.TRADE_POLICIES_ERROR:
      return setTradePoliciesError(state, action);
    case Types.LOADING_INTEGRATED_PRODUCTS:
      return setLoadingIntegratedProducts(state, action);
    case Types.ON_FETCH_INTEGRATED_PRODUCTS:
      return setIntegratedProducts(state, action);
    case Types.INTEGRATED_PRODUCTS_ERROR:
      return setIntegratedProductsError(state, action);
    case Types.FORM_ERROR:
      return setErrorOnFill(state, action);
    case Types.CLEAR:
      return clearAll(state, action);
    default:
      return state;
  }
}
