import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Skeleton } from '@material-ui/lab';

import PromoListComponent from '../../components/PromoList';
import { UploadFilesModal } from '../../components/UploadFilesModal';

import { fetchAll, remove } from '../../store/sales';
import { uploadSalesFile } from '../../services/PromoService';

import { Section, Title as DefaultTitle, Error } from '../../styles';
import spreadsheetModel from '../../assets/modelo_precos_promocionais.csv';

const Title = styled(DefaultTitle)`
  margin-bottom: 10px;
`;

function Instructions() {
  return (
    <div style={{ marginBottom: 30 }}>
      <p style={{ color: '#B00D1F' }}>
        OBS: Valores númericos devem ter suas casas decimais separadas por ponto
        (&quot;.&ldquo;)
      </p>
      <p style={{ color: '#B00D1F' }}>
        OBS 2: A coluna <b>PRECO_DE</b> é de preenchimento obrigatório
      </p>
    </div>
  );
}

export default function PromoList() {
  const {
    data: sales,
    paging,
    ordering,
    filters,
    loading,
    error,
  } = useSelector((state) => state.sales);

  const [isModalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchAll({ page: 1, limit: 15 }, { order_by: 'createdAt', order: 'desc' })
    );
  }, [dispatch]);

  const onOpenModal = () => {
    setModalOpen(!isModalOpen);
  };

  const removePromo = useCallback(
    async (productId, saleId) => {
      dispatch(remove(productId, saleId));
    },
    [dispatch]
  );

  if (loading)
    return (
      <Skeleton animation='wave' style={{ marginTop: '30px' }} height={300} />
    );

  if (error)
    return (
      <Section style={{ marginTop: '130px' }}>
        <Error>Tivemos um problema ao buscar as promoções</Error>
      </Section>
    );

  const KBYTE = 1000; // bytes
  const MEGABYTE = 1000 * KBYTE;

  const MAX_SIZE_IN_BYTES = 10 * MEGABYTE;

  return (
    <Section style={{ marginTop: '130px' }}>
      <Title>Promoções</Title>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 15,
        }}
      >
        <UploadFilesModal
          open={isModalOpen}
          setOpen={onOpenModal}
          maxSizeInBytes={MAX_SIZE_IN_BYTES}
          allowedFileExtensions={['.csv']}
          onSubmit={([file]) => uploadSalesFile(file)}
          label='Enviar preços'
          modelFile={spreadsheetModel}
          modelFileName='modelo_precos_promocionais.csv'
          additionalInstructions={Instructions}
        />
      </div>

      <PromoListComponent
        sales={sales}
        paging={paging}
        onChangePage={(page) =>
          dispatch(fetchAll({ page, limit: paging.limit }, ordering, filters))
        }
        ordering={ordering}
        onChangeOrder={(order) => dispatch(fetchAll(paging, order, filters))}
        onRemove={removePromo}
      />
    </Section>
  );
}
