import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Avatar, Modal } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Button } from 'sonoma-design-system';

import Divider from '../../components/Divider';
import { InvoiceForm } from '../../components/InvoiceForm';
import { PartialInvoiceForm } from '../../components/PartialInvoiceForm';
import { TrackingStatusForm } from '../../components/TrackingStatusForm';

import { findOne } from '../../services/OrderService';
import {
  invoice,
  updateTrackingData,
  updateTrackingStatus,
} from '../../services/HandleOrderStatusService';

import defaultWineImg from '../../assets/default-wine.png';
import { contains } from '../../utils/arrays';

import {
  Footer,
  Section,
  ProductCard,
  ProductCol,
  ProductDescription,
  CenterColumn,
  ModalBody,
} from './styles';

import './styles.css';

export default function OrderDetail() {
  const [isOpen, setIsOpen] = useState(false);
  const [isInvoiced, setIsInvoiced] = useState(false);

  const history = useHistory();

  const { orderId } = useParams();

  const [loading, setLoading] = useState(true);
  const [pedido, setPedido] = useState({
    cliente: {},
    entrega: {},
    pedido: {},
    produtos: [],
    pev_st_status: '',
    pev_st_status_description: '',
    pev_st_tipo_pagamento: '',
  });

  const isInvoice = useMemo(
    () => contains(['invoice', 'handling'], pedido?.pev_st_status),
    [pedido]
  );

  const isPartialInvoice = useMemo(
    () =>
      pedido?.pev_st_status === 'invoiced' &&
      !pedido?.faturamento[0]?.pvf_st_numero_rastreio,
    [pedido]
  );

  const isTrackingUpdate = useMemo(
    () =>
      pedido?.pev_st_status === 'invoiced' &&
      pedido?.faturamento[0]?.pvf_st_numero_rastreio,
    [pedido]
  );

  useEffect(() => {
    async function handlePedidoData() {
      const data = await findOne(orderId);

      setPedido(data);
      setLoading(false);
    }

    if (orderId) handlePedidoData();
  }, [orderId]);

  const onClose = useCallback(() => setIsOpen(false), []);

  const formatItems = useCallback(
    (items) =>
      items.map((p) => ({
        id: p.pro_in_codigo,
        price: p.pvp_de_valor,
        quantity: p.pvp_in_quantidade,
      })),
    []
  );

  const onSubmitInvoice = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const items = formatItems(pedido.produtos);

        await invoice(orderId, data, items);

        onClose();
        setIsInvoiced(true);
      } finally {
        setLoading(false);
      }
    },
    [orderId, pedido.produtos, formatItems, onClose]
  );

  const onSubmitPartialInvoice = useCallback(
    async ({ invoiceNumber, ...data }) => {
      try {
        setLoading(true);

        await updateTrackingData(orderId, invoiceNumber, data);

        onClose();
        setIsInvoiced(true);
      } finally {
        setLoading(false);
      }
    },
    [orderId, onClose]
  );

  const onSubmitTrackingStatusUpdate = useCallback(
    async ({ invoiceNumber, ...data }) => {
      try {
        setLoading(true);

        await updateTrackingStatus(orderId, invoiceNumber, data);

        onClose();
        setIsInvoiced(true);
      } finally {
        setLoading(false);
      }
    },
    [orderId, onClose]
  );

  function HandleDescricaoAndImage(descricao, imagem) {
    return (
      <ProductCol>
        <ProductCard>
          <Avatar
            variant='square'
            style={{
              marginRight: '10px',
              borderRadius: '5px',
              border: '1px solid #E0E0E0',
              height: '48px',
              width: '48px',
            }}
            src={imagem || defaultWineImg}
            alt={descricao}
          />

          <div>
            <ProductDescription title={descricao}>
              {descricao}
            </ProductDescription>
          </div>
        </ProductCard>
      </ProductCol>
    );
  }

  function DateColumn(date) {
    if (!date) return '-';
    return (
      <CenterColumn>{format(new Date(date), 'dd/MM/yyyy HH:mm')}</CenterColumn>
    );
  }

  if (loading) return <Skeleton animation='wave' height={600} />;

  return (
    <Section style={{ marginTop: '130px' }}>
      <div className='container_order_detail'>
        <div>
          <Button
            outlined
            onClick={() => history.push('/seller/orders')}
            backgroundColor='gray'
          >
            <ArrowBackIcon fontSize='20px' /> &nbsp; Voltar
          </Button>
        </div>
        <div>
          <p className='container_order_detail_title'>N° do pedido</p>
          <p
            className='container_order_detail-header-description'
            title={pedido?.pedido.pev_st_vtex_id || '-'}
          >
            {pedido?.pedido.pev_st_vtex_id || '-'}
          </p>
        </div>
        <div>
          <p className='container_order_detail_title'>Data pedido</p>
          <p
            className='container_order_detail-header-description'
            title={pedido?.pedido.pev_dt_data_criacao || '-'}
          >
            {DateColumn(pedido?.pedido.pev_dt_data_criacao || null)}
          </p>
        </div>
        <div>
          <p className='container_order_detail_title'>Pagamento</p>
          <p
            className='container_order_detail-header-description'
            title={pedido?.pev_st_tipo_pagamento || '-'}
          >
            {pedido?.pev_st_tipo_pagamento || '-'}
          </p>
        </div>
        <div>
          <p className='container_order_detail_title'>Status do pedido</p>
          <p
            className='container_order_detail-header-description'
            title={pedido?.pev_st_status_description || '-'}
          >
            {pedido?.pev_st_status_description || '-'}
          </p>
        </div>
      </div>
      {/* <Divider style={{ margin: '10px 0px 30px 0px' }} />
      <div>
        <h1>Barra com os status dos pagamentos</h1>
      </div> */}
      <Divider style={{ margin: '10px 0px 30px 0px' }} />
      <div>
        <p className='container_order_detail_title'>Dados do cliente</p>
        <p className='container_order_detail_dados-container'>
          <p className='container_order_detail_dados_container-cliente-subtitle'>
            Nome:
          </p>
          <p className='container_order_detail_dados-cliente-description'>
            &nbsp;{pedido?.cliente?.cli_st_nome || '-'}
          </p>
        </p>
        <p className='container_order_detail_dados-container'>
          <p className='container_order_detail_dados_container-cliente-subtitle'>
            CPF:
          </p>
          <p className='container_order_detail_dados-cliente-description'>
            &nbsp;{pedido?.cliente?.cli_st_cpf || '-'}
          </p>
        </p>
        <p className='container_order_detail_dados-container'>
          <p className='container_order_detail_dados_container-cliente-subtitle'>
            Endereço:
          </p>
          <p className='container_order_detail_dados-cliente-description'>
            &nbsp;{pedido?.entrega?.end_st_logradouro}{' '}
            {pedido?.entrega?.end_in_numero &&
              `N° ${pedido?.entrega?.end_in_numero}`}
          </p>
        </p>
        <p className='container_order_detail_dados-container'>
          <p className='container_order_detail_dados_container-cliente-subtitle'>
            Complemento:
          </p>
          <p className='container_order_detail_dados-cliente-description'>
            &nbsp;{pedido?.entrega?.end_st_complemento || '-'}
          </p>
        </p>
        <p className='container_order_detail_dados-container'>
          <p className='container_order_detail_dados_container-cliente-subtitle'>
            Bairro:
          </p>
          <p className='container_order_detail_dados-cliente-description'>
            &nbsp;{pedido?.entrega?.end_st_bairro || '-'}
          </p>
        </p>
        <p className='container_order_detail_dados-container'>
          <p className='container_order_detail_dados_container-cliente-subtitle'>
            CEP:
          </p>
          <p className='container_order_detail_dados-cliente-description'>
            &nbsp;{pedido?.entrega?.end_st_cep || '-'}
          </p>
        </p>
      </div>
      <Divider style={{ margin: '10px 0px 30px 0px' }} />
      <div>
        <p className='container_order_detail_title'>Lista de produtos</p>
        <br />
        <table style={{ width: '100%' }}>
          <tr>
            <th style={{ textAlign: 'left' }}>Nome do produto</th>
            <th>Quantidade</th>
            <th>Preço unitário</th>
            <th>Forma de entrega</th>
          </tr>
          {pedido?.produtos.map((e) => (
            <tr>
              <td>
                {HandleDescricaoAndImage(
                  e?.pro_st_titulopage,
                  e.image_firebase_url
                )}
              </td>
              <td style={{ textAlign: 'center' }}>
                {e.pvp_in_quantidade || '-'}
              </td>
              <td style={{ textAlign: 'center' }}>{e.pvp_de_valor || '-'}</td>
              <td style={{ textAlign: 'center' }}>
                {e.pvp_st_tipo_entrega || '-'}
              </td>
            </tr>
          ))}
        </table>
        <Divider style={{ margin: '10px 0px 30px 0px' }} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p className='container_order_detail_title'>Valor do frete</p>
        <h2>R$ {pedido?.pedido?.pev_de_total_frete || '---'}</h2>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p className='container_order_detail_title'>Descontos</p>
        <h2>R$ {pedido?.pedido?.pev_de_total_descontos || '---'}</h2>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p className='container_order_detail_title'>Valor total dos produtos</p>
        <h2>R$ {pedido?.pedido?.pev_de_total || '---'}</h2>
      </div>
      <Divider style={{ margin: '10px 0px 30px 0px' }} />
      <Footer>
        <Button
          outlined
          onClick={() => history.push('/seller/orders')}
          backgroundColor='gray'
        >
          <ArrowBackIcon fontSize='20px' /> &nbsp; Voltar
        </Button>

        {(isInvoice || isPartialInvoice || isTrackingUpdate) && !isInvoiced && (
          <Button onClick={() => setIsOpen(true)}>Faturar</Button>
        )}
      </Footer>

      <Modal open={isOpen} onClose={onClose}>
        <ModalBody>
          {isTrackingUpdate && (
            <TrackingStatusForm
              onSubmit={onSubmitTrackingStatusUpdate}
              onCancel={onClose}
            />
          )}

          {isPartialInvoice && (
            <PartialInvoiceForm
              onSubmit={onSubmitPartialInvoice}
              onCancel={onClose}
            />
          )}

          {isInvoice && (
            <InvoiceForm onSubmit={onSubmitInvoice} onCancel={onClose} />
          )}
        </ModalBody>
      </Modal>
    </Section>
  );
}
