import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Avatar } from '@material-ui/core';

import {
  ProductCard,
  ProductCol,
  ProductDescription,
  ProductLink,
} from '../../../styles';

import { removeAccentuation } from '../../../utils/strings';

import defaultWineImg from '../../../assets/default-wine.png';

export default function ProductColumn({
  item,
  handleRedirectToEditProduct,
  readonly,
}) {
  const url = useMemo(() => {
    let finalURL = item?.pro_st_url || '';

    if (finalURL.indexOf('/p') < 0) finalURL = `${finalURL}/p`;
    if (finalURL[0] === '/') finalURL = finalURL.replace('/', '');

    return finalURL.replace(/[^\w ]/g, removeAccentuation);
  }, [item?.pro_st_url]);

  const description = item.pro_st_titulopage || item.pro_st_descricao;

  return (
    <ProductCol>
      <ProductCard>
        <Avatar
          variant='square'
          style={{
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #E0E0E0',
            height: '48px',
            width: '48px',
            cursor: 'pointer',
          }}
          src={item.image_firebase_url || defaultWineImg}
          alt={description}
          onClick={!readonly && (() => handleRedirectToEditProduct(item))}
        />

        <div>
          <ProductDescription
            title={description}
            onClick={!readonly && (() => handleRedirectToEditProduct(item))}
          >
            {description}
          </ProductDescription>
          {item.pro_bo_magentostatus ? (
            <ProductLink
              href={`https://www.sonoma.com.br/${url}`}
              target='_blank'
              rel='noreferrer'
            >
              Ver no site
            </ProductLink>
          ) : null}
        </div>
      </ProductCard>
    </ProductCol>
  );
}

ProductColumn.propTypes = {
  item: PropTypes.object.isRequired,
  handleRedirectToEditProduct: PropTypes.func,
  readonly: PropTypes.bool,
};

ProductColumn.defaultProps = {
  handleRedirectToEditProduct: null,
  readonly: false,
};
