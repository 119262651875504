import { findAll as findAllProductsFromSeller } from '../../services/SellerProductService';

export const Types = {
  SET_LOADING: '@branches/inventory/loading/set',
  SET_ERROR: '@branches/inventory/error/set',

  SET_PRODUCTS: '@branches/inventory/products',

  SET_PAGING: '@branches/inventory/paging/set',
  SET_ORDERING: '@branches/inventory/ordering/set',
  SET_FILTERS: '@branches/inventory/filters/set',

  CLEAR: '@branches/inventory/clear',
};

export const setLoading = (loading) => ({
  type: Types.SET_LOADING,
  payload: loading,
});

export const setError = (error) => ({
  type: Types.SET_ERROR,
  payload: error,
});

export const setProducts = (products) => ({
  type: Types.SET_PRODUCTS,
  payload: products,
});

const setPaging = (paging) => ({
  type: Types.SET_PAGING,
  payload: paging,
});

const setOrdering = (ordering) => ({
  type: Types.SET_ORDERING,
  payload: ordering,
});

const setFilters = (filters) => ({
  type: Types.SET_FILTERS,
  payload: filters,
});

export const clear = () => ({
  type: Types.CLEAR,
});

export const fetchInventory =
  (filial, paging, ordering, filters) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await findAllProductsFromSeller(
        filial,
        paging,
        ordering,
        filters
      );

      return dispatch([
        setLoading(false),
        setError(false),

        setProducts(response.data),

        setPaging(response._metadata.paging),
        setOrdering(response._metadata.ordering),
        setFilters(response._metadata.filters),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };
