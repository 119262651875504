import { IconButton, Divider as MuiDivider } from '@material-ui/core';
import styled from 'styled-components';

export const EditIcon = styled(IconButton)`
  color: 'black';
  background-color: 'white';
  &:hover {
    background-color: #85b074;
    color: white;
    transition: 0.3s;
  }
`;

export const Title = styled.h1`
  color: #000000;
  font-size: 2.5rem;
  margin: 30px 0;
  text-transform: uppercase;
`;

export const ButtonContent = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ProductImage = styled.img`
  margin: 0 15px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
`;

export const SelectItem = styled.div`
  width: 200px;
  margin-right: 10px;
`;

export const Divider = styled(MuiDivider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SmallInfo = styled.small`
  color: tomato;
  font-weight: bold;

  display: block;
  width: 100%;
  text-align: right;
`;
