import { useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { BreadcrumbStepper, useStepper } from 'sonoma-design-system';

import VinhoForm from '../../components/VinhoForm';
import InformacoesForm from '../../components/InformacoesForm';

import { fetchProduct } from '../../store/vinhoEdit';
import { fetchProduct as fetchInfoEdit } from '../../store/informacoesEdit';
import { clearAll as clearWine } from '../../store/vinhoNew';
import { clearAll as clearInfo } from '../../store/informacoesForm';

import store from '../../store';

import { create as createProduct } from '../../services/PublisherProductService';

import {
  clearBlankKeys,
  filterKeys,
  removeKeys,
  clearBlankKeysFromNested,
} from '../../utils/objects';

import { Main, Title } from './styles';
import { getUser } from '../../auth/auth';
import { clearDB } from '../../config/db';

export default function PublisherEditVinho() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selected, next, previous } = useStepper();
  const { pro_in_codigo } = useParams();
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    dispatch([fetchProduct(pro_in_codigo), fetchInfoEdit(pro_in_codigo)]);

    // on leave the page
    return () => {
      dispatch([clearWine(), clearInfo()]);
      clearDB();
    };
  }, [dispatch, pro_in_codigo]);

  const titles = useMemo(
    () => ['VINHOS', 'INFORMAÇÕES', 'FRETE E VENDA', 'IMAGENS E VÍDEOS'],
    []
  );

  const scoreKeys = useMemo(
    () => [
      'pon_in_ws',
      'pon_in_tp',
      'pon_in_wes',
      'pon_in_bh',
      'pon_in_we',
      'pon_in_gp',
      'pon_in_rp',
      'pon_in_st',
      'pon_in_gd',
      'pon_in_js',
      'pon_in_jr',
      'pon_in_ag',
      'pon_in_ds',
      'pon_in_d',
      'pon_in_ta',
      'pon_in_pm',
      'pon_in_uw',
      'pon_in_ak',
      'pon_in_lm',
      'pon_in_ad',
      'pon_in_jr2',
      'pon_in_jd',
      'pon_in_s',
      'pon_in_v',
      'pon_in_mf',
    ],
    []
  );

  useEffect(
    () => () => {
      dispatch([clearWine(), clearInfo()]);
    },
    [dispatch]
  );

  const getPontuacao = useCallback(
    (data) => clearBlankKeys(filterKeys(data, scoreKeys)),
    [scoreKeys]
  );

  const handleCreateProduct = useCallback(async () => {
    try {
      const { product } = store.getState();
      const productWithouBlank = clearBlankKeysFromNested(product.edit);

      setBtnLoading(true);

      const user = getUser();

      const {
        vinho: { form: wineData },
        informacoes: {
          form: {
            pro_st_descricao,
            pro_st_titulopage,
            pro_st_url,
            pro_st_tags,
            dep_in_codigo,
            pro_st_palavraschave,
            pro_lt_sobre,
            pro_lt_historia,
            pro_lt_metatagdesc,
          },
        },
      } = productWithouBlank;

      const pontuacao = getPontuacao(wineData);

      const wine = removeKeys(wineData, scoreKeys);

      const {
        harmonizacoes,
        tipos,
        uvas,
        selos,

        pai_in_codigo,
        reg_in_codigo,
        pro_st_conteudo,
        pro_st_sku,
        vin_in_quantidadecaixa,

        ...data
      } = wine;

      const dataToCreate = {
        pai_in_codigo,
        reg_in_codigo,
        product_type: 'vinho',
        updatedBy: user.usu_in_codigo,
        ...clearBlankKeysFromNested({
          pro_in_codigo,
          pro_st_conteudo,
          pro_st_titulopage,
          pro_st_nomesite: pro_st_titulopage,
          pro_st_descricao,
          pro_lt_metatagdesc,
          pro_st_url,
          pro_st_tags,
          dep_in_codigo,
          pro_st_palavraschave,
          pro_lt_sobre,
          pro_lt_historia,

          tipos,
          harmonizacoes,
          selos,
          vinho: {
            ...data,
            uvas,
            pontuacao,
          },
        }),
      };

      await createProduct(dataToCreate);

      setBtnLoading(false);
      history.push('/produtos');
    } catch (err) {
      setBtnLoading(false);
      // handle error
    }
  }, [getPontuacao, history, pro_in_codigo, scoreKeys]);

  return (
    <Main>
      <Title>Produtos e SKUs | {titles[selected]}</Title>

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Especificações',
            component: () => <VinhoForm onNext={next} publisher isEdit />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm
                onNext={next}
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
                publisher
                isEdit
                isLoading={btnLoading}
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
