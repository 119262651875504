import { findOne } from '../../services/ProductService';

export const setForm = (data) => ({
  type: '@gourmetEdit/setForm',
  payload: data,
});

export const setLoading = (loading) => ({
  type: '@gourmetEdit/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@gourmetEdit/setError',
  payload: error,
});

export const setErrorOnFill = (error) => ({
  type: '@gourmetEdit/setErrorOnFill',
  payload: error,
});

export const clearAll = () => ({
  type: '@gourmetEdit/clearAll',
});

export const fetchProduct = (productId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const pontuacoes = [];

    const { data: productData } = await findOne(productId);

    const tipos = productData.tipos.map((item) => item.tipo);
    const harmonizacoes = productData.produtoHarmonizacao.map(
      (item) => item.harmonizacao
    );
    const selos = productData.selos.map((item) => item.selo);

    if (productData.pontuacao.length > 0) {
      const pontuacaoEdited = productData.pontuacao.reduce(
        (acc, curr) => acc + curr
      );
      delete pontuacaoEdited.pon_in_codigo;
      delete pontuacaoEdited.pro_in_codigo;

      pontuacoes.push(pontuacaoEdited);
    }

    return dispatch([
      setForm({
        pro_st_sku: productData.pro_st_sku,
        pai_in_codigo: productData.pai_in_codigo,
        reg_in_codigo: productData.reg_in_codigo,
        pro_st_conteudo: productData.pro_st_conteudo,
        cur_in_codigo: productData.gourmet?.cur_in_codigo,
        gou_lt_ingredientes: productData.gourmet?.gou_lt_ingredientes,
        gou_lt_informacoesnutri: productData.gourmet?.gou_lt_informacoesnutri,
        gou_lt_alergicos: productData.gourmet?.gou_lt_alergicos,
        gou_lt_armazenamento: productData.gourmet?.gou_lt_armazenamento,
        tipos,
        harmonizacoes,
        selos,
        ...pontuacoes[0],
      }),
      setLoading(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
