import { toast } from 'react-toastify';
import api from './api';

export async function findAll(
  filial,
  from,
  to,
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  if (!filial) return null;
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    const isFetchingAll = filial === 'TODAS FILIAIS';

    const response = await api.get(
      isFetchingAll ? 'pedidos' : `filiais/${filial}/pedidos`,
      {
        headers: { authorization: `bearer ${token}` },
        params: { ...paging, ...ordering, ...filters, from, to },
      }
    );

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar pedidos');
    throw e;
  }
}
