import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { format, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { Table } from 'sonoma-design-system';

import { Grid, Modal } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FaCalendarCheck } from 'react-icons/fa';

import { getUser, getUserRoles } from '../../auth/auth';
import { fetchPedidos } from '../../store/pedidos';

import DateRangePicker from '../../components/DateRangePicker';
import { handleStatusMln } from '../../utils/MlnStatus';

import { CenterColumn, Section, Small } from './styles';

export default function Pedidos() {
  const dispatch = useDispatch();

  const [filial, setFilial] = useState(false);
  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const [canSeeAllPedidos, setCanSeeAllPedidos] = useState(false);

  const pedidos = useSelector((state) => state.pedidos.pedidos);
  const loading = useSelector((state) => state.pedidos.loading);
  const error = useSelector((state) => state.pedidos.error);

  const paging = useSelector((state) => state.pedidos.paging);
  const ordering = useSelector((state) => state.pedidos.ordering);
  const filters = useSelector((state) => state.pedidos.filters);

  const [showCalendar, setShowCalendar] = useState(false);

  const formatDate = useCallback((date) => format(date, 'yyyy-MM-dd'), []);

  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );

  useEffect(() => {
    const user = getUser();
    const userRoles = getUserRoles();

    setFilial(user.fil_in_codigo);

    if (userRoles.find((e) => e === 'ADMIN' || e === 'INSIDER')) {
      setCanSeeAllPedidos(true);
    }

    if (
      userRoles.find((e) => e === 'ADMIN' || e === 'INSIDER') ||
      selectedFilial
    ) {
      dispatch(
        fetchPedidos(
          selectedFilial ? selectedFilial?.fil_in_codigo : 'TODAS FILIAIS',
          startDate,
          endDate
        )
      );
    } else {
      dispatch(fetchPedidos(user.fil_in_codigo, startDate, endDate));
    }
  }, [dispatch, endDate, selectedFilial, startDate]);

  function DateColumn({ item, field }) {
    return (
      <CenterColumn>
        {format(new Date(item[field]), 'dd/MM/yyyy HH:mm')}
      </CenterColumn>
    );
  }

  DateColumn.propTypes = {
    item: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
  };

  function handleStatus({ item }) {
    const statusDescription = handleStatusMln(item.ped_st_status_description);

    return <CenterColumn>{statusDescription}</CenterColumn>;
  }

  handleStatus.propTypes = {
    item: PropTypes.object.isRequired,
  };

  const cols = useMemo(
    () => [
      { id: 'ped_st_numero', title: 'Pedido' },
      {
        id: 'cliente.cli_st_nome',
        title: 'Nome do cliente',
      },
      {
        id: 'ped_dt_dataemissao',
        title: 'Data de emissão',
        component: (props) => (
          <DateColumn {...props} field='ped_dt_dataemissao' />
        ),
      },
      {
        id: 'total_pedidos',
        title: 'Valor do pedido',
      },
      {
        id: 'ped_in_quantidade',
        title: 'Quantidade',
      },
      {
        id: 'ped_st_status_description',
        title: 'Status',
        component: (props) => handleStatus(props),
      },
      canSeeAllPedidos && {
        id: 'filial.fil_st_fantasia',
        title: 'Seller',
      },
      {
        id: 'updatedAt',
        title: 'Última alteração',
        component: (props) => <DateColumn {...props} field='updatedAt' />,
      },
    ],
    [canSeeAllPedidos]
  );

  const findPedidosByDate = useCallback(
    (ranges) => {
      const pagingData = { page: 1, limit: 15 };

      if (!ranges) {
        dispatch(
          fetchPedidos(
            canSeeAllPedidos
              ? selectedFilial?.fil_in_codigo || 'TODAS FILIAIS'
              : filial,
            formatDate(startOfMonth(new Date())),
            formatDate(endOfMonth(new Date())),
            pagingData,
            { order: ordering.order, order_by: ordering.order_by },
            filters
          )
        );
        return;
      }

      const { selection } = ranges;

      if (selection.startDate && selection.endDate) {
        setStartDate(formatDate(selection.startDate));
        setEndDate(formatDate(selection.endDate));

        dispatch(
          fetchPedidos(
            canSeeAllPedidos && selectedFilial
              ? selectedFilial?.fil_in_codigo
              : filial,
            formatDate(selection.startDate),
            formatDate(selection.endDate),
            pagingData,
            { order: ordering.order, order_by: ordering.order_by },
            filters
          )
        );
      }
    },
    [
      canSeeAllPedidos,
      dispatch,
      filial,
      filters,
      formatDate,
      ordering.order,
      ordering.order_by,
      selectedFilial,
    ]
  );

  return (
    <Section
      style={{
        backgroundColor: '#fff',
        marginTop: '130px',
      }}
    >
      <Small>
        *O período selecionado se refere a data de emissão do pedido!
      </Small>

      <Modal
        open={showCalendar}
        onClose={() => setShowCalendar(false)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={findPedidosByDate}
          />
        </div>
      </Modal>

      {loading && (
        <>
          <Skeleton animation='wave' height={600} />
        </>
      )}

      {error && <p>Tivemos um problema ao carregar os dados dos pedidos</p>}

      {!loading && !error && (
        <>
          <Grid style={{ marginTop: '30px' }}>
            <Table
              headerTitle='Pedidos / Detalhes dos pedidos'
              refValue={filters.ref}
              rows={pedidos}
              columns={cols.filter((c) => c)}
              ordering={ordering}
              onChangeOrder={(order) =>
                dispatch(
                  fetchPedidos(
                    canSeeAllPedidos
                      ? selectedFilial?.fil_in_codigo || 'TODAS FILIAIS'
                      : filial,
                    format(parseISO(startDate), 'yyyy-MM-dd'),
                    format(parseISO(endDate), 'yyyy-MM-dd'),
                    { page: paging.page, limit: paging.limit },
                    order,
                    filters
                  )
                )
              }
              paging={paging}
              onChangePage={(page) =>
                dispatch(
                  fetchPedidos(
                    canSeeAllPedidos
                      ? selectedFilial?.fil_in_codigo || 'TODAS FILIAIS'
                      : filial,
                    format(parseISO(startDate), 'yyyy-MM-dd'),
                    format(parseISO(endDate), 'yyyy-MM-dd'),
                    { page, limit: paging.limit },
                    { order: ordering.order, order_by: ordering.order_by },
                    filters
                  )
                )
              }
              onSearch={(ref) =>
                dispatch(
                  fetchPedidos(
                    canSeeAllPedidos
                      ? selectedFilial?.fil_in_codigo || 'TODAS FILIAIS'
                      : filial,
                    format(parseISO(startDate), 'yyyy-MM-dd'),
                    format(parseISO(endDate), 'yyyy-MM-dd'),
                    { page: 1, limit: paging.limit },
                    { order_by: 'createdAt', order: 'asc' },
                    { ...filters, ref }
                  )
                )
              }
              searchPlaceholder='Buscar por nota ou cliente'
              headerComponent={() => (
                <div
                  style={{
                    margin: '0 0 0 15px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '40%' }}>
                    <div
                      role='button'
                      tabIndex={0}
                      aria-hidden='true'
                      onClick={() => setShowCalendar((current) => !current)}
                      style={{
                        width: '80%',
                        height: '100%',
                        alignItems: 'center',
                        paddingBottom: '5px',
                        borderBottom: '1px solid #8A8A8A',
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <FaCalendarCheck size='25px' />
                      &nbsp;
                      <button
                        type='button'
                        className='custom-input'
                        style={{ all: 'unset' }}
                      >
                        de {format(parseISO(startDate), 'dd-MM')} até{' '}
                        {format(parseISO(endDate), 'dd-MM')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            />
          </Grid>
        </>
      )}
    </Section>
  );
}
