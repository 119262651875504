import io from 'socket.io-client';

const BASE_URL = process.env.REACT_APP_API_URL;

export const Socket = {
  _io: null,

  getInstance() {
    return new Promise((resolve) => {
      if (!this._io) {
        this._io = io(BASE_URL);

        this._io.on('connect', () => resolve(this._io));
        return;
      }

      resolve(this._io);
    });
  },
};
