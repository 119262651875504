import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from '@material-ui/core';

import { useDispatch } from 'react-redux';

import { BreadcrumbStepper, useStepper } from 'sonoma-design-system';

import { getUser, getUserRoles } from '../../auth/auth';

import VinhoForm from '../../components/VinhoForm';
import InformacoesForm from '../../components/InformacoesForm';
import FreteForm from '../../components/FreteForm';
import ImagensForm from '../../components/ImagensForm';

import { clearAll as clearWine } from '../../store/vinhoNew';
import { clearAll as clearInfo } from '../../store/informacoesForm';
import { clearAll as clearFreight } from '../../store/freteForm';
import { clearAll as clearImagens } from '../../store/imagensForm';
import store from '../../store';

import { create as createProduct } from '../../services/ProductService';
import { create as sellerCreateProduct } from '../../services/SellerProductService';

import {
  clearBlankKeys,
  filterKeys,
  removeKeys,
  clearBlankKeysFromNested,
} from '../../utils/objects';

import { Main, Title } from './styles';
import { clearDB } from '../../config/db';

export default function CreateVinho() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { selected, next, previous } = useStepper();

  const [isLoading, setLoading] = useState(false);

  const isDeliveryBySeller =
    getUserRoles().includes('DELIVERY_BY_SELLER') || getUser().slr_in_codigo;

  const titles = useMemo(
    () => [
      'Produtos e SKUs / Vinhos',
      'Produtos / Cadastro de Vinho / Informações ',
      isDeliveryBySeller
        ? 'Produtos / Cadastro de Vinho / Frete e Venda'
        : 'Produtos / Cadastro de Vinho / Venda',
      'Produtos / Cadastro de Vinho / Imagens e Vídeo',
    ],
    [isDeliveryBySeller]
  );

  const scoreKeys = useMemo(
    () => [
      'pon_in_ws',
      'pon_in_tp',
      'pon_in_wes',
      'pon_in_bh',
      'pon_in_we',
      'pon_in_gp',
      'pon_in_rp',
      'pon_in_st',
      'pon_in_gd',
      'pon_in_js',
      'pon_in_jr',
      'pon_in_ag',
      'pon_in_ds',
      'pon_in_d',
      'pon_in_ta',
      'pon_in_pm',
      'pon_in_uw',
      'pon_in_ak',
      'pon_in_lm',
      'pon_in_ad',
      'pon_in_jr2',
      'pon_in_jd',
      'pon_in_s',
      'pon_in_v',
      'pon_in_gg',
      'pon_in_bh2',
      'pon_in_iwr',
      'pon_in_ce',
      'pon_in_mf',
    ],
    []
  );

  useEffect(
    () => () => {
      dispatch([clearWine(), clearInfo(), clearFreight(), clearImagens()]);
      clearDB();
    },
    [dispatch]
  );

  const getPontuacao = useCallback(
    (data) => clearBlankKeys(filterKeys(data, scoreKeys)),
    [scoreKeys]
  );

  const handleCreateProduct = useCallback(
    async (images) => {
      setLoading(true);

      const roles = getUserRoles();
      const user = getUser();
      const isSeller = roles.find((e) => e === 'SELLER');

      try {
        const { product } = store.getState();
        const productWithouBlank = clearBlankKeysFromNested(product.new);

        const {
          vinho: { form: wineData },
          frete: { form: freightData },
          informacoes: { form: infoData },
          imagens: { form: imagens },
        } = productWithouBlank;

        const {
          produtos_suplentes,
          safra,
          pro_lt_sobre,
          pro_lt_historia,
          ...dataInfo
        } = infoData;

        const similares = Object.keys(produtos_suplentes).map((key) => ({
          pro_in_codigo: produtos_suplentes[key].child_pro_in_codigo,
          pro_st_sku: produtos_suplentes[key].children.pro_st_sku,
          pro_st_descricao: produtos_suplentes[key].children.pro_st_descricao,
        }));

        const pontuacao = getPontuacao(wineData);

        const wine = removeKeys(wineData, scoreKeys);

        // main image path
        const main = images.find((img) => img.main)?.path;

        const {
          harmonizacoes,
          tipos,
          uvas,
          selos,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create, // País name in case of create new one
          reg_in_codigo,
          reg_name_to_create, // Regiao name in case of create new one
          pro_st_conteudo,

          ...data
        } = wine;

        const dataToCreate = {
          ...freightData,
          ...dataInfo,

          pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
          pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),

          product_type: 'vinho',

          similares,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create,
          reg_in_codigo,
          reg_name_to_create,
          pro_st_conteudo,
          pro_st_descricao: infoData.pro_st_descricao,
          pro_st_nomesite: infoData.pro_st_titulopage,
          pro_st_palavraschave: infoData.pro_st_tags,
          pro_st_video: imagens?.pro_st_video,

          updatedBy: user.usu_in_codigo,

          tipos,
          harmonizacoes,
          selos,
          vinho: {
            ...data,
            // split and join to save safra without dot (.)
            vin_in_safra: safra?.split('.').join(''),
            uvas,
            pontuacao,
          },

          main,
        };

        const dataToCreateWithouBlank = clearBlankKeysFromNested(dataToCreate);

        if (isSeller) {
          await sellerCreateProduct(dataToCreateWithouBlank, images);
        } else {
          await createProduct(dataToCreateWithouBlank, images);
        }

        history.push('/produtos');
      } catch (err) {
        // handle error
        setLoading(false);
      }
    },
    [getPontuacao, history, scoreKeys]
  );

  return (
    <Main style={{ marginTop: '130px' }}>
      <Title>{titles[selected]}</Title>
      <Divider style={{ backgroundColor: '#E0E0E0' }} />

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Especificações',
            component: () => <VinhoForm onNext={next} />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm wine onNext={next} onPrevious={previous} />
            ),
          },
          {
            title: isDeliveryBySeller ? 'Frete e Venda' : 'Venda',
            component: () => <FreteForm onNext={next} onPrevious={previous} />,
          },
          {
            title: 'Imagens e Vídeos',
            component: () => (
              <ImagensForm
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
                loading={isLoading}
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
