import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { CenterColumn } from '../styles';

export function DateColumn({ item, field }) {
  return (
    <CenterColumn>
      {format(new Date(item[field]), 'dd/MM/yyyy HH:mm')}
    </CenterColumn>
  );
}

DateColumn.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};
