const INITIAL_STATE = {
  curadores: [],
  loading: false,
  error: false,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order_by: null,
    order: 'asc',
    availableOrderby: [],
  },
  filters: {
    ref: '',
  },
};

const setCuradores = (state, action) => ({
  ...state,
  curadores: action.payload,
});

const setPaging = (state, action) => ({
  ...state,
  paging: action.payload,
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: action.payload,
});

const setFilters = (state, action) => ({
  ...state,
  filters: action.payload,
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

export default function userListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@curador/setCuradores':
      return setCuradores(state, action);
    case '@curador/setPaging':
      return setPaging(state, action);
    case '@curador/setOrdering':
      return setOrdering(state, action);
    case '@curador/setFilters':
      return setFilters(state, action);
    case '@curador/setLoading':
      return setLoading(state, action);
    case '@curador/setError':
      return setError(state, action);
    default:
      return state;
  }
}
