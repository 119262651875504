import {
  findAll as findSellers,
  findById as findSeller,
} from '../../services/SellerService';

export const setLoading = (loading) => ({
  type: '@sellers/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@sellers/setError',
  payload: error,
});

export const setPaging = (paging) => ({
  type: '@sellers/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@sellers/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@sellers/setFilters',
  payload: filters,
});

export const clearAll = () => ({
  type: '@sellers/clearAll',
});

export const setSellers = (sellers) => ({
  type: '@sellers/setSellers',
  payload: sellers,
});

export const setSeller = (seller) => ({
  type: '@sellers/setSeller',
  payload: seller,
});

export const setForm = (data) => ({
  type: '@sellers/setFormSeller',
  payload: data,
});

export const fetchAllSellers =
  (paging, ordering, filters) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await findSellers(paging, ordering, filters);

      return dispatch([
        setLoading(false),
        setSellers(response.data),
        setPaging(response._metadata.paging),
        setOrdering(response._metadata.ordering),
        setFilters(response._metadata.filters),
        setError(false),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };

export const fetchSellerById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findSeller(id);

    return dispatch([setLoading(false), setSeller(response), setError(false)]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
