import styled from 'styled-components';

export const Title = styled.h2`
  color: #000;
  text-transform: uppercase;
  padding-top: 2rem;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
