import PropTypes from 'prop-types';
import { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Input, Button } from 'sonoma-design-system';

import Divider from '../Divider';
import FieldsetTitle from '../FieldsetTitle';
import { DatePickerComponent } from '../DatePicker';
import { ButtonDescription, Footer } from './styles';

export function PartialInvoiceForm({ onSubmit, onCancel }) {
  const [invoiceNumber, setInvoiceNumber] = useState('');

  const [courier, setCourier] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');
  const [dispatchedDate, setDispatchedDate] = useState(new Date());

  function handleSubmit(e) {
    e.preventDefault();

    onSubmit({
      invoiceNumber,
      courier,
      trackingNumber,
      trackingUrl,
      dispatchedDate,
    });
  }

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <FieldsetTitle>Rastreio</FieldsetTitle>

        <Grid spacing={2} container className={classes.root}>
          <Grid item xs={12} lg={12}>
            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <Input
                  inputId='invoiceNumber'
                  size='small'
                  label='Número da nota'
                  fullWidth
                  value={invoiceNumber}
                  handleChange={(e) => setInvoiceNumber(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Input
                  inputId='courier'
                  size='small'
                  label='Transportadora'
                  fullWidth
                  value={courier}
                  handleChange={(e) => setCourier(e.target.value)}
                  required
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <Input
                  inputId='trackingNumber'
                  size='small'
                  label='Número de rastreio'
                  fullWidth
                  value={trackingNumber}
                  handleChange={(e) => setTrackingNumber(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Input
                  inputId='trackingUrl'
                  size='small'
                  label='URL do rastreio'
                  fullWidth
                  value={trackingUrl}
                  handleChange={(e) => setTrackingUrl(e.target.value)}
                  required
                />
              </Grid>
            </Grid>

            <Grid spacing={2} container className={classes.row}>
              <Grid item xs={12} lg={6}>
                <p>Data de envio*</p>
              </Grid>

              <Grid item xs={12} lg={6}>
                <DatePickerComponent
                  value={dispatchedDate}
                  setValue={setDispatchedDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ margin: '10px 0px 30px 0px' }} />

        <Footer>
          <Button
            outlined
            isStartIcon
            onClick={onCancel}
            backgroundColor='#8A8A8A'
          >
            <ButtonDescription>Cancelar</ButtonDescription>
          </Button>

          <Button type='submit' backgroundColor='#9CD186'>
            <ButtonDescription>Enviar faturamento</ButtonDescription>
          </Button>
        </Footer>
      </form>
    </section>
  );
}

PartialInvoiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
