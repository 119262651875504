import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';

const token = getToken();

export async function fetchProductTaxData(sku) {
  try {
    const response = await api.get(`produtos/${sku}/tributos`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Informações tributárias não encontradas');
    throw e;
  }
}
