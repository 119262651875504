const INITIAL_STATE = {
  loading: false,
  error: false,
  sales: 0,
  total: 0,
  totalItemsSold: 0,
  totalBilled: 0,
  averageTicket: 0,
  bestSellers: [],
  leastSelling: [],
  filial: null,
  from: null,
  to: null,
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order: 'desc',
    order_by: 'estoque',
    availableOrderBy: ['estoque', 'totalMes', 'averageTicket', 'valorTotalMes'],
  },
};

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const setSales = (state, action) => ({ ...state, sales: action.payload });

const setTotal = (state, action) => ({ ...state, total: action.payload });

const setTotalItemsSold = (state, action) => ({
  ...state,
  totalItemsSold: action.payload,
});

const setTotalBilled = (state, action) => ({
  ...state,
  totalBilled: action.payload,
});

const setAverageTicket = (state, action) => ({
  ...state,
  averageTicket: action.payload,
});

const setBestSellers = (state, action) => ({
  ...state,
  bestSellers: action.payload,
});

const setLeastSelling = (state, action) => ({
  ...state,
  leastSelling: action.payload,
});

const setFilial = (state, action) => ({ ...state, filial: action.payload });

const setFrom = (state, action) => ({ ...state, from: action.payload });

const setTo = (state, action) => ({ ...state, to: action.payload });

const setPaging = (state, action) => ({
  ...state,
  paging: {
    ...state.paging,
    ...action.payload,
  },
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: {
    ...state.ordering,
    ...action.payload,
  },
});

export default function billingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@billing/setLoading':
      return setLoading(state, action);
    case '@billing/setError':
      return setError(state, action);
    case '@billing/setSales':
      return setSales(state, action);
    case '@billing/setTotal':
      return setTotal(state, action);
    case '@billing/setTotalItemsSold':
      return setTotalItemsSold(state, action);
    case '@billing/setTotalBilled':
      return setTotalBilled(state, action);
    case '@billing/setAverageTicket':
      return setAverageTicket(state, action);
    case '@billing/setBestSellers':
      return setBestSellers(state, action);
    case '@billing/setLeastSelling':
      return setLeastSelling(state, action);
    case '@billing/setFilial':
      return setFilial(state, action);
    case '@billing/setFrom':
      return setFrom(state, action);
    case '@billing/setTo':
      return setTo(state, action);
    case '@billing/setPaging':
      return setPaging(state, action);
    case '@billing/setOrdering':
      return setOrdering(state, action);
    case '@billing/clear':
      return INITIAL_STATE;
    default:
      return state;
  }
}
