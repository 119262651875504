import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'sonoma-design-system';
import { Grid, CircularProgress } from '@material-ui/core';
import { Container } from './styles';

import { create } from '../../services/newSeller';

import logo from '../../assets/logo-completo.png';

export default function NewSeller() {
  const history = useHistory();

  const [nes_st_descricao, setNes_st_descricao] = useState('');
  const [nes_st_website, setNes_st_website] = useState('');
  const [nes_st_nome, setNes_st_nome] = useState('');
  const [nes_st_cargo, setNes_st_cargo] = useState('');
  const [nes_st_sobrenome, setNes_st_sobrenome] = useState('');
  const [nes_st_email, setNes_st_email] = useState('');
  const [nes_st_telefone, setNes_st_telefone] = useState('');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleCreate() {
    setLoading(true);
    let newErrors = {};

    if (!nes_st_nome || nes_st_nome.length === 0) {
      newErrors = { ...newErrors, nes_st_nome: 'Preencha o nome' };
    }
    if (!nes_st_descricao || nes_st_descricao.length === 0) {
      newErrors = { ...newErrors, nes_st_descricao: 'Preencha a descrição' };
    }
    if (!nes_st_website || nes_st_website.length === 0) {
      newErrors = { ...newErrors, nes_st_website: 'Preencha o website' };
    }
    if (!nes_st_cargo || nes_st_cargo.length === 0) {
      newErrors = { ...newErrors, nes_st_cargo: 'Preencha o cargo' };
    }
    if (!nes_st_sobrenome || nes_st_sobrenome.length === 0) {
      newErrors = { ...newErrors, nes_st_sobrenome: 'Preencha o sobrenome' };
    }
    if (!nes_st_telefone || nes_st_telefone.length === 0) {
      newErrors = { ...newErrors, nes_st_telefone: 'Preencha o telefone' };
    }
    if (!nes_st_email || nes_st_email.length === 0) {
      newErrors = { ...newErrors, nes_st_email: 'Preencha o email' };
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      await create({
        nes_st_nome,
        nes_st_descricao,
        nes_st_website,
        nes_st_cargo,
        nes_st_sobrenome,
        nes_st_telefone,
        nes_st_email,
      });

      history.push('/');
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <Container>
        <div>
          <p
            style={{
              color: 'white',
              height: '10vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1.5rem',
            }}
          >
            <img src={logo} alt='Logo' />
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '2.5rem',
          }}
        >
          <Grid
            item
            xs={9}
            lg={9}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '80vh',
            }}
          >
            <Grid spacing={2} container style={{ marginBottom: '15px' }}>
              <Grid item xs={12} lg={12}>
                <h1 style={{ textAlign: 'center' }}>
                  SUA EMPRESA ATUA NO RAMO DE VINHOS, DESTILADOS, PRODUTOS
                  GOURMET E ALIMENTOS DE ALTA QUALIDADE?
                </h1>
                <h4 style={{ textAlign: 'center' }}>
                  Não importa se importadora, produtora, distribuidora ou até
                  revendedora, vire um seller da plataforma Sonoma Market
                </h4>
              </Grid>
              <Grid item xs={12} lg={12} style={{ marginBottom: '15px' }}>
                <Input
                  error={errors && !nes_st_descricao}
                  errorText={errors?.nes_st_descricao}
                  sText='Descreva os produtos que você produz, importa ou distribui'
                  inputId='descricao'
                  value={nes_st_descricao}
                  handleChange={(e) => setNes_st_descricao(e.target.value)}
                  size='small'
                  label='Descreva os produtos que você produz, importa ou distribui *'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={6} md={6} style={{ marginBottom: '15px' }}>
                <Input
                  inputId='website'
                  error={errors && !nes_st_website}
                  errorText={errors?.nes_st_website}
                  value={nes_st_website}
                  handleChange={(e) => setNes_st_website(e.target.value)}
                  size='small'
                  label='Website*'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={6} style={{ marginBottom: '15px' }}>
                <Input
                  inputId='telefone'
                  error={errors && !nes_st_telefone}
                  errorText={errors?.nes_st_telefone}
                  value={nes_st_telefone}
                  handleChange={(e) => setNes_st_telefone(e.target.value)}
                  size='small'
                  label='Telefone/Celular*'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={6} style={{ marginBottom: '15px' }}>
                <Input
                  inputId='nome'
                  error={errors && !nes_st_nome}
                  errorText={errors?.nes_st_nome}
                  value={nes_st_nome}
                  handleChange={(e) => setNes_st_nome(e.target.value)}
                  size='small'
                  label='Nome*'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={6} style={{ marginBottom: '15px' }}>
                <Input
                  inputId='cargo'
                  error={errors && !nes_st_cargo}
                  errorText={errors?.nes_st_cargo}
                  value={nes_st_cargo}
                  handleChange={(e) => setNes_st_cargo(e.target.value)}
                  size='small'
                  label='Cargo*'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={6} style={{ marginBottom: '15px' }}>
                <Input
                  inputId='sobrenome'
                  error={errors && !nes_st_sobrenome}
                  errorText={errors?.nes_st_sobrenome}
                  value={nes_st_sobrenome}
                  handleChange={(e) => setNes_st_sobrenome(e.target.value)}
                  size='small'
                  label='Sobrenome*'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} lg={6} style={{ marginBottom: '15px' }}>
                <Input
                  inputId='email'
                  error={errors && !nes_st_email}
                  errorText={errors?.nes_st_email}
                  value={nes_st_email}
                  handleChange={(e) => setNes_st_email(e.target.value)}
                  size='small'
                  label='Email*'
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Grid spacing={1} container style={{ marginBottom: '15px' }}>
              <Grid item xs={6} lg={6}>
                <Button
                  fullWidth
                  outlined
                  backgroundColor='#b00d1f'
                  onClick={() => history.push('/')}
                >
                  {loading ? <CircularProgress size={25} /> : 'Voltar'}
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button fullWidth onClick={() => handleCreate()}>
                  {loading ? <CircularProgress size={25} /> : 'Cadastrar !'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
