import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { MdAddCircle } from 'react-icons/md';
import { Input, TextEditor, Select, Tag, Button } from 'sonoma-design-system';
import { normalizeToUrl } from '../../utils/strings';
import { TagsContainer, Label } from './styles';

function SEOFields({
  form,

  departamentos,
  categorias_vitrine,
  categorias,

  onAddTag,
  onFormChange,
  onSetForm,
  errorOnFill,

  publisher,
  isKit,

  urlAlreadyExist,
}) {
  const [tagTxt, setTagTxt] = useState('');

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const handleAddTag = useCallback(() => {
    // avoid empty
    if (tagTxt.length === 0) return;

    const oldTags = form.pro_st_tags;

    // avoid duplicated
    if (oldTags?.split(',').find((t) => t === tagTxt)) return;

    const newTags = `${oldTags ? `${oldTags},` : ''}${tagTxt}`;

    onSetForm({ pro_st_tags: newTags });
    setTagTxt('');
  }, [onSetForm, tagTxt, form.pro_st_tags]);

  const handleDeleteTag = useCallback(
    (tag) => {
      const tags = form.pro_st_tags.split(',').filter((t) => t !== tag);

      onSetForm({
        pro_st_tags: tags.length > 0 ? tags.join(',') : null,
      });
    },
    [form.pro_st_tags, onSetForm]
  );

  const handleGenerateUrl = useCallback(() => {
    const descricao = form?.pro_st_descricao;
    const safra = form?.safra;

    const url = `${descricao && normalizeToUrl(descricao.trim())}${
      safra ? `-${safra}` : ''
    }`;

    onSetForm({ pro_st_url: url });
  }, [form, onSetForm]);

  return (
    <Grid
      spacing={2}
      container
      className={classes.root}
      style={{ marginTop: '30px' }}
    >
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={6}>
            <Grid spacing={2} container>
              <Grid item lg={8}>
                <Input
                  inputId='tags'
                  value={tagTxt}
                  handleChange={(e) => setTagTxt(e.target.value)}
                  size='small'
                  label='Tags (Palavras substitutivas)'
                  helperText='Para adicionar múltiplas tags, separe-as por vírgula. Ex: rosso, montalcino, ultra premium'
                  fullWidth
                />
                <TagsContainer>
                  {form?.pro_st_tags?.split(',').map((tag) => (
                    <div style={{ marginTop: '5px' }}>
                      <Tag onDelete={() => handleDeleteTag(tag)}>{tag}</Tag>
                    </div>
                  ))}
                </TagsContainer>
              </Grid>

              <Grid item lg={4}>
                <Button
                  onClick={handleAddTag}
                  outlined
                  fullWidth
                  size='medium'
                  icon={<MdAddCircle />}
                >
                  Novas Tags
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid spacing={2} container>
              <Grid item lg={8}>
                <Input
                  inputId='pro_st_url'
                  value={form?.pro_st_url || ''}
                  handleChange={onFormChange}
                  error={errorOnFill && urlAlreadyExist}
                  errorText='URL já existente, por favor digite outra.'
                  size='small'
                  label='URL Amigável'
                  helperText='Não adicionar ano da safra'
                  fullWidth
                />
              </Grid>

              <Grid item lg={4}>
                <Button
                  onClick={handleGenerateUrl}
                  outlined
                  fullWidth
                  size='medium'
                  disabled={form?.pro_st_url}
                >
                  Gerar URL
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {isKit && (
            <Grid item xs={12} lg={6}>
              <Grid spacing={2} container>
                <Grid item lg={6}>
                  <Select
                    selectId='categorias_similares'
                    options={[
                      { value: null, label: 'Selecione' },
                      ...categorias_vitrine,
                    ]}
                    handleChange={(e) =>
                      onAddTag(
                        e.target.name,
                        {
                          vic_in_codigo: e.target.value,
                          vic_st_descricao: categorias_vitrine.find(
                            (t) => t.value === Number(e.target.value)
                          ).label,
                        },
                        'vic_in_codigo'
                      )
                    }
                    size='small'
                    label='Categorias Adicionais'
                  />
                  <TagsContainer>
                    {form?.categorias_similares?.map((tag) => (
                      <Tag
                        key={tag.vic_in_codigo}
                        onDelete={() =>
                          onSetForm({
                            categorias_similares:
                              form.categorias_similares.filter(
                                (t) => t.vic_in_codigo !== tag.vic_in_codigo
                              ),
                          })
                        }
                      >
                        {tag.vic_st_descricao}
                      </Tag>
                    ))}
                  </TagsContainer>
                </Grid>
                <Grid item lg={6}>
                  <Input
                    inputId='pro_st_codseller'
                    value={form?.pro_st_codseller || ''}
                    handleChange={onFormChange}
                    size='small'
                    label='SKU Seller'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!isKit && !publisher && (
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={4}>
              <Select
                selectId='dep_in_codigo'
                error={errorOnFill && !form?.dep_in_codigo}
                options={[
                  { value: null, label: 'Selecione' },
                  ...departamentos,
                ]}
                value={form?.dep_in_codigo || ''}
                handleChange={onFormChange}
                size='small'
                label='Categoria Principal'
                required
              />
            </Grid>

            {form?.dep_in_codigo && (
              <Grid item xs={12} lg={4}>
                <Select
                  error={errorOnFill && !form?.cat_in_codigo}
                  selectId='cat_in_codigo'
                  options={[{ value: null, label: 'Selecione' }, ...categorias]}
                  value={form?.cat_in_codigo || ''}
                  handleChange={onFormChange}
                  size='small'
                  label='Categoria'
                  required
                />
              </Grid>
            )}

            {form?.cat_in_codigo && (
              <Grid item xs={12} lg={4}>
                <Select
                  error={errorOnFill && !form?.vic_in_codigo}
                  selectId='vic_in_codigo'
                  options={[
                    { value: null, label: 'Selecione' },
                    ...categorias_vitrine,
                  ]}
                  value={form?.vic_in_codigo || ''}
                  handleChange={onFormChange}
                  size='small'
                  label='Categoria Destino'
                  required
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={4}>
            <Input
              inputId='pro_st_ean'
              value={form?.pro_st_ean || ''}
              handleChange={onFormChange}
              size='small'
              label='Código EAN'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Input
              inputId='pro_lt_metatagdesc'
              value={form?.pro_lt_metatagdesc || ''}
              handleChange={onFormChange}
              size='small'
              label='Breve descrição'
              helperText='Utilizado como metadescription - O comprimento ideal do texto é entre 140 e 160 caracteres'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Input
              inputId='pro_st_codseller'
              value={form?.pro_st_codseller || ''}
              handleChange={onFormChange}
              size='small'
              label='SKU Seller'
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={6} lg={6}>
            <Label>Sobre o produto</Label>

            <TextEditor
              style={{ minWidth: '96px', maxWidth: '192px' }}
              initialValue={form?.pro_lt_sobre}
              onChange={(value) => onSetForm({ pro_lt_sobre: value })}
            />
          </Grid>
          <Grid item xs={6} lg={6}>
            <Label>História do produto</Label>

            <TextEditor
              style={{ minWidth: '96px', maxWidth: '192px' }}
              initialValue={form?.pro_lt_historia}
              onChange={(value) => onSetForm({ pro_lt_historia: value })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SEOFields.propTypes = {
  form: PropTypes.object.isRequired,

  departamentos: PropTypes.array.isRequired,
  categorias_vitrine: PropTypes.array.isRequired,
  categorias: PropTypes.array.isRequired,

  onAddTag: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSetForm: PropTypes.func.isRequired,
  errorOnFill: PropTypes.bool,
  publisher: PropTypes.bool,
  isKit: PropTypes.bool,

  urlAlreadyExist: PropTypes.bool,
};

SEOFields.defaultProps = {
  errorOnFill: false,
  publisher: false,
  isKit: false,
  urlAlreadyExist: false,
};

export default SEOFields;
