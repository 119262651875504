import styled from 'styled-components';
import { IconButton, CircularProgress } from '@material-ui/core';
import { MdBrightness1 } from 'react-icons/md';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Error = styled.p`
  color: tomato;
`;

export const EditIcon = styled(IconButton)`
  color: 'black';
  background-color: 'white';
  &:hover {
    background-color: #85b074;
    color: white;
    transition: 0.3s;
  }
`;

export const Title = styled.h1`
  color: #000000;
  font-size: 2.5rem;
  margin: 30px 0;
  text-transform: uppercase;
`;

export const ButtonContent = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CircleIndicator = styled(MdBrightness1)`
  color: ${({ active }) => (active ? '#85B074' : '#E53535')};
`;

export const CenterColumn = styled.td`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875em;
  text-align: center;
  color: #000000;
`;

export const Section = styled.section`
  margin-bottom: 30px;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductCol = styled.td`
  padding: 7px;
`;

export const ProductDescription = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75em;

  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductLink = styled.a`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75em;
  font-weight: 700;
  color: #99ce86;
`;

export const LoadingSpinner = styled(CircularProgress)`
  color: #85b074;
  margin-right: 30px;
`;
