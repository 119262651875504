/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MdFileDownload } from 'react-icons/md';
import { FaFileArchive } from 'react-icons/fa';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Skeleton } from '@material-ui/lab';

import {
  Grid,
  Avatar,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Select, DragAndDrop, Button, Input } from 'sonoma-design-system';

import { directories } from './handlers/directories';
import { useHandleDrop } from './handlers/useHandleDrop';
import { useHandleSubmit } from './handlers/useHandleSubmit';

import { findAll as fetchAllBranches } from '../../services/FilialService';
import {
  findAllByFilial,
  deleteByFinanceiroArquivoId,
} from '../../services/FinancialConciliation';
import dragndropIcon from '../../assets/dragndrop-icon.svg';

import {
  Title,
  Divider,
  BackButtonsContainer,
  ButtonDescription,
} from './styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .MuiTabs-indicator': {
      backgroundColor: '#9CD186',
      height: 3,
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#9CD186',
    },
  },
});

const FIRST_POSITION = 0;

function HandleFilialConciliationData({
  diretorios,
  diretorioData,
  handleDeleteArchive,
  selectedFilial,
}) {
  function textMessage(message) {
    return (
      <p
        style={{
          fontSize: '20px',
          padding: '15px 0px',
          textAlign: 'center',
        }}
      >
        {message}
      </p>
    );
  }

  if (!selectedFilial) return textMessage('Nenhuma filial selecionada.');

  if (diretorios.length === 0)
    return textMessage('Nenhuma conciliação cadastrada para essa filial');

  return (
    <>
      <div
        style={{
          marginTop: '15px',
          paddingBottom: '15px',
        }}
      >
        {diretorios.map((e) => (
          <Accordion key={e} style={{ marginBottom: '15px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>{e}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {diretorioData
                  .filter((el) => el.far_st_diretorio === e)
                  .map((d) => (
                    <div
                      key={d.far_in_codigo}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                        flexDirection: 'column',
                        width: '50%',
                      }}
                    >
                      <a
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                        href={d.url}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Avatar>
                          <MdFileDownload />
                        </Avatar>
                        <p>{d.far_st_nomearquivo}</p>
                      </a>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '15px',
                        }}
                      >
                        <Button
                          outlined
                          onClick={() => handleDeleteArchive(d.far_in_codigo)}
                          backgroundColor='#B00D1F'
                        >
                          <ButtonDescription>Excluir</ButtonDescription>
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
}

HandleFilialConciliationData.propTypes = {
  diretorios: PropTypes.array,
  diretorioData: PropTypes.array,
  handleDeleteArchive: PropTypes.func.isRequired,
  selectedFilial: PropTypes.object,
};

HandleFilialConciliationData.defaultProps = {
  diretorios: [],
  diretorioData: [],
  selectedFilial: null,
};

export default function Conciliacao() {
  const history = useHistory();
  const classes = useStyles();

  const [branches, setBranches] = useState([]);

  const [branch, setBranch] = useState(null);
  const [selectedDiretory, setSelectedDiretory] = useState(null);

  const { files, handleDrop, setFiles } = useHandleDrop();
  const { handleSubmit, errors, loading } = useHandleSubmit(
    branch,
    selectedDiretory,
    files
  );

  const [tabValue, setTabValue] = useState(0);
  const [diretorios, setDiretorios] = useState([]);
  const [diretorioData, setDiretorioData] = useState([]);
  const [loadingConciliationData, setLoadingConciliationData] = useState(false);

  const selectedFilialForm = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchDiretorioData = useCallback(async () => {
    setLoadingConciliationData(true);
    if (selectedFilialForm) {
      const result = await findAllByFilial(selectedFilialForm.fil_in_codigo);

      const directoryFiltered = result.data.map((e) => e.far_st_diretorio);
      const directoryWithoutDuplicated = [...new Set(directoryFiltered)];

      setDiretorios(directoryWithoutDuplicated);
      setDiretorioData(result.data);
    }
    setLoadingConciliationData(false);
  }, [selectedFilialForm]);

  const handleDeleteArchive = useCallback(
    async (financeiroArquivoId) => {
      setLoadingConciliationData(true);
      await deleteByFinanceiroArquivoId(financeiroArquivoId);

      await fetchDiretorioData();
      setLoadingConciliationData(false);
    },
    [fetchDiretorioData]
  );

  useEffect(() => {
    fetchDiretorioData();
  }, [fetchDiretorioData, selectedFilialForm]);

  useEffect(() => {
    async function fetchBranches() {
      const { data } = await fetchAllBranches();

      setBranches(
        data.map((b) => ({
          label: b.apelido,
          value: b.fil_in_codigo,
        }))
      );
    }

    fetchBranches();
  }, []);

  return (
    <div
      style={{
        marginTop: '130px',
        backgroundColor: '#fff',
        padding: '0px 15px',
      }}
    >
      <Title>Conciliação Financeira</Title>
      <Divider style={{ marginBottom: '30px' }} />

      <Paper square className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='secondary'
          textColor='primary'
          aria-label='icon label tabs'
        >
          <Tab icon={<CloudUploadOutlined />} label='Cadastrar' />
          <Tab icon={<DescriptionOutlined />} label='Consultar' />
        </Tabs>
      </Paper>

      {tabValue === FIRST_POSITION ? (
        <>
          <Grid spacing={2} container style={{ marginTop: '25px' }}>
            <Grid item xs={6} lg={6}>
              <Input
                inputId='modalidade'
                size='small'
                value='Fulfills'
                label='Modalidade'
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Select
                selectId='branch'
                size='small'
                label='*Filial'
                error={errors.branch && !branch}
                errorText={errors.branch}
                value={branch}
                options={[{ value: null, label: 'Selecione' }, ...branches]}
                handleChange={(e) => setBranch(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} lg={6}>
              <Select
                selectId='diretorio'
                size='small'
                label='*Diretorio'
                error={errors.selectedDiretory && !selectedDiretory}
                errorText={errors.selectedDiretory}
                value={selectedDiretory}
                options={[{ value: null, label: 'Selecione' }, ...directories]}
                handleChange={(e) => setSelectedDiretory(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <DragAndDrop
                  onDrop={handleDrop}
                  icon={dragndropIcon}
                  isButton
                  buttonTitle='Inserir Arquivos'
                />
                {files?.map((file) => (
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      onClick={() => setFiles([])}
                      style={{
                        height: '50px',
                        width: '50px',
                        cursor: 'pointer',
                      }}
                    >
                      <FaFileArchive />
                    </Avatar>
                    <p>{file.path}</p>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <Divider style={{ margin: '30px 0' }} />
          <BackButtonsContainer>
            <Button
              disabled={loading}
              outlined
              isStartIcon
              onClick={() => history.push('/')}
              backgroundColor='#8A8A8A'
            >
              <ButtonDescription>Cancelar</ButtonDescription>
            </Button>
            <Button onClick={handleSubmit} backgroundColor='#9CD186'>
              <ButtonDescription>
                {loading ? <CircularProgress size='16px' /> : 'Cadastrar'}
              </ButtonDescription>
            </Button>
          </BackButtonsContainer>
        </>
      ) : loadingConciliationData ? (
        <Skeleton animation='wave' height={600} />
      ) : (
        HandleFilialConciliationData({
          diretorios,
          diretorioData,
          handleDeleteArchive,
          selectedFilial: selectedFilialForm,
        })
      )}
    </div>
  );
}
