export const Types = {
  CLEAR: '@invoices/clear',
  SET_LOADING: '@invoices/loading/set',
};

export const setLoading = (isLoading) => ({
  type: Types.SET_LOADING,
  payload: isLoading,
});

export const clear = () => ({
  type: Types.CLEAR,
});
