import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button } from 'sonoma-design-system';
import { SaveAlt } from '@material-ui/icons';
import { Grid, Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { getToken, getUser, getUserRoles } from '../../auth/auth';
import { fetchProdutos } from '../../store/editBulkProdutos';
import { updateProductsPrice } from '../../services/EditBulkProdutos';
import { Price } from './Price';
import { CenterColumn, Section } from './style';
import { ProductCard, ProductCol, ProductDescription } from '../../styles';
import defaultWineImg from '../../assets/default-wine.png';
import SkuColunm from '../ProductsList/SkuColumn';
import MagentoIntegrationColumn from './MagentoIntegrationColumn';

function SellerName({ item }) {
  return (
    <td title={item.filial?.fil_st_fantasia} style={{ textAlign: 'center' }}>
      {item.filial?.fil_st_fantasia.length > 13
        ? item.filial?.fil_st_fantasia.substring(0, 13).concat('...')
        : item.filial?.fil_st_fantasia || '-'}
    </td>
  );
}

SellerName.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function EditBulkProdutos() {
  const produtos = useSelector((state) => state.editBulkProdutos.produtos);
  const produtosModified = useSelector(
    (state) => state.editBulkProdutos.produtosModified
  );
  const loading = useSelector((state) => state.editBulkProdutos.loading);
  const error = useSelector((state) => state.editBulkProdutos.error);

  const paging = useSelector((state) => state.editBulkProdutos.paging);
  const ordering = useSelector((state) => state.editBulkProdutos.ordering);
  const filters = useSelector((state) => state.editBulkProdutos.filters);

  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const [canSeeAllProdutos, setCanSeeAllProdutos] = useState(false);
  const [filial, setFilial] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const token = getToken();

  const user = token && getUser();

  useEffect(() => {
    if (produtosModified.length) {
      setDisabled(false);
    }
  }, [produtosModified]);

  useEffect(() => {
    const userRoles = getUserRoles();

    setFilial(user.fil_in_codigo || user.slr_in_codigo);

    if (userRoles.find((e) => e === 'ADMIN' || 'INSIDER')) {
      setCanSeeAllProdutos(true);
    }

    dispatch(
      fetchProdutos({
        canSeeAllProdutos: userRoles.find((e) => e === 'ADMIN' || 'INSIDER'),
        filteringByFilial: selectedFilial?.fil_in_codigo || null,
        filialId: selectedFilial
          ? selectedFilial?.fil_in_codigo
          : user.fil_in_codigo || user.slr_in_codigo,
      })
    );
  }, [
    canSeeAllProdutos,
    dispatch,
    selectedFilial,
    user.fil_in_codigo,
    user.slr_in_codigo,
  ]);

  function Product({ item }) {
    const description = item.pro_st_titulopage || item.pro_st_descricao;

    return (
      <ProductCol style={{ maxWidth: '270px' }}>
        <ProductCard>
          <Avatar
            variant='square'
            style={{
              marginRight: '10px',
              borderRadius: '5px',
              border: '1px solid #E0E0E0',
              height: '48px',
              width: '48px',
            }}
            src={item.image_firebase_url || defaultWineImg}
            alt={description}
          />

          <div>
            <ProductDescription title={description}>
              {(description.length > 20
                ? description.substring(0, 20).concat('...')
                : description) || '-'}
            </ProductDescription>
          </div>
        </ProductCard>
      </ProductCol>
    );
  }

  Product.propTypes = {
    item: PropTypes.object.isRequired,
  };

  function DateColumn({ item, field }) {
    return (
      <CenterColumn>
        {format(new Date(item[field]), 'dd/MM/yyyy HH:mm')}
      </CenterColumn>
    );
  }

  DateColumn.propTypes = {
    item: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
  };

  DateColumn.propTypes = {
    item: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
  };

  const cols = useMemo(
    () => [
      {
        id: 'pro_st_titulopage',
        title: 'Produto',
        component: Product,
      },
      { id: 'pro_st_sku', title: 'SKU', component: SkuColunm },
      {
        id: 'pro_de_precode',
        title: 'Preço De',
        component: Price,
      },
      {
        id: 'pro_de_precopor',
        title: 'Preço Por',
        component: Price,
      },
      {
        id: 'pro_de_precoatacado',
        title: 'Preço Caixa',
        component: Price,
      },
      {
        id: 'integrationStatus',
        title: 'Status de integração',
        component: MagentoIntegrationColumn,
      },
      {
        id: 'updatedAt',
        title: 'Última alteração',
        component: (props) => <DateColumn {...props} field='updatedAt' />,
      },
      canSeeAllProdutos && {
        id: 'filial.fil_st_fantasia',
        title: 'Seller',
        component: SellerName,
      },
    ],
    [canSeeAllProdutos]
  );

  function ButtonContinue({ fullWidth }) {
    const produtosToSave = produtosModified.map((e) => ({
      pro_in_codigo: e.pro_in_codigo,
      pro_st_sku: e.pro_st_sku,
      pro_de_precopor: e.pro_de_precopor,
      pro_de_precode: e.pro_de_precode,
      pro_de_precoatacado: e.pro_de_precoatacado,
      slr_in_codigo: e.slr_in_codigo,
      fil_in_codigo: e.fil_in_codigo,
      arm_in_codigo: e.armazem,
      estoque: e.estoque,
    }));

    async function sendProducts() {
      setDisabled(true);
      await updateProductsPrice(produtosToSave);
    }

    return (
      <div
        style={{
          margin: '0 0 0 15px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ marginLeft: '15px' }}>
          <Button
            size='sm'
            onClick={() => dispatch(sendProducts)}
            disabled={disabled}
            fullWidth={fullWidth}
          >
            Salvar <SaveAlt fontSize='3rem' style={{ marginLeft: '5px' }} />
          </Button>
        </div>
      </div>
    );
  }

  ButtonContinue.propTypes = {
    fullWidth: PropTypes.bool,
  };

  ButtonContinue.defaultProps = {
    fullWidth: false,
  };

  return (
    <Section style={{ marginTop: '130px' }}>
      {loading && (
        <>
          {canSeeAllProdutos && <Skeleton animation='wave' height={100} />}
          <Skeleton animation='wave' height={600} />
        </>
      )}

      {error && <p>Tivemos um problema ao carregar os produtos</p>}

      {!loading && !error && (
        <>
          <Grid style={{ marginTop: '30px' }}>
            <Table
              headerTitle='Editar preço(s) do(s) produto(s)'
              refValue={filters.ref}
              rows={produtos}
              columns={cols.filter((c) => c)}
              ordering={ordering}
              onChangeOrder={(order) =>
                dispatch(
                  fetchProdutos(
                    {
                      canSeeAllProdutos,
                      filteringByFilial: selectedFilial?.fil_in_codigo,
                      filialId: selectedFilial?.fil_in_codigo || filial,
                    },
                    { page: paging.page, limit: paging.limit },
                    order,
                    filters
                  )
                )
              }
              paging={paging}
              onChangePage={(page) =>
                dispatch(
                  fetchProdutos(
                    {
                      canSeeAllProdutos,
                      filteringByFilial: selectedFilial?.fil_in_codigo,
                      filialId: selectedFilial?.fil_in_codigo || filial,
                    },
                    { page, limit: paging.limit },
                    ordering,
                    filters
                  )
                )
              }
              onSearch={(ref) =>
                dispatch(
                  fetchProdutos(
                    {
                      canSeeAllProdutos,
                      filteringByFilial: selectedFilial?.fil_in_codigo,
                      filialId: selectedFilial?.fil_in_codigo || filial,
                    },
                    { page: 1, limit: paging.limit },
                    { order_by: 'createdAt', order: 'asc' },
                    { ...filters, ref: ref }
                  )
                )
              }
              headerComponent={() => <ButtonContinue />}
              searchPlaceholder='Buscar produto por nome ou SKU'
            />
          </Grid>
        </>
      )}
    </Section>
  );
}
