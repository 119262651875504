import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Input } from 'sonoma-design-system';

function MoreCheeseInfoFields({ form, onFormChange }) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 30,
    },
  }));

  const classes = useStyles();

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={3}>
            <Input
              inputId='que_lt_ingredientes'
              size='small'
              helperText='Inserir ingredientes que compõe o queijo'
              label='Ingredientes'
              value={form?.que_lt_ingredientes}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='que_lt_informacoesnutri'
              size='small'
              helperText='Inserir informações nutricionais do queijo'
              label='Informações Nutricionais'
              value={form?.que_lt_informacoesnutri}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='que_lt_alergicos'
              size='small'
              helperText='Inserir conteúdo alérgico do queijo'
              label='Alérgicos'
              value={form?.que_lt_alergicos}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              inputId='que_lt_armazenamento'
              size='small'
              helperText='Inserir armazenamento do queijo'
              label='Armazenamento'
              value={form?.que_lt_armazenamento}
              handleChange={onFormChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MoreCheeseInfoFields.propTypes = {
  form: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

export default MoreCheeseInfoFields;
