import { toast } from 'react-toastify';

import { getToken } from '../auth/auth';
import api from './api';

const token = getToken();

export async function updateActivationProduct({
  produtoId,
  pro_bo_ativo,
  product,
}) {
  try {
    await api.put(
      `produtos/${produtoId}/active`,
      { product, pro_bo_ativo },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    toast.success(
      `Produto ${pro_bo_ativo ? 'ativado' : 'desativado'} com sucesso !`
    );
  } catch (e) {
    toast.error(`Erro ao ${pro_bo_ativo ? 'ativar' : 'desativar'} produto.`);

    throw e;
  }
}
