import { findAll } from '../../services/integratedProducts';

export const Types = {
  SET_PRODUCTS: '@newKit/setProducts',
  SET_PAGING: '@newKit/setPaging',
  SET_ORDERING: '@newKit/setOrdering',
  SET_FILTERS: '@newKit/setFilters',
  SET_LOADING: '@newKit/setLoading',
  SET_ERROR: '@newKit/setError',
  CLEAR: '@newKit/clear',

  ADD_KIT: '@newKit/kit/add',
  REMOVE_KIT: '@newKit/kit/remove',
  UPDATE_PRICE: '@newKit/price/update',
  UPDATE_AMOUNT: '@newKit/amount/update',
};

export const setProducts = (products) => ({
  type: Types.SET_PRODUCTS,
  payload: products,
});

export const setPaging = (paging) => ({
  type: Types.SET_PAGING,
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: Types.SET_ORDERING,
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: Types.SET_FILTERS,
  payload: filters,
});

export const setLoading = (loading) => ({
  type: Types.SET_LOADING,
  payload: loading,
});

export const setError = (error) => ({
  type: Types.SET_ERROR,
  payload: error,
});

export const clear = () => ({
  type: Types.CLEAR,
});

export const addToKit = (product) => ({
  type: Types.ADD_KIT,
  payload: product,
});

export const removeFromKit = (product) => ({
  type: Types.REMOVE_KIT,
  payload: product,
});

export const updatePrice = (product, price) => ({
  type: Types.UPDATE_PRICE,
  payload: { product, price },
});

export const updateAmount = (product, amount) => ({
  type: Types.UPDATE_AMOUNT,
  payload: { product, amount },
});

export const fetchAll = (paging, ordering, filters) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findAll(paging, ordering, filters);

    return dispatch([
      setLoading(false),
      setProducts(response.data),
      setPaging(response._metadata.paging),
      setOrdering(response._metadata.ordering),
      setFilters(response._metadata.filters),
      setError(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
