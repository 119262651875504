import styled from 'styled-components';

export const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 5px;
  background: #85b074;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2000;
`;

export const ShowChat = styled.button`
  border: transparent;
  background: transparent;
  position: fixed;
  bottom: 30px;
  right: 75px;
  cursor: pointer;
`;
