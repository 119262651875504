import { toast } from 'react-toastify';

import api from './api';
import { getToken } from '../auth/auth';
import { jsonToFormData } from '../utils/buildFormData';

const token = getToken();

export async function create(data, file) {
  try {
    const formData = jsonToFormData(data);

    // append files
    for (let i = 0; i < file.length; i += 1) {
      const fileToAppend = file[i];

      formData.append('file', fileToAppend);
    }

    await api.post('financial/conciliation', formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Conciliação criada com sucesso!');
  } catch (err) {
    toast.error('Erro ao criar, por favor tente novamente mais tarde.');
    throw err;
  }
}

export async function findAllBySeller(sellerId) {
  try {
    const result = await api.get(`financial/conciliation/sellers/${sellerId}`, {
      headers: { authorization: `bearer ${token}` },
    });

    return result.data;
  } catch (err) {
    toast.error('Erro ao buscar conciliação financeira.');
    throw err;
  }
}

export async function findAllByFilial(filialId) {
  try {
    const result = await api.get(`financial/conciliation/filiais/${filialId}`, {
      headers: { authorization: `bearer ${token}` },
    });

    return result.data;
  } catch (err) {
    toast.error('Erro ao buscar conciliação financeira.');
    throw err;
  }
}

export async function deleteByFinanceiroArquivoId(financeiroArquivoId) {
  try {
    await api.delete(`financial/conciliation/${financeiroArquivoId}`, {
      headers: { authorization: `bearer ${token}` },
    });

    toast.success('Arquivo deletado !');
  } catch (err) {
    toast.error('Erro ao buscar conciliação financeira.');
    throw err;
  }
}
