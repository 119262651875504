const INITIAL_STATE = {
  selectedFilial: null,
};

const setSelectedFilial = (state, action) => ({
  ...state,
  selectedFilial: action.payload,
});

export default function selectedFilialToFilterReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case '@selectedFilialToFilter/setSelectFilial':
      return setSelectedFilial(state, action);
    default:
      return state;
  }
}
