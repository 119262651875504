import { toast } from 'react-toastify';

import api from './api';

import { jsonToFormData } from '../utils/buildFormData';

export async function findAll(
  paging = { page: 1, limit: 15 },
  ordering,
  filters
) {
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    const response = await api.get('produtos', {
      params: { ...paging, ...ordering, ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produtos');
    throw e;
  }
}

export async function remove(productId) {
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    await api.delete(`produtos/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    toast.error('Erro ao deletar produto');
    throw e;
  }
}

export async function findOne(productId) {
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    const response = await api.get(`produtos/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar produto');
    throw e;
  }
}

export async function create(data) {
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    const formData = jsonToFormData(data);

    await api.post('writer/produtos', formData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success('Produto criado com sucesso!');
  } catch (e) {
    toast.error('Erro ao criar produto');
    throw e;
  }
}
