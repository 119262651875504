import { findAll } from '../../services/UserService';

export const setUsers = (users) => ({
  type: '@userList/setUsers',
  payload: users,
});

export const setPaging = (paging) => ({
  type: '@userList/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@userList/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@userList/setFilters',
  payload: filters,
});

export const setLoading = (loading) => ({
  type: '@userList/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@userList/setError',
  payload: error,
});

export const fetchAll = (paging, ordering, filters) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findAll(paging, ordering, filters);

    return dispatch([
      setUsers(response.data),
      setPaging(response._metadata.paging),
      setOrdering(response._metadata.ordering),
      setFilters(response._metadata.filters),

      setLoading(false),
      setError(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
