const INITIAL_STATE = {
  loading: false,
  error: false,
  errorOnFill: false,
  form: {
    pro_de_comprimento: 16,
    pro_de_peso: 1.4,
    pro_de_largura: 11,
    pro_de_altura: 33,
  },
};

const setForm = (state, action) => ({
  ...state,
  loading: false,
  form: {
    ...state.form,
    ...action.payload,
  },
});

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });
const setErrorOnFill = (state, action) => ({
  ...state,
  errorOnFill: action.payload,
});

const clearAll = () => INITIAL_STATE;

export default function freteEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@freteEdit/setForm':
      return setForm(state, action);
    case '@freteEdit/setLoading':
      return setLoading(state, action);
    case '@freteEdit/setError':
      return setError(state, action);
    case '@freteEdit/setErrorOnFill':
      return setErrorOnFill(state, action);
    case '@freteEdit/clearAll':
      return clearAll(state, action);
    default:
      return state;
  }
}
