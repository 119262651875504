import { Parser } from 'json2csv';

export const downloadCsv = (filename, dataJson) => {
  const parser = new Parser();
  const csvData = parser.parse(dataJson);
  const csvFile = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');

  const url = URL.createObjectURL(csvFile);
  link.setAttribute('href', url);
  link.setAttribute('download', filename || 'file.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
