import PropTypes from 'prop-types';

import { CenterColumn } from '../styles';

export function ItemAmount({ item }) {
  return (
    <CenterColumn>
      {item.produtos.reduce((acc, curr) => acc + curr.pvp_in_quantidade, 0)}
    </CenterColumn>
  );
}

ItemAmount.propTypes = {
  item: PropTypes.object.isRequired,
};
