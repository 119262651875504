import { toast } from 'react-toastify';
import api from './api';

export async function getBillingData(filial, from, to) {
  if (!filial) return null;
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    const response = await api.get(`filiais/${filial}/billing`, {
      headers: { authorization: `bearer ${token}` },
      params: { from, to },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar dados do faturamento');

    throw e;
  }
}

export async function getProductsBillingData(
  filial,
  from,
  to,
  paging,
  ordering
) {
  if (!filial) return null;
  try {
    const token = localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');

    const response = await api.get(`filiais/${filial}/billing/produtos`, {
      headers: { authorization: `bearer ${token}` },
      params: {
        from,
        to,
        page: paging.page,
        limit: paging.limit,
        order: ordering?.order,
        order_by: ordering?.order_by,
        availableOrderBy: ordering?.availableOrderBy,
      },
    });

    return response.data;
  } catch (e) {
    toast.error('Erro ao buscar índice de produtos');

    throw e;
  }
}
