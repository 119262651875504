import { findAll, remove as removeSale } from '../../services/PromoService';

export const Types = {
  CLEAR: '@sales/list/clear',
  SET_LOADING: '@sales/list/loading/set',
  SET_ERROR: '@sales/list/error/set',

  SET_PAGING: '@sales/list/paging/set',
  SET_ORDERING: '@sales/list/ordering/set',
  SET_FILTERS: '@sales/list/filters/set',

  SET_DATA: '@sales/list/data/set',
};

export const setData = (data) => ({
  type: Types.SET_DATA,
  payload: data,
});

export const setPaging = (paging) => ({
  type: Types.SET_PAGING,
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: Types.SET_ORDERING,
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: Types.SET_FILTERS,
  payload: filters,
});

export const setLoading = (loading) => ({
  type: Types.SET_LOADING,
  payload: loading,
});

export const setError = (error) => ({
  type: Types.SET_ERROR,
  payload: error,
});

export const clear = () => ({
  type: Types.CLEAR,
});

export const fetchAll = (paging, ordering, filters) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await findAll(paging, ordering, filters);

    return dispatch([
      setLoading(false),
      setData(response.data),
      setPaging(response._metadata.paging),
      setOrdering(response._metadata.ordering),
      setFilters(response._metadata.filters),
      setError(false),
    ]);
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};

export const remove = (productId, saleId) => async (dispatch, getStore) => {
  try {
    dispatch(setLoading(true));

    const { paging, ordering, filters } = getStore().sales;

    await removeSale(productId, saleId);

    return dispatch(fetchAll(paging, ordering, filters));
  } catch (e) {
    return dispatch([setLoading(false), setError(true)]);
  }
};
