import { decode } from './jwt';

export function getToken() {
  return localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN');
}

export function getDecodedToken() {
  const token = getToken();

  if (!token) throw new Error('INVALID_TOKEN');

  return decode(token);
}

export function getUserRoles() {
  const decoded = getDecodedToken();

  return decoded.roles || [];
}

export function getUser() {
  const decoded = getDecodedToken();

  return decoded.user;
}

export function getExpiration() {
  return new Date(localStorage.getItem('SONOMA_PRODUTOS_JSONWEBTOKEN_EXP'));
}

export function setToken(data) {
  localStorage.setItem('SONOMA_PRODUTOS_JSONWEBTOKEN', data);
}

export function setTokenExpiration(data) {
  localStorage.setItem('SONOMA_PRODUTOS_JSONWEBTOKEN_EXP', data);
}

export function clearAuthData() {
  localStorage.removeItem('SONOMA_PRODUTOS_JSONWEBTOKEN');
  localStorage.removeItem('SONOMA_PRODUTOS_JSONWEBTOKEN_EXP');
}
