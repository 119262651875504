import { findAll as findAllTaxProfiles } from '../../services/perfisImpostos';
import { findAll as findAllProductOrigins } from '../../services/ProductOriginService';
import { findAll as findAllTaxClassfications } from '../../services/classificacaoFiscal';
import { findAll as findAllShowcaseCategories } from '../../services/categoriasVitrine';
import { findAll as findAllProductClasifications } from '../../services/classificacaoProduto';
import { findAll as findAllCategories } from '../../services/categorias';
import { findAll as findAllDepartments } from '../../services/departamentos';
import { findAll as findAllTradePolicies } from '../../services/politicasComerciais';
import { findAll as findAllIntegratedProducts } from '../../services/integratedProducts';

export const Types = {
  CLEAR: '@info/clear',
  SET_FORM: '@info/form/set_value',
  FORM_ERROR: '@info/form/error',

  // tax profiles
  ON_FETCH_TAX_PROFILES: '@info/form/tax_profiles/fetch',
  LOADING_TAX_PROFILES: '@info/form/tax_profiles/loading',
  TAX_PROFILES_ERROR: '@info/form/tax_profiles/error',

  // product origins
  ON_FETCH_ORIGINS: '@info/form/origins/fetch',
  LOADING_ORIGINS: '@info/form/origins/loading',
  ORIGINS_ERROR: '@info/form/origins/error',

  // tax classifications
  ON_FETCH_TAX_CLASSIFICATIONS: '@info/form/tax_classifications/fetch',
  LOADING_TAX_CLASSIFICATIONS: '@info/form/tax_classifications/loading',
  TAX_CLASSIFICATIONS_ERROR: '@info/form/tax_classifications/error',

  // showcase categories
  ON_FETCH_SHOWCASE_CATEGORIES: '@info/form/showcase_categories/fetch',
  LOADING_SHOWCASE_CATEGORIES: '@info/form/showcase_categories/loading',
  SHOWCASE_CATEGORIES_ERROR: '@info/form/showcase_categories/error',

  // product classifications
  ON_FETCH_PRODUCT_CLASSIFICATIONS: '@info/form/product_classifications/fetch',
  LOADING_PRODUCT_CLASSIFICATIONS: '@info/form/product_classifications/loading',
  PRODUCT_CLASSIFICATIONS_ERROR: '@info/form/product_classifications/error',

  // categories
  ON_FETCH_CATEGORIES: '@info/form/categories/fetch',
  LOADING_CATEGORIES: '@info/form/categories/loading',
  CATEGORIES_ERROR: '@info/form/categories/error',

  // departments
  ON_FETCH_DEPARTMENTS: '@info/form/departments/fetch',
  LOADING_DEPARTMENTS: '@info/form/departments/loading',
  DEPARTMENTS_ERROR: '@info/form/departments/error',

  // trade policies
  ON_FETCH_TRADE_POLICIES: '@info/form/trade_policies/fetch',
  LOADING_TRADE_POLICIES: '@info/form/trade_policies/loading',
  TRADE_POLICIES_ERROR: '@info/form/trade_policies/error',

  // integrated Products
  ON_FETCH_INTEGRATED_PRODUCTS: '@info/form/integrated_products/fetch',
  LOADING_INTEGRATED_PRODUCTS: '@info/form/integrated_products/loading',
  INTEGRATED_PRODUCTS_ERROR: '@info/form/integrated_products/error',
};

export const setForm = (data) => ({
  type: Types.SET_FORM,
  payload: data,
});

export const setErrorOnFill = (error) => ({
  type: Types.FORM_ERROR,
  payload: error,
});

export const clearAll = () => ({
  type: Types.CLEAR,
});

export const fetchTaxProfiles = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_TAX_PROFILES });

    const data = await findAllTaxProfiles();

    return dispatch({ type: Types.ON_FETCH_TAX_PROFILES, payload: data });
  } catch (e) {
    return dispatch({ type: Types.TAX_PROFILES_ERROR });
  }
};

export const fetchOrigins = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_ORIGINS });

    const data = await findAllProductOrigins();

    return dispatch({ type: Types.ON_FETCH_ORIGINS, payload: data });
  } catch (e) {
    return dispatch({ type: Types.ORIGINS_ERROR });
  }
};

export const fetchTaxClassifications = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_TAX_CLASSIFICATIONS });

    const data = await findAllTaxClassfications();

    return dispatch({
      type: Types.ON_FETCH_TAX_CLASSIFICATIONS,
      payload: data,
    });
  } catch (e) {
    return dispatch({ type: Types.TAX_CLASSIFICATIONS_ERROR });
  }
};

export const fetchShowcaseCategories = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_SHOWCASE_CATEGORIES });

    const data = await findAllShowcaseCategories();

    return dispatch({
      type: Types.ON_FETCH_SHOWCASE_CATEGORIES,
      payload: data,
    });
  } catch (e) {
    return dispatch({ type: Types.SHOWCASE_CATEGORIES_ERROR });
  }
};

export const fetchProductClassifications = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_PRODUCT_CLASSIFICATIONS });

    const data = await findAllProductClasifications();

    return dispatch({
      type: Types.ON_FETCH_PRODUCT_CLASSIFICATIONS,
      payload: data,
    });
  } catch (e) {
    return dispatch({ type: Types.PRODUCT_CLASSIFICATIONS_ERROR });
  }
};

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_CATEGORIES });

    const { data } = await findAllCategories();

    return dispatch({
      type: Types.ON_FETCH_CATEGORIES,
      payload: data,
    });
  } catch (e) {
    return dispatch({ type: Types.CATEGORIES_ERROR });
  }
};

export const fetchDepartments = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_DEPARTMENTS });

    const { data } = await findAllDepartments();

    return dispatch({
      type: Types.ON_FETCH_DEPARTMENTS,
      payload: data,
    });
  } catch (e) {
    return dispatch({ type: Types.DEPARTMENTS_ERROR });
  }
};

export const fetchTradePolicies = () => async (dispatch) => {
  try {
    dispatch({ type: Types.LOADING_TRADE_POLICIES });

    const { data } = await findAllTradePolicies();

    return dispatch({
      type: Types.ON_FETCH_TRADE_POLICIES,
      payload: data,
    });
  } catch (e) {
    return dispatch({ type: Types.TRADE_POLICIES_ERROR });
  }
};

export const fetchIntegratedProducts =
  (paging, ordering, filters) => async (dispatch) => {
    try {
      dispatch({ type: Types.LOADING_INTEGRATED_PRODUCTS });

      const data = await findAllIntegratedProducts(paging, ordering, filters);

      return dispatch({
        type: Types.ON_FETCH_INTEGRATED_PRODUCTS,
        payload: data,
      });
    } catch (e) {
      return dispatch({ type: Types.INTEGRATED_PRODUCTS_ERROR });
    }
  };

export const fetchData = () => async (dispatch) =>
  dispatch([
    fetchTaxProfiles(),
    fetchOrigins(),
    fetchTaxClassifications(),
    fetchShowcaseCategories(),
    fetchProductClassifications(),
    fetchCategories(),
    fetchDepartments(),
    fetchTradePolicies(),
    fetchIntegratedProducts(),
  ]);
