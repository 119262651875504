import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Input, Select, Checkbox } from 'sonoma-design-system';
import { getUserRoles } from '../../auth/auth';
import { containsAny } from '../../utils/arrays';

// import { TagsContainer } from './styles';

function InfoFields({
  form,
  // politicas_comerciais,
  onFormChange,
  onCheckbox,
  // onAddTag,
  // onSetForm,
}) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const isSeller = getUserRoles().includes('SELLER');
  const isInsider = useMemo(
    () => containsAny(getUserRoles(), ['ADMIN', 'INSIDER']),
    []
  );

  const classes = useStyles();

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item lg={3}>
            <Select
              selectId='politicas_comerciais'
              options={[{ value: 1, label: 'Principal' }]}
              value={1}
              handleChange={onFormChange}
              size='small'
              label='Políticas Comerciais'
              disabled
            />
            {/* <Select
              selectId='politicas_comerciais'
              options={[
                { value: null, label: 'Selecione' },
                ...politicas_comerciais,
              ]}
              handleChange={(e) =>
                onAddTag(
                  e.target.name,
                  {
                    poc_in_codigo: e.target.value,
                    poc_st_descricao: politicas_comerciais.find(
                      (t) => t.value === Number(e.target.value)
                    ).label,
                  },
                  'poc_in_codigo'
                )
              }
              size='small'
              label='Políticas Comerciais'
            />

            <TagsContainer>
              {form?.politicas_comerciais?.map((tag) => (
                <Tag
                  key={tag.poc_in_codigo}
                  onDelete={() =>
                    onSetForm({
                      politicas_comerciais: form.politicas_comerciais.filter(
                        (t) => t.poc_in_codigo !== tag.poc_in_codigo
                      ),
                    })
                  }
                >
                  {tag.poc_st_descricao}
                </Tag>
              ))}
            </TagsContainer> */}
          </Grid>

          <Grid item lg={3}>
            <Input
              type='number'
              inputId='pro_in_score'
              value={form?.pro_in_score || ''}
              handleChange={onFormChange}
              size='small'
              label='Score (Ordenação de Produtos)'
              helperText='Número da ordenação dos produtos'
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid spacing={2} container lg={12} style={{ marginBottom: '1rem' }}>
          {!isSeller && (
            <Grid item lg={3}>
              <Checkbox
                checkboxId='pro_bo_exibirnosite'
                value={form?.pro_bo_exibirnosite || ''}
                handleChange={onCheckbox}
                label='Exibir no site'
              />
            </Grid>
          )}

          <Grid item lg={3}>
            <Checkbox
              checkboxId='pro_bo_ativo'
              value={form?.pro_bo_ativo || ''}
              handleChange={onCheckbox}
              label='Produto ativo'
            />
          </Grid>

          <Grid item lg={4}>
            <Checkbox
              checkboxId='pro_bo_mostraresgotado'
              value={form?.pro_bo_mostraresgotado || ''}
              handleChange={onCheckbox}
              label='Exibir "Avise-me quando chegar"'
            />
          </Grid>

          {isInsider && (
            <Grid item lg={2}>
              <Checkbox
                checkboxId='pro_bo_express'
                value={form?.pro_bo_express || ''}
                handleChange={onCheckbox}
                label='Entrega Expressa'
              />
            </Grid>
          )}
        </Grid>
        {/* <Grid container lg={12} style={{ marginBottom: '1rem' }}>
          <Grid item lg={6}>
            <Select
              selectId='proSuplentes'
              size='small'
              label='Produtos Suplentes'
              options={proSuplentesOptions}
              handleChange={(e) =>
                handleChangeSelectTags(
                  e.target.value,
                  proSuplentesOptions,
                  'proSuplentesTags'
                )
              }
            />
            <div
              style={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              {infoStepData?.proSuplentesTags?.map((tag) => (
                <div style={{ marginTop: '5px' }}>
                  <Tag
                    onDelete={() =>
                      handleRemoveTag(tag.value, 'proSuplentesTags')
                    }
                  >
                    {tag.label}
                  </Tag>
                </div>
              ))}
            </div>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

InfoFields.propTypes = {
  form: PropTypes.object.isRequired,
  // politicas_comerciais: PropTypes.array.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onCheckbox: PropTypes.func.isRequired,
  // onAddTag: PropTypes.func.isRequired,
  // onSetForm: PropTypes.func.isRequired,
};

export default InfoFields;
