import styled from 'styled-components';

export const Title = styled.h1`
  color: #000000;
  font-size: 2.5rem;
  margin: 30px 0;
  text-transform: uppercase;
`;

export const ButtonContent = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CenterColumn = styled.td`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875em;
  text-align: center;
  color: #000000;
`;

export const Error = styled.p`
  color: tomato;
`;

export const Section = styled.section`
  margin-bottom: 30px;
`;
