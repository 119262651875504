import Dexie from 'dexie';

const db = new Dexie('sonoma-backoffice');

db.version(3).stores({
  wines:
    '++id, sku, tipos, pais, regiao, teorAlcoolico, temperatura, tempoDescanso, uvas, barrica, conteudo, curador, harmonizacoes, selos, caixa, pon_in_ws, pon_in_we, pon_in_gd, pon_in_pm, pon_in_jr2, pon_in_ad, pon_in_iwr, pon_in_tp, pon_in_uw, pon_in_gg, pon_in_ce, pon_in_jd, pon_in_js, pon_in_gp, pon_in_wes, pon_in_rp, pon_in_jr, pon_in_ak, pon_in_s, pon_in_ag, pon_in_ta, pon_in_v, pon_in_lm, pon_in_d, pon_in_st, pon_in_bh, pon_in_mf',
  gourmets:
    '++id, sku, tipos, pais, regiao, harmonizacoes, selos, curador, conteudo, ingredientes, informacoes_nutri, alergicos, armazenamento',
  cheeses:
    '++id, sku, tipos, pais, regiao, harmonizacoes, selos, conteudo, produtor, casca, massa, intensidade, variedade_leite, vegano, vegetariano, ingredientes, informacoes_nutri, alergicos, armazenamento',
  freight:
    '++id, comprimento, peso, altura, largura, preco_de, preco_por, preco_caixa, vendido_por, entregue_por',
  info: '++id, nome, ean, safra, url, tags, departmento, sku_seller, categoria_vitrine, categoria, descricao, sobre, historia, codigo_fiscal, perfil_imposto, classificacao_prod, origem_prod, score, exibir_site, ativo, exibir_avise, produtos_suplentes',
});

function clearDB() {
  db.wines.clear();
  db.gourmets.clear();
  db.cheeses.clear();
  db.freight.clear();
  db.info.clear();
}

export { db, clearDB };
