import { getUser, getUserRoles } from '../../auth/auth';
import { findAll } from '../../services/ProductService';
import { findAll as findAllFromSeller } from '../../services/SellerProductService';

export const setProducts = (products) => ({
  type: '@productsList/setProducts',
  payload: products,
});

export const setSeller = (seller) => ({
  type: '@productsList/setSeller',
  payload: seller,
});

export const setPaging = (paging) => ({
  type: '@productsList/setPaging',
  payload: paging,
});

export const setOrdering = (ordering) => ({
  type: '@productsList/setOrdering',
  payload: ordering,
});

export const setFilters = (filters) => ({
  type: '@productsList/setFilters',
  payload: filters,
});

export const setLoading = (loading) => ({
  type: '@productsList/setLoading',
  payload: loading,
});

export const setError = (error) => ({
  type: '@productsList/setError',
  payload: error,
});

export const clear = () => ({
  type: '@productsList/clear',
});

export function fetchProducts(sellerId, paging, ordering, filters) {
  const roles = getUserRoles();
  const user = getUser();

  const isSeller = roles.find((e) => e === 'SELLER');

  if (isSeller)
    return findAllFromSeller(
      user.fil_in_codigo || user.slr_in_codigo,
      paging,
      ordering,
      filters
    );

  // NOTE: if an admin is fetching products by seller, FOR NOW, we are only
  // considering FF Sellers, so these sellers will have an fil_in_codigo key
  // and also the admin, which means the URL will be correct. Otherwise, the
  // url will probably be wrong.
  if (sellerId) return findAllFromSeller(sellerId, paging, ordering, filters);

  return findAll(paging, ordering, filters);
}

export const fetchAll =
  (paging, ordering, filters, sellerId) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await fetchProducts(sellerId, paging, ordering, filters);

      return dispatch([
        setLoading(false),
        setProducts(response.data),
        setPaging(response._metadata.paging),
        setOrdering(response._metadata.ordering),
        setFilters(response._metadata.filters),
        setError(false),
      ]);
    } catch (e) {
      return dispatch([setLoading(false), setError(true)]);
    }
  };
