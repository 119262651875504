import { ParentDiv } from './styles';

export function LoadingSpinner() {
  return (
    <ParentDiv>
      <div />
      <div />
      <div />
      <div />
    </ParentDiv>
  );
}
