import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyBeAYbzNRAD_HlMb4jHSSLI8c-YlM8wJnQ',
  authDomain: 'sonoma-produtos.firebaseapp.com',
  projectId: 'sonoma-produtos',
  storageBucket: 'sonoma-produtos.appspot.com',
  messagingSenderId: '62199232073',
  appId: '1:62199232073:web:8a2aeb87f031090356f198',
  measurementId: 'G-K4GGCRWVXT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export { analytics, logEvent };
