import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Drawer, ClippedDrawer, Select } from 'sonoma-design-system';
import { useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { getUserRoles } from '../../auth/auth';

import { findAll as findAllFiliais } from '../../services/FilialService';
import { setSelectedFilialToFilter } from '../../store/selectedFilialToFilter';

import SonomaLogoCompleto from '../../assets/logo-completo.png';
import SonomaLogo from '../../assets/sonoma-logo.svg';

import { ShowSupportChat } from '../../components/SupportChat';

export function AuthenticatedDrawer({ menuItens }) {
  const dispatch = useDispatch();
  const userRoles = getUserRoles();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [filiais, setFiliais] = useState([]);
  const isMoreThan = useMediaQuery('(min-width:800px)');

  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  useEffect(() => {
    const userRolesToCheck = getUserRoles();

    async function fetchFiliais() {
      const filiaisData = await findAllFiliais();

      setFiliais(filiaisData.data);
    }

    if (
      userRolesToCheck.find(
        (e) => e === 'ADMIN' || e === 'INSIDER' || e === 'FINANCIAL'
      )
    ) {
      fetchFiliais();
    }
  }, []);

  function handleSelectedFilial(filial) {
    if (filial === 'Não Selecionado') {
      dispatch(setSelectedFilialToFilter(null));
    } else {
      const filialInfo = filiais.find(
        (e) => e.fil_in_codigo === parseInt(filial, 10)
      );

      dispatch(
        setSelectedFilialToFilter({
          fil_in_codigo: filialInfo.fil_in_codigo,
          fil_st_email: filialInfo.fil_st_email,
          fil_st_nome: filialInfo.fil_st_nome,
        })
      );
    }
  }

  return (
    <>
      <ClippedDrawer logo={SonomaLogoCompleto}>
        <div
          style={{
            height: '80px',
            maxWidth: '350px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {userRoles.find(
            (e) => e === 'ADMIN' || e === 'INSIDER' || e === 'FINANCIAL'
          ) && (
            <Select
              selectId='filiais'
              size='small'
              label='Filial'
              value={selectedFilial?.fil_in_codigo || null}
              options={[
                { value: null, label: 'Não Selecionado' },
                ...filiais.map((e) => ({
                  value: e.fil_in_codigo,
                  label: e.fil_st_apelido || e.fil_st_nome,
                })),
              ]}
              handleChange={(e) => {
                handleSelectedFilial(e.target.value);
              }}
            />
          )}
        </div>
      </ClippedDrawer>

      <Drawer
        variant={isMoreThan ? 'permanent' : 'temporary'}
        openState={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
        firstBrandLabel='Sonoma'
        secondBrandLabel='Sonoma Market'
        svgIcon={SonomaLogo}
        as={Link}
        menuItens={menuItens}
      />
      <ShowSupportChat />
    </>
  );
}

AuthenticatedDrawer.propTypes = {
  menuItens: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      Icon: PropTypes.func.isRequired,
    })
  ).isRequired,
};
