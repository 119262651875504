import PropTypes from 'prop-types';

import { FaCalendarCheck } from 'react-icons/fa';
import { Button, Container } from './styles';

export function CalendarSelect({ handleClick, from, to }) {
  return (
    <Container>
      <div style={{ width: '40%' }}>
        <Button
          role='button'
          tabIndex={0}
          aria-hidden='true'
          onClick={handleClick}
        >
          <FaCalendarCheck size='25px' />
          &nbsp;
          <button
            type='button'
            className='custom-input'
            style={{ all: 'unset' }}
          >
            de {from} até {to}
          </button>
        </Button>
      </div>
    </Container>
  );
}

CalendarSelect.propTypes = {
  handleClick: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
