import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'sonoma-design-system';

import { updateActivationProduct } from '../../../services/activeProductService';
import { fetchAll, setLoading } from '../../../store/productsList';

import { CenterColumn } from '../../../styles';

export default function CheckboxColumn({ item }) {
  const dispatch = useDispatch();

  const paging = useSelector((state) => state.product.list.paging);
  const ordering = useSelector((state) => state.product.list.ordering);
  const filters = useSelector((state) => state.product.list.filters);

  const selectedFilial = useSelector(
    (state) => state.selectedFilialToFilter.selectedFilial
  );

  const onCheckbox = async (e) => {
    dispatch(setLoading(true));

    await updateActivationProduct({
      pro_bo_ativo: e.target.checked,
      product: item,
      produtoId: item.pro_in_codigo,
    });

    dispatch(
      fetchAll(
        { page: paging.page, limit: paging.limit },
        ordering,
        filters,
        selectedFilial?.fil_in_codigo
      )
    );
  };

  if (!item.pro_bo_magentostatus) {
    return (
      <td>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          -
        </p>
      </td>
    );
  }

  return (
    <CenterColumn>
      <Checkbox
        checkboxId='pro_bo_mostraresgotado'
        value={item?.pro_bo_ativo || ''}
        handleChange={onCheckbox}
        label='Produto Visível'
      />
    </CenterColumn>
  );
}

CheckboxColumn.propTypes = {
  item: PropTypes.object.isRequired,
};
