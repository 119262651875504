import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Input } from 'sonoma-design-system';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { formatNumber, reverseFormatNumber } from '../../utils/number';

function PriceFields({ form, onSetForm, errorOnFill }) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 30,
      flex: 1,
    },
  }));

  const classes = useStyles();

  const validatePrice = useCallback((e) => {
    if (
      e.target.value &&
      formatNumber(e.target.value.replaceAll('R$', '')) < 10
    )
      toast.warn('Atenção! Você está inserindo um valor abaixo de R$10');
  }, []);

  return (
    <Grid spacing={2} container className={classes.root}>
      <Grid item xs={9} lg={9}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={3}>
            <Input
              isNumeric
              inputId='pro_de_precode'
              value={reverseFormatNumber(form?.pro_de_precode)}
              handleChange={(e) =>
                onSetForm({
                  pro_de_precode: formatNumber(
                    e.target.value.replaceAll('R$', '')
                  ),
                })
              }
              onBlur={validatePrice}
              prefix='R$'
              size='small'
              label='Preço DE'
              fullWidth
              helperText='Caso queria incluir uma condição no preço de produto adicionado uma % de desconto no valor final, preencha este campo com valor diferente do valor venda'
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              isNumeric
              error={errorOnFill && !form?.pro_de_precopor}
              errorText='Preço unitário'
              inputId='pro_de_precopor'
              value={reverseFormatNumber(form?.pro_de_precopor)}
              handleChange={(e) =>
                onSetForm({
                  pro_de_precopor: formatNumber(
                    e.target.value.replaceAll('R$', '')
                  ),
                })
              }
              onBlur={validatePrice}
              prefix='R$'
              size='small'
              label='Preço de venda'
              fullWidth
              required
              helperText='Preço unitário'
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Input
              isNumeric
              inputId='pro_de_precoatacado'
              value={reverseFormatNumber(form?.pro_de_precoatacado)}
              handleChange={(e) =>
                onSetForm({
                  pro_de_precoatacado: formatNumber(
                    e.target.value.replaceAll('R$', '')
                  ),
                })
              }
              onBlur={validatePrice}
              prefix='R$'
              size='small'
              label='Preço caixa'
              fullWidth
              helperText='Caso aceite as condições de venda em preço caixa (preço atacado) informe o valor final para exibir o preço ao cliente'
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Input
              isNumeric
              inputId='pro_de_take_produto'
              value={reverseFormatNumber(form?.pro_de_take_produto)}
              handleChange={(e) =>
                onSetForm({
                  pro_de_take_produto: formatNumber(e.target.value),
                })
              }
              suffix='%'
              size='small'
              label='Take do produto'
              fullWidth
              helperText='Take específico do produto (opcional)'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PriceFields.propTypes = {
  form: PropTypes.object.isRequired,
  onSetForm: PropTypes.func.isRequired,
  errorOnFill: PropTypes.bool,
};

PriceFields.defaultProps = {
  errorOnFill: false,
};

export default PriceFields;
