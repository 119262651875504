import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export function useHandleDrop() {
  const [files, setFiles] = useState(null);

  const handleDrop = useCallback(
    (filesDroped) => {
      if (filesDroped.length > 1) {
        toast.warning('Não é possivel cadastrar mais de um arquivo');
        return;
      }

      if (filesDroped[0].size > 10240000) {
        toast.warning(
          'Peso do arquivo inválido. Por favor, utilize arquivos menores ou iguais a 10mb'
        );
        return;
      }

      const permittedImagesExtensions = ['.zip'];

      for (let i = 0; i < filesDroped.length; i += 1) {
        if (
          permittedImagesExtensions.some((e) => filesDroped[i].path.includes(e))
        ) {
          setFiles([
            Object.assign(filesDroped[i], {
              preview: URL.createObjectURL(filesDroped[i]),
              index: uuidv4(),
            }),
          ]);
        } else {
          toast.warning(
            'Fomato inválido. Por favor, utilize apenas arquivos .zip'
          );
        }
      }
    },
    [setFiles]
  );

  return { handleDrop, files, setFiles };
}
