import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { BreadcrumbStepper, useStepper } from 'sonoma-design-system';

import { getUser, getUserRoles } from '../../auth/auth';

import QueijoForm from '../../components/QueijoForm';
import InformacoesForm from '../../components/InformacoesForm';
import FreteForm from '../../components/FreteForm';
import ImagensForm from '../../components/ImagensForm';

import { clearAll as clearCheese } from '../../store/queijoNew';
import { clearAll as clearInfo } from '../../store/informacoesForm';
import { clearAll as clearFreight } from '../../store/freteForm';
import { clearAll as clearImagens } from '../../store/imagensForm';
import store from '../../store';

import { create as createProduct } from '../../services/ProductService';
import { create as sellerCreateProduct } from '../../services/SellerProductService';

import { clearBlankKeysFromNested } from '../../utils/objects';

import { Main, Title } from './styles';
import { clearDB } from '../../config/db';

export default function CreateQueijo() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const { selected, next, previous } = useStepper();

  const isDeliveryBySeller =
    getUserRoles().includes('DELIVERY_BY_SELLER') || getUser().slr_in_codigo;

  const titles = useMemo(
    () => [
      'Produtos / Cadastro de Queijos / Especificações',
      'Produtos / Cadastro de Queijos / Informações',
      isDeliveryBySeller
        ? 'Produtos / Cadastro de Queijos / Frete e Venda'
        : 'Produtos / Cadastro de Queijos / Venda',
      'Produtos / Cadastro de Queijos / Imagens e Vídeo',
    ],
    [isDeliveryBySeller]
  );

  useEffect(
    () => () => {
      dispatch([clearCheese(), clearInfo(), clearFreight(), clearImagens()]);
      clearDB();
    },
    [dispatch]
  );

  const handleCreateProduct = useCallback(
    async (images) => {
      setLoading(true);

      const roles = getUserRoles();
      const user = getUser();
      const isSeller = roles.find((e) => e === 'SELLER');

      try {
        const { product } = store.getState();

        const productWithouBlank = clearBlankKeysFromNested(product.new);

        const {
          queijo: { form: cheeseData },
          frete: { form: freightData },
          informacoes: { form: infoData },
          imagens: { form: imagens },
        } = productWithouBlank;

        const {
          produtos_suplentes,
          pro_lt_sobre,
          pro_lt_historia,
          ...dataInfo
        } = infoData;

        const similares = Object.keys(produtos_suplentes).map((key) => ({
          pro_in_codigo: produtos_suplentes[key].child_pro_in_codigo,
          pro_st_sku: produtos_suplentes[key].children.pro_st_sku,
          pro_st_descricao: produtos_suplentes[key].children.pro_st_descricao,
        }));

        // main image path
        const main = images.find((img) => img.main)?.path;

        const {
          harmonizacoes,
          selos,
          tipos,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create, // País name in case of create new one
          reg_in_codigo,
          reg_name_to_create, // Regiao name in case of create new one
          pro_st_conteudo,

          ...data
        } = cheeseData;

        const dataToCreate = {
          ...freightData,
          ...dataInfo,

          pro_lt_sobre: pro_lt_sobre?.replaceAll('<p></p>', '</br>'),
          pro_lt_historia: pro_lt_historia?.replaceAll('<p></p>', '</br>'),

          product_type: 'queijo',

          similares,

          pro_st_sku,
          pai_in_codigo,
          pai_name_to_create,
          reg_in_codigo,
          reg_name_to_create,
          pro_st_conteudo,
          pro_st_nomesite: infoData.pro_st_titulopage,
          pro_st_palavraschave: infoData.pro_st_tags,
          pro_st_video: imagens?.pro_st_video,

          updatedBy: user.usu_in_codigo,

          tipos,
          harmonizacoes,
          selos,
          queijo: data,

          main,
        };

        const dataToCreateWithouBlank = clearBlankKeysFromNested(dataToCreate);

        if (isSeller) {
          await sellerCreateProduct(dataToCreateWithouBlank, images);
        } else {
          await createProduct(dataToCreateWithouBlank, images);
        }

        history.push('/produtos');
      } catch (err) {
        // handle error
        setLoading(false);
      }
    },
    [history]
  );

  return (
    <Main style={{ marginTop: '130px' }}>
      <Title>{titles[selected]}</Title>

      <BreadcrumbStepper
        selected={selected}
        steps={[
          {
            title: 'Especificações',
            component: () => <QueijoForm onNext={next} />,
          },
          {
            title: 'Informações',
            component: () => (
              <InformacoesForm onNext={next} onPrevious={previous} />
            ),
          },
          {
            title: isDeliveryBySeller ? 'Frete e Venda' : 'Venda',
            component: () => <FreteForm onNext={next} onPrevious={previous} />,
          },
          {
            title: 'Imagens e Vídeos',
            component: () => (
              <ImagensForm
                loading={isLoading}
                onPrevious={previous}
                onCreateProduct={handleCreateProduct}
              />
            ),
          },
        ]}
      />
    </Main>
  );
}
