import styled from 'styled-components';
import { Divider as MuiDivider } from '@material-ui/core';

export const Title = styled.h1`
  color: #000000;
  font-size: 2.5rem;
  margin: 30px 0;
  text-transform: uppercase;
`;

export const Divider = styled(MuiDivider)`
  margin: 15px 0;
`;
