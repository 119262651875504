import { Types } from './sellerInventory.action';

const INITIAL_STATE = {
  loading: false,
  error: false,
  products: [],
  paging: {
    page: 1,
    limit: 15,
    total: 0,
  },
  ordering: {
    order: 'desc',
    order_by: 'createdAt',
    availableOrderBy: [],
  },
  filters: {
    ref: '',
  },
};

const setLoading = (state, action) => ({ ...state, loading: action.payload });

const setError = (state, action) => ({ ...state, error: action.payload });

const setProducts = (state, action) => ({ ...state, products: action.payload });

const setPaging = (state, action) => ({
  ...state,
  paging: {
    ...state.paging,
    ...action.payload,
  },
});

const setOrdering = (state, action) => ({
  ...state,
  ordering: {
    ...state.ordering,
    ...action.payload,
  },
});

const setFilters = (state, action) => ({
  ...state,
  filters: {
    ...state.filters,
    ...action.payload,
  },
});

export default function billingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_LOADING:
      return setLoading(state, action);
    case Types.SET_ERROR:
      return setError(state, action);
    case Types.SET_PRODUCTS:
      return setProducts(state, action);
    case Types.SET_PAGING:
      return setPaging(state, action);
    case Types.SET_ORDERING:
      return setOrdering(state, action);
    case Types.SET_FILTERS:
      return setFilters(state, action);
    case Types.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
