import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { Input } from 'sonoma-design-system';
import PropTypes from 'prop-types';

import { setForm } from '../../store/informacoesForm';
import { setForm as setFormEdit } from '../../store/informacoesEdit';
import { normalizeToUrl } from '../../utils/strings';
import { getUserRoles } from '../../auth/auth';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  row: {
    marginBottom: 15,
  },
}));

function SkuAndDescriptionFields({
  publisher,
  errorSkuOnFill,
  skuForm,
  skuExist,
  onFormSkuChange,
  wine,
  isEdit,
  descricaoExist,
  fetchTaxData,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const infoForm = useSelector((state) =>
    isEdit
      ? state.product.edit.informacoes.form
      : state.product.new.informacoes.form
  );

  const infoErrorOnFill = useSelector((state) =>
    isEdit
      ? state.product.edit.informacoes.errorOnFill
      : state.product.new.informacoes.errorOnFill
  );

  const roles = getUserRoles();
  const isAdmin = roles.includes('ADMIN');
  const isDBS = roles.includes('DELIVERY_BY_SELLER');

  const onFormChange = useCallback(
    (e) => {
      dispatch(
        isEdit
          ? setFormEdit({ [e.target.name]: e.target.value })
          : setForm({ [e.target.name]: e.target.value })
      );
    },
    [dispatch, isEdit]
  );

  const onSetInfoForm = useCallback(
    (values) => {
      dispatch(isEdit ? setFormEdit({ ...values }) : setForm({ ...values }));
    },
    [dispatch, isEdit]
  );

  const onChangeDescription = useCallback(
    (e) => {
      onFormChange({
        ...e,
        target: {
          ...e.target,
          value: e.target.value.toUpperCase(),
          name: e.target.name,
        },
      });

      if (!isEdit) {
        let url = '';

        if (e.target.name === 'pro_st_descricao') {
          const safra = infoForm?.safra;

          url = `${normalizeToUrl(e.target.value.trim())}${
            safra ? `-${safra}` : ''
          }`;
        }

        if (e.target.name === 'safra') {
          const productDescription = infoForm?.pro_st_descricao;
          const safra = e.target.value;

          url = `${
            productDescription && normalizeToUrl(productDescription.trim())
          }${safra && `-${safra}`}`;
        }

        onSetInfoForm({ pro_st_url: url });
      }
    },
    [infoForm, isEdit, onFormChange, onSetInfoForm]
  );

  return (
    <>
      <Grid
        spacing={2}
        container
        className={classes.root}
        style={{ padding: '0 8px 20px 8px' }}
      >
        <Grid item xs={12} lg={wine ? 8 : 12}>
          <Input
            inputId='pro_st_descricao'
            error={
              (infoErrorOnFill && !infoForm?.pro_st_descricao) || descricaoExist
            }
            errorText={
              descricaoExist
                ? 'Descrição do produto já existe'
                : 'Descrição do produto'
            }
            value={infoForm?.pro_st_descricao || ''}
            disabled={isEdit && !isAdmin && !isDBS}
            handleChange={onChangeDescription}
            size='small'
            helperText='Descrição do produto'
            label='Nome do produto'
            fullWidth
            required
          />
        </Grid>

        {wine && (
          <Grid item xs={12} lg={4}>
            <Input
              type='number'
              inputId='safra'
              value={infoForm?.safra || ''}
              handleChange={onChangeDescription}
              size='small'
              label='Safra'
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      <Grid
        spacing={2}
        container
        className={classes.root}
        style={{ padding: '0 8px 20px 8px' }}
      >
        {!publisher && (
          <Grid item xs={12} lg={4}>
            <Input
              error={(errorSkuOnFill && !skuForm?.pro_st_sku) || skuExist}
              errorText={skuExist ? 'SKU ja existente' : 'Inserir número sku'}
              inputId='pro_st_sku'
              value={skuForm?.pro_st_sku || ''}
              handleChange={onFormSkuChange}
              onBlur={() => fetchTaxData(skuForm?.pro_st_sku)}
              size='small'
              helperText='Inserir número sku'
              label='SKU'
              fullWidth
              required
            />
          </Grid>
        )}

        <Grid item xs={12} lg={publisher ? 12 : 8}>
          <Input
            inputId='pro_st_titulopage'
            error={infoErrorOnFill && !infoForm?.pro_st_titulopage}
            errorText='Título exibido no site'
            value={infoForm?.pro_st_titulopage || ''}
            handleChange={onChangeDescription}
            size='small'
            helperText='Título exibido no site'
            label='Título do produto no site'
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </>
  );
}

SkuAndDescriptionFields.propTypes = {
  publisher: PropTypes.bool,
  errorSkuOnFill: PropTypes.bool,
  skuForm: PropTypes.object.isRequired,
  // check if sku exist
  // if exist don't go to the next step
  skuExist: PropTypes.bool,
  // check if descricao exist
  // if exist don't go to the next step
  descricaoExist: PropTypes.bool,
  // onChange for sku field
  onFormSkuChange: PropTypes.func.isRequired,
  // fetch tax data function on blur sku input
  fetchTaxData: PropTypes.func,
  // if its wine show field safra
  wine: PropTypes.bool,
  isEdit: PropTypes.bool,
};

SkuAndDescriptionFields.defaultProps = {
  publisher: false,
  errorSkuOnFill: false,
  skuExist: false,
  descricaoExist: false,
  wine: false,
  isEdit: false,
  fetchTaxData: null,
};

export default SkuAndDescriptionFields;
