import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const ButtonDescription = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
