import styled from 'styled-components';

export const Title = styled.h1`
  color: #3b3b3a;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;
  background-color: #fff;
`;

export const ImagesContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin: 0px 10px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;
