import { Redirect } from 'react-router-dom';

import { getToken, getUser } from '../../auth/auth';

import { Main } from './styles';

export default function Home() {
  const token = getToken();

  const user = token && getUser();

  const isAuthenticated = !!(token && user);

  if (!isAuthenticated) return <Redirect to='/login' />;

  return (
    <Main>
      <h1 style={{ marginTop: '130px' }}>Seja Bem-vindo</h1>
    </Main>
  );
}
