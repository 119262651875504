import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics, logEvent } from '../../config/firebase';
import { getToken, getUser } from '../../auth/auth';

const isAuthenticated = getToken();

function AnalyticPageView() {
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) return;

    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      const user = getUser();
      const page_path = location.pathname + location.search;

      logEvent(analytics, 'page_view', {
        page_path,
        usu_in_codigo: user.usu_in_codigo,
      });
    }
  }, [location]);

  return <></>;
}

export { AnalyticPageView };
