import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

export const LoadingSpinner = styled(CircularProgress)`
  color: #85b074;
  margin-right: 30px;
`;
