import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 500px;

  background: var(--background);
  border-radius: 4px;
  border: 4px dashed var(--text);

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

export const BoldText = styled.p`
  font-size: 1.3em;
  font-weight: 600;
  color: var(--text);
`;

export const SmallText = styled.small`
  font-size: 1em;
  color: var(--text);
`;

export const AlertContainer = styled.div`
  text-align: center;
`;

export const AlertSmall = styled.small`
  font-size: 0.85em;
  color: tomato;
  font-weight: 600;
`;
