import { createSelector } from 'reselect';

const aggregator = (state) => state.product.new.informacoes;

const taxProfiles = (state) => {
  const selected =
    aggregator(state).form.clf_in_codigo ||
    state.product.edit.informacoes.form.clf_in_codigo;

  if (selected) {
    return aggregator(state)
      .perfis_impostos.data?.filter((el) => el.clf_in_codigo === selected)
      .map((el) => ({
        value: el.pei_in_codigo,
        label: el.pei_st_descricao,
      }));
  }

  return aggregator(state).perfis_impostos.data?.map((el) => ({
    value: el.pei_in_codigo,
    label: el.pei_st_descricao,
  }));
};

const taxCodes = (state) =>
  aggregator(state).codigos_fiscais.data?.map((el) => ({
    value: el.clf_in_codigo,
    label: el.clf_st_descricao,
  }));

const showcaseCategories = (state) =>
  aggregator(state).categories_vitrine.data?.map((el) => ({
    value: el.vic_in_codigo,
    label: el.vic_st_descricao,
  }));

const productClassifications = (state) =>
  aggregator(state).classificacoes_produto.data?.map((el) => ({
    value: el.clp_in_codigo,
    label: el.clp_st_descricao,
  }));

const categories = (state) =>
  aggregator(state).categorias.data?.map((el) => ({
    value: el.cat_in_codigo,
    label: el.cat_st_descricao,
  }));

const departments = (state) =>
  aggregator(state).departamentos.data?.map((el) => ({
    value: el.dep_in_codigo,
    label: el.dep_st_descricao,
  }));

const tradePolicies = (state) =>
  aggregator(state).politicas_comerciais.data?.map((el) => ({
    value: el.poc_in_codigo,
    label: el.poc_st_descricao,
  }));

const productOrigins = (state) =>
  aggregator(state).origens_produto.data?.map((el) => ({
    value: el.ori_in_codigo,
    label: el.ori_st_descricao,
  }));

const isLoading = (state) =>
  aggregator(state).perfis_impostos.loading ||
  aggregator(state).codigos_fiscais.loading ||
  aggregator(state).categories_vitrine.loading ||
  aggregator(state).classificacoes_produto.loading ||
  aggregator(state).categorias.loading ||
  aggregator(state).departamentos.loading ||
  aggregator(state).politicas_comerciais.loading;

const isErrored = (state) =>
  aggregator(state).perfis_impostos.error ||
  aggregator(state).codigos_fiscais.error ||
  aggregator(state).categories_vitrine.error ||
  aggregator(state).classificacoes_produto.error ||
  aggregator(state).categorias.error ||
  aggregator(state).departamentos.error ||
  aggregator(state).politicas_comerciais.error;

export default () =>
  createSelector(
    taxProfiles,
    taxCodes,
    showcaseCategories,
    productClassifications,
    categories,
    departments,
    tradePolicies,
    productOrigins,
    isLoading,
    isErrored,
    (
      perfis_impostos,
      codigos_fiscais,
      categorias_vitrine,
      classificacoes_produto,
      categorias,
      departamentos,
      politicas_comerciais,
      origens_produto,
      loading,
      error
    ) => ({
      perfis_impostos,
      codigos_fiscais,
      categorias_vitrine,
      classificacoes_produto,
      categorias,
      departamentos,
      politicas_comerciais,
      origens_produto,
      loading,
      error,
    })
  );
