import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const Section = styled.section`
  background: #fff;
  padding: 15px;
`;

export const InputContainer = styled.div`
  width: 250px;
`;

export const ProductCol = styled.td`
  padding: 7px 0px;
`;

export const ProductDescription = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75em;

  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
`;

export const CenterColumn = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
  text-align: center;
`;

export const ModalBody = styled.div`
  width: 800px;
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 800px;
  overflow-y: scroll;
`;
