const INITIAL_STATE = {
  loading: false,
  error: false,
  user: null,
};

const setLoading = (state, action) => ({
  ...state,
  loading: action.payload,
});

const setError = (state, action) => ({
  ...state,
  error: action.payload,
});

const setUser = (state, action) => ({
  ...state,
  user: action.payload,
});

export default function userEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@userEdit/setLoading':
      return setLoading(state, action);
    case '@userEdit/setError':
      return setError(state, action);
    case '@userEdit/setUser':
      return setUser(state, action);
    case '@userEdit/clear':
      return INITIAL_STATE;
    default:
      return state;
  }
}
