import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { Input, Button } from 'sonoma-design-system';
import { MdAdd, MdRemove } from 'react-icons/md';

import { DatePickerComponent } from '../DatePicker';
import { ControlsContainer, EventTitle } from './styles';

export function TrackingEvents({
  events,
  setEvents,
  onAddNewEvent,
  onRemoveEvent,
}) {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const setValue = useCallback(
    (index, key, value) => {
      setEvents(
        events.map((event, i) => {
          if (i === index) {
            return { ...event, [key]: value };
          }

          return event;
        })
      );
    },
    [events, setEvents]
  );

  return events.map((event, index) => (
    <Grid spacing={2} container className={classes.root}>
      <EventTitle>Evento {index + 1}</EventTitle>

      <Grid item xs={12} lg={12}>
        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={6}>
            <p>Cidade</p>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Input
              inputId={`city-${index}`}
              size='small'
              label='Cidade'
              fullWidth
              value={event.city}
              handleChange={(e) => setValue(index, 'city', e.target.value)}
              required
            />
          </Grid>
        </Grid>

        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={6}>
            <p>Estado</p>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Input
              inputId={`state-${index}`}
              size='small'
              label='Estado'
              fullWidth
              value={event.state}
              handleChange={(e) => setValue(index, 'state', e.target.value)}
              required
            />
          </Grid>
        </Grid>

        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={6}>
            <p>Descrição</p>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Input
              inputId={`description-${index}`}
              size='small'
              label='Descrição'
              fullWidth
              value={event.description}
              handleChange={(e) =>
                setValue(index, 'description', e.target.value)
              }
              required
            />
          </Grid>
        </Grid>

        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={6}>
            <p>Data</p>
          </Grid>

          <Grid item xs={12} lg={6}>
            <DatePickerComponent
              value={event.date}
              setValue={(value) => setValue(index, 'date', value)}
            />
          </Grid>
        </Grid>

        <Grid spacing={2} container className={classes.row}>
          <Grid item xs={12} lg={12}>
            <ControlsContainer>
              {index > 0 ? (
                <Button
                  onClick={() => onRemoveEvent(index)}
                  backgroundColor='#e53535'
                >
                  <MdRemove />
                </Button>
              ) : (
                <div />
              )}

              {index === events.length - 1 && (
                <Button onClick={onAddNewEvent} backgroundColor='#9CD186'>
                  <MdAdd />
                </Button>
              )}
            </ControlsContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ));
}

TrackingEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Date).isRequired,
    })
  ).isRequired,
  setEvents: PropTypes.func.isRequired,
  onAddNewEvent: PropTypes.func.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
};
