const year = new Date().getFullYear();

export const directories = [
  {
    value: `dezembro-${year - 1}`,
    label: `Dezembro - ${year - 1}`,
  },
  {
    value: `janeiro-${year}`,
    label: `Janeiro - ${year}`,
  },
  {
    value: `fevereiro-${year}`,
    label: `Fevereiro - ${year}`,
  },
  {
    value: `março-${year}`,
    label: `Março - ${year}`,
  },
  {
    value: `abril-${year}`,
    label: `Abril - ${year}`,
  },
  {
    value: `maio-${year}`,
    label: `Maio - ${year}`,
  },
  {
    value: `junho-${year}`,
    label: `Junho - ${year}`,
  },
  {
    value: `julho-${year}`,
    label: `Julho - ${year}`,
  },
  {
    value: `agosto-${year}`,
    label: `Agosto - ${year}`,
  },
  {
    value: `setembro-${year}`,
    label: `Setembro - ${year}`,
  },
  {
    value: `outubro-${year}`,
    label: `Outubro - ${year}`,
  },
  {
    value: `novembro-${year}`,
    label: `Novembro - ${year}`,
  },
  {
    value: `dezembro-${year}`,
    label: `Dezembro - ${year}`,
  },
];
