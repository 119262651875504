import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';
import { Input, Button } from 'sonoma-design-system';
import { endOfMonth } from 'date-fns';

import FieldsetTitle from '../FieldsetTitle';
import Divider from '../Divider';

import { formatNumber, reverseFormatNumber } from '../../utils/number';
import { DatePickerComponent } from '../DatePicker';

import { Error } from '../../styles';
import { Footer, Section, InputContainer, Label } from './styles';

export default function PromoForm({ onSubmit, onCancel, product }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const [price, setPrice] = useState(null);
  const [specialPrice, setSpecialPrice] = useState(null);
  const [boxPrice, setBoxPrice] = useState(null);
  const [productTake, setProductTake] = useState(null);

  const [errors, setErrors] = useState({});

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    row: {
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const clearForm = useCallback(() => {
    setPrice(product?.pro_de_precopor || 0);
    setStartDate(new Date());
    setEndDate(endOfMonth(new Date()));
  }, [product]);

  const handleSubmit = useCallback(() => {
    // validation
    let newErrors = {};

    if (!price && !specialPrice && !boxPrice) {
      newErrors = {
        ...newErrors,
        specialPrice:
          '"Preço de" OU "Preço por" OU "Preço caixa" deve ter valor.',
        price: '"Preço de" OU "Preço por" OU "Preço caixa" deve ter valor.',
        boxPrice: '"Preço de" OU "Preço por" OU "Preço caixa" deve ter valor.',
      };
    }

    if (!startDate) {
      newErrors = {
        ...newErrors,
        date: 'A data de início da promoção é necessária',
      };
    }

    if (new Date(endDate) < new Date()) {
      newErrors = {
        ...newErrors,
        date: 'O período informado é inválido',
      };
    }

    if (!endDate) {
      newErrors = {
        ...newErrors,
        date: 'A data final da promoção é necessária',
      };
    }

    if (price >= product.pro_de_precode) {
      newErrors = {
        ...newErrors,
        price:
          'O preço promocional deve ser inferior ao preço atual do produto',
      };
    }

    if (specialPrice >= product.pro_de_precopor) {
      newErrors = {
        ...newErrors,
        specialPrice:
          'O preço promocional deve ser inferior ao preço atual do produto',
      };
    }

    if (
      product.pro_de_precoatacado &&
      boxPrice >= product.pro_de_precoatacado
    ) {
      newErrors = {
        ...newErrors,
        boxPrice:
          'O preço promocional deve ser inferior ao preço atual do produto',
      };
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    clearForm();

    onSubmit({
      from: startDate,
      to: endDate,
      price: price > 0 ? price : null,
      specialPrice: specialPrice > 0 ? specialPrice : null,
      boxPrice: boxPrice > 0 ? boxPrice : null,
      productTake: productTake,
    });
  }, [
    onSubmit,
    startDate,
    endDate,
    price,
    specialPrice,
    boxPrice,
    product,
    clearForm,
    productTake,
  ]);

  return (
    <Section>
      <FieldsetTitle>Nova Promoção</FieldsetTitle>

      <p>
        Preencha somente os campos que devem ser alterados durante a promoção.
        Os demais, podem ser deixados em branco
      </p>

      <br />

      <Grid spacing={2} container className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={4}>
              <Label>Data início *</Label>

              <InputContainer>
                <DatePickerComponent
                  value={startDate}
                  setValue={setStartDate}
                  showTimeSelect
                />
              </InputContainer>

              {errors.date && <Error>{errors.date}</Error>}
            </Grid>

            <Grid item xs={12} lg={4}>
              <Label>Data final *</Label>

              <InputContainer>
                <DatePickerComponent
                  value={endDate}
                  setValue={setEndDate}
                  showTimeSelect
                />
              </InputContainer>

              {errors.date && <Error>{errors.date}</Error>}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container className={classes.row}>
            <Grid item xs={12} lg={4}>
              <Label>Preço de</Label>

              <InputContainer>
                <Input
                  isNumeric
                  error={errors.price}
                  errorText={errors.price}
                  inputId='price'
                  value={reverseFormatNumber(price)}
                  handleChange={(e) =>
                    setPrice(formatNumber(e.target.value.replaceAll('R$', '')))
                  }
                  prefix='R$'
                  size='small'
                  label='Preço de'
                  fullWidth
                />
              </InputContainer>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Label>Preço por</Label>

              <InputContainer>
                <Input
                  isNumeric
                  error={errors.specialPrice}
                  errorText={errors.specialPrice}
                  inputId='specialPrice'
                  value={reverseFormatNumber(specialPrice)}
                  handleChange={(e) =>
                    setSpecialPrice(
                      formatNumber(e.target.value.replaceAll('R$', ''))
                    )
                  }
                  prefix='R$'
                  size='small'
                  label='Preço por'
                  fullWidth
                />
              </InputContainer>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Label>Preço caixa</Label>

              <InputContainer>
                <Input
                  isNumeric
                  error={errors.boxPrice}
                  errorText={errors.boxPrice}
                  inputId='boxPrice'
                  value={reverseFormatNumber(boxPrice)}
                  handleChange={(e) =>
                    setBoxPrice(
                      formatNumber(e.target.value.replaceAll('R$', ''))
                    )
                  }
                  prefix='R$'
                  size='small'
                  label='Preço caixa'
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Label>Take do produto para essa promoção</Label>

              <InputContainer>
                <Input
                  isNumeric
                  inputId='product_take'
                  value={reverseFormatNumber(productTake)}
                  handleChange={(e) =>
                    setProductTake(formatNumber(e.target.value), e.target.value)
                  }
                  suffix='%'
                  size='small'
                  label='Take do produto'
                  fullWidth
                  helperText='Take específico do produto nessa promoção (opcional)'
                />
              </InputContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: '20px' }} />

      <Footer>
        <Button outlined onClick={onCancel}>
          CANCELAR
        </Button>

        <Button onClick={handleSubmit}>SALVAR</Button>
      </Footer>
    </Section>
  );
}

PromoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  product: PropTypes.object.isRequired,
};
